import reducerCreator from '../../../helpers/reducerCreator';

import helper from 'helpers/steinerHelper';
import { actionTypes } from '../actions/calendarioAttivita';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            dataInizio: '',
            dataFine: '',
            utente: '',
        },
    },
});

// export function WithUser(DEFAULT_STATE);

// const handlers = helper.createHandlers(actionTypes);

const handlers = helper.createHandlers(actionTypes, {
    items: 'data.data',
    idKey: 'id',
    total: 'data.meta.total',
    per_page: null,
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('calendarioAttivita');
