import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

// import helper from 'helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';
import { generateRoutes } from '../../../helpers/hotelModuleHelper';
import { selectors } from '../reducers/offerte';

const routes = generateRoutes('offerte', selectors, {
    itemLabelKey: 'titoli.it'
});

const categoriePath = '/hotel/:hotelId/categorieOfferte';

routes.list.categorie = {
    hotelFilter: true,
    path: categoriePath,
    componentPath: 'containers/CategorieOfferteLoader',
    breadcrumb: 'Categorie offerte'
};

routes.patterns.categorie = categoriePath;

const offerteSortPath = '/hotel/:hotelId/offerteSort';

routes.list.sort = {
    hotelFilter: true,
    path: offerteSortPath,
    componentPath: 'containers/OfferteSortLoader',
    breadcrumb: 'Ordina offerte'
};

routes.patterns.sort = offerteSortPath;

// routes.patterns = {
//     create: '/hotel/:hotelId/offerte/create',
//     edit: '/hotel/:hotelId/offerte/:id',
//     list: '/hotel/:hotelId/offerte'
// };

// routes.list.edit.pattern = routes.patterns.edit;
// routes.list.list.pattern = routes.patterns.list;

const list = _.values(routes.list).map(route => ({
    ...route,
    hotelFilter: true,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'offerte' */ `modules/offerte/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('offerte', routes.patterns);
routeRegister.addLinks('offerte', links);

export default list;
