import _ from 'lodash';
import { createSelector } from 'reselect';

import { reducerCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import { getCurrentContractYear } from '../../../helpers/date';
import { getOrderTotal } from '../helpers/helpers';

import { actionTypes } from '../actions/venduto';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        comparison_period_items: [],
        comparison_year_items: [],
        filters: {
            article_id: null,
            article_year: `${getCurrentContractYear()}`,
            from_date: null,
            to_date: null,
            hotel_id: null,
            perPage: null,
            page: null,
            produttore: null,
            row_hotel_id: null,
            macro_id: null,
            compare_with_last_year: false
        }
    }
});

const handlers = helper.createHandlers(actionTypes, {
    items: 'data.data',
    total: 'data.meta.total',
    idKey: 'hotel_id'
});

handlers[actionTypes.SET_COMPARISON_PERIOD_DATA] = function(state, action) {
    return state.setIn(['list', 'comparison_period_items'], action.payload);
};

handlers[actionTypes.SET_COMPARISON_YEAR_DATA] = function(state, action) {
    return state.setIn(['list', 'comparison_year_items'], action.payload);
};

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

const defaultSelectors = reducerCreator.createSelectors('venduto');

const comparisonYearSelector = state =>
    state.venduto.list.comparison_year_items;
const comparisonPeriodSelector = state =>
    state.venduto.list.comparison_period_items;
const articleIdSelector = state => state.venduto.list.filters.article_id;

const itemsSelectorProper = createSelector(
    defaultSelectors.itemsSelector,
    comparisonYearSelector,
    articleIdSelector,
    (items, comparison_items, article_id) => {
        if (comparison_items.length === 0) {
            return items.map(i => {
                return {
                    ...i,
                    total_prev: 0,
                    list_total_prev: 0,
                    diff: 0,
                    new: true
                };
            });
        }

        // console.warn(items, comparison_items);

        // TODO: integrare i dati di comparazione
        return items.map(i => {
            const prev = _.find(comparison_items, { hotel_id: i.hotel_id });

            const total_article_prev = prev
                ? getOrderTotal(
                      [...prev.orders],
                      'article_total',
                      true,
                      `${article_id}`
                  )
                : 0;

            const total_article_list_prev = prev
                ? getOrderTotal(
                      [...prev.orders],
                      'article_price_list',
                      true,
                      `${article_id}`
                  )
                : 0;

            return {
                ...i,
                total_article_prev,
                total_article_list_prev,
                total_prev: prev ? prev.total : 0,
                list_total_prev: prev ? prev.price_list_total : 0,
                diff: prev ? prev.total - prev.price_list_total : 0,
                new: !prev
            };
        });
    }
);

export const selectors = Object.assign({}, defaultSelectors, {
    comparisonYearSelector,
    comparisonPeriodSelector,
    itemsIdSelector: state => state.venduto.list.itemsId,
    itemsSelector: itemsSelectorProper
});
