// Shim per react@16
import 'core-js/es6/map';
import 'core-js/es6/set';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Immutable from 'seamless-immutable';
import viviMessages from '@adrias-online/vivi/lib/messages/it';
import steinerMessages from '@adrias-online/steiner/lib/messages/it';
// import Flatpickr from 'flatpickr';
// import { it } from 'flatpickr/dist/l10n/it';

import App from './App';
import configureStore from './store/configureStore';
import rootSaga from './sagas';
import AuthHelper from 'helpers/auth';
import './styles/index.css';
import history from './apphistory';

// import registerServiceWorker from './registerServiceWorker';
require('promise.prototype.finally').shim();

// it.rangeSeparator = ' a ';
// Flatpickr.localize(it);

export const store = configureStore({
    router: Immutable({
        current: {
            location: history.location,
            action: history.action,
        },
        previous: {
            location: null,
            action: null,
        },
    }),
    settings: Immutable({
        language: 'it',
        translations: steinerMessages,
    }),
});

store.runSaga(rootSaga);

new AuthHelper(store);

const translations = {
    ...viviMessages,
    steiner: steinerMessages.components,
};

ReactDOM.render(
    <Provider store={store}>
        <App translations={translations} />
    </Provider>,
    document.getElementById('root')
);

// registerServiceWorker();

// import whyDidYouUpdate from 'why-did-you-update';

// whyDidYouUpdate(React, { include: /^StatisticheLayout/, exclude: /^Connect/ });
