import { sagaCreator } from '@adrias-online/steiner/lib/helpers';

import api from '../apis/ordini';

import helper from '../../../helpers/steinerHelper';
import { actions, actionTypes } from '../actions/ordini';
import { selectors, DEFAULT_STATE } from '../reducers/ordini';

const sagas = helper.createSagas(
    'ordini',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE,
    {
        idKey: 'order_id'
    }
);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [...defaultSagas];
