import { reducerCreator } from '@adrias-online/steiner';
import _ from 'lodash';
import { createSelector } from 'reselect';

import helper from 'helpers/steinerHelper';
import { actionTypes } from '../actions/sitePhoto';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            perPage: 999
        }
    }
});

const handlers = helper.createHandlers(actionTypes);
handlers[actionTypes.addPhoto] = function(state, action) {
    return state
        .update('list', list => {
            return {
                ...list,
                itemsById: {
                    ...list.itemsById,
                    [action.payload.id]: action.payload
                },
                total: list.total + 1
            };
        })
        .updateIn(['list', 'itemsId'], items => {
            const ids = items.asMutable();

            ids.push(action.payload.id);

            return ids;
        });
};

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

const defaultSelectors = reducerCreator.createSelectors('sitePhoto');

const idsSelector = state => state.sitePhoto.list.itemsId;
const itemsSelector = state => state.sitePhoto.list.itemsById;

const orderedItemsSelector = createSelector(
    idsSelector,
    itemsSelector,
    (ids, items) => _.sortBy(ids.map(id => items[id]), 'file_name')
);

export const selectors = Object.assign({}, defaultSelectors, {
    itemsSelector: orderedItemsSelector
    // itemsSelector: state => _.sortBy(state['sitePhoto'].list.itemsId.map(id => state['sitePhoto'].list.itemsById[id]), 'file_name')
});
