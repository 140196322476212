import { reducerCreator } from '@adrias-online/steiner';
// import { createSelector } from 'reselect';
// import Immutable from 'seamless-immutable';

import helper from 'helpers/steinerHelper';
import { createHandlers } from 'helpers/hotelModuleHelper';
// import { selectors as currentHotelSelectors } from 'reducers/currentHotel';
import { actionTypes } from '../actions/offerte';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            hotelId: null,
            expiration: 'actual'
        }
    }
});

const handlers = createHandlers(actionTypes);

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('offerte');

// const filtersSelector = selectors.getFilters;
// const hotelIdSelector = currentHotelSelectors.getCurrentHotelId;

// const finalFiltersSelector = createSelector(
//     filtersSelector,
//     hotelIdSelector,
//     (filters, hotelId) => {
//         return Immutable({
//             ...filters,
//             hotelId
//         });
//     }
// );

// selectors.getFilters = finalFiltersSelector;
