import React, { Component } from 'react';
import HotelWidgetLink from 'components/HotelWidgetLink';

export default class WidgetMiniAmministrazione extends Component {

    render() {
        return (
            <div className="widget">
                <h2 className="pb-2">Amministrazione</h2>
                <div className="row">
                    <div className="col-12">
                        <HotelWidgetLink to="/dipendenti" icon="fas fa-people-group">
                            Visualizza i colleghi presenti
                        </HotelWidgetLink>
                    </div>
                    <div className="col-12">
                        <HotelWidgetLink to="/ordini" icon="fas fa-handshake">
                            Consulta gli Ordini
                        </HotelWidgetLink>
                    </div>
                </div>
            </div>
        );
    }
}
