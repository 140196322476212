import { routeCreator } from '@adrias-online/steiner/lib/helpers';
import _ from 'lodash';

import routeRegister from '../../../helpers/routeRegister';
import helper from '../../../helpers/steinerHelper';
import { selectors } from '../reducers/mailTemplateAssoc';

const routes = helper.generateRoutes('mailTemplateAssoc', selectors);
const list = _.values(routes.list).map((route) => ({
    ...route,
    requiredRoles: ['admin'],
    getComponent() {
        return new Promise((resolve) => {
            import(
                /* webpackChunkName: 'mailTemplateAssoc' */ `modules/mailTemplateAssoc/${route.componentPath}`
            ).then((response) => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('mailTemplateAssoc', routes.patterns);
routeRegister.addLinks('mailTemplateAssoc', links);

export default list;
