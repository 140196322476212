import React, { Component } from 'react';
import HotelWidgetLink from 'components/HotelWidgetLink';

export default class WidgetMiniCommerciali extends Component {
    createInitialFormValues(item) {
        return item;
    }

    render() {
        return (
            <div className="widget">
                <h2 className="pb-2">Commerciali</h2>
                <div className="row">
                    <div className="col-12">
                        <HotelWidgetLink
                            to="/statVenditaCommerciale"
                            icon="fas fa-chart-bar"
                        >
                            Reports
                        </HotelWidgetLink>
                    </div>
                    <div className="col-12">
                        <HotelWidgetLink
                            to="/crmContatti"
                            icon="fas fa-address-book"
                        >
                            Rubrica clienti
                        </HotelWidgetLink>
                    </div>
                </div>
            </div>
        );
    }
}
