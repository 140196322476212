import helper from 'helpers/steinerHelper';
import apiClient from 'apis/api';

const apis = helper.createApi('Produttori', apiClient, {
    top: 'perPage',
    page: 'page',
    filter: 'q',
    orderBy: 'orderKey',
    orderdir: 'orderDirection'
});

export default apis;
 