import reducerCreator from '@adrias-online/steiner/lib/helpers/reducerCreator';
import Immutable from 'seamless-immutable';

import { actionTypes } from '../actions/sidebar';

export const DEFAULT_STATE = Immutable({
    isGestionaleOpened: true,
    isAmministrazioneOpened: false,
    isStrumentiOpened: false,
    isClientiOpened: true,
    isGestioneHotelOpened: true,
    isGestionePortaliOpened: false,
    isStatisticheOpened: true,
    isStatistichePortaliOpened: false,
    isPortaliOpened: false,
    isCMSOpened: false,
    selectHotelOpen: false,
    selectPortaleOpen: false
});

function setSidebarStatus(state, action) {
    if (action.payload) {
        return Immutable(action.payload);
    }

    return DEFAULT_STATE;
}

const handlers = {
    [actionTypes.setSidebarStatus]: setSidebarStatus
};

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = {
    getCurrentSidebarStatus(state) {
        return state.sidebarStatus;
    }
};
