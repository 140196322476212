import { createApi } from 'helpers/hotelModuleHelper';
import client from 'apis/api';

const apis = createApi('Listino', client, {
    top: 'perPage',
    page: 'page',
    filter: 'q',
    orderby: 'orderKey',
    orderdir: 'orderDirection'
});

apis.create = function(data) {
    return client({
        url: 'Listino',
        method: 'post',
        data
    });
};

// const rows = {
//     "1151": [
//         {
//             "idCamera": 1151,
//             "dal": "19\/05\/2019",
//             "al": "01\/06\/2019",
//             "ordinamento": 1,
//             "idOfferta": 119035,
//             "prezzi": {
//                 "12466": {
//                     "prezzo": "330",
//                     "sconto": "",
//                 },
//                 "12467": {
//                     "prezzo": "0",
//                     "sconto": "",
//                 }
//             }
//         },
//         {
//             "idCamera": 1151,
//             "dal": "02\/06\/2019",
//             "al": "08\/04\/2019",
//             "ordinamento": 2,
//             "prezzi": {
//                 "12466": {
//                     "prezzo": "345",
//                     "sconto": "",
//                 },
//                 "12467": {
//                     "prezzo": "0",
//                     "sconto": "",
//                 }
//             }
//         },
//         {
//             "idCamera": 1151,
//             "dal": "09\/06\/2019",
//             "al": "15\/06\/2019",
//             "ordinamento": 3,
//             "prezzi": {
//                 "12466": {
//                     "prezzo": "450",
//                     "sconto": "",
//                 },
//                 "12467": {
//                     "prezzo": "0",
//                     "sconto": "",
//                 }
//             }
//         },
//         {
//             "idCamera": 1151,
//             "dal": "16\/06\/2019",
//             "al": "22\/06\/2019",
//             "ordinamento": 4,
//             "prezzi": {
//                 "12466": {
//                     "prezzo": "500",
//                     "sconto": "",
//                 },
//                 "12467": {
//                     "prezzo": "0",
//                     "sconto": "",
//                 }
//             }
//         },
//         {
//             "idCamera": 1151,
//             "dal": "23\/06\/2019",
//             "al": "29\/06\/2019",
//             "ordinamento": 5,
//             "prezzi": {
//                 "12466": {
//                     "prezzo": "550",
//                     "sconto": "",
//                 },
//                 "12467": {
//                     "prezzo": "0",
//                     "sconto": "",
//                 }
//             }
//         }
//     ]
// };

// apis.fetch = function (id) {
//     return new Promise(resolve => {
//         client({
//             url: `/Listino/${id}`
//         }).then(res => {
//             res.data.rows = rows;

//             resolve(res);
//         });
//     })
// }

export default apis;
