import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/statVenditaCommerciale';
import helper from 'helpers/steinerHelper';

const routes = helper.generateRoutes('statVenditaCommerciale', selectors);

routes.list.list.breadcrumb = 'Report vendite';

const list = _.values(routes.list).map(route => ({
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'statVenditaCommerciale' */ `modules/statVenditaCommerciale/${route.componentPath}`)
                .then(response => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('statVenditaCommerciale', routes.patterns);
routeRegister.addLinks('statVenditaCommerciale', links);

export default list;