import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/utenti';

const routes = helper.generateRoutes('utenti', selectors);

const patternPermessi = '/utenti/:id/permessi';

routes.patterns.permessi = patternPermessi;

routes.list.contratti = {
    breadcrumb: 'Permessi',
    componentPath: 'containers/UtentiLoader',
    path: patternPermessi
};

routes.list.edit.exact = true;

const list = _.values(routes.list).map(route => ({
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'utenti' */ `modules/utenti/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('utenti', routes.patterns);
routeRegister.addLinks('utenti', links);

export default list;
