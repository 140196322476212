import _ from 'lodash';

import {
    all,
    put,
    call,
    cancel,
    cancelled,
    select,
    fork,
    takeEvery
} from 'redux-saga/effects';
import { delay } from 'redux-saga';

import queryString from 'query-string';
import { isBefore, endOfMonth, format } from 'date-fns';

import routing from '@adrias-online/steiner/lib/routing';

import { dataKeys, mappers } from '../helpers';
import { isAdmin, roles } from '../../../helpers/auth';

import { actions, actionTypes } from '../actions/statistiche';
import {
    selectors,
    DEFAULT_STATE
} from '../../../modules/statistiche/reducers/statistiche';
import { selectors as currentHotelSelectors } from '../../../reducers/currentHotel';
import api from '../apis/statistiche';
import hotelApi from '../../../modules/hotel/apis/hotel';
import {
    /*getCurrentCalendarYear,*/ getCurrentContractYear
} from '../../../helpers/date';
import { getUser } from '../../../helpers/auth/reducer';

export function getSectionForSourceAndUser(source, user) {
    const base = ['overview', 'interest', 'summary', 'contract'];

    switch (source) {
        case 1:
        case 2: {
            let keys = [].concat(base, 'adults', 'children', 'nights');

            if (isAdmin(user) || user.role === roles.developer) {
                keys = [].concat(keys, 'mediumPerc');

                if (parseInt(source, 10) === 1) {
                    // eslint-disable-line eqeqeq
                    keys = [].concat(keys, 'network', 'referral');
                }
            }

            if (parseInt(source, 10) === 2) {
                const index = keys.indexOf('contract');
                keys.splice(index, 1);
            }

            return keys;
        }
        case 3: {
            let keys = [].concat(base, 'referral');

            if (isAdmin(user) || user.role === roles.developer) {
                keys = [].concat(keys, 'mediumPerc');
            }

            return keys;
        }
        // no default
    }
}

const dataMappers = {
    interest: mappers.interestMapper,
    mediumNumber: mappers.mediumMapper,
    mediumPerc: mappers.mediumMapper,
    overview: mappers.overviewMapper,
    summary: data => data,
    adults: mappers.adultsMapper,
    children: mappers.childrenMapper,
    nights: mappers.nightsMapper,
    network: mappers.networkMapper,
    referral: mappers.referralMapper
};

export function* fetchData(key, apiCall, filters) {
    try {
        const response = yield call(apiCall, filters);

        yield put(actions.setData(key, dataMappers[key](response.data)));
    } catch (error) {
        // TODO: ottenere se possibile un messaggio di errore piu rilevante
        yield put(actions.setError(key, error.message, error));
    } finally {
        yield put(actions.stopLoading(key));
    }
}

function* fetchDataWithWeather(key, apiCall, filters) {
    try {
        // console.warn(currentHotel);
        const [response, weatherData] = yield all([
            call(apiCall, filters),
            call(fetchWeatherData)
        ]);

        yield put(
            actions.setData(key, dataMappers[key](response.data, weatherData))
        );
    } catch (error) {
        // TODO: ottenere se possibile un messaggio di errore piu rilevante
        yield put(actions.setError(key, error.message, error));
    } finally {
        if (yield cancelled()) {
            //
        } else {
            yield put(actions.stopLoading(key));
        }
    }
}

function* fetchInterestData(filters) {
    const key = 'interest';

    const year = format(filters.endDate, 'YYYY');

    const interestFilters = filters.merge({
        startDate: `${year}-01-01`,
        endDate: `${year}-12-31`
    });

    try {
        const response = yield call(api[key], interestFilters);

        yield put(actions.setData(key, dataMappers[key](response.data)));
    } catch (error) {
        // TODO: ottenere se possibile un messaggio di errore piu rilevante
        yield put(actions.setError(key, error.message, error));
    } finally {
        yield put(actions.stopLoading(key));
    }
}

function* fetchContractData(filters) {
    const key = 'contract';

    try {
        let contractYear = getCurrentContractYear();

        let response = yield call(
            hotelApi.fetchContratti,
            filters.hotelId,
            contractYear
        );

        const limit = endOfMonth(new Date(contractYear, 1));

        // Se il contratto per l'anno contrattuale (che parte dal 1 ottobre é vuoto e mi trovo prima del 28 febbraio allora prendo il contratto dell'anno precedente)
        if (response.data.data.length === 0 && isBefore(new Date(), limit)) {
            contractYear = contractYear - 1;
            response = yield call(
                hotelApi.fetchContratti,
                filters.hotelId,
                contractYear
            );
        }

        yield put(actions.setContractYear(contractYear));
        yield put(
            actions.setData(key, mappers.contractMapper(response.data.data))
        );
    } catch (error) {
        yield put(actions.setError(key, error.message, error));
    } finally {
        yield put(actions.stopLoading(key));
    }
}

function* fetchWeatherData() {
    for (let i = 0; i < 3; i++) {
        const filters = yield select(selectors.getFilters);

        try {
            const currentHotel = yield select(
                currentHotelSelectors.getCurrentHotel
            );

            const response = yield call(
                api.weather,
                currentHotel.comuneId,
                filters
            );

            return response.data;
        } catch (error) {
            if (i < 2) {
                yield call(delay, 1000);
            } else {
                // TODO: lanciando l'errore non mostro totalmente il grafico
                // throw error;
            }
        }
    }
}

let overviewFetchTask;

export function* fetchStatistiche() {
    try {
        const filters = yield select(selectors.getFilters);

        if (!filters.hotelId) {
            for (let i = 0; i < dataKeys.length; i++) {
                const key = dataKeys[i];
                yield put(actions.stopLoading(key));
                // TODO: impostare un errore globale da mostrare al posto dei grafici?
                yield put(
                    actions.setError(
                        key,
                        'Selezionare un hotel per procedere!',
                        {}
                    )
                );
            }
        } else {
            const user = yield select(getUser);
            const requestKeys = getSectionForSourceAndUser(
                filters.sourceId,
                user
            );

            yield put(actions.resetAll());

            for (let i = 0; i < requestKeys.length; i++) {
                const key = requestKeys[i];
                const apiCall = api[key];
                if (key === 'overview') {
                    if (overviewFetchTask && overviewFetchTask.isRunning()) {
                        yield cancel(overviewFetchTask);
                    }
                    overviewFetchTask = yield fork(
                        fetchDataWithWeather,
                        key,
                        apiCall,
                        filters
                    );
                } else if (key === 'contract') {
                    yield fork(fetchContractData, filters);
                } else if (key === 'interest') {
                    yield fork(fetchInterestData, filters);
                } else {
                    yield fork(fetchData, key, apiCall, filters);
                }
            }
        }
    } catch (error) {
        console.error(error);
    }
}

function getDiff(src, matchers) {
    return _.omitBy(src, (v, k) => matchers[k] == v); // eslint-disable-line eqeqeq
}

function sameKeys(objA, objB) {
    // TODO: non sono molto sicuro che xor sia corretto...
    return _.isEmpty(_.xor(_.keys(objA), _.keys(objB)));
}

function* setFilters() {
    const filters = yield select(selectors.getFilters);

    const defaultFilters = DEFAULT_STATE.filters;

    // console.warn(filters);
    // console.warn(defaultFilters);

    const diff = getDiff(filters.asMutable(), defaultFilters.asMutable());

    const current = queryString.parse(window.location.search);

    if (!_.isEmpty(getDiff(diff, current)) || !sameKeys(diff, current)) {
        const location = {
            pathname: '/statistiche',
            search: `?${queryString.stringify(diff)}`,
            query: diff
        };

        yield put(routing.actions.navigate(location, 'PUSH'));
    }
}

function* syncFilters(action) {
    const numberParams = ['hotelId', 'sourceId'];
    const filters = {};

    _.forOwn(action.payload, (value, key) => {
        filters[key] = _.includes(numberParams, key)
            ? parseInt(value, 10)
            : value;
    });

    yield put(actions.setFilters(filters));
}

function* resetFilters() {
    // TODO: mantenere idhotel?
    const location = {
        pathname: '/statistiche',
        search: null,
        query: null
    };

    yield put(routing.actions.navigate(location, 'PUSH'));
}

export default [
    takeEvery(actionTypes.refreshAll, fetchStatistiche),
    takeEvery(actionTypes.resetFilters, resetFilters),
    takeEvery(actionTypes.setFilters, setFilters),
    takeEvery(actionTypes.syncFilters, syncFilters)
];
