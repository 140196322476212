import { reducerCreator } from '@adrias-online/steiner';

import helper from '../../../helpers/steinerHelper';
import { createHandlers } from '../../../helpers/portaleModuleHelper';
import { actionTypes } from '../actions/portaliConsigliati';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            comune_id: null,
            perPage: 100000
        }
    }
});

const handlers = createHandlers(actionTypes, {
    items: 'data.actual',
    total: 'data.actual.length',
    searchKeys: [
        'id',
        'hotel_name',
        'comune',
        'regione',
        'anno_contratto',
        'comune_reccomended'
    ],
    additionalFilters: {
        comune_id: {
            field: 'comune_id',
            op: '$eq'
        }
    }
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('portaliConsigliati');
