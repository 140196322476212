import helper from '../../../helpers/steinerHelper';
import client from 'apis/apiv2';

const paramsMap = {
    limit: 'perPage',
    page: 'page',
    hotel_id: 'hotelId',
    site_id: 'siteId',
    template_id: 'q'
};

const apis = helper.createApi('TemplateMail', client, paramsMap);

apis.create = (data) => {
    return client({
        url: `/Site/${data.site_id}/TemplateMail`,
        method: 'post',
        data
    });
}

export default apis;