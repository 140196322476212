import { sagaCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import { actions, actionTypes } from '../actions/statVenditaCommerciale';
import { selectors, DEFAULT_STATE } from '../reducers/statVenditaCommerciale';
import api from '../apis/statVenditaCommerciale';

const sagas = helper.createSagas('statVenditaCommerciale', actionTypes, actions, api, selectors, DEFAULT_STATE);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [
    ...defaultSagas
];