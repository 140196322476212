import { createSagas, bootSagas } from 'helpers/hotelModuleHelper';

import { actions, actionTypes } from '../actions/trattamenti';
import { selectors, DEFAULT_STATE } from '../reducers/trattamenti';
import api from '../apis/trattamenti';

const sagas = createSagas(
    'trattamenti',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE
);

const defaultSagas = bootSagas(sagas, actionTypes);

export default [...defaultSagas];
