import helper from 'helpers/steinerHelper';
import client from '../../../apis/apiProfiling';

const paramsMap = {
    titolo: 'q',
    per_page: 'perPage',
    page: 'page',
    sort_field: 'orderKey',
    sort_direction: 'orderDirection',
    label: 'label',
    categoria: 'categoria',
};

const apis = helper.createApi('criteriQualitativi', client, paramsMap);

apis.aggiornaCriterioQualitativo = (criterioQualitativoId, data) => {
    return client({
        url: `criteriQualitativi/${criterioQualitativoId}`,
        method: 'patch',
        data: data,
    });
};

export default apis;
