import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/form/v1/Tooltip';
import {Link} from 'react-router-dom';
import PortaleFilterLink from './PortaleFilterLink';

export const TableShowToPrint = ({ to, tooltip, dataId }) => (
    <Tooltip content={tooltip}>
        <Link className="btn btn-link" data-id={dataId} to={to}>
            <i className="fas fa-file" aria-hidden="true" />
        </Link>
    </Tooltip>
);

TableShowToPrint.propTypes = {
    to: PropTypes.string,
    tooltip: PropTypes.string,
    dataId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export const TableEditButton = ({ to, tooltip, dataId, dataCy }) => (
    <Tooltip content={tooltip}>
        <Link
            className="btn btn-link"
            data-id={dataId}
            data-cy={dataCy}
            to={to}
        >
            <i className="fas fa-pencil" />
        </Link>
    </Tooltip>
);

TableEditButton.propTypes = {
    to: PropTypes.string,
    tooltip: PropTypes.string,
};

TableEditButton.defaultProps = {
    tooltip: 'Modifica',
};

export const TableDeleteButton = ({ onClick, dataId, dataCy }) => (
    <Tooltip content="Elimina">
        <button
            className="btn btn-link"
            data-id={dataId}
            data-cy={dataCy}
            onClick={onClick}
        >
            <i className="fas fa-trash-alt" />
        </button>
    </Tooltip>
);

TableDeleteButton.propTypes = {
    onClick: PropTypes.func,
};


export const IconButton = ({ onClick, children,  icon, to }) => (
    <div className="widget-link">
        <Link
            to={to}
            onClick={onClick}
            className="d-flex align-items-center">
            <i className={`${icon} widget-icon`} aria-hidden="true" />{' '}
            {children}
        </Link>
    </div>
);

IconButton.propTypes = {
    onClick: PropTypes.func,
    icon: PropTypes.string,
    to: PropTypes.string
};



export const TableCreateButton = ({ to, label, dataId, dataCy, className }) => (
    // <Tooltip content={label ? label : `Crea`}>
        <Link
            className={`btn btn-crea ${className ? className : ''}`}
            data-id={dataId}
            data-cy={dataCy}
            to={to}
        >
            <i className="fas fa-plus me-2" /> {label ? label : 'Crea'}
        </Link>
    // </Tooltip>
);

TableCreateButton.propTypes = {
    label: PropTypes.string,
    to: PropTypes.string,
};

export const TablePhotoButton = ({ to }) => (
    <Tooltip content="Carica foto">
        <PortaleFilterLink classes="btn btn-sm btn-info" to={to}>
            Carica Foto <i className="fas fa-camera" aria-hidden="true" />
        </PortaleFilterLink>
    </Tooltip>
);

TablePhotoButton.propTypes = {
    to: PropTypes.string,
};

export const GoBackButton = ({ position, to, tooltip }) => (
    <Tooltip content={tooltip} position={position}>
        <Link className="btn btn-link" to={to}>
            <i aria-hidden="true" className="fas fa-backward" />
        </Link>
    </Tooltip>
);

GoBackButton.propTypes = {
    position: PropTypes.string,
    to: PropTypes.string,
    tooltip: PropTypes.string,
};

GoBackButton.defaultProps = {
    position: 'top',
    tooltip: 'Torna alla pagina precendete',
};
