import axios from 'axios';
import client from 'apis/security';

export function login(data) {
    return axios.post(window.__RUNTIME_CONFIG__.REACT_APP_LOGIN_URL, data);
}

export function recoverPassword(email) {
    return client({
        url: '/management/user/forgot-password',
        method: 'post',
        data: {
            email
        }
    });
}

export function resetPassword(token, data) {
    return client({
        url: `/management/user/reset-password?token=${token}`,
        method: 'post',
        data
    });
}

export function changePassword(id, data) {
    return client({
        url: `/management/user/${id}/change-password`,
        method: 'patch',
        data
    });
}

export function editPersonalData(id, data) {
    return client({
        url: `/management/user/personalData/${id}`,
        method: 'patch',
        data
    });
}
