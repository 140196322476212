import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/articoli';

const routes = helper.generateRoutes('articoli', selectors, {
    omit: ['create', 'edit']
});

const list = _.values(routes.list).map(route => ({
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'articoli' */ `modules/articoli/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const patterns = _.omit(routes.patterns, ['edit', 'create']);

const links = routeCreator.generateLinks(patterns);

routeRegister.addPatterns('articoli', patterns);
routeRegister.addLinks('articoli', links);

export default list;
