import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SidebarMenuItem extends Component {
    render() {
        const { title, description, icon, link, onClick } = this.props;

        return (
            <Link to={link} onClick={onClick}>
                <div className="sideber-menu-item">
                    <h4>{title}</h4>
                    {description}{' '}
                    <i className={`${icon} ms-3 text-tertiary`}></i>
                </div>
            </Link>
        );
    }
}

export default SidebarMenuItem;
