import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import routeRegister from '../../../helpers/routeRegister';
import { generateRoutes } from '../../../helpers/hotelModuleHelper';

import { selectors } from '../reducers/camere';

const routes = generateRoutes('camere', selectors, {
    itemLabelKey: 'names.it'
});

const associazionePath = '/hotel/:hotelId/associazioneCamereServizi';

routes.list.associazione = {
    hotelFilter: true,
    path: associazionePath,
    componentPath: 'containers/AssociazioniCamereServiziLoader',
    breadcrumb: 'Associazione camere e servizi'
};

routes.patterns.associazione = associazionePath;

const list = _.values(routes.list).map(route => ({
    ...route,
    hotelFilter: true,
    getComponent() {
        return new Promise((resolve) => {
            import(/* webpackChunkName: 'camere' */ `modules/camere/${
                route.componentPath
                }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('camere', routes.patterns);
routeRegister.addLinks('camere', links);

export default list;
