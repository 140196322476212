import helper from 'helpers/steinerHelper';
import client from 'apis/apiMarketingSuite';

const paramsMap = {
    titolo: 'q',
    per_page: 'perPage',
    page: 'page',
    sort_field: 'orderKey',
    sort_direction: 'orderDirection',
    ao_hotel_id: 'ao_hotel_id',
};

const apis = helper.createApi('pagine', client, paramsMap);

apis.modificaPagina = (paginaId, nome, url) => {
    return client({
        url: `pagine/${paginaId}`,
        method: 'patch',
        data: { nome: nome, url: url }
    });
};

apis.creaPagina = (nome, hotel, url) => {
    return client({
        url: 'pagine',
        method: 'post',
        data: { nome: nome, hotel: hotel, url: url }
    });
};

apis.fetchPagine = (hotelId) => {
    return client({
        url: `pagine?ao_hotel_id=${hotelId}`,
        method: 'get',
    });
};

export default apis;