import { sagaCreator } from '@adrias-online/steiner';

// import helper from 'helpers/steinerHelper';
import { createSagas } from 'helpers/siteModuleHelper';
import { actions, actionTypes } from '../actions/sitePage';
import { selectors, DEFAULT_STATE } from '../reducers/sitePage';
import api from '../apis/sitePage';

const sagas = createSagas(
    'sitePage',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE
);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [...defaultSagas];
