import { reducerCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import { actionTypes } from '../actions/macros';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            page: 1,
            per_page: 20,
            with_submacros: null
        }
    }
});

const handlers = helper.createHandlers(actionTypes, {
    items: 'data.data',
    total: 'data.meta.total'
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('macros');
