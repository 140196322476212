import { sagaCreator } from '@adrias-online/steiner';
import { put, call, takeEvery } from 'redux-saga/effects';

import helper from 'helpers/steinerHelper';
import { actions, actionTypes } from '../actions/ruoli';
import { selectors, DEFAULT_STATE } from '../reducers/ruoli';
import api from '../apis/ruoli';

const sagas = helper.createSagas(
    'ruoli',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE
);

sagas.updatePermissions = function*(action) {
    try {
        const response = yield call(
            api.updatePermissions,
            action.payload.id,
            action.payload.role_permissions.map(p => p.id)
        );

        const notification = {
            title: 'Successo!',
            message: 'Permessi aggiornati con successo!',
            status: 'success'
        };

        yield put(actions.updatePermissionSuccess(response, notification));
    } catch (error) {
        const notification = {
            title: 'Errore!',
            message:
                "Si è verificato un errore durante l'aggiornamento dei permessi!",
            status: 'error'
        };

        const errorPayload = {
            message: 'Si è verificato un errore inaspettato'
        };

        yield put(actions.updatePermissionFail(errorPayload, notification));
    }
};

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [
    ...defaultSagas,
    takeEvery(actionTypes.updatePermission, sagas.updatePermissions)
];
