import React, { Component } from 'react';
import HotelWidgetLink from 'components/HotelWidgetLink';

export default class WidgetGestioneCookie extends Component {
    render() {
        return (
            <div className="widget">
                <h2>Cookie</h2>
                <div className="row">
                    <div className="col-md-4">
                        <HotelWidgetLink
                            icon="fas fa-user-tie"
                            to="/clienti"
                        >
                            Clienti
                        </HotelWidgetLink>
                    </div>
                    <div className="col-md-4">
                        <HotelWidgetLink
                            icon="fas fa-browser"
                            to="/sitiClienti"
                        >
                            Siti clienti
                        </HotelWidgetLink>
                    </div>
                    <div className="col-md-4">
                        <HotelWidgetLink
                            icon="fas fa-cookie-bite"
                            to="/cookieConfig"
                        >
                            Configurazioni cookie
                        </HotelWidgetLink>
                    </div>
                    <div className="col-md-4">
                        <HotelWidgetLink
                            icon="fas fa-cookie"
                            to="/cookies"
                        >
                            Lista cookie
                        </HotelWidgetLink>
                    </div>
                </div>
            </div>
        );
    }
}