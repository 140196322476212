import apiClient from '../../../apis/apiWebReputation';

import { createApi } from 'helpers/webReputationModuleHelper';

const paramsMap = {
    raccolta_da: 'raccolta_da',
    raccolta_fino_a: 'raccolta_fino_a',
    canale: 'canale',
    punteggio_min: 'punteggio',
    punteggio_max: 'punteggio',
    page: 'page',
    per_page: 'perPage',
    titolo: 'q',
};

const apis = createApi('recensione/struttura', apiClient, paramsMap);

apis.infoRecensioni = (id) => {
    return apiClient({
        url: `recensione/${id}`,
        method: 'GET',
    });
};

apis.datiDashboard = (idHotel, raccolta_da, raccolta_fino_a) => {
    return apiClient({
        url: `struttura/dashboard/${idHotel}?raccolta_da=${raccolta_da}&raccolta_fino_a=${raccolta_fino_a}`,
        method: 'GET',
    });
};

apis.getGraficoRecensioni = (idHotel, raccolta_da) => {
    return apiClient({
        url: `struttura/numeroRecensioni/${idHotel}?raccolta_da=${raccolta_da}&intervallo_temporale=mese`,
        method: 'GET',
    });
};



export default apis;
