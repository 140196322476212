import { sagaCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import { actions, actionTypes } from '../actions/obbVenditaAnnuali';
import { selectors, DEFAULT_STATE } from '../reducers/obbVenditaAnnuali';
import api from '../apis/obbVenditaAnnuali';

const sagas = helper.createSagas(
    'obbVenditaAnnuali', 
    actionTypes, 
    actions, 
    api, 
    selectors, 
    DEFAULT_STATE
);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [ ...defaultSagas]; 