import { reducerCreator } from '@adrias-online/steiner';
import Immutable from 'seamless-immutable';
import auth from '@adrias-online/steiner/lib/auth';

import { actionTypes } from '../actions/currentHotel';

export const DEFAULT_STATE = Immutable({
    value: null,
    label: null,
    currentHotel: null
});

function setHotel(state, action) {
    if (action.payload) {
        return Immutable(action.payload);
    }

    return DEFAULT_STATE;
}

function handleLogin(state, action) {
    if (
        action.payload.userAssociations &&
        action.payload.userAssociations.hotel_id
    ) {
        return Immutable({
            value: action.payload.userAssociations.hotel_id,
            label: action.payload.name,
            comuneId: null
        });
    }

    return state;
}

const handlers = {
    [actionTypes.setHotel]: setHotel,
    [actionTypes.syncHotel]: setHotel,
    [auth.actionTypes.loginSuccess]: handleLogin,
    [auth.actionTypes.logoutSuccess]: () => DEFAULT_STATE
};

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = {
    getCurrentHotel(state) {
        return state.currentHotel;
    },
    getCurrentHotelId(state) {
        return state.currentHotel.value;
    }
};
