import _ from 'lodash';
import {routeCreator} from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';
import {selectors} from '../reducers/commerciali';

const routes = helper.generateRoutes('commerciali', selectors);
/*
const patternLavorazioni = '/commerciali/lavorazioni/:id';

routes.patterns.lavorazioni = patternLavorazioni;

routes.list.lavorazioni = {
    breadcrumb: 'Incarichi',
    componentPath: 'containers/IncarichiLoader',
    path: patternLavorazioni,
};*/

const list = _.values(routes.list).map(route => ({
    requiredRoles: ['admin', 'developer', 'employee', 'amministrazione'],
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'commerciali' */ `modules/commerciali/${route.componentPath}`)
                .then(response => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('commerciali', routes.patterns);
routeRegister.addLinks('commerciali', links);

export default list;