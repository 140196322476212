import { sagaCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { actions, actionTypes } from '../actions/interazioniPagine';
import { selectors, DEFAULT_STATE } from '../reducers/interazioniPagine';
import api from '../apis/interazioniPagine';

const sagas = helper.createSagas(
    'interazioniPagine',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE
);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [...defaultSagas];
