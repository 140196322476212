import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import helper from 'helpers/steinerHelper';
import routeRegister from 'helpers/routeRegister';
import { selectors } from '../reducers/interazioniLayout';

const routes = helper.generateRoutes('interazioniLayout', selectors);

const list = _.values(routes.list).map((route) => ({
    ...route,
    getComponent() {
        return new Promise((resolve) => {
            import(
                /* webpackChunkName: 'interazioniLayout' */ `modules/interazioniLayout/${route.componentPath}`
            ).then((response) => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('interazioniLayout', routes.patterns);
routeRegister.addLinks('interazioniLayout', links);

export default list;
