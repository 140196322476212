import { reducerCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import { actionTypes } from '../actions/obbVenditaAnnuali';
import { getCurrentContractYear } from '../../../helpers/date';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            seller_id: null,
            year: getCurrentContractYear() + '',
            id: null
        }
    }
});

const handlers = helper.createHandlers(actionTypes, {
    items: 'data.data',
    total: 'data.meta.total'
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('obbVenditaAnnuali');