import axios from 'axios';

const { REACT_APP_SHERLOCK } = window.__RUNTIME_CONFIG__;

const sherlock = axios.create({
    baseURL: REACT_APP_SHERLOCK,
    responseType: 'json'
});

export default sherlock;
