import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ProgressBar from '@ramonak/react-progress-bar';
import api from '../../modules/WebReputationKeywords/apis/WebReputationKeywords';

let WidgetWebReputation = (props) => {
    const [recensione, setRecensione] = useState('');

    useEffect(() => {
        api.datiDashboard(props.currentHotel.value, null, null).then((res) => {
            setRecensione(res.data);
        });
    }, []);
  let bar = '';
  let numeroRec = '' ;
  let valutazioneRec = '' ;
    if (recensione !== '') {
        numeroRec = recensione.numero_recensioni;
        valutazioneRec = (Math.round(recensione.punteggio * 10) / 10);
        bar = (
            <>
                <div className="row py-1">
                    <div className="col-5" style={{fontSize: '0.9rem'}}>Posizione</div>
                    <div className="col-7 m-auto">
                        <ProgressBar
                            maxCompleted={5}
                            completed={
                                typeof recensione.valutazioni.location ===
                                'number'
                                    ? recensione.valutazioni.location
                                    : 0
                            }
                            isLabelVisible={false}
                            animateOnRender={true}
                            transitionDuration={'1.5s'}
                            height={'6px'}
                            bgColor={'#59B28F'}
                        />
                    </div>
                </div>
                <div className="row py-1">
                    <div className="col-5" style={{fontSize: '0.9rem'}}>Pulizia</div>
                    <div className="col-7 m-auto">
                        <ProgressBar
                            maxCompleted={5}
                            completed={
                                typeof recensione.valutazioni.cleanliness ===
                                'number'
                                    ? recensione.valutazioni.cleanliness
                                    : 0
                            }
                            isLabelVisible={false}
                            animateOnRender={true}
                            transitionDuration={'1.5s'}
                            height={'6px'}
                            bgColor={'#59B28F'}
                        />
                    </div>
                </div>
                <div className="row py-1">
                    <div className="col-5" style={{fontSize: '0.9rem'}}>Servizio</div>
                    <div className="col-7 m-auto">
                        <ProgressBar
                            maxCompleted={5}
                            completed={
                                typeof recensione.valutazioni.amenities ===
                                'number'
                                    ? recensione.valutazioni.amenities
                                    : 0
                            }
                            isLabelVisible={false}
                            animateOnRender={true}
                            transitionDuration={'1.5s'}
                            height={'6px'}
                            bgColor={'#59B28F'}
                        />
                    </div>
                </div>
                <div className="row py-1">
                    <div className="col-5" style={{fontSize: '0.9rem'}}>Staff</div>
                    <div className="col-7 m-auto">
                        <ProgressBar
                            maxCompleted={5}
                            completed={
                                typeof recensione.valutazioni.staff === 'number'
                                    ? recensione.valutazioni.staff
                                    : 0
                            }
                            isLabelVisible={false}
                            animateOnRender={true}
                            transitionDuration={'1.5s'}
                            height={'6px'}
                            bgColor={'#59B28F'}
                        />
                    </div>
                </div>
                <div className="row py-1">
                    <div className="col-5" style={{fontSize: '0.9rem'}}>Comfort</div>
                    <div className="col-7 m-auto">
                        <ProgressBar
                            maxCompleted={5}
                            completed={
                                typeof recensione.valutazioni.comfort ===
                                'number'
                                    ? recensione.valutazioni.comfort
                                    : 0
                            }
                            isLabelVisible={false}
                            animateOnRender={true}
                            transitionDuration={'1.5s'}
                            height={'6px'}
                            bgColor={'#59B28F'}
                        />
                    </div>
                </div>{' '}
            </>
        );
    }
    return (
        <div className="widget" style={{height:'360px'}}>
            <h2 className="text-center py-3">Web Reputation Analysis</h2>
            <div className="row" style={{ background: '#f4f4f4' }}>
                <div className="col-md-6 py-3 ps-4">{bar}</div>
                <div className="col-md-6 d-flex m-auto px-5">
                    <div
                        className="p-1 text-white fw-bold m-1"
                        style={{
                            background: '#59B28F',
                            fontSize: '1.5rem',
                            borderRadius: '8px 0 8px 0',
                        }}
                    >
                        {valutazioneRec}
                    </div>
                    <div className="d-block px-3">
                        <div className="fw-bold" style={{ fontSize: '1.2rem' }}>
                            Fantastico
                        </div>
                        <div>{numeroRec} recensioni</div>
                    </div>
                </div>
            </div>
            <div className="w-100 py-3 text-center px-5">
                <Link to={`/hotel/${props.currentHotel.value}/webReputationkeywords`} className={'btn btn-tertiary text-center'}>
                    Le tue recensioni
                </Link>
            </div>
        </div>
    );
};

export default withRouter(WidgetWebReputation);
