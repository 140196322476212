import { call, put, takeEvery } from 'redux-saga/effects';

import { actions, actionTypes } from '../actions/systemNotifications';
import api from 'modules/notification/apis/notification';

function* refreshUnreadCount() {
    try {
        const res = yield call(api.list, { unseen: true });

        if (Object.keys(res.data).length !== 0) {
            yield put(actions.setUnreadNotifications(res.data.results.length));
        } else {
            yield put(actions.setUnreadNotifications(0));
        }
    } catch (err) {
        console.error(err);
    }
}

export default [takeEvery(actionTypes.refreshUnreadCount, refreshUnreadCount)];
