// import moment from 'moment';
import getTime from 'date-fns/get_time';
import addWeeks from 'date-fns/add_weeks';

const HOTEL_STORE = 'adriasonline_area_riservata_hotels';

class HotelStore {
    constructor() {
        this.hotels = JSON.parse(localStorage.getItem(HOTEL_STORE)) || {};
        this.now = getTime(new Date());
    }

    get(hotelId) {
        const hotel = this.hotels[hotelId];

        if (!hotel) {
            return null;
        }

        if (this.now > hotel.expires) {
            return null;
        }

        return hotel;
    }

    set(hotelId, data) {
        const expireDate = addWeeks(new Date(), 1);

        this.hotels[hotelId] = {
            ...data,
            expires: getTime(expireDate)
        };

        localStorage.setItem(HOTEL_STORE, JSON.stringify(this.hotels));
    }
}

export default new HotelStore();
