import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import WidgetClienteRichieste from 'components/widget/WidgetClienteRichieste';
import WidgetClienteHoteldoor from 'components/widget/WidgetClienteHoteldoor';
import WidgetClienteOfferteV2 from 'components/widget/WidgetClienteOfferteV2';
import WidgetGestioneHotel from 'components/widget/WidgetGestioneHotel';
import WidgetClienteAmministrazione from 'components/widget/WidgetClienteAmministrazione';
import WidgetClientePosta from 'components/widget/WidgetClientePosta';
import WidgetSelectGestione from 'components/widget/WidgetSelectGestione';
import WidgetMediaGallery from 'components/widget/WidgetMediaGallery';
import WidgetWebReputation from 'components/widget/WidgetWebReputation';
import MediaGalleryCarousel from 'components/MediaGalleryCarousel';
import SelectHotelV2 from '../SelectHotelV2';
import {roles} from 'helpers/auth';
import WithUser from '../WithUser';
import {Steps} from 'intro.js-react';
import {getCurrentContractYear} from 'helpers/date';
import apiContratti from '../../modules/hotel/apis/hotel';

import 'intro.js/introjs.css';
import Cookies from 'js-cookie';

const introSteps = [
    {
        element: '.Sidebar__navbar-toggle',
        intro: '<h6>Ti diamo il benvenuto nella nuova Area Riservata!</h6><p>Dai un’occhiata al Menu per scoprire tutte le funzioni e iniziare a gestire il tuo hotel</p>',
    },
    {
        element: '.row.dati-cliente',
        intro: '<h6>I dati più importanti subito in evidenza</h6><p>La tua dashboard personalizzata ti accoglie con i numeri più importanti e aggiornati in tempo reale</p>',
    },
    {
        element: '.row.sito-cliente',
        intro: '<h6>Il tuo sito sempre aggiornato</h6><p>Basta un click per modificare un’offerta, il listino o i servizi</p>',
    },
];

const DashboardCliente = (props) => {
    const { currentHotel, userRole } = props;

    const [stepsEnabled, setStepsEnabled] = useState(true);
    const [initialStep] = useState(0);
    const [steps] = useState(introSteps);
    const [magnewsDbId, setMagnewsDbId] = useState(currentHotel.magnewsDb);
    const [isWebReputation, setIsWebReputation] = useState(false);
    const [isMediagallery, setIsMediagallery] = useState(false);

    useEffect(() => {
        const clientContrattoCheck = async () => {
            const response = await apiContratti.fetchContratti(
                props.userHotelId,
                getCurrentContractYear()
            );
            const contrattiData = await response.data;

            setIsWebReputation(
                contrattiData.data.some((el) => el.article_id === 'INTERINF2')
            );
            setIsMediagallery(
                contrattiData.data.some((el) => el.article_id === 'INTERINF1')
            );
        };

        clientContrattoCheck();
    }, []);

    const introCompleted = () => {
        Cookies.set('ao_tour_completed', '1', { expires: 730 });
    };

    const isUser = userRole === roles.user;
    const isMultihotel = userRole === roles.multihotel;

    const checkTourCompleted = () => {
        if (Cookies.get('ao_tour_completed') === '1') {
            return true;
        }
        return false;
    };

    if (currentHotel != null) {
        useEffect(() => {
            if (currentHotel.magnewsDb) {
                setMagnewsDbId(currentHotel.magnewsDb);
            }
        }, [currentHotel]);
    }

    let pageView = sessionStorage.getItem('carousel_WR');
    pageView = JSON.parse(pageView);

    return (
        <>
            {isMultihotel && (
                <WidgetSelectGestione title="Gestisci Hotel">
                    <SelectHotelV2 setMagnewsDbId={setMagnewsDbId} />
                </WidgetSelectGestione>
            )}
            {isUser && (
                <div className="row dati-cliente">
                    {!checkTourCompleted() && (
                        <Steps
                            enabled={stepsEnabled}
                            steps={steps}
                            initialStep={initialStep}
                            onExit={() => {
                                introCompleted();
                                setStepsEnabled(false);
                            }}
                            onComplete={() => introCompleted()}
                            options={{
                                showStepNumbers: true,
                                showBullets: false,
                                exitOnOverlayClick: false,
                                doneLabel: 'Completato',
                                nextLabel: 'Avanti',
                                prevLabel: 'Indietro',
                                hideNext: false,
                                showProgress: true,
                                disableInteraction: true,
                                tooltipPosition: 'right',
                            }}
                        />
                    )}
                    <div className="col-md-4">
                        <WidgetClienteRichieste
                            userHotelId={currentHotel.value}
                        />
                    </div>
                    <div className="col-md-4">
                        <WidgetClienteOfferteV2
                            userHotelId={currentHotel.value}
                        />
                    </div>
                    <div className="col-md-4">
                        <WidgetClienteHoteldoor
                            userHotelId={currentHotel.value}
                        />
                    </div>
                </div>
            )}

            <div className="row pb-3">
                {!isWebReputation && !isMediagallery && !pageView && (
                    <div className="col-12 widget p-0">
                        <MediaGalleryCarousel />
                    </div>
                )}

                {isWebReputation && (
                    <div className={isMediagallery ? 'col-6' : 'col-12'}>
                        <WidgetWebReputation currentHotel={currentHotel} />
                    </div>
                )}
                {isMediagallery && (
                    <div className={isWebReputation ? 'col-6' : 'col-12'}>
                        <WidgetMediaGallery
                            currentHotel={currentHotel}
                            nFoto={isWebReputation ? 3 : 6}
                        />
                    </div>
                )}
            </div>

            <div className="row sito-cliente">
                <div className="col-md-12">
                    <WidgetGestioneHotel
                        nomeHotel="la tua presenza online"
                        currentHotel={currentHotel}
                        idHotel={currentHotel.value}
                        magnewsDbId={magnewsDbId}
                        isUser={isUser}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <WidgetClienteAmministrazione
                        userHotelId={currentHotel.value}
                    />
                </div>
                <div className="col-md-6">
                    <WidgetClientePosta userHotelId={currentHotel.value} />
                </div>
            </div>
        </>
    );
};

DashboardCliente.propTypes = {
    currentHotel: PropTypes.object,
    userRole: PropTypes.string,
};

export default WithUser(DashboardCliente);
