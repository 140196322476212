import React, {Component} from "react";
import apis from "../apis/crmContatti";
import {TableCreateButton, TableEditButton} from "../../../components/buttons";
import routeRegister from 'helpers/routeRegister';
import {Link} from "react-router-dom";

export default class WidgetUltimiContatti extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contatti: []
        };
    }

    componentDidMount() {
        this.fetchContatto();
    }

    fetchContatto = () => {
        const codiceProduttore = this.props.userCodiceProduttore;
        if (codiceProduttore !== undefined) {
            apis.fetchCrmContattoByProduttore(codiceProduttore).then((res) => {
                this.setState({
                    contatti: res.data.data,
                });
            });
        }
    };

    render() {
        const {contatti} = this.state;
        return (
            <div className="widget">
                <div className="row pb-2">
                    <div className="col-8">
                        <h2>Contatti</h2>
                    </div>
                    <div className="col-4">
                        <Link className="btn-link" to="/crmContatti">
                            Vai alla Rubrica
                        </Link>
                    </div>
                </div>
                {contatti && contatti.length > 0 && (
                    <div className="row">
                        <div className="col-12">
                            <ul className="list-group">
                                {contatti.filter((item, idx) => idx <= 9).map((contatto, i) => (
                                    <li key={i} className="list-group-item py-1">
                                        <div className="row">
                                            <div className="col-4 text-end">
                                                <p className="text-start">
                                                    {contatto.telefono_referente}
                                                </p>
                                            </div>
                                            <div className="col-6">
                                                <p className="text-start">{contatto.nome_referente} -
                                                    ({contatto.nome_struttura} {contatto.comune_name})</p>
                                            </div>
                                            <div className="col-2 text-end">
                                                <TableEditButton
                                                    to={routeRegister.getLinkTo('crmContatti.edit', {
                                                        id: contatto.id
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </li>))}
                            </ul>
                            <p>Gli utlimi contatti inseriti</p>
                        </div>
                    </div>
                )}
                <div className="row mt-4">
                    <div className="col-12">
                        <TableCreateButton
                            label="Aggiungi contatto"
                            className="w-100"
                            to={routeRegister.getLinkTo('crmContatti.create')}
                        />
                    </div>
                </div>
            </div>
        );
    }
}