import helper from 'helpers/steinerHelper';
import client from 'apis/apiMarketingSuite';

const paramsMap = {
    titolo: 'q',
    per_page: 'perPage',
    page: 'page',
    sort_field: 'orderKey',
    sort_direction: 'orderDirection',
    ao_hotel_id: 'ao_hotel_id'
};

const apis = helper.createApi('contatti', client, paramsMap);

apis.creaContattiEmail = (nome, hotel, tipoTurista, csv) => {
    return client({
        url: `contatti`,
        method: 'post',
        data: { nome: nome, hotel: hotel, tipo_turista: tipoTurista, csv: csv }
    });
};

apis.modificaContattiEmail = (idContattiEmail, nome, csv) => {
    return client({
        url: `contatti/${idContattiEmail}`,
        method: 'post',
        data: { nome: nome, csv: csv }
    });
};

apis.fetchContattiEmail = (hotelId) => {
    return client({
        url: `contatti?ao_hotel_id=${hotelId}`,
        method: 'get',
    });
};

export default apis;