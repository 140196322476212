import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
// import _ from 'lodash';

// import NavLink from './NavLink';
import { selectors } from '../reducers/currentHotel';

// function isActive(match, location) {
//     console.warn(match);
//     console.warn(location);
//     return _.startsWith(location.pathname, match.path);
// }

class HotelAwareLink extends Component {
    render() {
        const { children, hotelId, to, icon, exact, className } = this.props;

        // console.warn(this.props);

        if (!hotelId) {
            // return <NavLink className="link-disabled">{children}</NavLink>;
            return <span className="link-disabled">{children}</span>;
        }

        // const query = hotelId ? { hotelId } : {};

        // const location = {
        //     pathname: to,
        //     query
        // };

        const location = to.replace(/:hotelId/, hotelId);

        return (
            <NavLink
                to={location}
                exact={exact}
                className={className ? className : ''}
                // isActive={isActive}
            >
                <i className={icon} aria-hidden="true" /> {children}
            </NavLink>
        );
    }
}

HotelAwareLink.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    hotelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    icon: PropTypes.string,
    to: PropTypes.string,
    exact: PropTypes.bool,
};

HotelAwareLink.defaultProps = {
    exact: false,
};

function mapStateToProps(state) {
    return {
        hotelId: selectors.getCurrentHotelId(state),
    };
}

export default withRouter(connect(mapStateToProps)(HotelAwareLink));
