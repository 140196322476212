import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import WithUser from './WithUser';
import roles from 'helpers/auth';

export class FoglioPresenze extends Component {
    render() {
        const { userRole } = this.props;

        if (userRole === roles.user) {
            return <Redirect to="/" />;
        }

        return (
            <iframe
                style={{
                    width: '100%',
                    height: 'calc(100vh - 150px)'
                }}
                title="Foglio presenze"
                src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRe7w9I8-4kI-OhzaR2juGOq4uuTEpgTNcpkV0e8x6m7zkpsR4CG6aoLwl2Olih5BkF18d7isWkZeay/pubhtml?widget=true&amp;headers=false" frameBorder="0"
            />
        );
    }
}

FoglioPresenze.propTypes = {
    userRole: PropTypes.string
};

export default WithUser(FoglioPresenze);
