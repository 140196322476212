import helper from 'helpers/steinerHelper';
import client from 'apis/apiCalendarioAttivita';

const paramsMapTracciamento = {
    dataInizio: 'dataInizio',
    dataFine: 'dataFine',
    utente: 'utente',
};

const apis = helper.createApi(
    'tracciamenti-attivita',
    client,
    paramsMapTracciamento
);

apis.clienti = helper.createApi('clienti', client /*paramsMap*/);
apis.attivita = helper.createApi('attivita', client /*paramsMap*/);

apis.fetchAttivitaHotel = (aoHotelId) => {
    return client({
        url: `attivita?aoHotelId=${aoHotelId}`,
    });
};

export default apis;
