import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { selectors } from '../reducers/currentHotelV2';

class HotelFilterMagnewsLink extends Component {
    render() {
        const { children, hotelId, to, icon, magnewsDbId } = this.props;

        // console.log(this.props)

        if (!hotelId) {
            // return <NavLink className="link-disabled">{children}</NavLink>;
            return <span className="link-disabled">{children}</span>;
        }

        const location = {
            pathname: to,
            search: `?magnews_id_database=${magnewsDbId}`,
        };

        return (
            <div className="widget-link">
                <NavLink to={location} className="d-flex align-items-center">
                    <i className={`${icon} widget-icon`} aria-hidden="true" />{' '}
                    {children}
                </NavLink>
            </div>
        );
    }
}

HotelFilterMagnewsLink.propTypes = {
    children: PropTypes.any,
    hotelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    to: PropTypes.string,
    type: PropTypes.string,
};

export default withRouter(
    connect((state) => ({ hotelId: selectors.getCurrentHotelId(state) }))(
        HotelFilterMagnewsLink
    )
);
