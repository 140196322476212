import { createApi } from 'helpers/hotelModuleHelper';
import client from 'apis/apiv2';
import v1Client from 'apis/api';

const apis = createApi('ServizioAggiuntivo', client);

apis.fetchServiziBase = (idHotel) => {
    return client({
        url: `Hotel/${idHotel}/ServiziBase`,
    });
};

apis.updateServiziBase = (idHotel, data) => {
    return v1Client({
        url: `Hotel/${idHotel}/ServiziBase`,
        method: 'post',
        data,
    });
};

export default apis;
