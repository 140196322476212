import { reducerCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import { actionTypes } from '../actions/articoliListino';
import { getCurrentContractYear } from '../../../helpers/date';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            perPage: 20,
            page: 1,
            year: getCurrentContractYear() + '',
        }
    }
});

const handlers = helper.createHandlers(actionTypes, {
    items: 'data.data',
    total: 'data.meta.pagination.total',
    perPage: 'data.meta.perPage',
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('articoliListino');
