import apiClient from '../../../apis/apiWebReputation';
import helper from 'helpers/steinerHelper';
import { buildParams } from '@adrias-online/steiner/lib/helpers/apiCreator';

const paramsMap = {
    raccolta_da: 'raccolta_da',
    raccolta_fino_a: 'raccolta_fino_a',
};

const apis = helper.createApi('struttura/dashboard', apiClient, paramsMap);

apis.list = function (idHotel, filters) {
    return apiClient({
        url: `struttura/dashboard/${idHotel}`,
        params: buildParams(filters, helper.getFinalParamsMap(paramsMap, {})),
    });
};

apis.create = function (data) {
    const idHotel = data.hotelId;

    return apiClient({
        url: `struttura/dashboard/${idHotel}`,
        method: 'post',
        data,
    });
};

apis.datiDashboard = (idHotel, raccolta_da, raccolta_fino_a) => {
    return apiClient({
        url: `struttura/dashboard/${idHotel}?raccolta_da=${raccolta_da}&raccolta_fino_a=${raccolta_fino_a}`,
        method: 'GET',
    });
};

export default apis;
