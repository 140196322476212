import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import _ from 'lodash';
import arrify from 'arrify';

import WithUser from './WithUser';

// Inspired by https://github.com/jtmthf/react-router-match-async
class MatchWhenRole extends Component {
    getComponent = () => {
        const { getComponent } = this.props;

        const maybePromise = getComponent((err, component) => {
            if (err) {
                this.errorHandler(err);
            } else {
                this.setComponent(component);
            }
        });

        if (maybePromise && typeof maybePromise.then === 'function') {
            maybePromise.then(this.setComponent).catch(this.errorHandler);
        }
    };

    setComponent = component => {
        this.component = component;
        this.setState({ loaded: true });
    };

    errorHandler = err => {
        const { onError } = this.props;

        if (onError) {
            onError(err);
        } else {
            throw err;
        }
    };

    renderComponent(props) {
        const { component, getComponent } = this;

        if (component === undefined) {
            getComponent();
        }

        return component !== undefined ? (
            React.createElement(component, props)
        ) : (
            <span />
        );
    }

    render() {
        const {
            user,
            userRole,
            isAdmin,
            redirectTo,
            requiredRoles,
            ...rest
        } = this.props;

        return (
            <Route
                {...rest}
                render={props => {
                    if (!user) {
                        return (
                            <Redirect
                                to={{
                                    pathname: redirectTo,
                                    state: { from: props.location }
                                }}
                            />
                        );
                    }

                    if (!requiredRoles || isAdmin) {
                        return this.renderComponent(props);
                    }

                    if (!_.includes(arrify(requiredRoles), userRole)) {
                        return (
                            <Redirect
                                to={{
                                    pathname: '/'
                                }}
                            />
                        );
                    }

                    return this.renderComponent(props);
                }}
            />
        );
    }
}

MatchWhenRole.propTypes = {
    getComponent: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool,
    onError: PropTypes.func,
    redirectTo: PropTypes.string,
    requiredRoles: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    user: PropTypes.object,
    userRole: PropTypes.string
};

MatchWhenRole.defaultProps = {
    redirectTo: '/'
};

export default WithUser(MatchWhenRole);
