import { parse, format } from 'date-fns';

const formatter = new Intl.NumberFormat('it-IT', {
    style: 'decimal',
    // currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export function formatCurrency(value) {
    return `€ ${formatter.format(value)}`;
}

export function fromUStoIT(date) {
    const d = parse(date);

    return format(d, 'DD/MM/YYYY');
}