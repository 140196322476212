import React, { useEffect, useState } from 'react';
import HotelAwareLink from '../HotelAwareLink';
import PropTypes from 'prop-types';
import apiOfferte from '../../modules/offerte/apis/offerte';
import Modal from 'react-modal';
import CountUp from 'react-countup';
import CreazioneOffertaModal from '../../modules/offerte/components/CreazioneOffertaModal';
import { addDays, compareAsc } from 'date-fns';
import { getCurrentCalendarYear } from 'helpers/date';

const WidgetClienteOfferteV2 = (props) => {
    const [offerteCurrentYear, setOfferteCurrentYear] = useState([]);
    const [offerteAttive, setOfferteAttive] = useState([]);
    const [offerteScadenza, setOfferteScadenza] = useState([]);
    const [isShowingModal, setIsShowingModal] = useState(false);

    const unformatDate = (formattedDate) => {
        const dateArray = formattedDate.split('/');
        return new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
    };

    const today = new Date();
    const nextWeek = addDays(new Date(), 7);

    useEffect(() => {
        const clientOfferteCurrentYear = async () => {
            const response = await apiOfferte.fetchOfferteCurrentYear(
                props.userHotelId
            );

            const offerteData = await response.data;

            setOfferteCurrentYear(offerteData.values);

            let offerteAttiveArr = [];
            offerteData.values.forEach((offerta) => {
                if (
                    compareAsc(
                        unformatDate(offerta.data_fine_pubblicazione),
                        today
                    ) !== -1
                ) {
                    offerteAttiveArr.push(offerta);
                }
            });
            setOfferteAttive(offerteAttiveArr);

            let offerteInScadenza = [];
            offerteAttiveArr.forEach((offerta) => {
                if (
                    compareAsc(
                        nextWeek,
                        unformatDate(offerta.data_fine_pubblicazione)
                    ) !== -1
                ) {
                    offerteInScadenza.push(offerta);
                }
            });

            setOfferteScadenza(offerteInScadenza);
        };

        clientOfferteCurrentYear();
    }, []);

    return (
        <div className="widget p-0">
            <div
                className="d-flex align-items-center flex-shrink-1 px-0 bg-primary text-center text-white position-relative"
                style={{ borderRadius: '10px' }}
            >
                <div className="p-3 mx-auto">
                    <i className="widget-cliente-icon fas fa-tag fa-2x"></i>
                    <div className="py-2">
                        {offerteAttive.length !== 0 ? (
                            <CountUp
                                start={0}
                                end={offerteAttive.length}
                                duration={1}
                                delay={0}
                            >
                                {({ countUpRef }) => (
                                    <span
                                        ref={countUpRef}
                                        className="widget-cliente-counting"
                                    />
                                )}
                            </CountUp>
                        ) : (
                            <span className="widget-cliente-counting">0</span>
                        )}
                        <h3 className="text-white">Offerte</h3>
                    </div>
                    <div className="widget-icon-bottom">Online</div>
                </div>
            </div>
            <div className="p-3 text-center">
                <HotelAwareLink
                    to="/hotel/:hotelId/offerte"
                    className="mb-2 d-block"
                >
                    Consulta elenco offerte
                </HotelAwareLink>
                <button
                    onClick={() => setIsShowingModal(true)}
                    className="btn btn-sm btn-tertiary ms-1"
                >
                    <i className="fas fa-plus me-2"></i> Crea una nuova offerta
                </button>
                <Modal
                    className="modal-dialog modal-dialog-centered"
                    isOpen={isShowingModal}
                    onRequestClose={() => setIsShowingModal(false)}
                    contentLabel="Edit Interazioni"
                    shouldCloseOnOverlayClick={true}
                    ariaHideApp={false}
                >
                    <CreazioneOffertaModal
                        dispatch={props.dispatch}
                        currentHotel={props.userHotelId}
                        onClose={() => setIsShowingModal(false)}
                    />
                </Modal>
            </div>
            <div className="py-2">
                <div className="row sfondo-colore-body p-2 my-1 mx-3">
                    <div className="col-2 text-end">
                        <div className="text-tertiary fw-bold">
                            {offerteScadenza.length}
                        </div>
                    </div>
                    <div className="col-10">In scadenza</div>
                </div>
                <div className="row sfondo-colore-body p-2 my-1 mx-3">
                    <div className="col-2 text-end">
                        <div className="text-tertiary fw-bold">
                            {offerteCurrentYear.length - offerteAttive.length}
                        </div>
                    </div>
                    <div className="col-10">Scadute</div>
                </div>
                <div className="row sfondo-colore-body p-2 my-1 mx-3">
                    <div className="col-2 text-end">
                        <div className="text-tertiary fw-bold">
                            {offerteCurrentYear.length}
                        </div>
                    </div>
                    <div className="col-10">
                        Anno {getCurrentCalendarYear()}
                    </div>
                </div>
            </div>
        </div>
    );
};

WidgetClienteOfferteV2.propTypes = {
    userHotelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default WidgetClienteOfferteV2;
