import { select /*, call*/ } from 'redux-saga/effects';
// import { takeEvery } from 'redux-saga';
import { sagaCreator } from '@adrias-online/steiner';
import { buildParams } from '@adrias-online/steiner/lib/helpers/apiCreator';
import { getCurrentRoute } from '@adrias-online/steiner/lib/routing/reducer';
// import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { DEFAULT_PARAMS_MAP } from '@adrias-online/steiner/lib/helpers/apiCreator';

import helper from './steinerHelper';
import { actionTypes as currentHotelActionTypes } from 'actions/currentHotel';
import { selectors as currentHotelSelectors } from 'reducers/currentHotel';

export const createHandlers = function(actionTypes, options) {
    const handlers = helper.createHandlers(actionTypes, options);

    handlers[currentHotelActionTypes.setHotel] = function(state, action) {
        return state.setIn(
            ['list', 'filters', 'hotelId'],
            action.payload.value
        );
    };

    // handlers[currentHotelActionTypes.syncHotel] = function(state, action) {
    //     return state.setIn(['list', 'filters', 'hotelId'], action.payload.value);
    // }

    return handlers;
};

function* getApiListParams(selectors) {
    const filters = yield select(selectors.getFilters);
    const hotelId = yield select(currentHotelSelectors.getCurrentHotelId);

    return [hotelId, filters];
}

export const createSagas = function(
    resource,
    actionTypes,
    actions,
    api,
    selectors,
    defaultState,
    options = {}
) {
    const finalOptions = Object.assign({}, { getApiListParams }, options);

    const sagas = helper.createSagas(
        resource,
        actionTypes,
        actions,
        api,
        selectors,
        defaultState,
        finalOptions
    );

    // sagas.setHotel = function*() {
    //     // Chiamo filter solo se mi trovo nella rotta specifica della risorsa
    //     if (_.startsWith(window.location.pathname, `/${resource}`)) {
    //         yield call(sagas.filter);
    //     }
    // }

    return sagas;
};

export const bootSagas = function(sagas, actionTypes) {
    const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

    return [
        ...defaultSagas
        // takeEvery(currentHotelActionTypes.setHotel, sagas.setHotel)
    ];
};

export const createApi = function(endpoint, client, paramsMap, options) {
    const apis = helper.createApi(endpoint, client, paramsMap, options);

    apis.list = function(idHotel, filters) {
        return client({
            url: `Hotel/${idHotel}/${endpoint}`,
            params: buildParams(
                filters,
                helper.getFinalParamsMap(paramsMap, {})
            )
        });
    };

    apis.create = function(data) {
        const idHotel = data.hotelId;

        return client({
            url: `Hotel/${idHotel}/${endpoint}`,
            method: 'post',
            data
        });
    };

    return apis;
};

export const generateRoutes = function(resource, selectors, options) {
    let routes = helper.generateRoutes(resource, selectors, options);

    routes.patterns = {
        create: `/hotel/:hotelId/${resource}/create`,
        edit: `/hotel/:hotelId/${resource}/:id`,
        list: `/hotel/:hotelId/${resource}`
    };

    routes.list.edit.path = routes.patterns.edit;
    routes.list.list.path = routes.patterns.list;

    return routes;
};

export const connectList = function(component, actions, selectors) {
    function mapStateToProps(state) {
        const list = selectors.listSelector(state);

        return {
            ...list,
            filters: selectors.getFilters(state),
            items: selectors.itemsSelector(state),
            selected: selectors.getSelectedId(state),
            currentHotel: currentHotelSelectors.getCurrentHotel(state),
            currentRoute: getCurrentRoute(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        const boundedActions = bindActionCreators(actions, dispatch);

        return {
            ...boundedActions,
            dispatch
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(component);
};
