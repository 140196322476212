import { sagaCreator } from '@adrias-online/steiner';

import { createSagas } from 'helpers/siteModuleHelper';
import { actions, actionTypes } from '../actions/sitePhotogallery';
import { selectors, DEFAULT_STATE } from '../reducers/sitePhotogallery';
import api from '../apis/sitePhotogallery';

const sagas = createSagas(
    'sitePhotogallery',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE
);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [...defaultSagas];
