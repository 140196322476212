import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { addNotification as notify } from 'reapop';
import { reduxForm, Field } from 'redux-form';
import { validator } from 'validate-this';
import _ from 'lodash';
import AutoSelectField from 'components/form/AutoSelectField';
import SelectValueField from 'components/form/SelectValueField';
import SelectHotelAo from 'components/select/SelectHotelAo';
import TextareaField from 'components/form/v1/TextareaField';
import { roles } from 'helpers/auth';
import api from '../apis/calendarioAttivita';
import { withRouter } from 'react-router';
import WithUser from 'components/WithUser';
import DatePickerPortali from 'components/form/DatePickerPortali';
import { getHours, getMinutes, addMinutes, format } from 'date-fns';
import { confirm } from '../../../helpers/confirm/confirm';
import TranslatorHoc from '../../../components/TranslatorHoc';

const numDueCifre = (numero) => {
    if (numero < 10) {
        return `0${numero}`;
    }
    return numero;
};

let NuovaAttivitaModal = (props) => {
    const [selectedHotel, setSelectedHotel] = useState();
    const [hotelAttivita, setHotelAttivita] = useState([]);

    useEffect(() => {
        const clientHotelAttivita = async () => {
            if (typeof selectedHotel !== 'undefined') {
                const response = await api.fetchAttivitaHotel(selectedHotel);

                const attivitaData = await response.data;

                setHotelAttivita(attivitaData.data);
            }
        };

        clientHotelAttivita();
    }, [selectedHotel]);

    const handleDelete = (id) => {
        props.dispatch(
            confirm.actions.showConfirmDialog({
                message: props.confirmDeleteMessage,
                onSuccess: () => {
                    props.onClose();
                    props.delete(id);
                },
            })
        );
    };

    if ((props.item != null) & !_.isEmpty(props.item)) {
        useEffect(() => {
            setSelectedHotel(props.item.aoHotelId);

            const data = props.item;
            let userTimezone =
                new Date(data.dataConsuntivo).getTimezoneOffset() / 60;
            const orarioInizio = `${numDueCifre(
                getHours(data.dataConsuntivo) + userTimezone
            )}:${numDueCifre(getMinutes(data.dataConsuntivo))}`;

            const minutiAggiuntivi = data.tempo * 60;

            let dataFineIso = addMinutes(data.dataConsuntivo, minutiAggiuntivi);
            dataFineIso = addMinutes(dataFineIso, userTimezone * 60);

            const orarioFine = `${numDueCifre(
                getHours(dataFineIso)
            )}:${numDueCifre(getMinutes(dataFineIso))}`;

            let tempoView = data.tempo.toString();
            tempoView = tempoView.split('.');
            let tempoMinuti = 0;
            if (tempoView[1] === '25') {
                tempoMinuti = '15';
            } else if (tempoView[1] === '5') {
                tempoMinuti = '30';
            } else if (tempoView[1] === '75') {
                tempoMinuti = '45';
            } else {
                tempoMinuti = '00';
            }

            const tempoTot = tempoView[0] + ':' + tempoMinuti;
            props.initialize({
                ...data,
                orario_inizio: orarioInizio,
                orario_fine: orarioFine,
                tempo: tempoTot,
            });
        }, []);
    }

    const calcoloOrario = (orario, divisore) => {
        const orarioArr = orario.split(divisore);
        const ore = orarioArr[0] * 60;
        return Number(ore) + Number(orarioArr[1]);
    };

    const submit = (data) => {
        let dataInizioAttivita;

        if (!data.tempo) {
            dataInizioAttivita = `${data.dataConsuntivo}T01:00:00.000Z`;
        } else {
            dataInizioAttivita = `${format(
                data.dataConsuntivo,
                'YYYY-MM-DD'
            )}T01:00:00.000Z`;
        }

        data.tempo = calcoloOrario(data.tempo, ':') / 60;

        data.utente = props.user.email;
        data.dataConsuntivo = dataInizioAttivita;

        let today = new Date();
        let userTimezoneOffset = today.getTimezoneOffset() * 60000;
        today = new Date(today.getTime() - userTimezoneOffset);

        //  const payload = _.omit(data, ['orario_inizio', 'orario_fine']);
        const payload = _.omit(data);

        let xhr;

        if (typeof props.item !== 'undefined') {
            if (props.item.id) {
                xhr = api.update(props.item.id, data);
            } else {
                xhr = api.create({
                    ...payload,
                });
            }
        } else {
            xhr = api.create({
                ...payload,
            });
        }

        xhr.then((response) => {
            props.onClose();

            props.dispatch(
                notify({
                    title: 'Successo!',
                    message: 'Nuova attività creata correttamente!',
                    status: 'success',
                })
            );
        });

        xhr.catch(() => {
            props.dispatch(
                notify({
                    title: 'Errore!',
                    message: 'Si è verificato un errore inaspettato!',
                    status: 'error',
                })
            );
        });

        props.aggiorna();

        return xhr;
    };

    const { handleSubmit, submitting, userRole } = props;

    const isAdmin = userRole === roles.admin;

    let quarterHours = ['00', '15', '30', '45'];
    let opzioniOrario = [];
    for (let i = 0; i < 9; i++) {
        for (let j = 0; j < 4; j++) {
            opzioniOrario.push(i.toString() + ':' + quarterHours[j]);
        }
    }

    let verifica = true;

    typeof props.item !== 'undefined'
        ? (props.item.bloccato === true && !isAdmin)
            ? (verifica = true)
            : (verifica = false)
        : (verifica = false);

    return (
        <div className="modal-content widget">
            <form onSubmit={handleSubmit(submit)} className="form-horizontal">
                <div className="modal-body attivita">
                    <div className="container-fluid">
                        <h2 className="text-primary text-center">
                            Aggiungi orario attività
                        </h2>
                        <div className="text-center pb-4">
                            Seleziona il task e imposta l'orario della durata
                        </div>
                        <Field
                            name="aoHotelId"
                            horizontal={false}
                            component={AutoSelectField}
                            SelectComponent={SelectHotelAo}
                            hideLabel
                            clearable={false}
                            onChange={(value) => {
                                setSelectedHotel(value);
                            }}
                            disabled={verifica}
                        />
                        <SelectValueField
                            name="attivita.id"
                            horizontal={false}
                            placeholder="Seleziona attività"
                            options={hotelAttivita.map((attivita) => ({
                                value: attivita.id,
                                label: attivita.descrizione,
                            }))}
                            hideLabel
                            clearable={false}
                            disabled={verifica}
                        />
                        <div className="form-group row">
                            <div className="col-sm-8">
                                <Field
                                    name="dataConsuntivo"
                                    placeholders="Data attività"
                                    component={DatePickerPortali}
                                    style={{ pointerEvents: 'none' }}
                                    className={
                                        typeof props.item !== 'undefined'
                                            ? props.item.id
                                                ? 'pe-none'
                                                : null
                                            : null
                                    }
                                />
                            </div>
                            <div className="col-sm-4">
                                <SelectValueField
                                    name="tempo"
                                    horizontal={false}
                                    placeholder="Tempo impiegato"
                                    options={opzioniOrario.map((orario) => ({
                                        value: orario,
                                        label: orario,
                                    }))}
                                    hideLabel
                                    clearable={false}
                                />
                            </div>
                        </div>
                        <Field
                            name="note"
                            component={TextareaField}
                            horizontal={false}
                            placeholder="Note"
                            hideLabel
                            disabled={verifica}
                        />
                    </div>
                </div>
                <div className="row py-3">
                    <div className="col-12 text-center">
                        <button
                            type="button"
                            className="btn btn-sm btn-outline-light"
                            onClick={props.onClose}
                            disabled={submitting}
                        >
                            Annulla
                        </button>
                        {typeof props.item !== 'undefined'
                            ? verifica === true
                                ? null
                                : props.item.id && (
                                      <button
                                          type="button"
                                          className="btn btn-sm btn-danger ms-2"
                                          onClick={() =>
                                              handleDelete(props.item.id)
                                          }
                                          disabled={submitting}
                                      >
                                          Elimina
                                      </button>
                                  )
                            : null}
                        {typeof props.item !== 'undefined' ? (
                            verifica === true ? null : (
                                <button
                                    className="btn btn btn-sm btn-primary ms-2"
                                    disabled={submitting}
                                    onClick={() => submit}
                                >
                                    {typeof props.item !== 'undefined'
                                        ? props.item.id
                                            ? 'Modifica'
                                            : 'Crea'
                                        : 'Crea'}{' '}
                                    attività
                                </button>
                            )
                        ) : (
                            <button
                                className="btn btn btn-sm btn-primary ms-2"
                                disabled={submitting}
                            >
                                {typeof props.item !== 'undefined'
                                    ? props.item.id
                                        ? 'Modifica'
                                        : 'Crea'
                                    : 'Crea'}{' '}
                                attività
                            </button>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
};

NuovaAttivitaModal.propTypes = {
    dispatch: PropTypes.func,
    handleSubmit: PropTypes.func,
    onClose: PropTypes.func,
    submitting: PropTypes.bool,
};

NuovaAttivitaModal.defaultProps = {
    confirmDeleteMessage: 'Do you really want to delete the selected item?',
};

function validate(values) {
    return validator(
        values,
        (v) => {
            v.validate(
                'attivita.id',
                'dataConsuntivo',
                'tempo',
                'aoHotelId'
            ).required();
        },
        (msg, field) => {
            return 'Campo obbligatorio';
        }
    );
}

NuovaAttivitaModal = reduxForm({
    form: 'creaAttivita',
    validate,
})(NuovaAttivitaModal);

export default WithUser(
    withRouter(
        TranslatorHoc(NuovaAttivitaModal, {
            confirmDeleteMessage: 'steiner.messages.confirmDelete',
        })
    )
);
