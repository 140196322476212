import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { select } from 'redux-saga/effects';

import helper from './steinerHelper';
import { sagaCreator } from '@adrias-online/steiner/lib/helpers';
import { buildParams } from '@adrias-online/steiner/lib/helpers/apiCreator';

import { getUserCodiceProduttore } from '../reducers/selectors';

function formatItem(item) {
    const {
        id,
        hotel_id,
        hotel_name,
        hotel_city,
        seller_code,
        seller_name,
        funnel_year,
        revenue_year,
        macros,
        notes,
        phone_calls_notes,
        notes_for_new_year,
        meeting_notes,
        total_amount
    } = item;

    const mappedMacros = macros.map(macro => {
        const {
            id,
            name,
            label,
            description,
            articles,
            question,
            answer,
            submacros
        } = macro;

        const mappedArticles = articles.map(article => {
            const {
                amount,
                article_id,
                article_name,
                for_sale,
                id,
                is_sold
            } = article;

            return {
                amount,
                article_id,
                article_name,
                for_sale,
                id,
                is_sold
            };
        });

        const mappedSubmacros = submacros.map(macro => {
            const {
                id,
                name,
                label,
                description,
                articles,
                question,
                answer
            } = macro;

            const mappedArticles = articles.map(article => {
                const {
                    amount,
                    article_id,
                    article_name,
                    for_sale,
                    id,
                    is_sold
                } = article;

                return {
                    amount,
                    article_id,
                    article_name,
                    for_sale,
                    id,
                    is_sold
                };
            });

            return {
                answer,
                color: name,
                description,
                id,
                label,
                articles: [...mappedArticles],
                question
            };
        });

        return {
            answer,
            color: name,
            description,
            id,
            label,
            articles: [...mappedArticles],
            question,
            submacros: [...mappedSubmacros]
        };
    });

    return {
        funnel_year,
        hotel_id,
        hotel_city,
        hotel_name,
        id,
        macros: [...mappedMacros],
        meeting_notes,
        notes,
        notes_for_new_year,
        phone_calls_notes,
        revenue_year,
        seller_code,
        seller_name,
        total_amount
    };
}

export const createHandlers = function(actionTypes, options) {
    const handlers = helper.createHandlers(actionTypes, options);
    const { fetchSuccess } = actionTypes;

    handlers[fetchSuccess] = function(state, action) {
        return state.update('current', () => ({
            item: formatItem(action.payload.data),
            isFetching: false,
            errorMessage: null
        }));
    };

    handlers[getUserCodiceProduttore] = function(state, action) {
        return state.setIn(['list', 'filters', 'produttore'], action.payload);
    };

    return handlers;
};

function* getApiListParams(selectors) {
    const filters = yield select(selectors.getFilters);
    const codProduttore = yield select(getUserCodiceProduttore);

    return [codProduttore, filters];
}

export const createSagas = function(
    resource,
    actionTypes,
    actions,
    api,
    selectors,
    defaultState,
    options = {}
) {
    const finalOptions = Object.assign({}, { getApiListParams }, options);

    return helper.createSagas(
        resource,
        actionTypes,
        actions,
        api,
        selectors,
        defaultState,
        finalOptions
    );
};

export const bootSagas = function(sagas, actionTypes) {
    const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);
    return [...defaultSagas];
};

export const createApi = function(endpoint, client, paramsMap, options) {
    const apis = helper.createApi(endpoint, client, paramsMap, options);

    apis.list = function(filters) {
        return client({
            url: `funnels`,
            params: buildParams(
                filters,
                helper.getFinalParamsMap(paramsMap, {})
            )
        });
    };

    return apis;
};

export const generateRoutes = function(resource, selectors, options) {
    let routes = helper.generateRoutes(resource, selectors, options);

    routes.patterns = {
        list: `/funnel`,
        edit: `/funnel/edit/:id`,
        show: `/funnel/show/:id`
    };

    routes.list.show = {
        path: routes.patterns.show,
        componentPath: 'containers/FunnelShowPrintable',
        breadcrumb: 'Visualizza Funnel Stampabile'
    };

    routes.list.edit.breadcrumb = 'Modifica Funnel';

    routes.list.list.path = routes.patterns.list;
    routes.list.edit.path = routes.patterns.edit;

    return routes;
};

export const connectList = function(component, actions, selectors) {
    function mapStateToProps(state) {
        const list = selectors.listSelector(state);

        return {
            ...list,
            filters: selectors.getFilters(state),
            items: selectors.itemsSelector(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        const boundedActions = bindActionCreators(actions, dispatch);

        return {
            ...boundedActions,
            dispatch
        };
    }

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(component);
};
