import { routeCreator } from '@adrias-online/steiner';
import _ from 'lodash';

import { generateRoutes } from '../../../helpers/hotelModuleHelper';
import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/mediaGalleryPrivata';

const patternMediaGallery = '/hotel/:id/mediaGalleryPrivata';

const routes = generateRoutes('mediaGalleryPrivata', selectors);

routes.list.list = {
    breadcrumb: 'Media Gallery',
    componentPath: 'containers/MediaGalleryPrivataListLayout',
    path: patternMediaGallery,
    hotelFilter: true
};

const list = _.values(routes.list).map(route => ({
    ...route,
    hotelFilter: true,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'mediaGallery' */ `modules/mediaGalleryPrivata/${route.componentPath}`)
                .then(response => resolve(response.default));
        });
    },
}));



const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('mediaGalleryPrivata', routes.patterns);
routeRegister.addLinks('mediaGalleryPrivata', links);

export default list;