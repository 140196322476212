import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';

import { selectors } from '../reducers/currentPortale';

class PortaleFilterLink extends Component {
    render() {
        const { children, portaleId, to, classes } = this.props;

        if (!portaleId) {
            return <NavLink className="link-disabled">{children}</NavLink>;
        }

        const location = {
            pathname: to,
            search: `?portaleId=${portaleId}`
        };

        return (
            <NavLink className={classes} to={location}>
                {children}
            </NavLink>
        );
    }
}

PortaleFilterLink.propTypes = {
    children: PropTypes.any,
    to: PropTypes.string,
    portaleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    classes: PropTypes.string
};

export default withRouter(
    connect(state => ({ portaleId: selectors.getCurrentPortaleId(state) }))(
        PortaleFilterLink
    )
);
