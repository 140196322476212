import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/notification';

const routes = helper.generateRoutes('notification', selectors, {
    itemLabelKey: 'title'
});

routes.list.list.breadcrumb = 'Notifiche';

const list = _.values(routes.list).map(route => ({
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'notification' */ `modules/notification/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('notification', routes.patterns);
routeRegister.addLinks('notification', links);

export default list;
