const SET_PORTALE = 'SET_PORTALE';
const SYNC_PORTALE = 'SYNC_PORTALE';

function setPortale(portale) {
    return {
        type: SET_PORTALE,
        payload: portale
    };
}

function syncPortale(portale) {
    return {
        type: SYNC_PORTALE,
        payload: portale
    };
}

export const actionTypes = {
    setPortale: SET_PORTALE,
    syncPortale: SYNC_PORTALE,
    [SET_PORTALE]: SET_PORTALE,
    [SYNC_PORTALE]: SYNC_PORTALE
};

export const actions = {
    setPortale,
    syncPortale
};
