import statsClient from 'apis/stats';
import helper from 'helpers/steinerHelper';

const apis = helper.createApi('search', statsClient, {
    per_page: 'perPage',
    page: 'page',
    stat_from: 'from',
    stat_to: 'to',
    id_hotel: 'hotelId',
    site_id(filters) {
        if (filters.source === 'portali') {
            return null;
        }

        return filters.source;
    },
    network(filters) {
        if (filters.source === 'portali') {
            return true;
        }

        return null;
    },
    email_cliente: 'q'
});

export default apis;
