import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import WithUser from './WithUser';

import SidebarPrincipaleToggle from './sidebar/SidebarPrincipaleToggle';
import ModalAssistenza from './ModalAssistenza';
import logoHeader from '../images/aol-logo-mini-testata.png';
import SidebarPrincipale from './sidebar/SidebarPrincipale';
import SidebarProfiloToggle from './sidebar/SidebarProfiloToggle';
import SidebarProfilo from './sidebar/SidebarProfilo';

const Header = (props) => {
    const [isModalAssistenzaOpen, setIsModalAssistenzaOpen] = useState(false);
    const [isSidebarProfiloOpen, setIsSidebarProfiloOpen] = useState(false);
    const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useState(false);

    const { user, onToggleHelpModal } = props;

    let navClass = 'navbar navbar-inverse fixed-top';

    if (isSidebarMenuOpen) {
        navClass = `${navClass} sidebar-is-open`;
    }

    const nomeUtente = user ? user.name : '';

    return (
        <>
            <div className={navClass}>
                <ModalAssistenza
                    isOpen={isModalAssistenzaOpen}
                    onClose={() => setIsModalAssistenzaOpen(false)}
                />
                <div className="container-fluid p-0">
                    <div className="d-flex align-items-center">
                        {user.lastName && (
                            <>
                                <SidebarPrincipale
                                    onStateChange={(state) =>
                                        setIsSidebarMenuOpen(state.isOpen)
                                    }
                                    isOpen={isSidebarMenuOpen}
                                    closeMenu={() =>
                                        setIsSidebarMenuOpen(false)
                                    }
                                />
                                <SidebarPrincipaleToggle
                                    position="navbar"
                                    onClick={() =>
                                        setIsSidebarMenuOpen(!isSidebarMenuOpen)
                                    }
                                    isMenuOpen={isSidebarMenuOpen}
                                />
                            </>
                        )}
                        <Link to="/">
                            <img
                                src={logoHeader}
                                alt="Logo Adrias Online"
                                className="d-none d-md-inline-block"
                            />
                        </Link>
                    </div>
                    <div>
                        <Link to="/">
                            <img
                                src={logoHeader}
                                alt="Logo Adrias Online"
                                className="d-md-none"
                                style={{maxWidth: '184px'}}
                            />
                        </Link>
                    </div>
                    <div className="d-flex align-items-center">
                        {user.lastName && (
                            <div className="beamer-adrias text-white">
                                <span className="d-none d-lg-inline-block">
                                </span>
                            </div>
                        )}
                        <SidebarProfiloToggle
                            nomeUtente={nomeUtente}
                            setIsSidebarProfiloOpen={setIsSidebarProfiloOpen}
                            isSidebarProfiloOpen={isSidebarProfiloOpen}
                        />
                    </div>
                </div>
            </div>
            <SidebarProfilo
                isSidebarProfiloOpen={isSidebarProfiloOpen}
                setIsSidebarProfiloOpen={setIsSidebarProfiloOpen}
                onToggleHelpModal={onToggleHelpModal}
                dispatch={props.dispatch}
            />
        </>
    );
};

Header.propTypes = {
    dispatch: PropTypes.func,
    isSidebarOpen: PropTypes.bool,
    onToggleHelpModal: PropTypes.func,
    onToggleSidebar: PropTypes.func,
    routes: PropTypes.array,
    user: PropTypes.object,
    userRole: PropTypes.string,
};

export default WithUser(Header);
