import { reducerCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { actionTypes } from '../actions/coefficienti';


export const DEFAULT_STATE = helper.createDefaultState();

const handlers = helper.createHandlers(actionTypes, {
    items: 'data.data',
    idKey: 'id',
    total: 'data.meta.total',
    per_page: 'data.meta.per_page',
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('coefficienti');