import { reducerCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { createHandlers } from 'helpers/hotelModuleHelper';
import { actionTypes } from '../actions/WebReputation';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            canale: null,
            punteggio_min: null,
            raccolta_da: null,
            raccolta_fino_a: null,
        },
    },
});

const handlers = createHandlers(actionTypes, {
    items: 'data.data',
    total: 'data.meta.total',
});
export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('WebReputation');
