import { routeCreator } from '@adrias-online/steiner';
import _ from 'lodash';

import { generateRoutes } from '../../../helpers/hotelModuleHelper';
import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/WebReputation';

const patternWebReputation = '/hotel/:id/WebReputation';

const routes = generateRoutes('WebReputation', selectors);

routes.list.list = {
    breadcrumb: 'Web Reputation Analysis',
    componentPath: 'containers/WebReputationListLayout',
    path: patternWebReputation,
    hotelFilter: true,
};

const list = _.values(routes.list).map((route) => ({
    ...route,
    hotelFilter: true,
    getComponent() {
        return new Promise((resolve) => {
            import(
                /* webpackChunkName: 'WebReputation' */ `modules/WebReputation/${route.componentPath}`
            ).then((response) => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('WebReputation', routes.patterns);
routeRegister.addLinks('WebReputation', links);

export default list;
