import helper from 'helpers/steinerHelper';
import contrattiClient from 'apis/contratti';

export default helper.createApi('articoli', contrattiClient, {
    limit: 'perPage',
    page: 'page',
    orderBy: 'orderKey',
    orderDir: 'orderDirection',
    filter: 'q'
});
