import helper from 'helpers/steinerHelper';
import client from 'apis/apiDem';
import _ from 'lodash';
import { buildParams } from '@adrias-online/steiner/lib/helpers/apiCreator';

const paramsMap = {
    limit: 'perPage',
    page: 'page',
    magnews_id_database: 'magnews_id_database',
    filter: 'q',
    orderby: 'orderKey',
    orderdir: 'orderDirection'
};

const apis = helper.createApi('contacts', client, paramsMap);

export function getExportCsvUrl(magnews_id_database, filters, token) {
    const params = _.omit(
        buildParams(filters, helper.getFinalParamsMap(paramsMap, {})),
        'limit'
    );

    params.limit = 0;

    return `${
        window.__RUNTIME_CONFIG__.REACT_APP_DEM
    }/contacts/bulk/download?magnews_id_database=${magnews_id_database}`;
}

export default apis;
