import axios from 'axios';

const { REACT_APP_ORDERS_API_NEW_ROOT } = window.__RUNTIME_CONFIG__;

const client = axios.create({
    baseURL: REACT_APP_ORDERS_API_NEW_ROOT,
    responseType: 'json'
});

export default client;
