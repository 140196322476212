const SET_UNREAD_NOTIFICATIONS = 'SET_UNREAD_NOTIFICATIONS';
const REFRESH_UNREAD_COUNT = 'REFRESH_UNREAD_COUNT';

function setUnreadNotifications(count) {
    return {
        type: SET_UNREAD_NOTIFICATIONS,
        payload: count
    };
}

function refreshUnreadCount() {
    return {
        type: REFRESH_UNREAD_COUNT
    };
}

export const actionTypes = {
    setUnreadNotifications: SET_UNREAD_NOTIFICATIONS,
    refreshUnreadCount: REFRESH_UNREAD_COUNT,
    [SET_UNREAD_NOTIFICATIONS]: SET_UNREAD_NOTIFICATIONS,
    [REFRESH_UNREAD_COUNT]: REFRESH_UNREAD_COUNT
};

export const actions = {
    setUnreadNotifications,
    refreshUnreadCount
};
