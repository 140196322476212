import helper from 'helpers/steinerHelper';
import client from 'apis/apiMarketingSuite';

const apis = helper.createApi(
    'layouts',
    client
);

apis.modificaLayout = (layoutId, html) => {
    return client({
        url: `layouts/${layoutId}`,
        method: 'patch',
        data: { html: html },
    });
};

apis.fetchLayouts = () => {
    return client({
        url: 'layouts',
        method: 'get',
    });
};

export default apis;
