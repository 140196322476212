import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import routeRegister from '../../../helpers/routeRegister';
import { generateRoutes } from '../../../helpers/hotelModuleHelper';
import { selectors } from '../reducers/hotelServizi';

let routes = generateRoutes('servizi/serviziAggiuntivi', selectors, {
    itemLabelKey: 'names.it'
});

const patternServiziDashboard = '/hotel/:hotelId/servizi';
const patternServiziBase = '/hotel/:hotelId/servizi/serviziBase';

routes = _.merge(routes, {
    patterns: {
        dashboard: patternServiziDashboard,
        serviziBase: patternServiziBase
    },
    list: {
        list: {
            breadcrumb: 'Servizi Aggiuntivi',
            componentPath: 'containers/ServiziAggiuntiviListLayout'
        },
        edit: {
            componentPath: 'containers/ServiziAggiuntiviLoader'
        },
        dashboard: {
            exact: true,
            breadcrumb: 'Servizi',
            componentPath: 'components/ServiziDashboard',
            path: patternServiziDashboard
        },
        serviziBase: {
            breadcrumb: 'Servizi base',
            componentPath: 'components/ServiziBase',
            path: patternServiziBase
        }
    }
});

// routes.patterns.dashboard = patternServiziDashboard;
// routes.patterns['servizi base'] = patternServiziBase;

// routes.list.list.componentPath = 'containers/ServiziAggiuntiviListLayout';
// routes.list.list.breadcrumb = 'Servizi Aggiuntivi';
// routes.list.edit.componentPath = 'containers/ServiziAggiuntiviLoader';

// routes.list.dashboard = {
//     exact: true,
//     breadcrumb: 'Servizi',
//     componentPath: 'components/ServiziDashboard',
//     path: patternServiziDashboard
// };

// routes.list.serviziBase = {
//     breadcrumb: 'Servizi base',
//     componentPath: 'components/ServiziBase',
//     path: patternServiziBase
// };

const list = _.values(routes.list).map(route => ({
    ...route,
    hotelFilter: true,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'servizi-hotel' */ `modules/hotelServizi/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('hotelServizi', routes.patterns, {
    it: 'Servizi Hotel'
});
routeRegister.addLinks('hotelServizi', links);

export default list;
