import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import InputField from 'components/form/v1/InputField';
import LoadingButton from 'components/form/v1/LoadingButton';
import { Helmet } from 'react-helmet';

import { recoverPassword } from 'helpers/auth-api';
import LoginWrapper from './LoginWrapper';
import LoginPage from './LoginPage';

// function delay(time) {
//     return new Promise((resolve) => {
//         setTimeout(() => {
//             resolve();
//         }, time);
//     });
// }

export class RecuperoPassword extends Component {
    state = {
        submitSuccessfull: false,
    };

    componentDidMount() {
        this.form.elements[0].focus();
    }

    submit = (data) => {
        // TODO: validare email
        return recoverPassword(data.email).then(() => {
            this.setState({
                submitSuccessfull: true,
            });
        });
    };

    render() {
        const { submitSuccessfull } = this.state;
        const { handleSubmit, submitting } = this.props;

        if (submitSuccessfull) {
            return (
                <div>
                    <Helmet>
                        <title>Recupero password</title>
                    </Helmet>

                    <LoginPage
                        formTitle="Recupero password"
                        formComponent={
                            <LoginWrapper title={`Recupero password`}>
                                <div className="alert alert-info">
                                    Richiesta recupero password inviata,
                                    perfavore controlla la tua mail e segui le
                                    istruzioni riportate!
                                </div>
                                <div className="col-12 text-end">
                                    <Link to="/">Torna al login</Link>
                                </div>
                            </LoginWrapper>
                        }
                    />
                </div>
            );
        }

        return (
            <>
                <Helmet>
                    <title>Recupero password</title>
                </Helmet>

                <LoginPage
                    formTitle="Recupero password"
                    formComponent={
                        <LoginWrapper>
                            <form
                                ref={(form) => (this.form = form)}
                                onSubmit={handleSubmit(this.submit)}
                                className="py-3"
                            >
                                <div className="mb-4">
                                    <Field
                                        className="form-control"
                                        name="email"
                                        placeholder="Email"
                                        component={InputField}
                                        onlyInput={true}
                                        disabled={submitSuccessfull}
                                    />
                                </div>

                                <div className="mb-4">
                                    <LoadingButton
                                        className="btn-success btn-block"
                                        loading={submitting}
                                        disabled={submitSuccessfull}
                                    >
                                        Resetta password{' '}
                                        <i className="fas fa-sign-in" />
                                    </LoadingButton>
                                </div>
                                <div className="col-12 text-end">
                                    <Link to="/">Torna al login</Link>
                                </div>
                            </form>
                        </LoginWrapper>
                    }
                />
            </>
        );
    }
}

RecuperoPassword.propTypes = {
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
};

export default reduxForm({
    form: 'recupero_password',
})(RecuperoPassword);
