import { reducerCreator } from '@adrias-online/steiner';
import Immutable from 'seamless-immutable';

import { actionTypes } from '../actions/systemNotifications';

export const DEFAULT_STATE = Immutable({
    unread: 0
});

function setUnreadNotifications(state, action) {
    if (action.payload) {
        return state.set('unread', action.payload);
    }

    return DEFAULT_STATE;
}

const handlers = {
    [actionTypes.setUnreadNotifications]: setUnreadNotifications
};

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = {
    getUnreadCount(state) {
        return state.systemNotifications.unread;
    }
};
