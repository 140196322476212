import { reducerCreator } from '@adrias-online/steiner';

import helper from '../../../helpers/steinerHelper';
import { createHandlers } from '../../../helpers/portaleModuleHelper';
import { actionTypes } from '../actions/portaliSchedeHotel';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            portaleId: null,
            idComune: null,
            redirect: 'redirects'
        }
    }
});

const handlers = createHandlers(actionTypes, {
    items: 'data.data',
    total: 'data.meta.pagination.total'
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('portaliSchedeHotel');
