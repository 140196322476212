import helper from '../../../helpers/steinerHelper';
import contrattiClient from '../../../apis/contratti';
import contrattiClientNew from '../../../apis/contrattiNew';

export default helper.createApi('listino', contrattiClient, {
    limit: 'perPage',
    page: 'page',
    orderBy: 'orderKey',
    orderDir: 'orderDirection',
    filter: 'q',
    year: 'year',
    article_id: 'article_id',
    forReports: 'forReports'
});

const contrattiApiNew = helper.createApi('price-list', contrattiClientNew, {
    filter: 'q',
    year: 'year',
    article_code: 'article_code',
    forReports: 'forReports'
});

export { contrattiApiNew };
