import helper from 'helpers/steinerHelper';
import client from 'apis/apiMarketingSuite';

const paramsMap = {
    titolo: 'q',
    per_page: 'perPage',
    page: 'page',
    sort_field: 'orderKey',
    sort_direction: 'orderDirection',
    ao_hotel_id: 'ao_hotel_id',
};

const apis = helper.createApi('clusters', client, paramsMap);

apis.clonaCluster = (clusterId) => {
    return client({
        url: `clusters/${clusterId}/clona`,
        method: 'post',
    });
};

apis.modificaCluster = (clusterId, data) => {
    return client({
        url: `clusters/${clusterId}`,
        method: 'patch',
        data: {
            interessi: data.interessi,
            periodi: data.periodi,
            tipo_turista: data.tipo_turista,
            utm: data.utm,
            tipologia: data.tipologia,
            contatti_email: data.contatti_email,
        },
    });
};

apis.dettagliCluster = (clusterId) => {
    return client({
        url: `clusters/${clusterId}`,
        method: 'get',
    });
};

export default apis;
