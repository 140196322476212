import { routeCreator } from '@adrias-online/steiner/lib/helpers';
import _ from 'lodash';

import routeRegister from 'helpers/routeRegister';
import { generateRoutes } from 'helpers/hotelModuleHelper';
import { selectors } from '../reducers/interazioni';

const routes = generateRoutes('interazioni', selectors);
const list = _.values(routes.list).map((route) => ({
    ...route,
    hotelFilter: true,
    getComponent() {
        return new Promise((resolve) => {
            import(
                /* webpackChunkName: 'interazioni' */ `modules/interazioni/${route.componentPath}`
            ).then((response) => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('interazioni', routes.patterns);
routeRegister.addLinks('interazioni', links);

export default list;
