import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/portaliPhoto';

const routes = helper.generateRoutes('portaliPhoto', selectors);

routes.list.list.breadcrumb = 'Carica Foto';

const list = _.values(routes.list).map(route => ({
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'portaliPhoto' */ `modules/portaliPhoto/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('portaliPhoto', routes.patterns);
routeRegister.addLinks('portaliPhoto', links);

export default list;
