import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/funnel';
import { generateRoutes } from '../../../helpers/funnelModuleHelper';

const routes = generateRoutes('funnel', selectors, {
    omit: ['create']
});

const list = _.values(routes.list).map(route => ({
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'funnel' */ `modules/funnel/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('funnel', routes.patterns);
routeRegister.addLinks('funnel', links);

export default list;
