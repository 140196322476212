import { createApi } from 'helpers/hotelModuleHelper';
import client from 'apis/apiv2';

const apis = createApi('Camera', client);

apis.fetchAssociazioni = idHotel => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({
                data: {
                    255: {
                        1151: true,
                        5341: false,
                        5342: false
                    },
                    256: {
                        1151: true,
                        5341: true,
                        5342: false
                    }
                }
            });
        }, 500);
    });
}

apis.updateAssociazioni = (idHotel, data) => {
    console.warn(data);
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({ data: { success: true }});
            console.info('Associazioni aggiornate');
        }, 1000);
    });
}

export default apis;
