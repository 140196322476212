import client from 'apis/apiCookie';
import helper from 'helpers/steinerHelper';

const apis = helper.createApi('Clienti', client, {
    per_page: 'perPage',
    page: 'page',
    nome: 'q',
    hotel_id: 'hotelId',
});

export default apis;
