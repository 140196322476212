import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import routeRegister from '../../../helpers/routeRegister';
import { generateRoutes } from '../../../helpers/hotelModuleHelper';
import { selectors } from '../reducers/trattamenti';

const routes = generateRoutes('trattamenti', selectors, {
    itemLabelKey: 'names.it'
});

const list = _.values(routes.list).map(route => ({
    ...route,
    hotelFilter: true,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'trattamenti' */ `modules/trattamenti/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('trattamenti', routes.patterns);
routeRegister.addLinks('trattamenti', links);

export default list;
