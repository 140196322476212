import { reducerCreator } from '@adrias-online/steiner';
import { format, subMonths } from 'date-fns';

import helper from '../../../helpers/steinerHelper';
import { actionTypes } from '../actions/portaliArchivioMail';
import { createHandlers } from '../../../helpers/portaleModuleHelper';

const from = format(subMonths(new Date(), 3), 'YYYY-MM-DD');
const to = format(new Date(), 'YYYY-MM-DD');

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            portaleId: null,
            from,
            to
        }
    }
});

const handlers = createHandlers(actionTypes, {
    items: 'data.results',
    total: 'data.meta.total'
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('portaliArchivioMail');
