import helper from 'helpers/steinerHelper';

const defaultActionTypes = helper.createActionTypes('hotelListino');

const customActionTypes = {
    addRoom: 'listino/ADD_ROOM',
    initPricesData: 'listino/INIT_PRICES_DATA',
    removeRoom: 'listino/REMOVE_ROOM',
    selectRoom: 'listino/SELECT_ROOM',
    toggleBoardFromRoom: 'listino/TOGGLE_BOARD_FROM_ROOM'
};

export const actionTypes = Object.assign(
    {},
    defaultActionTypes,
    customActionTypes
);

const defaultActions = helper.createActions('hotelListino', defaultActionTypes);

const customActions = {
    addRoom(id, boards) {
        return {
            type: actionTypes.addRoom,
            payload: {
                id,
                boards
            }
        };
    },
    initPricesData(data) {
        return {
            type: actionTypes.initPricesData,
            payload: data
        };
    },
    removeRoom(roomId) {
        return {
            type: actionTypes.removeRoom,
            payload: roomId
        };
    },
    selectRoom(index) {
        return {
            type: actionTypes.selectRoom,
            payload: index
        };
    },
    toggleBoardFromRoom(roomId, boardId) {
        return {
            type: actionTypes.toggleBoardFromRoom,
            payload: {
                boardId,
                roomId
            }
        };
    }
};

export const actions = Object.assign({}, defaultActions, customActions);
