import client from 'apis/api';

import { createApi } from 'helpers/siteModuleHelper';

const apis = createApi('SitePhoto', client, {
    top: 'perPage',
    page: 'page',
    filter: 'q',
    orderby: 'orderKey',
    orderdir: 'orderDirection'
});

apis.update = function(id, data) {
    return client({
        url: `/SitePhoto/${id}/descriptions`,
        method: 'post',
        data
    });
};

export default apis;
