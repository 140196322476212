import { sagaCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { actions, actionTypes } from '../actions/hotelInterazioni';
import { selectors, DEFAULT_STATE } from '../reducers/hotelInterazioni';
import api from '../apis/hotelInterazioni';

const sagas = helper.createSagas('hotelInterazioni', actionTypes, actions, api, selectors, DEFAULT_STATE);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [
    ...defaultSagas
];