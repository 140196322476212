import { reducerCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { createHandlers } from 'helpers/hotelModuleHelper';
import { actionTypes } from '../actions/elencoAttivita';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            perPage: 20,
            page: 1,
            // orderDirection: 'DESC',
            // orderKey: 'data_iscrizione'
        },
    },
});

const handlers = createHandlers(actionTypes, {
    items: 'data.data',
    total: 'data.meta.total',
    idKey: 'id',
    perPage: 'data.meta.perPage',
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('elencoAttivita');