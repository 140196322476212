import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HotelWidgetLink from 'components/HotelWidgetLink';
import HotelFilterMagnewsLink from 'components/HotelFilterMagnewsLink';
import WithUser from '../WithUser';
import { roles } from 'helpers/auth';

class WidgetGestioneHotel extends Component {
    createInitialFormValues(item) {
        return item;
    }

    render() {
        const { nomeHotel, idHotel, userRole, magnewsDbId, crmContattoId } =
            this.props;

        const isUser = userRole === roles.user;
        const isMultihotel = userRole === roles.multihotel;
        const isProduttore = userRole === roles.produttore;

        return (
            <div className="widget gestione-hotel">
                <h2>Gestisci {nomeHotel}</h2>
                <div className="row">
                    <div className="col-md-4">
                        <HotelWidgetLink
                            idHotel={idHotel}
                            icon="fas fa-tag"
                            to="/hotel/:hotelId/offerte"
                        >
                            Offerte
                        </HotelWidgetLink>
                    </div>
                    <div className="col-md-4">
                        <HotelWidgetLink
                            idHotel={idHotel}
                            icon="fas fa-tags"
                            to="/hotel/:hotelId/categorieOfferte"
                        >
                            Categorie offerte
                        </HotelWidgetLink>
                    </div>
                    {!isProduttore && (
                        <>
                            <div className="col-md-4">
                                <HotelWidgetLink
                                    idHotel={idHotel}
                                    icon="fas fa-bed"
                                    to="/hotel/:hotelId/camere"
                                >
                                    Camere
                                </HotelWidgetLink>
                            </div>
                            <div className="col-md-4">
                                <HotelWidgetLink
                                    idHotel={idHotel}
                                    icon="fas fa-list"
                                    to="/hotel/:hotelId/listini"
                                >
                                    Listini
                                </HotelWidgetLink>
                            </div>
                            <div className="col-md-4">
                                <HotelWidgetLink
                                    idHotel={idHotel}
                                    icon="fas fa-concierge-bell"
                                    to="/hotel/:hotelId/trattamenti"
                                >
                                    Trattamenti
                                </HotelWidgetLink>
                            </div>
                        </>
                    )}
                    {!!magnewsDbId && (
                        <div className="col-md-4">
                            <HotelFilterMagnewsLink
                                magnewsDbId={magnewsDbId}
                                idHotel={idHotel}
                                icon="fas fa-newspaper"
                                to="/iscrittiNewsletter"
                            >
                                Iscritti Newsletter
                            </HotelFilterMagnewsLink>
                        </div>
                    )}
                    <div className="col-md-4">
                        <HotelWidgetLink
                            idHotel={idHotel}
                            icon="fas fa-dolly"
                            to="/hotel/:hotelId/servizi"
                        >
                            Servizi
                        </HotelWidgetLink>
                    </div>
                    {!isUser && !isMultihotel && (
                        <>
                            <div className="col-md-4">
                                <HotelWidgetLink
                                    idHotel={idHotel}
                                    icon="fas fa-images bg-crea"
                                    to="/hotel/:hotelId/mediaGallery"
                                >
                                    Media Gallery
                                </HotelWidgetLink>
                            </div>
                            <div className="col-md-4">
                                <HotelWidgetLink
                                    icon="fas fa-star bg-crea"
                                    to="/hotel/:hotelId/webReputationkeywords"
                                >
                                    Web Reputation Analysis
                                </HotelWidgetLink>
                            </div>
                        </>
                        )}
                    {!isUser && !isMultihotel && (
                        <div className="col-md-4">
                            <HotelWidgetLink
                                icon="fas fa-address-card"
                                to="/hotel/:hotelId"
                            >
                                Anagrafica
                            </HotelWidgetLink>
                        </div>
                    )}
                    {crmContattoId && !isUser && !isMultihotel && (
                        <div className="col-md-4">
                            <HotelWidgetLink
                                to={`/crmContatti/${crmContattoId}`}
                                icon="fas fa-address-book bg-crea"
                            >
                                Rubrica
                            </HotelWidgetLink>
                        </div>
                    )}
                    {!isUser && !isMultihotel && (
                        <div className="col-md-4">
                            <HotelWidgetLink
                                icon="far fa-file-alt bg-crea"
                                to="/hotel/:hotelId/contratti"
                            >
                                Contratto
                            </HotelWidgetLink>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

WidgetGestioneHotel.propTypes = {
    nomeHotel: PropTypes.string,
    idHotel: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isUser: PropTypes.bool,
    crmContattoId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default WithUser(WidgetGestioneHotel);
