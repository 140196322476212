import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import FormWrapper from 'components/FormWrapper';
import { addNotification as notify } from 'reapop';
import TranslatorHoc from './TranslatorHoc';
import InputSideField from 'components/InputSideField';

import { editPersonalData } from 'helpers/auth-api';
import { handleChangePasswordError } from 'helpers/auth';
import FormControls from 'components/FormControls';
import ListFilterWrapper from './ListFilterWrapper';
import { getUser } from '../helpers/auth/reducer';
import { updateProfileSuccess } from '../helpers/auth/actions';
import { validator } from 'validate-this';

export class PersonalData extends Component {
    state = {
        submitSuccessfull: false,
    };

    componentDidMount() {
        this.props.initialize(this.props.user);
    }

    submit = (data) => {
        // TODO: gestire errori
        const { name, lastName, cellulare, whatsapp } = data;

        return editPersonalData(this.props.user.id, {
            name,
            lastName,
            cellulare,
            whatsapp,
        })
            .then(() => {
                this.props.dispatch(
                    notify({
                        title: 'Successo!',
                        message: 'Profilo aggiornato con successo!',
                        status: 'success',
                    })
                );

                this.setState({
                    submitSuccessfull: true,
                });
            })
            .then(() => {
                this.props.dispatch(updateProfileSuccess(data));
            })
            .catch((err) => {
                handleChangePasswordError(err, this.props.dispatch);
            });
    };

    render() {
        const { handleSubmit } = this.props;

        if (this.state.submitSuccessfull) {
            return <Redirect to="/" />;
        }

        return (
            <ListFilterWrapper title="Profilo utente">
                <div className="row">
                    <Helmet>
                        <title>Profilo utente</title>
                    </Helmet>
                    <div className="col-12">
                        <FormWrapper
                            {...this.props}
                            outerControlsClass="row pt-5 pb-3"
                            cancelLink="/"
                            submit={handleSubmit(this.submit)}
                            goBackAfterSave={false}
                            FormControlsComponent={FormControls}
                            widgetSingolo
                            formClassName="form"
                            showCancelBtn={
                                this.props.user.lastName !== '' &&
                                this.props.user.lastName != null
                                    ? true
                                    : false
                            }
                        >
                            <Field
                                className="form-control"
                                name={`name`}
                                placeholder="Nome"
                                label="Nome*"
                                component={InputSideField}
                            />
                            <Field
                                className="form-control"
                                name={`lastName`}
                                placeholder="Cognome"
                                label="Cognome*"
                                component={InputSideField}
                            />
                            <Field
                                className="form-control"
                                name={`cellulare`}
                                placeholder="Cellulare"
                                label="Cellulare"
                                component={InputSideField}
                            />
                            <Field
                                className="form-control"
                                name={`whatsapp`}
                                placeholder="WhatsApp"
                                label="WhatsApp"
                                component={InputSideField}
                            />
                        </FormWrapper>
                    </div>
                </div>
            </ListFilterWrapper>
        );
    }
}

PersonalData.propTypes = {
    dispatch: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialize: PropTypes.func,
    reloadMessage: PropTypes.string,
    unsavedMessage: PropTypes.string,
    user: PropTypes.object,
};

PersonalData.defaultProps = {
    reloadMessage:
        'The page needs to be reloaded in order to apply the changes.',
    unsavedMessage: 'Are you sure? Any unsaved changes will be lost.',
};

function validate(values) {
    return validator(
        values,
        (v) => {
            v.validate('name', 'lastName').required();
        },
        (msg, field) => {
            return 'Campo obbligatorio';
        }
    );
}

const TranslatedPersonalData = TranslatorHoc(PersonalData, {
    reloadMessage: 'steiner.messages.confirmReload',
    unsavedMessage: 'steiner.messages.confirmUnsaved',
});

const ConnectedTranslatedPersonalData = connect((state) => ({
    user: getUser(state),
}))(TranslatedPersonalData);

export default reduxForm({
    form: 'personalData',
    validate,
})(ConnectedTranslatedPersonalData);
