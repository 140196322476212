import helper from '../../../helpers/steinerHelper';
import specsServicesClient from '../../../apis/gerard';

export default helper.createApi(
    'articles/specifications',
    specsServicesClient,
    {
        hotel_id: 'hotel_id',
        page: 'page',
        limit: 'perPage',
        filter: 'q',
    }
);
