// import helper from 'helpers/steinerHelper';
import client from 'apis/apiMarketingSuite';

import apis from '../../hotelInterazioni/apis/hotelInterazioni';

apis.fetchCampagne = (hotelId) => {
    return client({
        url: `campagne?ao_hotel_id=${hotelId}`,
        method: 'get',
    });
};

export default apis;
