import helper from '../../../helpers/steinerHelper';
import client from '../../../apis/gerard';

const api = helper.createApi('IndexSalesSellerTarget', {
    q: 'q',
    seller_id: 'seller_id',
    year: 'year',
    limit: 'perPage',
    page: 'page'

});

api.fetchStatVenditaCommerciali = (seller_id, year) => {
    return client({
        url:`/IndexSalesSellerTarget`,
        params: {
            seller_id,
            year
        }}
    )
};

export default api;