import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {formatCurrency} from '../../helpers/formatters';
import SellerBillingBarChart from './SellerBillingBarChart';
import {
    ImgPlus,
    ImgPotTop,
    ImgBase,
    ImgTop,
    ImgAzienda,
} from '../../modules/hotel/components/ImgRating';
import {fromCustomDashToISO} from '../../helpers/date';

import * as palette from '../../helpers/colors';
import WidgetUltimiContatti from "../../modules/crmContatti/components/WidgetUltimiContatti";
import WidgetUltimiIncarichi from "../../modules/crmContatti/components/WidgetUltimiIncarichi";

const icon_base = 'ff38ce96-268f-4a14-b4d7-d64bd9615b1b';
const icon_plus = 'd144237c-75bb-4f10-8102-d2792dfb68f4';
const icon_pot_top = '3bbaa7b5-8180-4ef8-897d-77d20d0b3d9d';
const icon_top = 'b67a6950-da85-4bd9-aaad-f8c8e5ddf0ac';
const icon_azienda = '9c9c01ce-3943-4644-8ae6-a1635f8e0b15';

const DashboardProduttore = () => {
    const [isFetching, setIsFetching] = useState(true);
    const [datiProduttore, setDatiProduttore] = useState([]);
    const [widthChart, setWidthChart] = useState(0);
    const barChart = useRef(null);

    const {REACT_APP_GERARD} = window.__RUNTIME_CONFIG__;

    useEffect(() => {
        const fetchDatiProduttore = async () => {
            const response = await axios(
                `${REACT_APP_GERARD}/sellers/dashboard`
            );

            const produttoreData = await response.data.data;
            setDatiProduttore(produttoreData);
            setIsFetching(false);
            setWidthChart(barChart.current.offsetWidth);
        };

        fetchDatiProduttore();
    }, []);

    const getSalesGraphData = (data) => {
        return [
            {
                label: `Fatturato mese dell'anno precedente`,
                value: data.previous_sales,
                color: palette.COLOR16,
            },
            {
                label: 'Fatturato mese in corso',
                value: data.in_progress_sales,
                color: palette.COLOR15,
            },
            {
                label: 'Obbiettivo del mese',
                value: data.target_sales,
                color: palette.COLOR18,
            },
        ];
    };

    return (
        <>
            <div className="widget">
                {!isFetching && (
                    <div className="row">
                        <div className="col-md-4">
                            <h2 className="pt-md-3 pb-3">
                                Statistiche vendita
                            </h2>
                            <p>
                                Questo mese hai fatturato{' '}
                                <span className="fw-bold text-primary">
                                    {formatCurrency(
                                        datiProduttore.in_progress_sales
                                    )}
                                </span>
                                .{' '}
                            </p>
                            <p>
                                Ti mancano ancora{' '}
                                <span className="fw-bold text-danger">
                                    {formatCurrency(
                                        datiProduttore.target_sales -
                                        datiProduttore.in_progress_sales
                                    )}
                                </span>
                                .
                            </p>
                            <p>
                                L'anno scorso dal{' '}
                                {fromCustomDashToISO(
                                    datiProduttore.order_date_from
                                )}{' '}
                                al{' '}
                                {fromCustomDashToISO(
                                    datiProduttore.order_date_to
                                )}{' '}
                                hai fatturato{' '}
                                <span className="fw-bold text-tertiary">
                                    {formatCurrency(
                                        datiProduttore.previous_sales
                                    )}
                                </span>
                                .
                            </p>
                            <Link
                                to="/statVenditaCommerciale"
                                className="btn btn-primary my-3"
                            >
                                Report vendite {datiProduttore.business_year}
                            </Link>
                        </div>

                        <div className="col-md-8" ref={barChart}>
                            <SellerBillingBarChart
                                key={'fatturato'}
                                data={getSalesGraphData(datiProduttore)}
                                width={widthChart}
                                height={380}
                                palette={palette}
                            />
                        </div>
                    </div>
                )}
            </div>
            {!isFetching && (
                <div className="row mt-2">
                    <div className="col-md-6">
                        <WidgetUltimiContatti userCodiceProduttore={datiProduttore.seller_id}/>
                    </div>
                    <div className="col-md-6">
                        <WidgetUltimiIncarichi userCodiceProduttore={datiProduttore.seller_id}/>
                    </div>
                </div>
            )}

            {!isFetching && datiProduttore.orders.length !== 0 && (
                <div className="widget">
                    <div className="row">
                        <h2 className="p-3">
                            {`Contratti dal ${fromCustomDashToISO(
                                datiProduttore.order_date_from
                            )} al ${fromCustomDashToISO(
                                datiProduttore.order_date_to
                            )}`}
                        </h2>
                        {datiProduttore.orders.map((a, i) => (
                            <div className="col-md-6 col-lg-4 mb-3" key={i}>
                                <div
                                    className="row py-3 px-2 shadow-sm mx-md-1"
                                    style={{
                                        border: '1px solid rgba(0,0,0,0.125)',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <div className="col-2 mt-2 text-center">
                                        {a.hotel_customer_category ===
                                        icon_base ? (
                                            <ImgBase/>
                                        ) : (
                                            ' '
                                        )}
                                        {a.hotel_customer_category ===
                                        icon_plus ? (
                                            <ImgPlus/>
                                        ) : (
                                            ' '
                                        )}
                                        {a.hotel_customer_category ===
                                        icon_pot_top ? (
                                            <ImgPotTop/>
                                        ) : (
                                            ' '
                                        )}
                                        {a.hotel_customer_category ===
                                        icon_top ? (
                                            <ImgTop/>
                                        ) : (
                                            ' '
                                        )}
                                        {a.hotel_customer_category ===
                                        icon_azienda ? (
                                            <ImgAzienda/>
                                        ) : (
                                            ' '
                                        )}
                                    </div>
                                    <div className="col-10">
                                        <div className="text-secondary fw-bold mb-2 text-truncate">
                                            {a.hotel_name} - {a.hotel_city} (
                                            {a.hotel_id})
                                        </div>
                                        <div className="fst-italic mb-2 text-truncate">
                                            Data:{' '}
                                            {fromCustomDashToISO(
                                                a.order_creation_date.split(
                                                    ' '
                                                )[0]
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col-6 border-end">
                                                Internet:
                                                <br/>
                                                {a.total_internet === 0
                                                    ? '-'
                                                    : formatCurrency(
                                                        a.total_internet
                                                    )}
                                            </div>
                                            <div className="col-6">
                                                Stampa:
                                                <br/>
                                                {a.total_paper === 0
                                                    ? '-'
                                                    : formatCurrency(
                                                        a.total_paper
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex pt-3">
                                        <Link
                                            className="btn btn-primary w-50"
                                            to={`hotel/${a.hotel_id}/contratti`}
                                        >
                                            Contratto
                                        </Link>
                                        <Link
                                            className="btn btn-secondary w-50 ms-2"
                                            to={`ordini/${a.order_id}`}
                                        >
                                            Ordini
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

        </>
    );
};

export default DashboardProduttore;
