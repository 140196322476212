import { createSagas, bootSagas } from 'helpers/hotelModuleHelper';

import { actions, actionTypes } from '../actions/hotelConfig';
import { selectors, DEFAULT_STATE } from '../reducers/hotelConfig';
import api from '../apis/hotelConfig';

const sagas = createSagas('hotelConfig', actionTypes, actions, api, selectors, DEFAULT_STATE);

const defaultSagas = bootSagas(sagas, actionTypes);

export default [
    ...defaultSagas
];