import { reducerCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { actionTypes } from '../actions/sitePage';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            language: null
        }
    }
});

const handlers = helper.createHandlers(actionTypes);

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('sitePage');
