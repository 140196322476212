import { routeCreator } from '@adrias-online/steiner';
import _ from 'lodash';

import { generateRoutes } from 'helpers/hotelModuleHelper';
import routeRegister from 'helpers/routeRegister';
import { selectors } from '../reducers/interazioniPagine';

const routes = generateRoutes('interazioniPagine', selectors);
const list = _.values(routes.list).map((route) => ({
    ...route,
    hotelFilter: true,
    getComponent() {
        return new Promise((resolve) => {
            import(
                /* webpackChunkName: 'interazioniPagine' */ `modules/interazioniPagine/${route.componentPath}`
            ).then((response) => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('interazioniPagine', routes.patterns);
routeRegister.addLinks('interazioniPagine', links);

export default list;
