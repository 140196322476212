import { reducerCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { createHandlers } from 'helpers/hotelModuleHelper';
import { actionTypes } from '../actions/mediaGalleryPrivata';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            categoryId: null,
            hotelId: null,
            page: 1,
            limit: 20,
        },
    },
});

const handlers = createHandlers(actionTypes, {
    items: 'data.data',
    idKey: 'nomeFile',
    total: 'data.meta.total',
    per_page: 'data.meta.per_page',
});


export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('mediaGalleryPrivata');