import { put, call, select, all } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { sagaCreator } from '@adrias-online/steiner/lib/helpers';
import { format, parse, subYears } from 'date-fns';
import _ from 'lodash';

import api from '../apis/venduto';

import helper from '../../../helpers/steinerHelper';
import { actions, actionTypes } from '../actions/venduto';
import { selectors, DEFAULT_STATE } from '../reducers/venduto';

const sagas = helper.createSagas(
    'venduto',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE
);

function getPrevYearDate(date) {
    const d = parse(date);

    return format(subYears(d, 1), 'YYYY-MM-DD');
}

function getCompareData(rawData, ids) {
    return _.filter(rawData, h => _.includes(ids, h.hotel_id));
}

function* getApiListParams(selectors) {
    return yield select(selectors.getFilters);
}

sagas.fetchList = function*() {
    yield call(delay, 100);

    let currentRes, prevYearRes, prevPeriodRes;

    try {
        const params = yield call(getApiListParams, selectors);

        if (params.compare_with_last_year) {
            const prevYear = parseInt(params.article_year, 10) - 1;

            const prevYearParams = {
                ...params,
                article_year: prevYear,
                from_date: null,
                to_date: null
            };

            const prevPeriodParams = {
                ...params,
                article_year: prevYear,
                from_date:
                    params.from_date !== null
                        ? getPrevYearDate(params.from_date)
                        : null,
                to_date:
                    params.to_date !== null
                        ? getPrevYearDate(params.to_date)
                        : null
            };

            [currentRes, prevYearRes, prevPeriodRes] = yield all([
                call(api.list, params),
                call(api.list, prevYearParams),
                call(api.list, prevPeriodParams)
            ]);
        } else {
            currentRes = yield call(api.list, params);
            prevYearRes = { data: { data: [] }};
            prevPeriodRes = { data: { data: [] }};
        }

        const action = {
            type: actionTypes.listSuccess,
            payload: currentRes,
            loadingBar: 'hide'
        };

        yield put(action);

        const currentIds = yield select(selectors.itemsIdSelector);

        yield put(actions.setComparisonPeriodData(prevPeriodRes.data.data));
        yield put(actions.setComparisonYearData(getCompareData(prevYearRes.data.data, currentIds)));
    } catch (error) {
        const action = {
            type: actionTypes.listFail,
            error,
            loadingBar: 'hide'
        };

        yield put(action);
    }
};

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [...defaultSagas];
