import sortBy from 'lodash/sortBy';
import React from 'react';
import * as PropTypes from 'prop-types';
import { formatCurrency } from '../../../helpers/formatters';

export function handleFilter(id, items, macro = null) {
    return items.map(order => {
        const { orders } = order;

        const filteredOrders = orders.map(row => {
            const { order_rows } = row;

            const filtered =
                macro !== null
                    ? order_rows.reduce((prev, order) => {
                          if (order.macros != null
                              && order.macros.includes(id)) {
                              prev.push({
                                  ...order
                              });
                          }

                          return prev;
                      }, [])
                    : order_rows.reduce((prev, order) => {
                          if (id === order.article_code) {
                              prev.push({
                                  ...order
                              });
                          }

                          return prev;
                      }, []);

            return {
                ...row,
                order_rows: filtered
            };
        });

        return {
            ...order,
            orders: filteredOrders,
            totalFiltered: getOrderTotal(filteredOrders, 'article_total')
        };
    });
}

export function getOrderTotal(
    orders,
    field,
    isFilteredWithArticle = false,
    article_id = null
) {
    if (isFilteredWithArticle) {
        return orders.reduce((prev, order) => {
            return (
                prev +
                order.order_rows.reduce((acc, row) => {
                    if (row.article_code === article_id) {
                        return acc + row[field];
                    }

                    return acc;
                }, 0)
            );
        }, 0);
    }

    return orders.reduce((prev, order) => {
        return (
            prev + order.order_rows.reduce((acc, row) => acc + row[field], 0)
        );
    }, 0);
}

export function getOrderedItems(handledItems, sortLabel, sortDirection) {
    let items = sortBy(handledItems, sortLabel);

    if (sortDirection === 'DESC') {
        items = items.reverse();
    }

    return items;
}

export function renderDiffCell(
    totalKey,
    listTotalKey,
    isFilteredWithMacro = false,
    isFilteredWithArticle = false,
    article_id = null
) {
    return function DiffCell({ rowData }) {
        const total =
            !isFilteredWithMacro && !isFilteredWithArticle
                ? rowData[totalKey]
                : isFilteredWithArticle
                ? getOrderTotal(rowData.orders, totalKey, true, article_id)
                : getOrderTotal(rowData.orders, totalKey);

        const list_total =
            !isFilteredWithMacro && !isFilteredWithArticle
                ? rowData[listTotalKey]
                : isFilteredWithArticle
                ? getOrderTotal(rowData.orders, listTotalKey, true, article_id)
                : getOrderTotal(rowData.orders, listTotalKey);

        const diff = total - list_total;
        const perc = list_total > 0 ? 100 - (total * 100) / list_total : -100;

        if (diff === 0) {
            return <span>-</span>;
        }

        const diffClass = diff > 0 ? 'text-success' : 'text-danger';

        return (
            <span className={diffClass}>
                {formatCurrency(diff)} / {perc.toFixed(2) * -1} %
            </span>
        );
    };
}

renderDiffCell.propTypes = {
    rowData: PropTypes.object
};
