import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';

export default class ModalHelp extends Component {
    render() {
        const { isOpen, onClose } = this.props;

        return (
            <Modal
                className="modal-dialog"
                isOpen={isOpen}
                onClose={onClose}
                staticModal={false}
            >
                <div className="modal-content widget">
                    <div className="modal-header">
                        <h2 className="p-3 pb-0">{window.__RUNTIME_CONFIG__.REACT_APP_NAME}</h2>
                        <button
                            type="button"
                            className="btn btn-link"
                            onClick={onClose}
                            style={{ color: '#999' }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h4>Scorciatoie da tastiera globali</h4>
                        <ul>
                            <li>
                                <strong>CTRL+B:</strong> Mostra/nascondi menù
                                laterale
                            </li>
                            <li>
                                <strong>CTRL+P:</strong> Mostra/nascondi
                                pannello azioni veloci
                            </li>
                            <li>
                                <strong>CTRL+H:</strong> Mostra nascondi
                                finestra di aiuto
                            </li>
                        </ul>
                        {/*<h4>Scorciatoie da tastiera nelle liste</h4>
                        <ul>
                            <li><strong>CTRL+D:</strong> Crea un nuovo oggetto</li>
                        </ul>*/}
                        <h4>Scorciatoie da tastiera nei form</h4>
                        <ul>
                            <li>
                                <strong>CTRL+S:</strong> Salva l'oggetto
                            </li>
                        </ul>
                    </div>
                </div>
            </Modal>
        );
    }
}

ModalHelp.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired
};

ModalHelp.defaultProps = {
    isOpen: false
};
