import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { createFormAction } from '../helpers/reduxFormSaga.js';
import auth from '../helpers/auth/index.js';
import { Helmet } from 'react-helmet';
import InputField from 'components/form/v1/InputField';
import LoadingButton from 'components/form/v1/LoadingButton';

import LoginWrapper from './auth/LoginWrapper';

class LoginForm extends Component {
    constructor(props) {
        super(props);

        this.submit = (data, dispatch) => {
            const action = createFormAction(auth.actionTypes.login, [
                auth.actionTypes.loginSuccess,
                auth.actionTypes.loginFail,
            ]);

            return action(data, dispatch);
        };
    }

    componentDidMount() {
        this.form.elements[0].focus();
    }

    render() {
        const { handleSubmit, submitting, error } = this.props;

        return (
            <>
                <Helmet>
                    <title>Login</title>
                </Helmet>
                <LoginWrapper
                    // title={`${window.__RUNTIME_CONFIG__.REACT_APP_NAME} | Login`}
                    // title={`Area Riservata`}
                    // title={`Login`}
                    message={
                        error && (
                            <div className="alert alert-danger">{error}</div>
                        )
                    }
                >
                    <form
                        ref={(form) => (this.form = form)}
                        onSubmit={handleSubmit(this.submit)}
                        className="py-3"
                    >
                        <div className="mb-4">
                            <div className="mb-2">Email</div>
                            <Field
                                className="form-control"
                                name="email"
                                placeholder="Email"
                                component={InputField}
                                onlyInput={true}
                            />
                        </div>
                        <div className="mb-4">
                            <div className="mb-2">Password</div>
                            <Field
                                className="form-control"
                                name="password"
                                placeholder="Password"
                                component={InputField}
                                type="password"
                                onlyInput={true}
                            />
                        </div>
                        <div className="mb-4 text-end">
                            <LoadingButton
                                className="btn-success btn-block w-100"
                                loading={submitting}
                            >
                                <i className="fas fa-sign-in me-2" /> Accedi
                                all'area riservata
                            </LoadingButton>
                        </div>
                        <div className="col-12 text-end">
                            <Link to="/recuperoPassword">
                                Password dimenticata?
                            </Link>
                        </div>
                    </form>
                </LoginWrapper>
            </>
        );
    }
}

LoginForm.propTypes = {
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
};

export default reduxForm({
    form: 'login',
})(LoginForm);
