import helper from 'helpers/steinerHelper';
import apiClient from 'apis/api';

const apis = helper.createApi('SephirothMailConfigs', apiClient, {
    top: 'perPage',
    page: 'page',
    filter: 'q',
    id: 'id',
    sites_type_id: 'sites_type_id',
    sites_id: 'sites_id',
    mail_provider_type_id: 'mail_provider_type_id',
    orderby: 'orderKey',
    orderdir: 'orderDirection'
});

export default apis;