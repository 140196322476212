import { sagaCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { actions, actionTypes } from '../actions/commerciali';
import { selectors, DEFAULT_STATE } from '../reducers/commerciali';
import api from '../apis/commerciali';

const sagas = helper.createSagas(
    'commerciali', 
    actionTypes, 
    actions, 
    api, 
    selectors, 
    DEFAULT_STATE,
    {
        idKey: 'codice'
    }
);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [...defaultSagas];