import { reducerCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import { actionTypes } from '../actions/funnel';
import { createHandlers } from '../../../helpers/funnelModuleHelper';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            seller_code: null,
            hotel_id: null,
            year: null
        }
    }
});

const handlers = createHandlers(actionTypes, {
    items: 'data.data',
    total: 'data.meta.total'
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('funnel');
