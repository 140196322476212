import helper from '../../../helpers/steinerHelper';
import client from 'apis/apiMonitorRichiesteCrm';


const paramsMap = {
    limit: 'perPage',
    page: 'page',
    aoHotelId: 'hotelId',
    dateRequest: 'dateRequest',
    q: 'q'
};

export default helper.createApi('requests',client,paramsMap);