import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { selectors } from '../reducers/currentPortaleV2';

class PortaleWidgetLink extends Component {
    render() {
        const { children, portaleId, to, icon, classes } = this.props;

        if (!portaleId) {
            return (
                <NavLink className="link-disabled">
                    <i className={icon} aria-hidden="true" /> {children}
                </NavLink>
            );
        }

        const location = to.replace(/:portaleId/, portaleId);

        return (
            <div className="widget-link">
                <NavLink className={classes} to={location}>
                    <i className={`${icon} widget-icon`} aria-hidden="true" />{' '}
                    {children}
                </NavLink>
            </div>
        );
    }
}

PortaleWidgetLink.propTypes = {
    children: PropTypes.any,
    portaleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    to: PropTypes.string,
    icon: PropTypes.string,
    classes: PropTypes.string,
};

export default withRouter(
    connect((state) => ({
        portaleId: selectors.getCurrentPortaleId(state),
    }))(PortaleWidgetLink)
);
