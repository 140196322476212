import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { selectors } from '../reducers/currentHotel';

class HotelFilterLink extends Component {
    render() {
        const { children, hotelId, to, type, className } = this.props;

        if (!hotelId) {
            // return <NavLink className="link-disabled">{children}</NavLink>;
            return <span className="link-disabled">{children}</span>;
        }

        const location = {
            pathname: to,
            search:
                type === 'underscore'
                    ? `?hotel_id=${hotelId}`
                    : `?hotelId=${hotelId}`,
        };

        return (
            <NavLink to={location} className={className ? className : ''}>
                {children}
            </NavLink>
        );
    }
}

HotelFilterLink.propTypes = {
    children: PropTypes.any,
    hotelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    to: PropTypes.string,
    type: PropTypes.string,
};

export default withRouter(
    connect((state) => ({ hotelId: selectors.getCurrentHotelId(state) }))(
        HotelFilterLink
    )
);
