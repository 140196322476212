import helper from 'helpers/steinerHelper';
import client from 'apis/apiCalendarioAttivita';

const paramsMap = {
    limit: 'perPage',
    page: 'page',
    filter: 'q',
    aoHotelId: 'ao_hotel_id',
};

const apis = helper.createApi('attivita', client, paramsMap);

export default apis;
