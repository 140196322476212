import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import apiClient from '../apis/portali';
import { actions } from '../actions/currentPortale';
import { selectors } from '../reducers/currentPortaleV2';

import SelectAsync from '@adrias-online/vivi/lib/SelectAsync';
import { Redirect, Switch } from 'react-router';
import { Link } from 'react-router-dom';

function fetchPortali(q, resolve) {
    apiClient.get(`sito?query=${q}`).then((res) => {
        const options = res.data.data.map((i) => ({
            value: i.id,
            label: `${i.name} (${i.id})`,
        }));

        resolve({ options });
    });
}

const debouncFetchedPortali = _.debounce(fetchPortali, 500);

function loadPortali(q) {
    return new Promise((resolve) => {
        debouncFetchedPortali(q, resolve);
    });
}

class SelectPortaleV2 extends Component {
    state = {
        redirect: false,
    };

    handleChange = (value) => {
        if (_.isNull(value) || _.isArray(value)) {
            return this.props.onChange({
                value: null,
                label: null,
            });
        }

        if (window.location.pathname === '/dashboardPortali') {
            this.props.toggleCambioPortale();
        }

        this.setState({ redirect: !this.state.redirect });

        this.props.onChange(value);
    };

    render() {
        const { autofocus, currentPortale } = this.props;

        if (
            this.state.redirect &&
            window.location.href.indexOf(`/dashboardPortali`) === -1
        ) {
            return (
                <Switch>
                    <Redirect push to={`/dashboardPortali`} />
                </Switch>
            );
        }

        return (
            <div className="d-flex dashboard-select-v2">
                <SelectAsync
                    autoFocus={autofocus}
                    ref={(ref) => (this.select = ref)}
                    value={
                        currentPortale && currentPortale.value
                            ? currentPortale
                            : null
                    }
                    placeholder="Seleziona un Portale"
                    loadOptions={loadPortali}
                    onChange={this.handleChange}
                    clearable={false}
                    openOnFocus={true}
                    className="flex-grow-1"
                />
                <Link to={`/dashboardPortali`} className={'btn btn-tertiary'}>
                    <i className="fas fa-chevron-right" />
                </Link>
            </div>
        );
    }
}

SelectPortaleV2.propTypes = {
    autofocus: PropTypes.bool,
    currentPortale: PropTypes.object,
    onChange: PropTypes.func,
};

SelectPortaleV2.defaultProps = {
    autofocus: false,
};

function mapStateToProps(state) {
    return {
        currentPortale: selectors.getCurrentPortale(state),
    };
}

function mapDispatchToProps(dispatch) {
    const boundActions = bindActionCreators(actions, dispatch);

    return {
        onChange: boundActions.setPortale,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectPortaleV2);
