import { routeCreator } from '@adrias-online/steiner';
import _ from 'lodash';

import { generateRoutes } from '../../../helpers/hotelModuleHelper';
import routeRegister from 'helpers/routeRegister';
import { selectors } from '../reducers/WebReputationKeywords';

const patternWebReputationKeywords = '/hotel/:id/WebReputationKeywords';

const routes = generateRoutes('WebReputationKeywords', selectors);

routes.list.list = {
    breadcrumb: 'Web Reputation Analysis',
    componentPath: 'containers/WebReputationKeywordsListLayout',
    path: patternWebReputationKeywords,
    hotelFilter: true,
};

const list = _.values(routes.list).map((route) => ({
    ...route,
    hotelFilter: true,
    getComponent() {
        return new Promise((resolve) => {
            import(
                /* webpackChunkName: 'WebReputationKeywords' */ `modules/WebReputationKeywords/${route.componentPath}`
            ).then((response) => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('WebReputationKeywords', routes.patterns);
routeRegister.addLinks('WebReputationKeywords', links);

export default list;
