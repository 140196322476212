import { sagaCreator } from '@adrias-online/steiner';
import helper from 'helpers/steinerHelper';

import { actions, actionTypes } from '../actions/cookies';
import { selectors, DEFAULT_STATE } from '../reducers/cookies';
import api from '../apis/cookies';

const sagas = helper.createSagas(
    'cookies',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE,
    {
        numberFilters: ['hotelId']
    }
);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [...defaultSagas];
