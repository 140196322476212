import { createSagas, bootSagas } from 'helpers/hotelModuleHelper';

import { actions, actionTypes } from '../actions/mediaGalleryPrivata';
import { selectors, DEFAULT_STATE } from '../reducers/mediaGalleryPrivata';
import api from '../apis/mediaGalleryPrivata';

const sagas = createSagas('mediaGalleryPrivata', actionTypes, actions, api, selectors, DEFAULT_STATE);

const defaultSagas = bootSagas(sagas, actionTypes);

export default [
    ...defaultSagas
];