import { reducerCreator } from '@adrias-online/steiner';
import Immutable from 'seamless-immutable';

import { actionTypes } from '../actions/currentPortale';

export const DEFAULT_STATE = Immutable({
    label: null,
    value: null,
    item: null,
});

function setPortale(state, action) {
    if (action.payload) {
        return Immutable(action.payload);
    }

    return DEFAULT_STATE;
}

const handlers = {
    [actionTypes.setPortale]: setPortale,
    [actionTypes.syncPortale]: setPortale
};

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = {
    getCurrentPortale(state) {
        return state.currentPortale;
    },
    getCurrentPortaleId(state) {
        return state.currentPortale.value;
    }
};
