import helper from '../../../helpers/steinerHelper';
import client from 'apis/apiFatturatoHotel';
import { buildParams } from '@adrias-online/steiner/lib/helpers/apiCreator';

const paramsMap = {
    limit: 'perPage',
    page: 'page',
    aoHotelId: 'hotelId',
    dataInizio: 'dataInizio',
    dataFine: 'dataFine',
    status: 'status'
};

const paramsMapMedia = {
    aoHotelId: 'hotelId',
};

const apis =  helper.createApi('bookings',client,paramsMap);

apis.fetchMedia = function(filters) {
    return client({
        url: '/bookings/index',
        params: buildParams(filters, paramsMapMedia)
    });
};

export default apis;