import client from '../../../apis/contrattiNew';

import helper from '../../../helpers/steinerHelper';
import { buildParams } from '@adrias-online/steiner/lib/helpers/apiCreator';

const paramsMap = {
    limit: 'perPage',
    page: 'page',
    orderBy: 'orderKey',
    orderDir: 'orderDirection',
    hotel_id: 'hotel_id',
    article_code: 'article_code',
    article_year: 'article_year',
    row_hotel_id: 'row_hotel_id',
    produttore: 'produttore',
    reg_date_from: 'from_date',
    reg_date_to: 'to_date',
    hotel_customer_category: 'hotel_customer_category'
};

const apis = helper.createApi('orders', client, paramsMap);

apis.fetchMedia = function(filters) {
    return client({
        // url: '/price-list',
        url: '/sales/average',
        params: buildParams(filters, paramsMap)
    });
};

export default apis;
