import helper from 'helpers/steinerHelper';

const defaultActionTypes = helper.createActionTypes('sitePhoto');
export const actionTypes = Object.assign({}, defaultActionTypes, {
    addPhoto: 'sitePhoto/ADD_PHOTO'
});

const defaultActions = helper.createActions('sitePhoto', actionTypes);
export const actions = Object.assign({}, defaultActions, {
    addPhoto(data) {
        return {
            type: actionTypes.addPhoto,
            payload: data
        };
    }
});
