import client from 'apis/api';

import { createApi } from 'helpers/siteModuleHelper';

export default createApi('SitePage', client, {
    top: 'perPage',
    page: 'page',
    filter: 'q',
    orderby: 'orderKey',
    orderdir: 'orderDirection'
});
