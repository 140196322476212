import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import { selectors } from '../reducers/portaliRedirects';
import routeRegister from '../../../helpers/routeRegister';
import { generateRoutes } from '../../../helpers/portaleModuleHelper';

const routes = generateRoutes('redirect', selectors);

const list = _.values(routes.list).map(route => ({
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'portaliRedirects' */ `modules/portaliRedirects/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('portaliRedirects', routes.patterns);
routeRegister.addLinks('portaliRedirects', links);

export default list;
