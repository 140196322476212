import { routeCreator } from '@adrias-online/steiner';
import _ from 'lodash';

import routeRegister from 'helpers/routeRegister';
import { generateRoutes } from '../../../helpers/hotelModuleHelper';
import { selectors } from '../reducers/clusters';

const routes = generateRoutes('clusters', selectors);
const list = _.values(routes.list).map((route) => ({
    ...route,
    hotelFilter: true,
    getComponent() {
        return new Promise((resolve) => {
            import(
                /* webpackChunkName: 'clusters' */ `modules/clusters/${route.componentPath}`
            ).then((response) => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('clusters', routes.patterns);
routeRegister.addLinks('clusters', links);

export default list;
