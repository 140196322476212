import React, {Component} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import {getUserRole} from '../reducers/selectors';
import WithUser from './WithUser';

const BackButton = () => {
    const history = useHistory();

    const redirect = () => {
        history.goBack();
    }

    return (
        <button
            className="btn btn-sm btn-primary"
            onClick={redirect}>
            <i className="fas fa-long-arrow-alt-left me-2"/>{' '}
            {'Indietro'}
        </button>
    );
};

class ListFilterWrapper extends Component {

    render() {
        const {
            title,
            subtitle,
            btnLink,
            btnTitle,
            lastCol,
            lastColClass,
            userRole,
            useBackHistory
        } = this.props;

        const titleClass =
            (btnLink && btnTitle) || useBackHistory ? 'col-md-6' : 'col-md-6 offset-md-3';

        const roleBtnUrl = userRole === 'user' ? '/' : btnLink;
        const roleBtnTitle = userRole === 'user' ? 'Dashboard' : btnTitle;

        // const useBh = useBackHistory === true && btnLink === undefined;

        return (
            <>
                <div className="row py-3 d-none d-md-flex">
                    {useBackHistory && (
                        <div className="col-md-3">
                            <BackButton />
                        </div>
                    )}
                    {btnLink && btnTitle && (
                        <div className="col-md-3">
                            <Link
                                className="btn btn-sm btn-primary"
                                to={roleBtnUrl}
                            >
                                <i className="fas fa-long-arrow-alt-left me-2"></i>{' '}
                                {roleBtnTitle}
                            </Link>
                        </div>
                    )}
                    <div className={`text-center ${titleClass}`}>
                        <h2 className="text-primary">{title}</h2>
                        {subtitle && <div> {subtitle}</div>}
                    </div>
                    {lastCol && (
                        <div className={`col-md-3 ${lastColClass}`}>
                            {lastCol}
                        </div>
                    )}
                </div>
                <div className="py-2 d-flex align-items-center d-md-none">
                    {btnLink && (
                        <div className="btn btn-outline-primary me-3">
                            <Link to={roleBtnUrl}>
                                <i className="fas fa-arrow-left fa-lg text-primary"></i>
                            </Link>
                        </div>
                    )}
                    <div className="flex-grow-1">
                        <h2 className="text-primary text-center mb-0">
                            {title}
                        </h2>
                    </div>
                    {btnLink && (
                        <div className="ms-3" style={{width: '45px'}}></div>
                    )}
                </div>
                {this.props.children}
            </>
        );
    }
}

// export default ListFilterWrapper;

function mapStateToProps(state) {
    return {
        userRole: getUserRole(state),
    };
}

export default connect(mapStateToProps)(WithUser(ListFilterWrapper));
