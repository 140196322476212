// import { call } from 'redux-saga/effects';
// import { sagaCreator } from '@adrias-online/steiner';
// import { takeEvery } from 'redux-saga';
// import _ from 'lodash';

// import helper from 'helpers/steinerHelper';
import { createSagas, bootSagas } from 'helpers/hotelModuleHelper';
import { actions, actionTypes } from '../actions/offerte';
import { selectors, DEFAULT_STATE } from '../reducers/offerte';
// import { actionTypes as currentHotelActionTypes } from 'actions/currentHotel';
// import { selectors as currentHotelSelectors } from 'reducers/currentHotel';
import api from '../apis/offerte';

// function* getApiListParams() {
//     const filters = yield select(selectors.getFilters);
//     const hotelId = yield select(currentHotelSelectors.getCurrentHotelId);

//     return [hotelId, filters];
// }

function updateFailErrorCreator(error) {
    if (error && error.response && error.response.status === 401) {
        return {
            message:
                "Non disponi dei privilegi necessari per aggiornare l'offerta"
        };
    }

    return {
        message: 'Si è verificato un errore inaspettato'
    };
}

const sagas = createSagas(
    'offerte',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE,
    {
        updateFailErrorCreator
    }
);

// const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);
const defaultSagas = bootSagas(sagas, actionTypes);

export default [
    ...defaultSagas
    // takeEvery(currentHotelActionTypes.setHotel, sagas.setHotel)
];
