export function getUserRole(state) {
    return state.user ? state.user.roleName : null;
}

export function getUserToken(state) {
    return state.user ? state.user.token : null;
}

export function getUserConfigId(state) {
    if (state.user && state.user.role === 'multihotel') {
        const {
            user: { userAssociations },
            currentHotel
        } = state;

        const map1 = userAssociations.config_multihotel.map(id => id.hotel_id);
        const indiceHotelId = map1.findIndex(
            element => element === currentHotel.value
        );
        return state.user && indiceHotelId > -1
            ? state.user.userAssociations.config_multihotel[indiceHotelId]
                  .config_id
            : null;
    }
    if (state.user && state.user.role === 'user') {
        return state.user ? state.user.userAssociations.config_id : null;
    }
}

export function getUserCodiceProduttore(state) {
    return state.user ? state.user.userAssociations.codice_produttore : null;
}

export function getUserHotelId(state) {
    return state.user ? state.user.userAssociations.hotel_id : null;
}

// export function getUserMagnewsDb(state) {
//     // console.log(state)
//     return state.user ? 13 : null;
// }

export function getUserPermissions(state) {
    return state.user ? state.user.permissions.all : null;
}
