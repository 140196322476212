import React, { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import { withRouter } from 'react-router-dom';
import advMediaGallery from 'images/web-reputation/adv-media-gallery.png';
import webReputation from 'images/web-reputation/web-reputation-img.png';
import ModalAssistenza from './ModalAssistenza';

let MediaGalleryCarousel = (props) => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [isShow, setIsShow] = useState(true);
    const [modalCommercialeOpen, setModalCommercialeOpen] = useState(false);

    const handleClick = () => {
        setIsShow((current) => !current);
        sessionStorage.setItem(
            'carousel_WR',
            JSON.stringify({ visible: false })
        );
    };

    const elementi = [
        <div
            className="row widget p-0 container"
            style={{ width: '1260px', height: '100%', boxShadow: 'none' }}
        >
            <div className="col-md-4 p-4 m-auto text-left">
                <span
                    className="text-primary fw-bold"
                    style={{ fontSize: '1.6rem' }}
                >
                    Attiva subito la Media Gallery di Adrias Online, una
                    raccolta di immagini professionali per persuadere i tuoi
                    potenziali clienti e aumentare le prenotazioni.
                </span>
                <p className="fw-bold py-2">
                    Gestisci le tue foto della struttura e le migliori foto
                    royalty free per le tue offerte.
                </p>
            </div>
            <div className="col-md-4 m-auto">
                <img
                    className="img-fluid"
                    src={advMediaGallery}
                    alt="adv media gallery"
                    style={{ maxHeight: '250px' }}
                />
            </div>
            <div className="col-md-4 p-4  text-center position-relative">
                <button
                    onClick={handleClick}
                    className="position-absolute"
                    style={{
                        border: 0,
                        background: 'transparent',
                        top: '5px',
                        right: '5px',
                    }}
                >
                    <i className="fal fa-times-circle"></i>
                </button>
                <div className="d-flex h-100">
                    <button
                        onClick={() => setModalCommercialeOpen(true)}
                        className="btn  btn-sm btn-tertiary m-auto"
                    >
                        <i className="fas fa-plus me-2"></i> Attiva la tua Media
                        Gallery
                    </button>
                </div>
            </div>
        </div>,
        <div
            className="row widget p-0 container"
            style={{ width: '1260px', height: '100%', boxShadow: 'none' }}
        >
            <div className="col-md-4 p-4 m-auto text-left">
                <span
                    className="text-primary fw-bold"
                    style={{ fontSize: '1.6rem' }}
                >
                    Cosa pensano i turisti della tua struttura? Quali sono i
                    servizi che vengono visti come i tuoi punti di forza? Quali
                    vengono percepiti come più carenti?
                </span>
                <p className="fw-bold py-2">
                    Scopri come è posizionata la tua struttura nel mercato
                    confrontandola con centinaia di strutture simili monitorate.
                </p>
            </div>
            <div className="col-md-4 text-center m-auto">
                <img
                    className="img-fluid"
                    src={webReputation}
                    alt="adv media gallery"
                    style={{ maxHeight: '250px' }}
                />
            </div>
            <div className="col-md-4 p-4 text-center position-relative">
                <button
                    onClick={handleClick}
                    className="position-absolute"
                    style={{
                        border: 0,
                        background: 'transparent',
                        top: '5px',
                        right: '5px',
                    }}
                >
                    <i className="fal fa-times-circle"></i>
                </button>
                <div className="d-flex h-100">
                    <button
                        onClick={() => setModalCommercialeOpen(true)}
                        className="btn  btn-sm btn-tertiary m-auto"
                    >
                        <i className="fas fa-plus me-2"></i> Attiva la Web
                        Reputation Analysis
                    </button>
                </div>
            </div>
        </div>,
    ];
    return (
        <>
            <ModalAssistenza
                isOpen={modalCommercialeOpen}
                onClose={() => setModalCommercialeOpen(false)}
                motivo="commerciale"
            />
            {isShow && (
                <ReactSimplyCarousel
                    activeSlideIndex={activeSlideIndex}
                    onRequestChange={setActiveSlideIndex}
                    itemsToShow={1}
                    itemsToScroll={1}
                    autoplay={true}
                    autoplayDelay={5000}
                    children={elementi}
                    delay={0}
                    easing={'linear'}
                    forwardBtnProps={{
                        show: false,
                    }}
                    backwardBtnProps={{
                        //here you can also pass className, or any other button element attributes
                        show: false,
                    }}
                    dotsNav={{
                        show: true,
                        containerProps: {
                            className: 'position-absolute',
                            style: {
                                bottom: '5px',
                                right: '10px',
                                width: 'unset',
                            },
                        },
                        itemBtnProps: {
                            style: {
                                borderRadius: '16px',
                                height: '15px',
                                width: '15px',
                                margin: '0 5px',
                                border: '1px solid #d1d1d1',
                                background: '#fff',
                            },
                        },
                        activeItemBtnProps: {
                            style: {
                                borderRadius: '16px',
                                height: '15px',
                                width: '15px',
                                margin: '0 5px',
                                border: '1px solid #5e7168',
                                background: '#d1d1d1',
                            },
                        },
                    }}
                    speed={500}
                    containerProps={{
                        className: 'position-relative',
                    }}
                ></ReactSimplyCarousel>
            )}
        </>
    );
};

export default withRouter(MediaGalleryCarousel);
