import helper from 'helpers/steinerHelper';

import client from 'apis/security';

const api = helper.createApi('management/notification', client, {
    limit: 'perPage',
    page: 'page',
    filter: 'q',
    orderBy: 'orderKey',
    sort: 'orderDirection',
    unseen: 'unseen'
});

api.fetch = function(id) {
    return client({
        url: `/management/notification/${id}/open`
    });
};

api.read = function(id) {
    return client({
        url: `/management/notification/${id}/read`
    });
};

export default api;
