import { routeCreator } from '@adrias-online/steiner';
import _ from 'lodash';

import helper from 'helpers/steinerHelper';
import routeRegister from 'helpers/routeRegister';
import { selectors } from '../reducers/CriteriQualitativi';

const routes = helper.generateRoutes('criteriQualitativi', selectors);
const list = _.values(routes.list).map(route => ({
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'criteriQualitativi' */ `modules/criteriQualitativi/${route.componentPath}`)
                .then(response => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('criteriQualitativi', routes.patterns);
routeRegister.addLinks('criteriQualitativi', links);

export default list;