import helper from 'helpers/steinerHelper';
import client from '../../../apis/apiProfiling';

const apis = helper.createApi('coefficienti', client);

apis.fetchCategorieCriteriQualitativi = () => {
    return client({
        url: `categorieCriteri/qualitativi`,
        method: 'get',
    })
}

export default apis;