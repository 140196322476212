import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import FormWrapper from 'components/FormWrapper';
import { addNotification as notify } from 'reapop';
import TranslatorHoc from './TranslatorHoc';
import InputField from 'components/form/v1/InputField';
// import SelectField from 'components/form/v1/SelectField';

import { changePassword } from 'helpers/auth-api';
import { validatePassword, handleChangePasswordError } from 'helpers/auth';
import PasswordField from 'components/form/PasswordField';
import FormControls from 'components/FormControls';
import ListFilterWrapper from './ListFilterWrapper';
import { getUser } from '../helpers/auth/reducer';

// function submit(data, dispatch) {
//     const action = createFormAction(auth.actionTypes.updateProfile, [auth.actionTypes.updateProfileSuccess, auth.actionTypes.updateProfileFail])

//     const payload = data;

//     return action(payload, dispatch);
// }

export class Profile extends Component {
    state = {
        submitSuccessfull: false,
    };

    componentDidMount() {
        this.props.initialize(this.props.user);
    }

    submit = (data) => {
        // TODO: gestire errori
        const { password, password_confirm } = data;

        return changePassword(this.props.user.id, {
            password,
            password_confirm,
        })
            .then(() => {
                this.props.dispatch(
                    notify({
                        title: 'Successo!',
                        message: 'Password modificata con successo!',
                        status: 'success',
                    })
                );

                this.setState({
                    submitSuccessfull: true,
                });
            })
            .catch((err) => {
                handleChangePasswordError(err, this.props.dispatch);
            });
    };

    render() {
        const { handleSubmit } = this.props;

        if (this.state.submitSuccessfull) {
            return <Redirect to="/" />;
        }

        return (
            <ListFilterWrapper title="Cambia Password">
                <div className="row">
                    <Helmet>
                        <title>Cambia password</title>
                    </Helmet>
                    <div className="col-12">
                        <FormWrapper
                            {...this.props}
                            outerControlsClass="row pt-5 pb-3"
                            cancelLink="/"
                            submit={handleSubmit(this.submit)}
                            goBackAfterSave={false}
                            FormControlsComponent={FormControls}
                            widgetSingolo
                            formClassName="form"
                        >
                            <div className="row">
                                <div className="col-12">
                                    <Field
                                        className="form-control"
                                        name="email"
                                        placeholder="Email"
                                        label="Il nome utente non è modificabile"
                                        horizontal={false}
                                        component={InputField}
                                        disabled={true}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Field
                                        className="form-control"
                                        name="password"
                                        placeholder="Password"
                                        label="Password"
                                        horizontal={false}
                                        component={PasswordField}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        className="form-control"
                                        name="password_confirm"
                                        horizontal={false}
                                        placeholder="Conferma password"
                                        label="Conferma password"
                                        component={InputField}
                                        type="password"
                                    />
                                </div>
                            </div>
                        </FormWrapper>
                    </div>
                </div>
            </ListFilterWrapper>
        );
    }
}

Profile.propTypes = {
    dispatch: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialize: PropTypes.func,
    reloadMessage: PropTypes.string,
    unsavedMessage: PropTypes.string,
    user: PropTypes.object,
};

Profile.defaultProps = {
    reloadMessage:
        'The page needs to be reloaded in order to apply the changes.',
    unsavedMessage: 'Are you sure? Any unsaved changes will be lost.',
};

const TranslatedProfile = TranslatorHoc(Profile, {
    reloadMessage: 'steiner.messages.confirmReload',
    unsavedMessage: 'steiner.messages.confirmUnsaved',
});

const ConnectedTranslatedProfile = connect((state) => ({
    user: getUser(state),
}))(TranslatedProfile);

export default reduxForm({
    form: 'profile',
    validate: validatePassword,
})(ConnectedTranslatedProfile);
