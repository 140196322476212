import { sagaCreator } from '@adrias-online/steiner';

import { createSagas } from '../../../helpers/portaleModuleHelper';
import { actions, actionTypes } from '../actions/portaliIndicatoriHotels';
import { selectors, DEFAULT_STATE } from '../reducers/portaliIndicatoriHotels';
import api from '../apis/portaliIndicatoriHotels';

const sagas = createSagas(
    'portaliIndicatoriHotels',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE,
    {
        resourceLabel: 'Indicatori'
    }
);
const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [
    ...defaultSagas
];