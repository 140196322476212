import getTime from 'date-fns/get_time';
import addWeeks from 'date-fns/add_weeks';

const PORTALE_STORE = 'adriasonline_area_riservata_portali';

class PortaliStore {
    constructor() {
        this.portali = JSON.parse(localStorage.getItem(PORTALE_STORE)) || {};
        this.now = getTime(new Date());
    }

    get(portaleId) {
        const portale = this.portali[portaleId];

        if (!portale) {
            return null;
        }

        if (this.now > portale.expires) {
            return null;
        }

        return portale;
    }

    set(portaleId, data) {
        const expireDate = addWeeks(new Date(), 1);

        this.portali[portaleId] = {
            ...data,
            expires: getTime(expireDate)
        };

        localStorage.setItem(PORTALE_STORE, JSON.stringify(this.portali));
    }
}

export default new PortaliStore();
