import { all, fork, put } from 'redux-saga/effects';
import { reduxFormSaga } from '@adrias-online/steiner/lib/helpers';
import { auth } from '@adrias-online/steiner';

import { confirm } from '../helpers/confirm/confirm';
import { login } from '../helpers/auth-api';
import articoli from '../modules/articoli/sagas/articoli';
import articoliListino from '../modules/articoliListino/sagas/articoliListino';
import camere from '../modules/camere/sagas/camere';
import calendarioAttivita from '../modules/calendarioAttivita/sagas/calendarioAttivita';
import clienti from '../modules/clienti/sagas/clienti';
import commerciali from '../modules/commerciali/sagas/commerciali';
import cookieConfig from '../modules/cookieConfig/sagas/cookieConfig';
import cookieConfigAuto from '../modules/cookieConfig/sagas/cookieConfigAuto';
import cookies from '../modules/cookies/sagas/cookies';
import currentHotel from './currentHotel';
import currentPortale from './currentPortale';
import dashboardCommerciali from '../modules/dashboardCommerciali/sagas/dashboardCommerciali';
import elencoAttivita from '../modules/elencoAttivita/sagas/elencoAttivita';
import funnel from '../modules/funnel/sagas/funnel';
import hotel from '../modules/hotel/sagas/hotel';
import hotelConfig from '../modules/hotelConfig/sagas/hotelConfig';
import hotelListino from '../modules/hotelListino/sagas/hotelListino';
import mailTemplateAssoc from '../modules/mailTemplateAssoc/sagas/mailTemplateAssoc';
import hotelNewsletter from '../modules/hotelNewsletter/sagas/hotelNewsletter';
import iscrittiNewsletter from '../modules/iscrittiNewsletter/sagas/iscrittiNewsletter';
import hotelServizi from '../modules/hotelServizi/sagas/hotelServizi';
import macro from '../modules/macros/sagas/macros';
import mailArchive from '../modules/mailArchive/sagas/mailArchive';
import sephirothMailAssoc from '../modules/sephirothMailAssoc/sagas/sephirothMailAssoc';
import notification from '../modules/notification/sagas/notification';
import obbVenditaAnnuali from '../modules/obbVenditaAnnuali/sagas/obbVenditaAnnuali';
import offerte from '../modules/offerte/sagas/offerte';
import ordini from '../modules/ordini/sagas/ordini';
import venduto from '../modules/venduto/sagas/venduto';
import permessi from '../modules/permessi/sagas/permessi';
import portaliArchivioMail from '../modules/portaliArchivioMail/sagas/portaliArchivioMail';
import portaliArticoli from '../modules/portaliArticoli/sagas/portaliArticoli';
import portaliConsigliati from '../modules/portaliConsigliati/sagas/portaliConsigliati';
import portaliGalleries from '../modules/portaliGalleries/sagas/portaliGalleries';
import portaliIndicatori from '../modules/portaliIndicatori/sagas/portaliIndicatori';
import portaliIndicatoriHotels from '../modules/portaliIndicatoriHotels/sagas/portaliIndicatoriHotels';
import portaliLandingPage from '../modules/portaliLandingPage/sagas/portaliLandingPage';
import portaliPages from '../modules/portaliPages/sagas/portaliPages';
import portaliPhoto from '../modules/portaliPhoto/sagas/portaliPhoto';
import portaliRedirects from '../modules/portaliRedirects/sagas/portaliRedirects';
import portaliSchedeHotel from '../modules/portaliSchedeHotel/sagas/portaliSchedeHotel';
import portaliStatistiche from '../modules/portaliStatistiche/sagas/portaliStatistiche';
import portfolio from '../modules/portfolio/sagas/portfolio';
import reports from '../modules/reports/sagas/reports';
import ruoli from '../modules/ruoli/sagas/ruoli';
import sitePage from '../modules/sitePage/sagas/sitePage';
import sitePhoto from '../modules/sitePhoto/sagas/sitePhoto';
import sitePhotogallery from '../modules/sitePhotogallery/sagas/sitePhotogallery';
import sitiClienti from '../modules/sitiClienti/sagas/sitiClienti';
import specsServices from '../modules/specsServices/sagas/specsServices';
import statistiche from '../modules/statistiche/sagas/statistiche';
import statVenditaCommerciale from '../modules/statVenditaCommerciale/sagas/statVenditaCommerciale';
import systemNotifications from './systemNotifications';
import trattamenti from '../modules/trattamenti/sagas/trattamenti';
import utenti from '../modules/utenti/sagas/utenti';
import hotelInterazioni from '../modules/hotelInterazioni/sagas/hotelInterazioni';
import interazioni from '../modules/interazioni/sagas/interazioni';
import clusters from '../modules/clusters/sagas/clusters';
import interazioniPagine from '../modules/interazioniPagine/sagas/interazioniPagine';
import interazioniEmail from '../modules/interazioniEmail/sagas/interazioniEmail';
import interessiMarketing from '../modules/interessiMarketing/sagas/interessiMarketing';
import criteriQualitativi from '../modules/criteriQualitativi/sagas/CriteriQualitativi';
import coefficienti from '../modules/coefficienti/sagas/coefficienti';
import monitorRichiesteCrm from '../modules/monitorRichiesteCrm/sagas/monitorRichiesteCrm';
import fatturatoHotels from '../modules/fatturatoHotels/sagas/fatturatoHotels';
import dipendenti from '../modules/dipendenti/sagas/dipendenti';
import crmContatti from '../modules/crmContatti/sagas/crmContatti';
import WebReputation from '../modules/WebReputation/sagas/WebReputation';
import WebReputationKeywords from '../modules/WebReputationKeywords/sagas/WebReputationKeywords';
import WebReputationScore from '../modules/WebReputationScore/sagas/WebReputationScore';
import mediaGallery from '../modules/mediaGallery/sagas/mediaGallery';
import mediaGalleryPrivata from '../modules/mediaGalleryPrivata/sagas/mediaGalleryPrivata';
import interazioniLayout from '../modules/interazioniLayout/sagas/interazioniLayout';

function logout() {
    return Promise.resolve();
}

function* loginSuccessAction() {
    const action = {
        type: auth.actionTypes.updateProfileSuccess,
        payload: {
            last_refresh: Date.now(),
        },
    };

    yield put(action);
}

function loginFailErrorCreator(error) {
    if (error && error.response) {
        if (error.response.data.needs_reset) {
            return {
                message:
                    'Per accedere è necessario resettare la password, perfavore segui le istruzioni che ti sono pervenute via email',
            };
        } else if (error.response.status === 404) {
            return {
                message: 'Nome utente o password non validi',
            };
        }
    }

    return {
        message:
            "Si è verificato un errore imprevisto durante il login. Riprovare più tardi o contattare l'assistenza",
    };
}

const formSaga = reduxFormSaga();
const confirmSaga = confirm.createConfirmSaga();
const authSaga = auth.createAuthSaga({
    loginAction: login,
    logoutAction: logout,
    loginSuccessAction,
    loginFailErrorCreator,
    // updateProfileAction: updateProfile
});

export default function* root() {
    yield all([
        fork(formSaga),
        ...articoli,
        ...articoliListino,
        ...authSaga,
        ...camere,
        ...calendarioAttivita,
        ...clienti,
        ...commerciali,
        ...cookieConfig,
        ...cookieConfigAuto,
        ...cookies,
        ...confirmSaga,
        ...currentHotel,
        ...currentPortale,
        ...dashboardCommerciali,
        ...elencoAttivita,
        ...funnel,
        ...hotel,
        ...hotelConfig,
        ...hotelListino,
        ...mailTemplateAssoc,
        ...hotelNewsletter,
        ...iscrittiNewsletter,
        ...hotelServizi,
        ...macro,
        ...mailArchive,
        ...sephirothMailAssoc,
        ...notification,
        ...obbVenditaAnnuali,
        ...offerte,
        ...ordini,
        ...permessi,
        ...portaliArchivioMail,
        ...portaliArticoli,
        ...portaliConsigliati,
        ...portaliGalleries,
        ...portaliIndicatori,
        ...portaliIndicatoriHotels,
        ...portaliLandingPage,
        ...portaliPages,
        ...portaliPhoto,
        ...portaliRedirects,
        ...portaliSchedeHotel,
        ...portaliStatistiche,
        ...portfolio,
        ...reports,
        ...ruoli,
        ...sitePage,
        ...sitePhoto,
        ...sitePhotogallery,
        ...sitiClienti,
        ...specsServices,
        ...statistiche,
        ...statVenditaCommerciale,
        ...systemNotifications,
        ...trattamenti,
        ...utenti,
        ...venduto,
        ...hotelInterazioni,
        ...interazioni,
        ...clusters,
        ...interazioniPagine,
        ...interazioniEmail,
        ...interessiMarketing,
        ...criteriQualitativi,
        ...coefficienti,
        ...monitorRichiesteCrm,
        ...fatturatoHotels,
        ...dipendenti,
        ...crmContatti,
        ...WebReputation,
        ...WebReputationKeywords,
        ...WebReputationScore,
        ...mediaGallery,
        ...mediaGalleryPrivata,
        ...interazioniLayout
    ]);
}
