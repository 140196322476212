import { reducerCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { actionTypes } from '../actions/commerciali';

export const DEFAULT_STATE = helper.createDefaultState();

const handlers = helper.createHandlers(actionTypes, {
    items: 'data.values',
    total: 'data.meta.pagination.total',
    idKey: 'codice'
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('commerciali');