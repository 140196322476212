import { createApi } from 'helpers/hotelModuleHelper';
import client from 'apis/apiMediaGallery';

const paramsMap = {
    limit: 'perPage',
    page: 'page',
    categoryId: 'categoryId'
};

const apis =  createApi('Galleria', client, paramsMap);

apis.fetchCategorie = (hotelId) => {
    return client.get(`Hotel/${hotelId}/Galleria/Categorie`);
};

apis.fetchImmagini = ( hotelId, categoryId, page) => {
    return client.get(`Hotel/${hotelId}/Galleria?categoryId=${categoryId}&page=${page}&limit=8`);
}

apis.fetchCategoriePrivata = (hotelId) => {
    return client.get(`Hotel/${hotelId}/GalleriaPrivata/Categorie`);
};

apis.fetchGalleriaPrivata = (hotelId) => {
    return client.get(`Hotel/${hotelId}/GalleriaPrivata`);
}

apis.immagineGalleria = (hotelId, offerId, urlImmagine) => {
    return client({
        url: `/Hotel/${hotelId}/Offerta/${offerId}`,
        method: 'POST',
        data: {
            url: urlImmagine,
        },
    });
};

export default apis;