import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker/DayPicker';
// import 'react-day-picker/lib/style.css';
import { parse, format } from 'date-fns';

import './DateRangePicker.css';

const ESC_KEY_CODE = 27;

const months = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
];
const weekdaysLong = [
    'Domenica',
    'Lunedì',
    'Martedì',
    'Mercoledi',
    'Giovedì',
    'Venerdì',
    'Sabato',
];
const weekdaysShort = ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'];

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear - 2, 0);
const toMonth = new Date(currentYear + 2, 11);

class YearMonthForm extends Component {
    handleChange = () => {
        this.props.onChange(
            new Date(this.yearSelect.value, this.monthSelect.value)
        );
    };

    render() {
        const { date, months } = this.props;

        const years = [];

        for (
            let i = fromMonth.getFullYear();
            i <= toMonth.getFullYear();
            i += 1
        ) {
            years.push(i);
        }

        return (
            <div className="DayPicker-Caption">
                <select
                    ref={(ref) => (this.monthSelect = ref)}
                    name="month"
                    onChange={this.handleChange}
                    value={date.getMonth()}
                >
                    {months.map((month, i) => (
                        <option key={i} value={i}>
                            {month}
                        </option>
                    ))}
                </select>
                <select
                    ref={(ref) => (this.yearSelect = ref)}
                    name="year"
                    onChange={this.handleChange}
                    value={date.getFullYear()}
                >
                    {years.map((year, i) => (
                        <option key={i} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </div>
        );
    }
}

YearMonthForm.propTypes = {
    date: PropTypes.any,
    onChange: PropTypes.func,
    months: PropTypes.array,
};

export default class DatepickerInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showOverlay: false,
            month: null,
        };

        this.clickedInside = false;
        this.clickTimeout = null;
        this.hideTimeout = null;
    }

    componentWillUnmount() {
        clearTimeout(this.clickTimeout);
        clearTimeout(this.hideTimeout);
    }

    showDayPicker = () => {
        this.setState({
            showOverlay: true,
        });
    };

    toggleDayPicker = () => {
        this.setState((prevState) => ({
            showOverlay: !prevState.showOverlay,
        }));
    };

    hideDayPicker = () => {
        this.setState({
            showOverlay: false,
        });
        // this.props.onHide();
    };

    resetMonth = () => {
        this.setState({
            month: null,
        });
    };

    hideAfterDayClick = () => {
        if (!this.props.hideOnDayClick) {
            return;
        }

        this.hideTimeout = setTimeout(
            () => this.hideDayPicker(),
            100 // give a timeout to show the clicked day
        );
    };

    handleFocus = (e) => {
        this.showDayPicker();

        if (this.props.onFocus) {
            e.persist();
            this.props.onFocus(e);
        }
    };

    handleBlur = (e) => {
        this.setState({
            showOverlay: this.clickedInside,
        });

        if (this.clickedInside === false) {
            this.resetMonth();
        }

        // Force input's focus if blur event was caused
        // by clicking inside the overlay
        if (this.clickedInside) {
            this.input.focus();
        }

        if (this.props.onBlur) {
            e.persist();
            this.props.onBlur(e);
        }
    };

    handleContainerMouseDown = () => {
        this.clickedInside = true;
        // The input's onBlur method is called from a queue right after onMouseDown event.
        // setImmediate adds another callback in the queue, but is called later than onBlur event
        this.clickTimeout = setImmediate(() => {
            this.clickedInside = false;
        });
    };

    handleOnKeyUp = (e) => {
        const isNotEsc = e.keyCode !== ESC_KEY_CODE;

        this.setState({
            showOverlay: isNotEsc,
        });

        if (!isNotEsc) {
            this.resetMonth();
        }

        // if (this.props.onKeyUp) {
        //     e.persist();
        //     this.props.onKeyUp(e);
        // }
    };

    handleDayClick = (day, modifiers /*, e*/) => {
        if (modifiers.disabled) {
            // Do nothing if the day is disabled
            return;
        }

        if (modifiers.selected && this.props.clickUnselectsDay) {
            // Unselect the day
            // this.setState({ value: '' }, this.hideAfterDayClick);

            if (this.props.onDayChange) {
                this.props.onDayChange(undefined, modifiers);
            }

            return;
        }

        // this.setState({ value: format(day, 'YYYY-MM-DD'), month: day }, () => {
        if (this.props.onDayChange) {
            this.props.onDayChange(day, modifiers);
        }

        this.hideAfterDayClick();
        // });
    };

    // handleChange = (e) => {
    //     const { value } = e.target;
    //     const { /*format, dayPickerProps, onDayChange,*/ onChange } = this.props;
    //     // const m = moment(value, format, true);

    //     if (onChange) {
    //         e.persist();
    //         onChange(e);
    //     }

    //     if (value.trim() === '') {
    //         this.setState({ value });

    //         if (this.props.onDayChange) {
    //             this.props.onDayChange(undefined, {});
    //         }

    //         return;
    //     }

    //     // if (!m.isValid()) {
    //     //     this.setState({ value });
    //     //     return;
    //     // }

    //     // const day = m.toDate();
    //     // this.setState({ month: day, value }, () => {
    //     //     if (!onDayChange) {
    //     //         return;
    //     //     }

    //     //     const modifiersObj = {
    //     //         disabled: dayPickerProps.disabledDays,
    //     //         selected: dayPickerProps.selectedDays,
    //     //         ...dayPickerProps.modifiers,
    //     //     };
    //     //     const modifiers = getModifiersForDay(
    //     //         day,
    //     //         modifiersObj
    //     //     ).reduce((obj, modifier) => {
    //     //         const newObj = { ...obj };
    //     //         newObj[modifier] = true;
    //     //         return newObj;
    //     //     }, {});

    //     //     this.props.onDayChange(m, modifiers);
    //     // });
    // }

    handleYearMonthChange = (month) => {
        this.setState({
            month,
        });
    };

    renderOverlay() {
        let selectedDay;

        if (this.props.value) {
            selectedDay = parse(this.props.value);
        }

        // console.warn(this.props.name, this.props.dayPickerProps.initialMonth, this.state.month);

        // const month = this.state.month === null ? this.props.dayPickerProps.month : this.state.month;

        return (
            <div className={'DayPickerInput-OverlayWrapper'}>
                <div className={'DayPickerInput-Overlay'}>
                    <DayPicker
                        ref={(el) => (this.daypicker = el)}
                        fixedWeeks={true}
                        firstDayOfWeek={1}
                        months={months}
                        weekdaysLong={weekdaysLong}
                        weekdaysShort={weekdaysShort}
                        {...this.props.dayPickerProps}
                        month={this.state.month}
                        selectedDays={selectedDay}
                        onDayClick={this.handleDayClick}
                        numberOfMonths={1}
                        captionElement={
                            <YearMonthForm
                                onChange={this.handleYearMonthChange}
                            />
                        }
                        className={window.innerWidth < 769 ? 'w-100' : null}
                    />
                </div>
            </div>
        );
    }

    render() {
        const { className, value, displayFormat, name, placeholder, onlyIcon } =
            this.props;

        const { showOverlay } = this.state;
        // const day = format(value, 'd');

        const wrapperClassName = `DayPickerInput ${className}`;

        return (
            <div
                className={wrapperClassName}
                onMouseDown={this.handleContainerMouseDown}
            >
                {onlyIcon === false && (
                    <input
                        id={this.props.input}
                        type="text"
                        name={name}
                        className="form-control"
                        ref={(ref) => (this.input = ref)}
                        value={value ? format(value, displayFormat) : ''}
                        onClick={this.showDayPicker}
                        onBlur={this.handleBlur}
                        onFocus={this.handleFocus}
                        onKeyUp={this.handleOnKeyUp}
                        // onChange={this.handleChange}
                        readOnly={true}
                        placeholder={placeholder}
                    />
                )}
                {onlyIcon && (
                    <div
                        onClick={this.toggleDayPicker}
                        style={{
                            width: '26px',
                            backgroundColor: showOverlay ? '#ccc' : '#fff',
                            color: showOverlay ? '#000' : '#999999',
                            border: '1px solid #ccc',
                            padding: '8px 4px',
                            cursor: 'pointer',
                        }}
                    >
                        <i className="fas fa-calendar" />
                    </div>
                )}
                {showOverlay && this.renderOverlay()}
            </div>
        );
    }
}

DatepickerInput.propTypes = {
    className: PropTypes.string,
    clickUnselectsDay: PropTypes.bool,
    dayPickerProps: PropTypes.object,
    displayFormat: PropTypes.string,
    hideOnDayClick: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onDayChange: PropTypes.func,
    onFocus: PropTypes.func,
    onlyIcon: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.any,
};

DatepickerInput.defaultProps = {
    className: '',
    dayPickerProps: {},
    displayFormat: 'DD/MM/YYYY',
    hideOnDayClick: true,
    onlyIcon: false,
    placeholder: 'Seleziona una data',
};
