import client from 'apis/apiCookie';
import helper from 'helpers/steinerHelper';

const apis = helper.createApi('Cookies', client, {
    per_page: 'perPage',
    page: 'page',
    categoria: 'categoria',
    dominio: 'dominio',
    nome: 'nome',
    filter: 'q',
    sort_field: 'orderKey',
    sort_direction: 'orderDirection',
});

export default apis;
