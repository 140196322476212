import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { select } from 'redux-saga/effects';

import { sagaCreator } from '@adrias-online/steiner';
import helper from './steinerHelper';
import { buildParams } from '@adrias-online/steiner/lib/helpers/apiCreator';

import { actionTypes as currentPortaleActionTypes } from '../actions/currentPortale';
import { selectors as currentPortaleSelectors } from '../reducers/currentPortale';
import { getCurrentRoute } from '@adrias-online/steiner/lib/routing/reducer';
import { getUser } from './auth/reducer';

export const createHandlers = function(actionTypes, options) {
    const handlers = helper.createHandlers(actionTypes, options);

    handlers[currentPortaleActionTypes.setPortale] = function(state, action) {
        return state.setIn(
            ['list', 'filters', 'portaleId'],
            action.payload.value
        );
    };

    return handlers;
};

function* getApiListParams(selectors) {
    const filters = yield select(selectors.getFilters);
    const portaleId = yield select(currentPortaleSelectors.getCurrentPortaleId);

    return [
        {
            ...filters,
            portaleId
        }
    ];
}

export const createSagas = function(
    resource,
    actionTypes,
    actions,
    api,
    selectors,
    defaultState,
    options = {}
) {
    const finalOptions = Object.assign({}, { getApiListParams }, options);

    return helper.createSagas(
        resource,
        actionTypes,
        actions,
        api,
        selectors,
        defaultState,
        finalOptions
    );
};

export const bootSagas = function(sagas, actionTypes) {
    const defaltSagas = sagaCreator.bootSagas(sagas, actionTypes);
    return [...defaltSagas];
};

export const portaleApi = function(endPoint, client, paramsMap, options) {
    const api = helper.createApi(endPoint, client, paramsMap, options);

    api.list = function(filters) {
        return client({
            url: `${endPoint}`,
            params: buildParams(
                filters,
                helper.getFinalParamsMap(paramsMap, {})
            )
        });
    };

    return api;
};

export const portaleApiWithId = function(endPoint, client, paramsMap, options) {
    const api = helper.createApi(endPoint, client, paramsMap, options);

    api.list = function(filters) {
        const portal_id = filters.portaleId;
        return client({
            url: `${endPoint}/${portal_id}`,
            params: buildParams(
                filters,
                helper.getFinalParamsMap(paramsMap, {})
            )
        });
    };

    return api;
};

export const generateRoutes = function(resource, selectors, options) {
    let routes = helper.generateRoutes(resource, selectors, options);

    routes.patterns = {
        list: `/portale/:portaleId/${resource}`,
        create: `/portale/:portaleId/${resource}/create`,
        edit: `/portale/:portaleId/${resource}/:id`
    };

    routes.list.list.breadcrumb = function(state) {
        const currentPortale = currentPortaleSelectors.getCurrentPortale(state),
            section = resource.charAt(0).toUpperCase() + resource.slice(1);

        return {
            breadcrumbName: `${currentPortale.label} / ${section}`
        };
    };

    routes.list.edit.breadcrumb = function(state) {
        const currentRoute = getCurrentRoute(state),
            idPagePortale = currentRoute.location.pathname.split('/');

        let idPage = '';

        if (idPagePortale[4] === 'create' && idPagePortale[3] === 'pagine') {
            idPage = 'Crea nuova Pagina';
        }

        if (idPagePortale[4] === 'create' && idPagePortale[3] === 'redirects') {
            idPage = 'Crea nuovo Redirect';
        }

        if (idPagePortale[4] === 'create' && idPagePortale[3] === 'articoli') {
            idPage = 'Crea nuovo Articolo';
        }

        if (
            idPagePortale[4] === 'create' &&
            idPagePortale[3] === 'landingPage'
        ) {
            idPage = 'Crea nuova Landing Page';
        }

        if (idPagePortale[4] !== 'create') {
            idPage = idPagePortale[4];
        }

        return {
            breadcrumbName: idPage
        };
    };

    routes.list.list.path = routes.patterns.list;
    routes.list.edit.path = routes.patterns.edit;

    return routes;
};

export const connectList = function(component, actions, selectors) {
    function mapStateToProps(state) {
        const list = selectors.listSelector(state);

        return {
            ...list,
            filters: selectors.getFilters(state),
            items: selectors.itemsSelector(state),
            selected: selectors.getSelectedId(state),
            currentPortale: currentPortaleSelectors.getCurrentPortale(state),
            currentRoute: getCurrentRoute(state),
            user: getUser(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        const boundedActions = bindActionCreators(actions, dispatch);

        return {
            ...boundedActions,
            dispatch
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(component);
};
