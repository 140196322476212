import helper from 'helpers/steinerHelper';
import client from 'apis/apiMarketingSuite';

const paramsMap = {
    titolo: 'q',
    per_page: 'perPage',
    page: 'page',
    sort_field: 'orderKey',
    sort_direction: 'orderDirection',
    ao_hotel_id: 'ao_hotel_id',
    stato_online: 'expiration',
};

const apis = helper.createApi('interazioni', client, paramsMap);

apis.aggiornaInterazione = (interazioneId, data) => {
    return client({
        url: `interazioni/${interazioneId}`,
        method: 'patch',
        data: data,
    });
};

apis.attivaInterazione = (interazioneId, value) => {
    return client({
        url: `interazioni/${interazioneId}/online`,
        method: 'post',
        data: { stato_online: value },
    });
};

apis.clonaInterazione = (interazioneId) => {
    return client({
        url: `interazioni/${interazioneId}/clona`,
        method: 'post',
    });
};

apis.dettaglioInterazione = (interazioneId) => {
    return client({
        url: `interazioni/${interazioneId}`,
        method: 'get',
    });
};

apis.fetchPagine = (hotelId) => {
    return client({
        url: `pagine?ao_hotel_id=${hotelId}`,
        method: 'get',
    });
};

apis.fetchClusters = (hotelId) => {
    return client({
        url: `clusters?ao_hotel_id=${hotelId}`,
        method: 'get',
    });
};

apis.fetchLayouts = (tipoComunicazione) => {
    return client({
        url: `layouts/${tipoComunicazione}`,
        method: 'get',
    });
}

export default apis;
