import { sagaCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { actions, actionTypes } from '../actions/elencoAttivita';
import { selectors, DEFAULT_STATE } from '../reducers/elencoAttivita';
import api from '../apis/elencoAttivita';

const sagas = helper.createSagas('elencoAttivita', actionTypes, actions, api, selectors, DEFAULT_STATE);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [
    ...defaultSagas
];