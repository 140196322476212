import { reducerCreator } from '@adrias-online/steiner';
// import _ from 'lodash';

import helper from 'helpers/steinerHelper';
import { actionTypes } from '../actions/portfolio';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            comune: null,
            posizione: null,
            produttore: null,
            year: null
        }
    }
});

const handlers = helper.createHandlers(actionTypes, {
    items: 'data.data',
    total: 'data.meta.pagination.total'
});

handlers[actionTypes.revealPhone] = (state, action) => {
    return state.updateIn(['list', 'itemsById', action.payload.id], item => {
        return {
            ...item,
            showPhone: true
        };
    });
};

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('portfolio');
