import apiClient from '../../../apis/portali';
import { portaleApi } from '../../../helpers/portaleModuleHelper';

const api = portaleApi('photoGalleries', apiClient, {
    top: 'perPage',
    page: 'page',
    filter: 'q',
    orderby: 'orderKey',
    orderdir: 'orderDirection',
    query({ portaleId }) {
        if (typeof portaleId === 'string' || typeof portaleId === 'number') {
            return `portal_id||=||${portaleId}`;
        }

        return null;
    }
});

api.getFullListPhotos = params => {
    return apiClient({
        url: 'photo',
        method: 'get',
        params
    });
};

api.addPhotosTo = (galleryId, ids) => {
    return apiClient({
        url: `photos/addPhotosTo/PhotoGalleries/${galleryId}`,
        method: 'post',
        data: {
            ids
        }
    });
};

api.orderPhotos = (galleryId, photos) => {
    return apiClient({
        url: `photos/updatePhotoGalleryOrder/${galleryId}`,
        method: 'post',
        data: {
            photos
        }
    });
};

api.unlinkPhoto = (portaleId, photoId) => {
    return apiClient({
        url: `photos/removePhotoFrom/PhotoGalleries/${portaleId}/${photoId}`,
        method: 'delete'
    });
};

api.editPhotoData = (photoId, data) => {
    return apiClient({
        url: `photo/${photoId}`,
        method: 'patch',
        data
    });
};

api.generaThumbs = galleryId => {
    return apiClient({
        url: `photos/regenPhotoGalleriesThumbs/${galleryId}`,
        method: 'get'
    });
};

export default api;
