import { routeCreator } from '@adrias-online/steiner/lib/helpers';
import _ from 'lodash';

import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/monitorRichiesteCrm';
import helper from '../../../helpers/steinerHelper';

const routes = helper.generateRoutes('monitorRichiesteCrm', selectors);
const list = _.values(routes.list).map((route) => ({
    ...route,
    hotelFilter: true,
    getComponent() {
        return new Promise((resolve) => {
            import(
                /* webpackChunkName: 'monitorRichiesteCrm' */ `modules/monitorRichiesteCrm/${route.componentPath}`
            ).then((response) => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('monitorRichiesteCrm', routes.patterns);
routeRegister.addLinks('monitorRichiesteCrm', links);

export default list;