import { createApi } from 'helpers/hotelModuleHelper';
import client from 'apis/apiMediaGallery';

const paramsMap = {
    limit: 'perPage',
    page: 'page',
    categoryId: 'categoryId'
};

const apis =  createApi('GalleriaPrivata', client, paramsMap);


apis.fetchCategoriePrivata = (hotelId) => {
    return client.get(`Hotel/${hotelId}/GalleriaPrivata/Categorie`);
};

apis.getFotoWidget = (hotelId, limit) => {
    return client.get(`Hotel/${hotelId}/GalleriaPrivata?limit=${limit}`);
}

export default apis;