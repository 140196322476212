import _ from 'lodash';
import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';
import { format, subMonths } from 'date-fns';

import { reducerCreator } from '@adrias-online/steiner/lib/helpers';

import { dataKeys } from '../helpers';
import { actionTypes as currentHotelActionTypes } from '../../../actions/currentHotel';
import { actionTypes } from '../actions/statistiche';
import { getSectionForSourceAndUser } from '../sagas/statistiche';
import { getUser } from '../../../helpers/auth/reducer';

// import { selectors as currentHotelSelectors } from 'reducers/currentHotel';

// const end = subDays(new Date(), 1);

// console.log(window.innerWidth);
// let startDate;
// switch (window.innerWidth) {
//     case window.innerWidth < 576:
//         startDate = format(subDays(new Date(), 7), 'YYYY-MM-DD');
//     case window.innerWidth < 768:
//         startDate = format(subDays(new Date(), 14), 'YYYY-MM-DD');
//     case window.innerWidth < 1024:
//         startDate = format(subMonths(new Date(), 1), 'YYYY-MM-DD');
// }

const startDate = format(subMonths(new Date(), 1), 'YYYY-MM-DD');
const endDate = format(new Date(), 'YYYY-MM-DD');

const DEFAULT_DATA_STATE = dataKeys.reduce((data, key) => {
    data[key] = {};

    if (key === 'contract' || key === 'overview') {
        data[key] = [];
    }

    return data;
}, {});

export const DEFAULT_STATE = Immutable({
    filters: {
        endDate,
        startDate,
        hotelId: null,
        sourceId: 1,
        isFiltered: false,
        ga_medium: null,
    },
    contractYear: null,
    data: DEFAULT_DATA_STATE,
    loading: dataKeys.reduce((loading, key) => {
        loading[key] = true;

        return loading;
    }, {}),
    errors: dataKeys.reduce((errors, key) => {
        errors[key] = null;

        return errors;
    }, {}),
});

function refreshAll(state) {
    const loading = dataKeys.reduce((data, key) => {
        data[key] = true;

        return data;
    }, {});

    const errors = dataKeys.reduce((data, key) => {
        data[key] = null;

        return data;
    }, {});

    return state.setIn(['loading'], loading).setIn(['errors'], errors);
}

function resetFilters(state) {
    return state.setIn(
        ['filters'],
        DEFAULT_STATE.filters.set('hotelId', state.filters.hotelId)
    );
}

function setData(state, action) {
    return state.setIn(['data', action.payload.key], action.payload.data);
}

function resetAll(state) {
    return state.set('data', DEFAULT_DATA_STATE);
}

function setError(state, action) {
    return state.setIn(['errors', action.payload.key], action.payload.error);
}

function setFilters(state, action) {
    return state.updateIn(['filters'], (filters) => ({
        ...filters,
        ...action.payload,
    }));
}

function startLoading(state, action) {
    const key = action.payload.key;
    return state.setIn(['loading', key], true).setIn(['errors', key], null);
}

function stopLoading(state, action) {
    return state.setIn(['loading', action.payload.key], false);
}

function setContractYear(state, action) {
    return state.setIn(['contractYear'], action.payload);
}

function setHotel(state, action) {
    return state.setIn(['filters', 'hotelId'], action.payload.value);
}

const handlers = {
    [actionTypes.refreshAll]: refreshAll,
    [actionTypes.resetFilters]: resetFilters,
    [actionTypes.setData]: setData,
    [actionTypes.resetAll]: resetAll,
    [actionTypes.setError]: setError,
    [actionTypes.setFilters]: setFilters,
    [actionTypes.startLoading]: startLoading,
    [actionTypes.stopLoading]: stopLoading,
    [actionTypes.setContractYear]: setContractYear,
    [currentHotelActionTypes.setHotel]: setHotel,
};

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

// const filtersSelector = state => state.statistiche.filters;
// const hotelIdSelector = currentHotelSelectors.getCurrentHotelId;

// const finalFiltersSelector = createSelector(
//     filtersSelector,
//     hotelIdSelector,
//     (filters, hotelId) => {
//         return Immutable({
//             ...filters,
//             hotelId
//         });
//     }
// );

const sourceIdSelector = (state) => state.statistiche.filters.sourceId;

const toDisplaySelector = createSelector(
    getUser,
    sourceIdSelector,
    (user, sourceId) => {
        const sections = getSectionForSourceAndUser(sourceId, user);

        return sections.reduce((result, key) => {
            result[key] = true;

            return result;
        }, {});
    }
);

export const selectors = {
    getContractYear(state) {
        return state.statistiche.contractYear;
    },
    getData(state) {
        return state.statistiche.data;
    },
    getErrors(state) {
        return state.statistiche.errors;
    },
    // getFilters: finalFiltersSelector,
    getFilters(state) {
        return state.statistiche.filters;
    },
    getIsLoading(state) {
        const loading = _.transform(
            state.statistiche.loading,
            (result, value, key) => {
                if (value === true) {
                    result.push(key);
                }

                return result;
            },
            []
        );

        const validSections = getSectionForSourceAndUser(
            state.statistiche.filters.sourceId,
            state.user
        );

        const loadingSections = _.intersection(loading, validSections);

        return loadingSections.length > 0;
    },
    getLoading(state) {
        return state.statistiche.loading;
    },
    getToDisplay: toDisplaySelector,
};
