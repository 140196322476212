import apiClient from '../../../apis/portali';
import { portaleApi } from '../../../helpers/portaleModuleHelper';

const api = portaleApi('statisticheIndicatoriHotels', apiClient, {
    top: 'perPage',
    page: 'page',
    filter: 'q',
    orderby: 'orderKey',
    orderdir: 'orderDirection',
    query({
        in_landing,
        mail_cost_diff,
        cost_previous,
        cost_current,
        comune,
        categoria
    }) {
        let params = [],
            filters;

        if (in_landing !== null) {
            params.push(`in_landing||=||${in_landing}`);
        }

        if (mail_cost_diff !== null) {
            params.push(`mail_cost_diff||>||${mail_cost_diff}`);
        }

        if (cost_previous !== null) {
            params.push(`cost_previous||>||${cost_previous}`);
        }

        if (cost_current !== null) {
            params.push(`cost_current||>||${cost_current}`);
        }

        if (comune !== null) {
            params.push(`comune_id||=||${comune}`);
        }

        if (categoria !== null) {
            params.push(`categoria_id||=||${categoria}`);
        }

        params.length > 0 ? (filters = params.join('::')) : (filters = null);

        return filters;
    }
});

export default api;