import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/articoliListino';

const routes = helper.generateRoutes('articoliListino', selectors, {
    itemLabelKey: 'description',
    label: {
        it: 'Listino Articoli'
    }
});

const list = _.values(routes.list).map(route => ({
    requiredRoles: ['admin', 'developer', 'employee', 'amministrazione'],
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'listino-articoli' */ `modules/articoliListino/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('articoliListino', routes.patterns, {
    it: 'Listino Articoli'
});

routeRegister.addLinks('articoliListino', links);

export default list;
