import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highlight from 'react-highlight-words';
import Select from 'react-select';
import _ from 'lodash';

import storage from 'helpers/storage';
import omitProps from 'components/OmitProps';

const DURATION = 60000;

// TODO: questa sarebbe da rifare sulla base di Async di react-select...
export class AutoSelectAsync extends Component {
    constructor(props) {
        super(props);

        this.labelCache = storage;
        this.queryCache = {};

        this.state = {
            options: props.options,
            isLoading: false
            // inputValue: ''
        };
    }

    componentDidMount() {
        if (this.props.value) {
            this.checkLabel(this.props.value);
        } else if (this.props.autoload) {
            this.handleInputChange('');
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value && this.props.value) {
            this.checkLabel(this.props.value);
        }
    }

    getLabelCacheKey(id) {
        return `${this.props.namespace}_${id}`;
    }

    mergeOptions = newOptions => {
        // console.warn('state', this.state.options.length);
        // console.warn('newOptions', newOptions.length);

        const result = _.uniqBy(
            _.concat(this.state.options, newOptions),
            'value'
        );

        // console.warn('result', result.length);

        return result;
    };

    checkLabel(value) {
        if (_.isArray(value)) {
            this.fetchLabels(value);
        } else {
            if (_.isObject(value)) {
                return false;
            }

            const match = _.find(this.state.options, { value });

            if (!match) {
                const cache = this.labelCache.get(this.getLabelCacheKey(value));

                if (cache) {
                    const options = this.mergeOptions(cache);

                    this.setState({
                        isLoading: false,
                        options
                    });
                } else {
                    this.setState({
                        isLoading: true
                    });

                    this.fetchLabel(value);
                }
            }
        }
    }

    fetchLabels(values) {
        Promise.all(
            values.map(value => {
                return this.fetchLabelArray(
                    _.isObject(value) ? value.value : value
                );
            })
        ).then(values => {
            if (this.select) {
                const options = this.mergeOptions(values);

                this.setState({
                    isLoading: false,
                    options
                });
            }
        });
    }

    fetchLabelArray(value) {
        const cache = this.labelCache.get(this.getLabelCacheKey(value));

        if (cache) {
            return Promise.resolve(cache[0]);
        }

        return new Promise((resolve, reject) => {
            this.props.fetchLabel(value).then(res => {
                this.labelCache.set(
                    this.getLabelCacheKey(value),
                    res.options,
                    new Date().getTime() + DURATION
                );
                    resolve(res.options[0]);
                });
        });
    }

    fetchLabel(value) {
        this.props.fetchLabel(value).then(res => {
            this.labelCache.set(
                this.getLabelCacheKey(value),
                res.options,
                new Date().getTime() + DURATION
            );
                // Imposto il valore solo se il componente è effettivamente montato nel DOM
                if (this.select) {
                    const options = this.mergeOptions(res.options);

                    this.setState({
                        isLoading: false,
                        options
                    });
                }
            });
    }

    handleInputChange = value => {
        if (this.queryCache[value]) {
            this.setState({
                // inputValue: value,
                options: this.queryCache[value]
            });

            return value;
        }

        this.setState({
            // inputValue: value,
            isLoading: true
        });

        this.props.loadOptions(value).then(res => {
                this.queryCache[value] = res.options;

                const options = this.mergeOptions(res.options);

                this.setState({
                    isLoading: false,
                    options
                });
            });

        return value;
    };

    render() {
        const { disabled, value, placeholder, onChange, multi } = this.props;

        return (
            <Select
                ref={ref => (this.select = ref)}
                value={value}
                disabled={disabled}
                isLoading={this.state.isLoading}
                options={this.state.options}
                placeholder={placeholder}
                onInputChange={this.handleInputChange}
                onChange={onChange}
                multi={multi}
                onCloseResetsInput={false}
                onBlurResetsInput={false}
                onSelectResetsInput={false}
                optionRenderer={(option, index, inputValue) => {
                    return (
                        <Highlight
                            autoEscape={true}
                            searchWords={[inputValue]}
                            textToHighlight={
                                option && option.label ? option.label : ''
                            }
                        />
                    );
                }}
            />
        );
    }
}

AutoSelectAsync.propTypes = {
    autoload: PropTypes.bool,
    disabled: PropTypes.bool,
    fetchLabel: PropTypes.func,
    loadOptions: PropTypes.func.isRequired,
    multi: PropTypes.bool,
    namespace: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    value: PropTypes.any
};

AutoSelectAsync.defaultProps = {
    autoload: false,
    multi: false,
    options: []
};

export default omitProps(['onDragStart', 'onBlur', 'onDrop', 'onFocus'])(
    AutoSelectAsync
);
