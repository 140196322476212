import { sagaCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { actions, actionTypes } from '../actions/calendarioAttivita';
import { selectors, DEFAULT_STATE } from '../reducers/calendarioAttivita';
import api from '../apis/calendarioAttivita';

const sagas = helper.createSagas('calendarioAttivita', actionTypes, actions, api, selectors, DEFAULT_STATE);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [
    ...defaultSagas
];