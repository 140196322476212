// import { createApi } from 'helpers/hotelModuleHelper';
import client from 'apis/apiCookie';
import helper from 'helpers/steinerHelper';

// export default createApi('cookieConfig', client);

const apis = helper.createApi('Configurazioni/Automatiche', client, {
    per_page: 'perPage',
    page: 'page',
    hotel_id: 'hotelId',
    dominio: 'dominio',
    id_sito: 'id_sito'
    // email_cliente: 'q'
});

apis.approvaConfig = (configId) => {
    return client({
        url: `/Configurazioni/Automatiche/${configId}`,
        method: 'PATCH',
    });
};  

apis.getCookieList = () => {
    return client({
        url: `Cookies`,
        method: 'GET',
    });
};

export const apiSiti = helper.createApi('Siti', client, {
    // per_page: 'perPage',
    // page: 'page',
    hotel_id: 'hotelId',
    dominio: 'dominio',
    // email_cliente: 'q'
});

export default apis;
