import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class WidgetUtilityPortale extends Component {
    renderPortaliCacheLink = () => {
        const {
            currentPortale: { value },
        } = this.props;

        let disabled = true;
        let href = '#';

        switch (value) {
            case 1:
                disabled = false;
                href = 'https://www.entrainhotel.com/admin/ccleaner';
                break;

            case 2:
                disabled = false;
                href = 'http://www.italhotels.net/admin/ccleaner';
                break;

            case 6:
                disabled = false;
                href = 'http://www.bambininriviera.it/admin/ccleaner';
                break;

            case 10:
                disabled = false;
                href = 'https://www.hotel.rimini.it/admin/ccleaner';
                break;

            case 33:
                disabled = false;
                href =
                    'https://www.familyhotelvillagetrentino.it/admin/ccleaner';
                break;

            default:
                disabled = true;
                break;
        }

        return (
            <a
                href={href}
                target={'_blank'}
                rel="noopener noreferrer"
                className={disabled ? 'btn disabled p-0 border-0' : null}
            >
                <i className="fas fa-recycle widget-icon" aria-hidden="true" />
                Svuota cache
            </a>
        );
    };

    render() {
        return (
            <div className="widget">
                <h2 className="pb-2">Utility portale</h2>
                <div className="row">
                    <div className="col-12">
                        <div className="widget-link">
                            {this.renderPortaliCacheLink()}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="widget-link">
                            <NavLink
                                to="/portali/tools"
                                className="d-flex align-items-center"
                            >
                                <i
                                    className="fas fa-tools widget-icon"
                                    aria-hidden="true"
                                ></i>
                                Tools
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
