import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addNotification as notify } from 'reapop';
import { reduxForm, Field } from 'redux-form';
import InputField from 'components/form/v1/InputField';
import { validator } from 'validate-this';

import api from '../apis/offerte';
import { withRouter } from 'react-router';
import { addDays, format } from 'date-fns';

class CreazioneOffertaModal extends Component {
    submit = (data) => {
        const idHotel = this.props.match.params.hotelId
            ? this.props.match.params.hotelId
            : this.props.currentHotel;

        const today = format(new Date(), 'DD/MM/YYYY');
        const tomorrow = format(addDays(new Date(), 7), 'DD/MM/YYYY');

        const payload = {
            tipologia: 1,
            ordinamento: 999,
            visibile: 'f',
            visibile_landing: false,
            lingua: 'it',
            price_type: 1,
            n_giorni: 'perNight',
            idHotel: idHotel,
            testi: {},
            approfondimenti: {},
            lista_servizi_top: {},
            descriptions: {},
            dettagli: {},
            prezzi_non_scontati: {},
            sconti: {},
            titoli: {
                it: data.titolo,
            },
            sottotitoli: {},
            titles: {},
            keywordss: {},
            associazioni_offerte: {},
            data_inizio: today,
            data_fine: tomorrow,
            data_pubblicazione: today,
            data_fine_pubblicazione: tomorrow,
        };

        const xhr = api.create({
            ...payload,
        });

        xhr.then((response) => {
            this.props.dispatch(
                notify({
                    title: 'Successo!',
                    message: 'Nuova offerta creata correttamente!',
                    status: 'success',
                })
            );
            this.props.onClose();

            if (response.data.id) {
                this.props.history.push(
                    `/hotel/${idHotel}/offerte/${response.data.id}`
                );
            }
        });

        xhr.catch(() => {
            this.props.dispatch(
                notify({
                    title: 'Errore!',
                    message: 'Si è verificato un errore inaspettato!',
                    status: 'error',
                })
            );
        });

        return xhr;
    };

    render() {
        const { handleSubmit, submitting } = this.props;

        return (
            <div className="modal-content widget">
                <form
                    onSubmit={handleSubmit(this.submit)}
                    className="form-horizontal"
                >
                    <div className="modal-body">
                        <div className="container-fluid">
                            <h2 className="text-primary text-center">
                                Crea una nuova offerta
                            </h2>
                            <div className="text-center pb-4">
                                Scegli un titolo per l’offerta e prosegui
                            </div>
                            <Field
                                name="titolo"
                                label="Titolo"
                                component={InputField}
                                placeholder="Inserisci un titolo breve, potrai cambiarlo in seguito"
                            />
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col-12 text-center">
                            <a // eslint-disable-line
                                className="btn btn-sm btn-outline-light"
                                onClick={this.props.onClose}
                                disabled={submitting}
                            >
                                Annulla
                            </a>
                            <button
                                className="btn btn btn-sm btn-primary ms-2"
                                disabled={submitting}
                            >
                                Crea offerta
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

CreazioneOffertaModal.propTypes = {
    dispatch: PropTypes.func,
    handleSubmit: PropTypes.func,
    onClose: PropTypes.func,
    submitting: PropTypes.bool,
};

function validate(values) {
    return validator(
        values,
        (v) => {
            v.validate('titolo').required();
        },
        (msg, field) => {
            return 'Campo obbligatorio';
        }
    );
}

CreazioneOffertaModal = reduxForm({
    form: 'creaOfferta',
    validate,
})(CreazioneOffertaModal);

export default withRouter(CreazioneOffertaModal);
