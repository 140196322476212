import React, { Component } from 'react';
import Tooltip from 'components/form/v1/Tooltip';
const imgsTipoCliente = `images/tipologia-cliente`;

const grandezzaIcona = 35

class ImgBase extends Component {
    render() {
        const img_base = `base`;
        return (
            <Tooltip content="Cliente Base">
                <img
                    src={`../${imgsTipoCliente}/${img_base}.png`}  
                    width={grandezzaIcona} 
                    alt="cliente Base"
                />
            </Tooltip>
        );
    }
}

class ImgPlus extends Component {
    render() {
        const img_plus = `plus`;
        return (
            <Tooltip content="Cliente Plus">
                <img
                    src={`../${imgsTipoCliente}/${img_plus}.png`}  
                    width={grandezzaIcona} 
                    alt="cliente Plus"
                />
            </Tooltip>
        );
    }
}

class ImgPotTop extends Component {
    render() {
        const img_pot_top = `pot_top`;
        return (
            <Tooltip content="Cliente Potenziale Top">
                <img
                    src={`../${imgsTipoCliente}/${img_pot_top}.png`}  
                    width={grandezzaIcona} 
                    alt="cliente Potenziale Top"
                />
            </Tooltip>
        );
    }
}

class ImgTop extends Component {
    render() {
        const img_top = `top`;
        return (
            <Tooltip content="Cliente Top">
                <img
                    src={`../${imgsTipoCliente}/${img_top}.png`}  
                    width={grandezzaIcona} 
                    alt="cliente Top"
                />
            </Tooltip>
        );
    }
}

class ImgAzienda extends Component {
    render() {
        const img_azienda = `azienda`;
        return (
            <Tooltip content="Azienda">
                <img
                    src={`../${imgsTipoCliente}/${img_azienda}.png`}  
                    width={grandezzaIcona} 
                    alt="Azienda"
                />
            </Tooltip>
        );
    }
}

class ImgTarget extends Component {
    render() {
        const img_azienda = `target`;
        return (
            <Tooltip content="Cliente Target">
                <img
                    src={`../${imgsTipoCliente}/${img_azienda}.png`}  
                    width={grandezzaIcona} 
                    alt="Cliente Target"
                />
            </Tooltip>
        );
    }
}

class ImgMoroso extends Component {
    render() {
        const img_azienda = `moroso`;
        return (
            <Tooltip content="Cliente Moroso">
                <img
                    src={`../${imgsTipoCliente}/${img_azienda}.png`}  
                    width={grandezzaIcona} 
                    alt="Cliente Moroso"
                />
            </Tooltip>
        );
    }
}


export {
    ImgBase,
    ImgPlus,
    ImgPotTop,
    ImgTop,
    ImgAzienda,
    ImgMoroso,
    ImgTarget
};