import statsClient from '../../../apis/stats';
import helper from '../../../helpers/steinerHelper';

const api = helper.createApi('search', statsClient, {
    per_page: 'perPage',
    page: 'page',
    stat_from: 'from',
    stat_to: 'to',
    site_id: 'portaleId',
    email_cliente: 'q'
});

export default api;
