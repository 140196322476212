const REFRESH_ALL = 'statistiche/REFRESH_ALL';
const RESET_FILTERS = 'statistiche/RESET_FILTERS';
const SET_DATA = 'statistiche/SET_DATA';
const RESET_ALL = 'statistiche/RESET_ALL';
const SET_ERROR = 'statistiche/SET_ERROR';
const SET_FILTERS = 'statistiche/SET_FILTERS';
const START_LOADING = 'statistiche/START_LOADING';
const STOP_LOADING = 'statistiche/STOP_LOADING';
const SYNC_FILTERS = 'statistiche/SYNC_FILTERS';
const SET_CONTRACT_YEAR = 'statistiche/SET_CONTRACT_YEAR';

function refreshAll() {
    return {
        type: REFRESH_ALL
    };
}

function resetFilters() {
    return {
        type: RESET_FILTERS
    };
}

function setData(key, data) {
    return {
        type: SET_DATA,
        payload: {
            key,
            data
        }
    };
}

function resetAll(key, data) {
    return {
        type: RESET_ALL
    };
}

function setError(key, errorMessage, error) {
    return {
        type: SET_ERROR,
        payload: {
            key,
            error: errorMessage
        },
        error
    };
}

function setFilters(filters) {
    return {
        type: SET_FILTERS,
        payload: filters
    };
}

function startLoading(key) {
    return {
        type: START_LOADING,
        payload: {
            key
        }
    };
}

function stopLoading(key) {
    return {
        type: STOP_LOADING,
        payload: {
            key
        }
    };
}

function syncFilters(filters) {
    return {
        type: SYNC_FILTERS,
        payload: filters
    };
}

function setContractYear(year) {
    return {
        type: SET_CONTRACT_YEAR,
        payload: year
    };
}

export const actionTypes = {
    refreshAll: REFRESH_ALL,
    resetFilters: RESET_FILTERS,
    setData: SET_DATA,
    resetAll: RESET_ALL,
    setError: SET_ERROR,
    setFilters: SET_FILTERS,
    startLoading: START_LOADING,
    stopLoading: STOP_LOADING,
    syncFilters: SYNC_FILTERS,
    setContractYear: SET_CONTRACT_YEAR,
    [REFRESH_ALL]: REFRESH_ALL,
    [RESET_FILTERS]: RESET_FILTERS,
    [SET_DATA]: SET_DATA,
    [RESET_ALL]: RESET_ALL,
    [SET_ERROR]: SET_ERROR,
    [SET_FILTERS]: SET_FILTERS,
    [START_LOADING]: START_LOADING,
    [STOP_LOADING]: STOP_LOADING,
    [SYNC_FILTERS]: SYNC_FILTERS,
    [SET_CONTRACT_YEAR]: SET_CONTRACT_YEAR
};

export const actions = {
    refreshAll,
    resetFilters,
    setData,
    resetAll,
    setError,
    setFilters,
    startLoading,
    stopLoading,
    syncFilters,
    setContractYear
};
