import client from 'apis/apiCookie';
import helper from 'helpers/steinerHelper';

const apis = helper.createApi('Siti', client, {
    per_page: 'perPage',
    page: 'page',
    categoria: 'categoria',
    hotel_id: 'hotelId',
    dominio: 'dominio',
    id_sito: 'id_sito',
    nome: 'nome',
    filter: 'q',
    sort_field: 'orderKey',
    sort_direction: 'orderDirection',
});

apis.scansioneSito = (idSito) => {
    return client({
        url: `/Siti/Scan/${idSito}`,
        method: 'POST',
    });
};

export default apis;
