import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import { selectors } from '../reducers/portaliSchedeHotel';
import routeRegister from '../../../helpers/routeRegister';
import { generateRoutes } from '../../../helpers/portaleModuleHelper';

const routes = generateRoutes('schedeHotel', selectors);

const list = _.values(routes.list).map(route => ({
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'portaliSchedeHotel' */ `modules/portaliSchedeHotel/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('portaliSchedeHotel', routes.patterns);
routeRegister.addLinks('portaliSchedeHotel', links);

export default list;
