import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import WithUser from '../WithUser';
import { roles } from '../../helpers/auth';
import SelectHotelV2 from '../SelectHotelV2';
import { selectors as hotelSelectors } from '../../reducers/currentHotel';
// import WidgetUltimeModificheSito from 'components/widget/WidgetUltimeModificheSito';
import WidgetSelectGestione from 'components/widget/WidgetSelectGestione';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

class DashboardGestionaleHotel extends Component {
    render() {
        const { user, userRole } = this.props;

        if (!user) {
            return <span>Perfavore effettua il login</span>;
        }

        const isUser = userRole === roles.user;

        const showHotelFilter = !isUser;

        return (
            <div className="row py-2">
                <div className="col-12 pb-4">
                    <Helmet>
                        <title>{`Gestionale hotel`}</title>
                    </Helmet>
                    <h2 className="text-primary text-center">
                        Seleziona hotel da gestire
                    </h2>
                </div>
                {showHotelFilter && (
                    <WidgetSelectGestione title="Gestisci Hotel">
                        <SelectHotelV2 />
                    </WidgetSelectGestione>
                )}
                {/* <div className="col-md-6">
                    <WidgetUltimeModificheSito />
                </div>
                <div className="col-md-6">
                    <WidgetUltimeModificheSito />
                </div> */}
            </div>
        );
    }
}

export default withRouter(
    connect((state) => ({
        currentHotel: hotelSelectors.getCurrentHotel(state),
    }))(WithUser(DashboardGestionaleHotel))
);
