import { reducerCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { actionTypes } from '../actions/notification';

export const DEFAULT_STATE = helper.createDefaultState();

const handlers = helper.createHandlers(actionTypes, {
    items: 'data.results',
    total: 'data.meta.total'
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('notification');
