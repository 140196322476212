import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputGroupHoc from 'components/form/v1/InputGroupHoc';

// import getPasswordStrength from 'password-strength-calc'; // Vedi nota in helper
import getPasswordStrength from 'helpers/password-strength-calc';

import './PasswordField.css';

export class ReactPasswordStrength extends Component {
    constructor() {
        super();

        this.state = {
            score: 0,
            isValid: false,
            password: ''
        };
    }

    // clear() {
    //     const { input: { onChange  }} = this.props;

    //     this.setState({
    //         score: 0,
    //         isValid: false,
    //         password: '',
    //     }, () => {
    //         this.passwordInput.value = '';

    //         if (onChange !== null) {
    //             onChange(this.state.password);
    //         }
    //     });
    // }

    isTooShort(password) {
        return password.length < this.props.minLength;
    }

    handleChange = () => {
        const {
            input: { onChange },
            minScore
        } = this.props;
        const password = this.passwordInput.value;

        let score;

        if (this.isTooShort(password)) {
            score = 0;
        } else {
            score = this.getScore(password);
        }

        this.setState(
            {
                isValid: score >= minScore,
                password,
                score
            },
            () => {
                if (onChange !== null) {
                    onChange(this.state.password);
                }
            }
        );
    };

    getScore(password) {
        const result = getPasswordStrength(password);

        const score = Math.ceil(result / 20) - 1;

        if (score < 0) {
            return 0;
        }

        return score;
    }

    render() {
        const { score, password, isValid } = this.state;

        const { scoreWords, placeholder, style, tooShortWord } = this.props;

        const wrapperClasses = [
            'form-group',
            'ReactPasswordStrength',
            this.props.meta.touched && this.props.meta.error ? 'has-error' : '',
            password.length > 0 ? `is-strength-${score}` : ''
        ];

        const inputClasses = ['ReactPasswordStrength-input', 'form-control'];

        if (isValid === true) {
            inputClasses.push('is-password-valid');
        } else if (password.length > 0) {
            inputClasses.push('is-password-invalid');
        }

        let strengthDesc;

        if (this.isTooShort(password)) {
            strengthDesc = tooShortWord;
        } else {
            strengthDesc = scoreWords[score];
        }

        return (
            <div className={wrapperClasses.join(' ')} style={style}>
                <input
                    type="password"
                    placeholder={placeholder}
                    className={inputClasses.join(' ')}
                    onChange={this.handleChange}
                    onBlur={this.props.input.onBlur}
                    ref={ref => (this.passwordInput = ref)}
                    value={password}
                />
                <div className="ReactPasswordStrength-strength-bar" />
                <span className="ReactPasswordStrength-strength-desc">
                    {strengthDesc}
                </span>
            </div>
        );
    }
}

ReactPasswordStrength.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    minLength: PropTypes.number,
    minScore: PropTypes.number,
    placeholder: PropTypes.string,
    scoreWords: PropTypes.array,
    tooShortWord: PropTypes.string,
    style: PropTypes.object
};

ReactPasswordStrength.defaultProps = {
    minLength: 6,
    minScore: 2,
    scoreWords: ['molto debole', 'debole', 'media', 'buona', 'forte'],
    tooShortWord: 'troppo corta'
};

export default InputGroupHoc(ReactPasswordStrength);
