import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/ruoli';

const routes = helper.generateRoutes('ruoli', selectors);
const list = _.values(routes.list).map(route => ({
    ...route,
    requiredRoles: ['admin'],
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'ruoli' */ `modules/ruoli/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('ruoli', routes.patterns);
routeRegister.addLinks('ruoli', links);

export default list;
