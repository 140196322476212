import _ from 'lodash';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { routing } from '@adrias-online/steiner';
import qs from 'query-string';

import { actions, actionTypes } from '../actions/currentPortale';
import { selectors } from '../reducers/currentPortale';
import apis from '../apis/portali';
import portaleStore from '../helpers/portaleStore';

function* syncPortale(action) {
    if (!action.payload.label) {
        let label;
        const portaleId = action.payload.value,
            portale = portaleStore.get(portaleId);

        if (portale) {
            label = portale.label;
        } else {
            const res = yield call(apis.fetch, portaleId);

            label = `${res.data.name} - (${res.data.id})`;

            portaleStore.set(portaleId, {
                label
            });
        }

        yield put(
            actions.syncPortale({
                value: portaleId,
                label
            })
        );
    }
}

function* setPortale(action) {
    const { value, label } = action.payload,
        portaleId = yield select(selectors.getCurrentPortaleId);

    let pathname = window.location.pathname;

    if (pathname.match(/^\/portale/)) {
        const parts = _.compact(pathname.split('/'));

        if (parts.length > 3) {
            pathname = `/${parts.splice(0, 3).join('/')}`;
        }

        const newPath = pathname.replace(
            /(\/portale\/)(.*)(\/.*)/,
            `$1${portaleId}$3`
        );

        const location = {
            pathname: newPath
        };

        yield put(routing.actions.navigate(location, 'PUSH'));
    } else {
        const q = qs.parse(window.location.search);

        if (q.portaleId) {
            q.portaleId = portaleId;

            const location = {
                pathname,
                search: qs.stringify(q)
            };

            yield put(routing.actions.navigate(location, 'PUSH'));
        }
    }

    portaleStore.set(value, {
        label
    });
}

export default [
    takeEvery(actionTypes.syncPortale, syncPortale),
    takeEvery(actionTypes.setPortale, setPortale)
];
