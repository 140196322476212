import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import InputField from 'components/form/v1/InputField';
import LoadingButton from 'components/form/v1/LoadingButton';
import { Helmet } from 'react-helmet';
import { addNotification as notify } from 'reapop';
import qs from 'query-string';

import { resetPassword } from 'helpers/auth-api';
import { validatePassword, handleChangePasswordError } from 'helpers/auth';
import PasswordField from 'components/form/PasswordField';
import LoginWrapper from './LoginWrapper';
import LoginPage from './LoginPage';

// function delay(time) {
//     return new Promise((resolve) => {
//         setTimeout(() => {
//             resolve();
//         }, time);
//     });
// }

export class ResetPassword extends Component {
    state = {
        submitSuccessfull: false,
    };

    componentDidMount() {
        this.form.elements[0].focus();
    }

    submit = (data) => {
        const { t } = qs.parse(window.location.search);

        return resetPassword(t, data)
            .then(() => {
                this.props.dispatch(
                    notify({
                        title: 'Successo!',
                        message: 'Password modificata con successo!',
                        status: 'success',
                        position: 'tc',
                        dismissAfter: 0,
                    })
                );

                this.setState({
                    submitSuccessfull: true,
                });
            })
            .catch((err) => {
                handleChangePasswordError(err, this.props.dispatch);
            });
    };

    render() {
        const { handleSubmit, submitting } = this.props;

        if (this.state.submitSuccessfull) {
            return <Redirect to="/" />;
        }

        return (
            <div>
                <Helmet>
                    <title>Reset password</title>
                </Helmet>

                <LoginPage
                    formTitle="Inserisci la tua nuova password"
                    formComponent={
                        <LoginWrapper title={`${window.__RUNTIME_CONFIG__.REACT_APP_NAME}`}>
                            <form
                                ref={(form) => (this.form = form)}
                                onSubmit={handleSubmit(this.submit)}
                                className="py-3"
                            >
                                <div className="mb-4">
                                    <Field
                                        className="form-control"
                                        name="password"
                                        placeholder="Password"
                                        component={PasswordField}
                                        onlyInput={true}
                                        type="password"
                                    />
                                </div>
                                <div className="mb-4">
                                    <Field
                                        className="form-control"
                                        name="password_confirm"
                                        component={InputField}
                                        placeholder="Conferma password"
                                        type="password"
                                        onlyInput={true}
                                        // inline={true}
                                    />
                                </div>
                                <div className="mb-4">
                                    <LoadingButton
                                        className="btn-success btn-block"
                                        loading={submitting}
                                    >
                                        <i className="fas fa-check" /> Conferma
                                    </LoadingButton>
                                </div>
                                <div className="col-12 text-end">
                                    <Link to="/">Torna al login</Link>
                                </div>
                            </form>
                        </LoginWrapper>
                    }
                />
            </div>
        );
    }
}

ResetPassword.propTypes = {
    dispatch: PropTypes.func,
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
};

export default reduxForm({
    form: 'reset_password',
    validate: validatePassword,
})(ResetPassword);
