import helper from 'helpers/steinerHelper';
import client from 'apis/contratti';

const apis = helper.createApi('rubrica', client, {
    limit: 'perPage',
    page: 'page',
    filter: 'q',
    codice_produttore: 'produttore',
    tipologia_cliente: 'tipologia_cliente',
    comune: 'comune',
    posizione: 'posizione',
    year: 'year',
    orderBy: 'orderKey',
    orderDir: 'orderDirection',
    customer_category_struttura: 'customer_category_struttura'
});

apis.revealPhone = id => {
    return client({
        url: `rubrica/opens/${id}`
    });
};

apis.addNotes = (data) => {
    return client({
        url: `rubrica/note/${data.id}`,
        method: 'POST',
        data: {
            id: [data.id],
            nota: data.nota,
        },
    });
};

apis.aggiungiTask = (data) => {
    return client({
        url: `rubrica/task/${data.id}`,
        method: 'POST',
        data: {
            description: data.description,
            date: data.date,
            type: data.type
        },
    });
};

apis.sendMail = (data) => {
    return client({
        url: `rubrica/send/${data.id}`,
        method: 'POST',
        data: {
            to: data.to,
            cc: data.cc,
            oggetto: data.oggetto,
            testo: data.testo,
        },
    });
};

apis.fetchCrmContatto = (hotelId) => {
    return client({
        url: `rubrica/hotel/${hotelId}`,
        method: 'get',
    });
};

apis.fetchCrmContattoByProduttore = (codiceProduttore) => {
    return client({
        url: `rubrica/produttore/${codiceProduttore}`,
        method: 'get',
    });
}

apis.fetchCrmIncarichiByProduttore = (codiceProduttore) => {
    return client({
        url: `rubrica/task/produttore/${codiceProduttore}`,
        method: 'get',
    });
}

export default apis;
