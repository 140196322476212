import { createSagas, bootSagas } from 'helpers/hotelModuleHelper';

import { actions, actionTypes } from '../actions/WebReputationKeywords';
import { selectors, DEFAULT_STATE } from '../reducers/WebReputationKeywords';
import api from '../apis/WebReputationKeywords';

const sagas = createSagas(
    'WebReputationKeywords',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE
);
const defaultSagas = bootSagas(sagas, actionTypes);

export default [...defaultSagas];
