import React, { Component } from 'react';
import logoHeader from 'images/logo-backoffice-AO-verde.png';

import bkg1 from 'images/bkg-login/bkg-wall-e-01.jpg';
import bkg2 from 'images/bkg-login/bkg-wall-e-02.jpg';
import bkg3 from 'images/bkg-login/bkg-wall-e-03.jpg';
import bkg4 from 'images/bkg-login/bkg-wall-e-04.jpg';
import bkg5 from 'images/bkg-login/bkg-wall-e-05.jpg';
// import bkgLogin from 'images/background-login.svg';
import _ from 'lodash';

const loginBgImages = [bkg1, bkg2, bkg3, bkg4, bkg5];

class LoginPage extends Component {
    render() {
        const { formTitle, formComponent } = this.props;
        return (
            <div className="login-page">
                <div className="login-header">
                    <img src={logoHeader} alt="Logo Adrias Online" />
                </div>
                <div className="container-fluid">
                    <div className="row no-gutter l--min-h-100">
                        <div className="col-md-6 my-auto">
                            <div className="py-5">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-9 col-lg-8 mx-auto">
                                            <h3 className="login-title">
                                            {formTitle}
                                            </h3>
                                            {formComponent}
                                            <a
                                                href="https://posta.adriasonline.it/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="d-block text-center my-5 btn btn-outline-light"
                                            >
                                                <i className="fas fa-envelope me-2"></i>{' '}
                                                <span>Accedi alla webmail</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="d-none d-md-flex col-md-6"
                            style={{
                                backgroundImage: `url("${_.sample(
                                    loginBgImages
                                )}")`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                zIndex: '-1',
                            }}
                        />
                    </div>
                </div>
                <div className="login-footer d-flex">
                    © {new Date().getFullYear()} Adrias Online. Tutti i diritti
                    riservati.
                    <div className="beamer-adrias invisible"></div>
                    <div className="ms-2">
                        {process.env.REACT_APP_VERSION !== undefined &&
                            'Release: v' + process.env.REACT_APP_VERSION}
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginPage;
