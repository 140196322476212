import React, { Component } from 'react';
import PropTypes from 'prop-types';

import format from 'date-fns/format/index';
import DatepickerInput from './DatepickerInput';
import InputGroupHoc from "./v1/InputGroupHoc";

class DatePickerPortali extends Component {
    handleChange = value => {
        this.props.input.onChange(format(value, 'YYYY-MM-DD'));
    };

    render() {
        const {
            className,
            input: { value }
        } = this.props;

        return (
            <DatepickerInput
                className={className}
                value={value}
                onDayChange={this.handleChange}
            />
        );
    }
}

DatePickerPortali.propTypes = {
    input: PropTypes.object
};

export default InputGroupHoc(DatePickerPortali);
