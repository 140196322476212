import React, { Component } from 'react';
import HotelWidgetLink from 'components/HotelWidgetLink';

export default class WidgetGestioneAmministrazione extends Component {
    render() {
        const { isUser, isMultihotel, isProduttore, userRole } = this.props;

        const puoGestireProfilazione =
            userRole === 'admin' || userRole === 'amministrazione';

        return (
            <div className="widget">
                <h2>Amministrazione</h2>
                {(isUser || isMultihotel) && (
                    <div className="row">
                        <div className="col-md-4">
                            <HotelWidgetLink
                                to="/hotel/:hotelId/anagrafica"
                                icon="fas fa-address-card"
                            >
                                Anagrafica
                            </HotelWidgetLink>
                        </div>
                        <div className="col-md-4">
                            <HotelWidgetLink
                                to="/hotel/:hotelId/contratti"
                                icon="fas fa-handshake"
                            >
                                Contratti
                            </HotelWidgetLink>
                        </div>

                    </div>
                )}
                {!isUser && !isMultihotel && (
                    <div className="row">
                        <div className="col-md-4">
                            <HotelWidgetLink
                                icon="fas fa-address-card"
                                to="/hotel"
                            >
                                Anagrafiche
                            </HotelWidgetLink>
                        </div>
                        {!isProduttore && (
                            <>
                                <div className="col-md-4">
                                    <HotelWidgetLink
                                        icon="fas fa-user-circle"
                                        to="/utenti"
                                    >
                                        Utenti
                                    </HotelWidgetLink>
                                </div>
                                <div className="col-md-4">
                                    <HotelWidgetLink
                                        icon="fas fa-briefcase"
                                        to="/commerciali"
                                    >
                                        Commerciali
                                    </HotelWidgetLink>
                                </div>
                                <div className="col-md-4">
                                    <HotelWidgetLink
                                        icon="fas fa-bullseye-arrow"
                                        to="/obbVenditaAnnuali"
                                    >
                                        Obbiettivi vendite annuali
                                    </HotelWidgetLink>
                                </div>
                            </>
                        )}
                        <div className="col-md-4">
                            <HotelWidgetLink
                                icon="fas fa-clipboard-list"
                                to="/articoliListino"
                            >
                                Listino articoli
                            </HotelWidgetLink>
                        </div>
                        {!isProduttore && (
                            <div className="col-md-4">
                                <HotelWidgetLink
                                    icon="fas fa-barcode"
                                    to="/articoli"
                                >
                                    Articoli
                                </HotelWidgetLink>
                            </div>
                        )}
                        <div className="col-md-4">
                            <HotelWidgetLink
                                icon="fas fa-handshake"
                                to="/ordini"
                            >
                                Ordini
                            </HotelWidgetLink>
                        </div>
                        {puoGestireProfilazione && (
                            <>
                                <div className="col-md-4">
                                    <HotelWidgetLink
                                        icon="fas fa-balance-scale"
                                        to="/criteriQualitativi"
                                    >
                                        Criteri Qualitativi
                                    </HotelWidgetLink>
                                </div>
                                <div className="col-md-4">
                                    <HotelWidgetLink
                                        icon="fas fa-calculator"
                                        to="/coefficienti"
                                    >
                                        Coefficienti
                                    </HotelWidgetLink>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
