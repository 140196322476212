import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import WidgetGestioneAdmin from '../widget/WidgetGestioneAdmin';
import WidgetGestioneCookie from '../widget/WidgetGestioneCookie';
import WidgetGestioneCms from '../widget/WidgetGestioneCms';
import WidgetGestioneUtility from '../widget/WidgetGestioneUtility';

class DashboardGestionaleAdmin extends Component {
    render() {
        return (
            <div className="row py-2">
                <div className="col-12 pb-4">
                    <Helmet>
                        <title>{`Gestionale Admin`}</title>
                    </Helmet>
                    <h2 className="text-primary text-center">
                        Gestionale Admin
                    </h2>
                </div>
                <div className="col-md-12">
                    <WidgetGestioneAdmin />
                </div>
                <div className="col-md-12">
                    <WidgetGestioneCookie />
                </div>
                <div className="col-md-12">
                    <WidgetGestioneCms />
                </div>
                <div className="col-md-12">
                    <WidgetGestioneUtility />
                </div>
            </div>
        );
    }
}

export default DashboardGestionaleAdmin;
