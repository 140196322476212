import _ from 'lodash';

const defaultOptions = {
    debounce: 500,
    dataPathItem: null,
    dataPathItems: null,
    itemGetter: null
};

export function createAutoSelectAsyncLoaders(options) {
    _.defaults(options, defaultOptions);

    const fetchItems = (q, resolve) => {
        options.apiCallItems(q).then(res => {
            const data = _.get(res.data, options.dataPathItems, res.data);
            const values = data.map(options.optionExtractor);

            resolve({ options: values });
        });
    };

    const debouncedFetchItems = _.debounce(fetchItems, options.debounce);

    return {
        loadItems(q) {
            return new Promise(resolve => {
                debouncedFetchItems(q, resolve);
            });
        },
        loadItemById(id) {
            return new Promise(resolve => {
                options.apiCallItem(id).then(res => {
                    const data = options.itemGetter
                        ? options.itemGetter(res.data, id)
                        : _.get(res.data, options.dataPathItem, res.data);

                    resolve({ options: [options.optionExtractor(data)] });
                });
            });
        }
    };
}
