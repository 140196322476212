import helper from 'helpers/steinerHelper';
import apiClient from 'apis/security';

const apis = helper.createApi('management/role', apiClient, {
    limit: 'perPage',
    page: 'page',
    filter: 'q'
});

apis.updatePermissions = function(id, permissions = []) {
    return apiClient({
        url: `/management/role/${id}/permission/change`,
        method: 'patch',
        data: {
            permissions
        }
    });
};

export default apis;
