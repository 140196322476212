import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/sitePhoto';

const routes = helper.generateRoutes('sitePhoto', selectors, {
    itemLabelKey: 'file_name',
    omit: ['create']
});

routes.list.list.breadcrumb = 'Foto sito';

// const patternUpload = '/sitePhoto/upload';
// const patternEdit = '/sitePhoto/:id(\\d+)';

// routes.patterns.upload = patternUpload;
// routes.patterns.edit = patternEdit;
// routes.list.edit.path = patternEdit;

// routes.list.upload = {
//     breadcrumb: 'Upload',
//     componentPath: 'components/SitePhotoUpload',
//     path: patternUpload,
// };

const list = _.values(routes.list).map(route => ({
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'photo-sito' */ `modules/sitePhoto/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('sitePhoto', routes.patterns, {
    it: 'Foto sito'
});
routeRegister.addLinks('sitePhoto', links);

export default list;
