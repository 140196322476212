import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import advMediaGallery from 'images/web-reputation/adv-media-gallery.png';
import api from '../../modules/mediaGalleryPrivata/apis/mediaGalleryPrivata';

let WidgetMediaGallery = (props) => {
    const [fotoWidget, setFotoWidget] = useState({
        data: [{ nomeFile: 'test', thumbUrl: 'test' }],
    });
    useEffect(() => {
        api.getFotoWidget(props.currentHotel.value, props.nFoto).then((res) => {
            setFotoWidget(res.data);
        });
    }, []);

    return (
        <div className="widget" style={{ height: '360px' }}>
            <h2 className="text-center py-3">Media Gallery</h2>
            <div
                className="row py-2"
                style={{ background: '#f4f4f4', height: '172px' }}
            >
                {fotoWidget.data.map((foto, i) => (
                    <div
                        className={props.nFoto === 3 ? 'col-4' : 'col-2'}
                        style={{ margin: 'auto' }}
                        key={i}
                    >
                        <img
                            className="img-fluid"
                            src={foto.thumbUrl}
                            alt={foto.nomeFile}
                        />
                    </div>
                ))}
            </div>
            <div className="w-100 py-3 text-center px-5">
                <Link
                    to={`/hotel/${props.currentHotel.value}/mediaGallery`}
                    className={'btn btn-tertiary text-center'}
                >
                    Le tue foto di qualità
                </Link>
            </div>
        </div>
    );
};

export default withRouter(WidgetMediaGallery);
