import { reducerCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { actionTypes } from '../actions/sephirothMailAssoc';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            sites_id: null
        }
    }
});

const handlers = helper.createHandlers(actionTypes, {
    items: 'data.values',
    total: 'data.meta.pagination.total',
    idKey: 'id'
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('sephirothMailAssoc');
