// import _ from 'lodash';
import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';

import { reducerCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import { actionTypes } from '../actions/ordini';
import { getCurrentContractYear } from '../../../helpers/date';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            article_code: null,
            article_year: `${getCurrentContractYear()}`,
            from_date: null,
            to_date: null,
            hotel_id: null,
            perPage: 9999,
            produttore: null,
            row_hotel_id: null
        }
    }
});

const handlers = helper.createHandlers(actionTypes, {
    items: 'data.data',
    idKey: 'order_id',
    total: 'data.meta.total'
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

const defaultSelectors = reducerCreator.createSelectors('ordini');

const itemsSelector = state => state.items;
const filtersSelector = state => state.filters;

const articleDataSelectors = createSelector(
    itemsSelector,
    filtersSelector,
    (items, filters) => {
        const rows = [];

        if (filters.article_id) {
            items.forEach(item => {
                const { order_rows } = item;

                const filtered = order_rows.filter(
                    row => row.article_id === filters.article_id
                );

                if (filtered) {
                    filtered.forEach(row => {
                        const { order_id, order_rows, seller_name } = item;
                        const { article_total, article_price_list } = row;

                        rows.push({
                            ...row,
                            order_id,
                            order_rows,
                            seller_name,
                            total: article_total,
                            internet_price_list_total: article_price_list
                        });
                    });
                }
            });
        }

        return Immutable(rows);
    }
);

export const selectors = Object.assign({}, defaultSelectors, {
    articleDataSelectors
});
