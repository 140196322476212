import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import { selectors } from '../reducers/portaliIndicatori';
import routeRegister from '../../../helpers/routeRegister';
import { generateRoutes } from '../../../helpers/portaleModuleHelper';

const routes = generateRoutes('indicatori', selectors);

const list = _.values(routes.list).map(route => ({
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'portaliIndicatori' */ `modules/portaliIndicatori/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('portaliIndicatori', routes.patterns);
routeRegister.addLinks('portaliIndicatori', links);

export default list;
