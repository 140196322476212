import { routeCreator } from '@adrias-online/steiner';
import _ from 'lodash';

import helper from 'helpers/steinerHelper';
import routeRegister from 'helpers/routeRegister';
import { selectors } from '../reducers/iscrittiNewsletter';

const routes = helper.generateRoutes('iscrittiNewsletter', selectors, {
    omit: ['create', 'edit'],
});

routes.list.list.breadcrumb = 'Iscritti Newsletter';

const list = _.values(routes.list).map((route) => ({
    ...route,
    hotelFilter: true,
    getComponent() {
        return new Promise((resolve) => {
            import(
                /* webpackChunkName: 'iscrittiNewsletter' */ `modules/iscrittiNewsletter/${route.componentPath}`
            ).then((response) => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('iscrittiNewsletter', routes.patterns, {
    it: 'Iscritti Newsletter'
});
routeRegister.addLinks('iscrittiNewsletter', links);

export default list;
