import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import { roles } from '../../../helpers/auth';
import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';

import { selectors } from '../reducers/sephirothMailAssoc';

const routes = helper.generateRoutes('sephirothMailAssoc', selectors, {
    itemLabelKey: 'id',
});

const requiredRoles = [roles.admin, roles.developer];

// console.warn(routes);
routes.list.list.requiredRoles = requiredRoles;
routes.list.edit.requiredRoles = requiredRoles;
routes.list.edit.exact = true;
routes.list.edit.breadcrumb = function (state, ownProps) {
    if (ownProps.match.params.id === 'create') {
        return {
            breadcrumbName: 'Crea nuovo',
        };
    }

    const current = selectors.currentSelector(state);

    if (ownProps.match.isExact && current && current.item) {
        return {
            breadcrumbName: `Configurazione ${current.item.id} (idHotel: ${current.item.sites_id})`,
        };
    }

    return {
        breadcrumbName: '...',
    };
};

const list = _.values(routes.list).map((route) => ({
    ...route,
    requiredRoles: ['admin'],
    getComponent() {
        return new Promise((resolve) => {
            import(
                /* webpackChunkName: 'hotel' */ `modules/sephirothMailAssoc/${route.componentPath}`
            ).then((response) => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('sephirothMailAssoc', routes.patterns);
routeRegister.addLinks('sephirothMailAssoc', links);

// console.log(list);

export default list;
