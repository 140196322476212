import { actions, actionTypes } from './actions.js';
import saga from './saga';

const confirmModule = {
    actions,
    actionTypes,
    createConfirmSaga: saga,
};

export const confirm = confirmModule;
