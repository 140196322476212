import { sagaCreator } from '@adrias-online/steiner';

import { createSagas } from '../../../helpers/portaleModuleHelper';
import { actions, actionTypes } from '../actions/portaliPages';
import { selectors, DEFAULT_STATE } from '../reducers/portaliPages';
import api from '../apis/portaliPages';

const sagas = createSagas(
    'portaliPages',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE,
    {
        resourceLabel: 'Pagina'
    }
);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [...defaultSagas];
