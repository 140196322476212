import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputGroupHoc from 'components/form/v1/InputGroupHoc';
import _ from 'lodash';

class AutoSelectInput extends Component {
    handleChange = option => {
        const { onChange } = this.props.input;

        if (!option) {
            return onChange(null);
        }

        if (this.props.multi) {
            // onChange(option.map(o => o.value));
            onChange(option);
        } else {
            onChange(option.value);
        }
    };

    render() {
        const {
            SelectComponent,
            disabled,
            input,
            options,
            placeholder,
            ...rest
        } = this.props;

        const selectProps = _.omit(rest, [
            'horizontal',
            'InputComponent',
            'label',
            'meta',
            'onlyInput'
        ]);

        return (
            <SelectComponent
                placeholder={placeholder}
                options={options}
                disabled={disabled}
                {...input}
                onChange={this.handleChange}
                {...selectProps}
            />
        );
    }
}

AutoSelectInput.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.object,
    label: PropTypes.string,
    multi: PropTypes.bool,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    SelectComponent: PropTypes.func
};

const AutoSelectField = InputGroupHoc(AutoSelectInput);

export default AutoSelectField;
