import { sagaCreator } from '@adrias-online/steiner/lib/helpers';

import api from '../apis/articoliListino';

import helper from '../../../helpers/steinerHelper';
import { actions, actionTypes } from '../actions/articoliListino';
import { selectors, DEFAULT_STATE } from '../reducers/articoliListino';

function handleError(error) {
    let payload = {
        message: "Si è verificato un errore durante l'invio del form"
    };

    if (error.response && error.response.status === 422) {
        payload = {
            message: 'Si sono verificati i seguenti errori di validazione:',
            validationErrors: error.response.data.errors
        };
    }

    return payload;
}

const sagas = helper.createSagas(
    'articoliListino',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE,
    {
        resourceLabel: {
            it: 'Listino Articoli'
        },
        createFailErrorCreator: handleError,
        updateFailErrorCreator: handleError
    }
);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [...defaultSagas];
