import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import helper from 'helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/portaliIndicatoriHotels';

const routes = helper.generateRoutes('portaliIndicatoriHotels', selectors);
routes.list.list.breadcrumb = `Portali Indicatori`;

const list = _.values(routes.list).map(route => ({
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'portaliIndicatoriHotels' */ `modules/portaliIndicatoriHotels/${route.componentPath}`)
                .then(response => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('portaliIndicatoriHotels', routes.patterns);
routeRegister.addLinks('portaliIndicatoriHotels', links);

export default list;