import SteinerHelperBase from './steinerHelperBase';

import client from '../apis/api';

export default new SteinerHelperBase({
    defaultClient: client,
    defaultPerPage: 20,
    listSuccessOptions: {
        items: 'data.values',
        total: 'data.meta.pagination.total'
    },
    paramsMap: {
        filter: 'q',
        limit: 'perPage',
        page: 'page',
        orderBy: 'orderKey',
        orderDir: 'orderDirection'
    }
});
