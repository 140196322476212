// N.B. per qualche motivo CRA non riesce a minificare questo file preso dal modulo `password-strength-calc` in fase di build, quindi sono stato costretto a importarlo qui
import functions from './password-calc-functions';

export default function getPasswordStrength(pwd) {
    if (!pwd) {
        return 0;
    }

    const list = pwd.replace(/\s/g, '').split('');
    const score = Object.values(functions).reduce((total, fn) => {
        total += fn(list);
        return total;
    }, 0);

    return Math.max(0, Math.min(100, score));
}
