import helper from '../../../helpers/steinerHelper';

const defaultActionTypes = helper.createActionTypes('portaliLandingPage');

const custmActionsTypes = {
    switchOnlineLanding: 'portaliLanding/SWITCH_ONLINE_LANDING'
};

export const actionTypes = Object.assign(
    {},
    defaultActionTypes,
    custmActionsTypes
);

const defaultActions = helper.createActions('portaliLandingPage', actionTypes);

const customActions = {
    switchOnlineLanding(id, value) {
        return {
            type: actionTypes.switchOnlineLanding,
            payload: {
                id,
                value
            }
        };
    }
};

export const actions = Object.assign({}, defaultActions, customActions);
