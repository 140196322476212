import { createSagas, bootSagas } from 'helpers/hotelModuleHelper';
import { actions, actionTypes } from '../actions/hotelListino';
import { selectors, DEFAULT_STATE } from '../reducers/hotelListino';
import api from '../apis/hotelListino';

const sagas = createSagas(
    'hotelListino',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE,
    {
        idKey: 'idListino'
    }
);

const defaultSagas = bootSagas(sagas, actionTypes);

export default [...defaultSagas];
