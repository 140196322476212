import { reducerCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { actionTypes } from '../actions/reports';
import { getCurrentContractYear } from '../../../helpers/date';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            article_id: null,
            hotel_id: null,
            year: getCurrentContractYear(),
            produttore: null
        }
    }
});

const handlers = helper.createHandlers(actionTypes, {
    items: 'data.data',
    total: 'data.meta.pagination.total'
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('reports');
