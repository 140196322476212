import { routeCreator } from '@adrias-online/steiner';
import _ from 'lodash';

import helper from 'helpers/steinerHelper';
import routeRegister from 'helpers/routeRegister';
import { selectors } from '../reducers/calendarioAttivita';

const routes = helper.generateRoutes('calendarioAttivita', selectors);
const list = _.values(routes.list).map((route) => ({
    ...route,
    requiredRoles: ['admin', 'developer', 'employee', 'amministrazione'],
    getComponent() {
        return new Promise((resolve) => {
            import(
                /* webpackChunkName: 'calendarioAttivita' */ `modules/calendarioAttivita/${route.componentPath}`
            ).then((response) => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('calendarioAttivita', routes.patterns);
routeRegister.addLinks('calendarioAttivita', links);

export default list;
