import React from 'react';
import { Field } from 'redux-form';
import SelectField from 'components/form/v1/SelectField';

const SelectValueField = props => {
    return (
        <Field
            component={SelectField}
            parse={option => (option ? option.value : option)}
            {...props}
        />
    );
};

export default SelectValueField;
