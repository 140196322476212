import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HotelWidgetLink from 'components/HotelWidgetLink';
import ModalAssistenza from '../ModalAssistenza';

const WidgetClientePosta = (props) => {
    const [modalAssistenzaOpen, setModalAssistenzaOpen] = useState(false);
    return (
        <>
            <ModalAssistenza
                isOpen={modalAssistenzaOpen}
                onClose={() => setModalAssistenzaOpen(false)}
                motivo="email-password"
            />
            <div className="widget" style={{ padding: '0 10px 5px' }}>
                <h2 className="p-3">Configurazione posta</h2>
                <div className="row">
                    <div className="col-md-12">
                        <HotelWidgetLink icon="fas fa-mobile-alt" to="/clientEmail">
                            Dati di Configurazione
                        </HotelWidgetLink>
                    </div>
                    <div className="col-md-12">
                        <div className="widget-link">
                            <a
                                href="https://posta.adriasonline.it/"
                                target={'_blank'}
                                rel="noopener noreferrer"
                                className="d-flex align-items-center"
                            >
                                <i
                                    className="fas fa-envelope-open widget-icon"
                                    aria-hidden="true"
                                />
                                Webmail e cambio password
                            </a>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="widget-link modale">
                            <button
                                onClick={() => setModalAssistenzaOpen(true)}
                                className="d-flex align-items-center text-start"
                            >
                                <i
                                    className="fas fa-key widget-icon"
                                    aria-hidden="true"
                                />
                                Recupera password email
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

WidgetClientePosta.propTypes = {
    userHotelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default WidgetClientePosta;
