import React, { Component } from 'react';
import PortaleFilterWidgetLink from '../PortaleFilterWidgetLink';
import PortaleWidgetLink from '../PortaleWidgetLink';

export default class WidgetGestionePortale extends Component {
    createInitialFormValues(item) {
        return item;
    }

    render() {
        const { nomePortale } = this.props;

        return (
            <div className="widget">
                <h2>Gestisci {nomePortale}</h2>
                <div className="row">
                    <div className="col-md-4">
                        <PortaleWidgetLink
                            icon="fas fa-window-restore"
                            to="/portale/:portaleId/schedeHotel"
                        >
                            Schede Hotel
                        </PortaleWidgetLink>
                    </div>
                    {/* <div className="col-md-4">
                        <PortaleWidgetLink
                            icon="fas fa-tag"
                            to="/portale/:portaleId/consigliati"
                        >
                            Consigliati
                        </PortaleWidgetLink>
                    </div> */}
                    <div className="col-md-4">
                        <PortaleFilterWidgetLink
                            to="/portaliGalleries"
                            icon="fas fa-images"
                        >
                            Gallery e foto
                        </PortaleFilterWidgetLink>
                    </div>
                    <div className="col-md-4">
                        <PortaleWidgetLink
                            icon="fas fa-file"
                            to="/portale/:portaleId/pagine"
                        >
                            Pagine
                        </PortaleWidgetLink>
                    </div>
                    <div className="col-md-4">
                        <PortaleWidgetLink
                            icon="fas fa-plane-arrival"
                            to="/portale/:portaleId/landingPage"
                        >
                            Landing Page
                        </PortaleWidgetLink>
                    </div>
                    <div className="col-md-4">
                        <PortaleWidgetLink
                            icon="fas fa-list-alt"
                            to="/portaliIndicatoriHotels"
                        >
                            Indicatori unificati
                        </PortaleWidgetLink>
                    </div>
                    <div className="col-md-4">
                        <PortaleWidgetLink
                            icon="fas fa-list"
                            to="/portale/:portaleId/indicatori"
                        >
                            Indicatori
                        </PortaleWidgetLink>
                    </div>
                    <div className="col-md-4">
                        <PortaleWidgetLink
                            icon="fas fa-sync-alt"
                            to="/portale/:portaleId/redirect"
                        >
                            Redirect
                        </PortaleWidgetLink>
                    </div>
                    <div className="col-md-4">
                        <PortaleWidgetLink
                            icon="fas fa-file-alt"
                            to="/portale/:portaleId/articoli"
                        >
                            Articoli
                        </PortaleWidgetLink>
                    </div>
                </div>
            </div>
        );
    }
}
