import statsClient from '../../../apis/stats';

import { dataKeys } from '../helpers';

function buildRequestParams(filters) {
    const params = {};

    if (filters.startDate) {
        params.stat_from = filters.startDate;
    }

    if (filters.endDate) {
        params.stat_to = filters.endDate;
    }

    if (filters.ga_medium) {
        params.ga_medium = filters.ga_medium;
    }

    if (filters.portaleId) {
        params.site_id = filters.portaleId;
    }

    if (filters.hotelId) {
        params.id_hotel = filters.hotelId;
    }

    return params;
}

const endpointMap = {
    interest: 'daily_interest',
    mediumNumber: 'number',
    mediumPerc: 'percentage',
    overview: 'overview',
    summary: 'summary',
    adults: 'adults',
    children: 'children',
    nights: 'nights',
    network: 'network',
    referral: 'sites_referral'
};

const dataRoutes = dataKeys.reduce((routes, key) => {
    routes[key] = function(filters) {
        const params = buildRequestParams(filters);

        return statsClient({
            url: `/stats/${endpointMap[key]}`,
            params
        });
    };

    return routes;
}, {});

export default {
    ...dataRoutes,
    mediumPerc(filters) {
        const params = buildRequestParams(filters);

        return statsClient({
            url: '/stats/medium/percentage',
            params
        });
    },
    network(filters) {
        const params = buildRequestParams(filters);

        return statsClient({
            url: '/stats/network',
            params
        });
    }
};
