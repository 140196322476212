import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/permessi';

const routes = helper.generateRoutes('permessi', selectors);
const list = _.values(routes.list).map(route => ({
    ...route,
    requiredRoles: ['admin'],
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'permessi' */ `modules/permessi/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('permessi', routes.patterns);
routeRegister.addLinks('permessi', links);

export default list;
