import { addNotification as notify } from 'reapop';

import history from 'apphistory';
import { AUTH_REFRESH_FAIL } from 'actions/auth';

const unauthorizedMiddlware = store => next => action => {
    if (
        action.error &&
        action.error.response &&
        action.error.response.status === 401
    ) {
        // console.warn(action);

        if (action.type === AUTH_REFRESH_FAIL) {
            store.dispatch(
                notify({
                    title: 'Attenzione!',
                    message:
                        'Sessione scaduta!. Perfavore effettuare nuovamente il login!',
                    status: 'error',
                    position: 'tc',
                    dismissAfter: 30000,
                    dismissible: true,
                    closeButton: true
                })
            );
        } else {
            store.dispatch(
                notify({
                    id: 'AUTH_ERROR',
                    title: 'Attenzione!',
                    message:
                        "Non si dispone dei permessi sufficienti per eseguire l'operazione richiesta!",
                    status: 'error',
                    position: 'tc',
                    dismissAfter: 0,
                    closeButton: true,
                    buttons: [
                        {
                            name: 'Indietro',
                            primary: true,
                            onClick() {
                                history.goBack();
                            }
                        },
                        {
                            name: 'Chiudi',
                            onClick() {}
                        }
                    ]
                })
            );
        }
    }

    return next(action);
};

export default unauthorizedMiddlware;
