import helper from '../../../helpers/steinerHelper';

const defaultActionTypes = helper.createActionTypes('venduto');

const SET_COMPARISON_PERIOD_DATA = 'SET_COMPARISON_PERIOD_DATA';
const SET_COMPARISON_YEAR_DATA = 'SET_COMPARISON_YEAR_DATA';

export const actionTypes = {
    ...defaultActionTypes,
    SET_COMPARISON_PERIOD_DATA,
    SET_COMPARISON_YEAR_DATA
};

const defaultActions = helper.createActions('venduto', actionTypes);

export const actions = {
    ...defaultActions,
    setComparisonPeriodData(data) {
        return {
            type: SET_COMPARISON_PERIOD_DATA,
            payload: data
        };
    },
    setComparisonYearData(data) {
        return {
            type: SET_COMPARISON_YEAR_DATA,
            payload: data
        };
    }
};
