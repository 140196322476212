import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { roles } from '../../helpers/auth';
import WidgetGestioneAmministrazione from '../widget/WidgetGestioneAmministrazione';
import WidgetGestioneCommerciali from '../widget/WidgetGestioneCommerciali';
import WithUser from '../WithUser';

class DashboardGestionaleAmministrazione extends Component {
    render() {
        const { userRole } = this.props;

        const isUser = userRole === roles.user;
        const isMultihotel = userRole === roles.multihotel;
        const isProduttore = userRole === roles.produttore;

        return (
            <div className="row py-2">
                <div className="col-12 pb-4">
                    <Helmet>
                        <title>{`Gestionale aziendale`}</title>
                    </Helmet>
                    <h2 className="text-primary text-center">
                        Gestionale e Commerciali
                    </h2>
                    <div className="text-center">
                        Anagrafiche, contratti, area amministrativa e
                        commerciale.
                    </div>
                </div>
                <div className="col-md-12">
                    <WidgetGestioneAmministrazione
                        isUser={isUser}
                        isMultihotel={isMultihotel}
                        isProduttore={isProduttore}
                        userRole={userRole}
                    />
                </div>
                {!isUser && !isMultihotel && (
                    <div className="col-md-12">
                        <WidgetGestioneCommerciali />
                    </div>
                )}
            </div>
        );
    }
}

export default WithUser(DashboardGestionaleAmministrazione);
