import _ from 'lodash';

import * as palette from '../../helpers/colors';

export const dataKeys = [
    'interest',
    /*'mediumNumber',*/ 'mediumPerc',
    'overview',
    'summary',
    'adults',
    'children',
    'nights',
    'network',
    'contract',
    'referral'
];

function mapColorsAndLabels(data, colors, labels) {
    const result = [];
    const unknown = {
        name: 'Altro',
        value: 0,
        color: palette.COLOR13
    };

    _.forOwn(data, (value, key) => {
        if (labels[key]) {
            result.push({
                name: labels[key],
                value: parseFloat(value),
                color: colors[key]
            });
        } else {
            unknown.value = parseFloat(unknown.value + value);
        }
    });

    if (unknown.value > 0) {
        result.push(unknown);
    }

    return result;
}

function formatDate(day) {
    const date = day.split('-');

    return `${date[2]}/${date[1]}/${date[0]}`;
}

function overviewMapper(data, weatherData) {
    return data.map(entry => {
        const forecast = _.find(weatherData, { date: entry.day });

        return {
            ...entry,
            day: formatDate(entry.day),
            meteo: forecast ? forecast.summary : null,
            meteo_icon: forecast ? forecast.icon : null
        };
    });
}

function interestMapper(data) {
    const result = [];

    _.forOwn(data, (value, key) => {
        result.push({
            day: formatDate(key),
            value
        });
    });

    return result;
}

function mediumMapper(data) {
    const colors = {
        null: palette.COLOR11,
        '(none)': palette.COLOR12,
        cpc: palette.COLOR01,
        ppc: palette.COLOR02,
        email: palette.COLOR03,
        Email: palette.COLOR05,
        organic: palette.COLOR04,
        portale: palette.COLOR06,
        social: palette.COLOR07,
        referral: palette.COLOR08,
        'visite al sito': palette.COLOR09
    };

    const labels = {
        null: 'Non specificato',
        '(none)': 'Utenti con anti-tracciamento',
        cpc: 'AdWords',
        ppc: 'Pay per click',
        email: 'Email Marketing',
        organic: 'SEO',
        portale: 'Visite dai portali',
        social: 'Social',
        referral: 'Visite da altri siti',
        'visite al sito': 'Accessi diretti'
    };

    return mapColorsAndLabels(data, colors, labels);
}

function networkMapper(data) {
    const colors = {
        1: palette.COLOR01,
        5: palette.COLOR02,
        6: palette.COLOR03,
        10: palette.COLOR04
    };

    const labels = {
        1: 'Entrainhotel',
        5: 'Hotel-rimini.it',
        6: 'Bambini in riviera',
        10: 'Hotel.Rimini.it'
    };

    return mapColorsAndLabels(data, colors, labels);
}

function nightsMapper(data) {
    const colors = {
        more_than_two_week: palette.COLOR01,
        one_week: palette.COLOR02,
        two_week: palette.COLOR03,
        weekend: palette.COLOR04
    };

    const labels = {
        more_than_two_week: 'Più di due settimane',
        one_week: 'Una settimana',
        two_week: 'Due settimane',
        weekend: 'Weekend'
    };

    if (_.sum(_.values(data)) === 0) {
        return [];
    }

    return mapColorsAndLabels(data, colors, labels);
}

function childrenMapper(data) {
    const colors = {
        0: palette.COLOR01,
        1: palette.COLOR02,
        2: palette.COLOR03,
        3: palette.COLOR04
    };

    const labels = {
        0: 'Nessun bambino',
        1: 'Un bambino',
        2: 'Due bambini',
        3: 'Tre o più bambini'
    };

    if (_.sum(_.values(data)) === 0) {
        return [];
    }

    return mapColorsAndLabels(data, colors, labels);
}

function adultsMapper(data) {
    const colors = {
        1: palette.COLOR01,
        2: palette.COLOR02,
        3: palette.COLOR03
    };

    const labels = {
        1: 'Un adulto',
        2: 'Due adulti',
        3: 'Tre o più adulti'
    };

    if (_.sum(_.values(data)) === 0) {
        return [];
    }

    return mapColorsAndLabels(data, colors, labels);
}

function contractMapper(data) {
    return data.map(row => {
        return {
            id: row.article_id,
            label: row.description
        };
    });
}

function referralMapper(data) {
    return data.map(i => {
        const { referral, perc } = i;

        return {
            name: referral,
            value: perc,
            color: `#${Math.floor(Math.random() * 16777215).toString(16)}`
        };
    });
}

export const mappers = {
    adultsMapper,
    childrenMapper,
    contractMapper,
    interestMapper,
    mediumMapper,
    networkMapper,
    nightsMapper,
    overviewMapper,
    referralMapper
};
