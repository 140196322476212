import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
// import _ from 'lodash';

// import NavLink from './NavLink';
import { selectors } from '../reducers/currentHotelV2';

class HotelWidgetLink extends Component {
    render() {
        const { children, hotelId, to, icon, exact } = this.props;

        if (!hotelId) {
            // return <NavLink className="link-disabled">{children}</NavLink>;
            return <span className="link-disabled">{children}</span>;
        }

        // const query = hotelId ? { hotelId } : {};

        // const location = {
        //     pathname: to,
        //     query
        // };

        const location = to.replace(/:hotelId/, hotelId);

        return (
            <div className="widget-link">
                <NavLink
                    to={location}
                    exact={exact}
                    className="d-flex align-items-center"
                    // isActive={isActive}
                >
                    <i className={`${icon} widget-icon`} aria-hidden="true" />{' '}
                    {children}
                </NavLink>
            </div>
        );
    }
}

HotelWidgetLink.propTypes = {
    children: PropTypes.any,
    hotelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    icon: PropTypes.string,
    to: PropTypes.string,
    exact: PropTypes.bool,
};

HotelWidgetLink.defaultProps = {
    exact: false,
};

function mapStateToProps(state) {
    return {
        hotelId: selectors.getCurrentHotelId(state),
    };
}

export default withRouter(connect(mapStateToProps)(HotelWidgetLink));
