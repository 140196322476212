import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/portfolio';

const routes = helper.generateRoutes('portfolio', selectors, {
    itemLabelKey: 'nome_referente'
});

const patternQuestionario = '/questionario/contatto/:id';

routes.patterns.questionario = patternQuestionario;

routes.list.list.breadcrumb = 'Rubrica';

routes.list.questionario = {
    breadcrumb: 'Questionario',
    componentPath: 'containers/QuestionarioLoader',
    path: patternQuestionario,
};

routes.list.edit.breadcrumb = (state, ownProps) => {
    if (ownProps.match.params.id === 'create') {
        return {
            breadcrumbName: 'Crea nuovo contatto'
        };
    }

    const current = selectors.currentSelector(state);

    if (current && current.item) {
        const name = current.item.nome_referente
            ? current.item.nome_referente
            : current.item.nome_struttura;
        const zona =
            current.item.frazione_name && current.item.frazione_name !== '-'
                ? current.item.frazione_name
                : current.item.comune_name;

        return {
            breadcrumbName: `${name} - ${zona}`
        };
    }

    return {
        breadcrumbName: '...'
    };
};

const list = _.values(routes.list).map(route => ({
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'portfolio-clienti' */ `modules/portfolio/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('portfolio', routes.patterns);
routeRegister.addLinks('portfolio', links);

export default list;
