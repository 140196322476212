import apiClient from '../../../apis/portali';
import { portaleApi } from '../../../helpers/portaleModuleHelper';

const api = portaleApi('posts', apiClient, {
    top: 'perPage',
    page: 'page',
    filter: 'q',
    orderby: 'orderKey',
    orderdir: 'orderDirection',
    query({ portaleId, comune_id, post_type_id }) {
        let params = [],
            filters;

        if (typeof portaleId === 'number') {
            params.push(`portal_id||=||${portaleId}`);
        }

        if (comune_id !== null) {
            params.push(`comune_id||=||${comune_id}`);
        }

        if (post_type_id !== null) {
            params.push(`post_type_id||=||${post_type_id}`);
        }

        params.length > 0 ? (filters = params.join('::')) : (filters = null);

        return filters;
    }
});

api.create = data => {
    return apiClient({
        url: 'posts',
        method: 'POST',
        data
    });
};

export default api;
