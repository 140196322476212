import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ListFilterWrapper from './ListFilterWrapper';

export class DatiConfigurazione extends Component {
    render() {
        return (
            <ListFilterWrapper
                title="Dati Configurazione"
                btnLink="/"
                btnTitle="Dashboard"
            >
                <div className="row">
                    <Helmet>
                        <title>Dati Configurazione</title>
                    </Helmet>
                    <div className="widget p-md-5">
                        <p>
                            Per configurare i vari client di posta (pc,
                            smartphone e tablet) dovranno essere utilizzati i
                            dati che seguono.
                        </p>
                        <h5 className="py-2">Server in entrata</h5>
                        <div className="row mb-2">
                            <div className="col-md-6">
                                <strong>Posta in Arrivo (IMAP):</strong>{' '}
                                posta.adriasonline.it
                                <br />
                                <strong>Nome account:</strong> indirizzo di
                                posta (es.: <em>nomecasella@nomedominio.ext</em>
                                )
                                <br />
                                <strong>Metodo di autenticazione:</strong>{' '}
                                <em>password</em>
                                <br />
                                <strong>
                                    Sicurezza della connessione:
                                </strong>{' '}
                                SSL/TLS
                                <br />
                                <strong>Porta Server:</strong> 993
                            </div>
                            <div className="col-md-6">
                                <strong>Posta in Arrivo (POP):</strong>{' '}
                                posta.adriasonline.it
                                <br />
                                <strong>Nome account:</strong> indirizzo di
                                posta (es.: <em>nomecasella@nomedominio.ext</em>
                                )
                                <br />
                                <strong>Metodo di autenticazione:</strong>{' '}
                                <em>password</em>
                                <br />
                                <strong>
                                    Sicurezza della connessione:
                                </strong>{' '}
                                SSL/TLS
                                <br />
                                <strong>Porta Server:</strong> 995
                            </div>
                        </div>
                        <h5 className="py-2"> Server in uscita</h5>
                        <div className="row mb-2">
                            <div className="col-md-6">
                                <p>
                                    <strong> Posta in uscita(SMTP): </strong>
                                    posta.adriasonline.it
                                    <br />
                                    <strong>Nome account:</strong> indirizzo di
                                    posta (es.: nomecasella@nomedominio.ext)
                                    <br />
                                    <strong>
                                        Metodo di autenticazione:
                                    </strong>{' '}
                                    <em>password</em>
                                    <br />
                                    <strong>
                                        Sicurezza della connessione:
                                    </strong>{' '}
                                    SSL/TLS
                                    <br />
                                    <strong>Porta:</strong> 465
                                </p>
                            </div>
                        </div>
                        <p>
                            Il server SMTP richiede l'autenticazione con stessi
                            user e password POP e IMAP.
                        </p>
                        <p>
                            Per comodità qui puoi trovare due guide per aiutarti
                            nella configurazione:
                        </p>
                        <ul>
                            <li>
                                <a
                                    href="https://support.microsoft.com/it-it/office/cambiare-o-aggiornare-le-impostazioni-dell-account-di-posta-elettronica-in-outlook-per-windows-560a9065-3c3a-4ec5-a24f-cdb9a8d622a2"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Configurazione di Outlook per Windows
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://support.mozilla.org/it/kb/configurazione-manuale-account"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Configurazione di Mozilla Thunderbird
                                </a>
                            </li>
                        </ul>
                        <p>
                            Si tratta di una procedura di routine, ma il nostro
                            consiglio è quello di farla eseguire da un tecnico,
                            per evitare possibili errori e che vi possa
                            assistere nella configurazione di tutti i
                            dispositivi che utilizzate per scaricare la posta.
                        </p>
                        <p>
                            Ti ricordiamo che comunque la posta potrà essere
                            sempre consultata anche online, dalla webmail
                            all'indirizzo {' '}
                            <a
                                href="https://posta.adriasonline.it/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                posta.adriasonline.it
                            </a>
                            .
                            <br />
                        </p>
                    </div>
                </div>
            </ListFilterWrapper>
        );
    }
}

export default DatiConfigurazione;
