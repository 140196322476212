import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/portaliArchivioMail';

const routes = helper.generateRoutes('portaliArchivioMail', selectors, {
    omit: ['create', 'edit']
});

routes.list.list.breadcrumb = 'Archivio mail Portali';

const list = _.values(routes.list).map(route => ({
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'portaliArchivioMail' */ `modules/portaliArchivioMail/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('portaliArchivioMail', routes.patterns);
routeRegister.addLinks('portaliArchivioMail', links);

export default list;
