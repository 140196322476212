import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import _ from 'lodash';

import {
    getUserRole,
    getUserToken,
    getUserConfigId,
    getUserHotelId,
    // getUserMagnewsDb,
    getUserCodiceProduttore,
    getUserPermissions,
} from '../reducers/selectors';
import { isAdmin } from '../helpers/auth';
import { getUser } from '../helpers/auth/reducer';

function mapStateToProps(state) {
    return {
        user: getUser(state),
        userCodiceProduttore: getUserCodiceProduttore(state),
        userConfigId: getUserConfigId(state),
        userHotelId: getUserHotelId(state),
        // userMagnewsDb: getUserMagnewsDb(state),
        userRole: getUserRole(state),
        userToken: getUserToken(state),
        userPermissions: getUserPermissions(state),
    };
}

function hasPermission(list, code) {
    const permission = _.find(_.values(list), (p) => {
        return p.code === code && p.enabled === true;
    });

    return !_.isUndefined(permission);
}

export default compose(
    connect(mapStateToProps),
    withProps((props) => {
        const {
            user,
            userCodiceProduttore,
            userConfigId,
            userHotelId,
            // userMagnewsDb,
            userRole,
            userToken,
            userPermissions,
        } = props;

        return {
            //isAdmin: true,
            isAdmin: isAdmin(user),
            user,
            userCodiceProduttore,
            userConfigId,
            userHotelId,
            // userMagnewsDb,
            userRole,
            userToken,
            hasPermission(code) {
                return hasPermission(userPermissions, code);
            },
        };
    })
    // onlyUpdateForKeys(['isAdmin', 'user', 'userCodiceProduttore', 'userConfigId', 'userHotelId', 'userRole', 'userToken']),
);
