import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import Modal from './Modal';
import SelectField from 'components/form/v1/SelectField';
import TextareaField from '@adrias-online/vivi/lib/Form/TextareaField';
import LoadingButton from 'components/form/v1/LoadingButton';
import { addNotification as notify } from 'reapop';
import { validator } from 'validate-this';

import client from 'apis/contratti';
import { connect } from 'react-redux';

const motivoOptions = [
    { label: 'Commerciale', value: 'commerciale' },
    { label: 'Email - Nuova Password', value: 'email-password' },
    {
        label: 'Portali - Listino, trattamenti e camere',
        value: 'portali-listino',
    },
    { label: 'Portali - Servizi', value: 'portali-servizi' },
    { label: 'Portali - Offerte e DEM', value: 'portali-offerte' },
    { label: 'Sito web - Modifiche', value: 'sito' },
];

export class ModalAssistenza extends Component {
    submit = (data) => {
        const payload = {
            ...data,
            motivo: data.motivo ? data.motivo.value : null,
        };

        const req = client({
            url: '/assistenza/send',
            method: 'post',
            data: payload,
        });
        // console.warn(data);

        // const p = t();

        req.then(() => {
            this.props.onClose();

            this.props.dispatch(
                notify({
                    title: 'Successo!',
                    message: 'Richiesta di assistenza inviata correttamente!',
                    status: 'success',
                })
            );
        });

        req.catch(() => {
            this.props.dispatch(
                notify({
                    title: 'Errore!',
                    message:
                        "Si è verificato un errore inaspettato durante l'invio della richiesta!",
                    status: 'error',
                })
            );
        });

        return req;
    };

    render() {
        const { isOpen, onClose, handleSubmit, submitting } = this.props;

        return (
            <Modal
                className="modal-dialog modal-assistenza"
                isOpen={isOpen}
                onClose={onClose}
                staticModal={true}
            >
                <div className="modal-content widget">
                    <div className="modal-header">
                        <h2 className="p-3 pb-0">Richiedi assistenza</h2>
                        <button
                            type="button"
                            className="btn btn-link"
                            onClick={onClose}
                            style={{ color: '#999' }}
                        >
                            <span aria-hidden="true">&times;</span>
                            {/* <span className="visually-hidden">Close</span> */}
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>Hai bisogno di assistenza? Siamo qui per questo.</p>
                        <form
                            onSubmit={handleSubmit(this.submit)}
                            className="form-horizontal"
                            // initialValues={myFormData}
                        >
                            <Field
                                name="motivo"
                                component={SelectField}
                                placeholder="Motivo"
                                label="Motivo"
                                options={motivoOptions}
                                selectOptions={{
                                    clearable: false,
                                }}
                                disabled={!!this.props.motivo}
                            />
                            <Field
                                name="testo"
                                component={TextareaField}
                                label="Testo"
                                placeholder="Raccontaci qualcosa in più..."
                            />
                            <div className="form-group">
                                <LoadingButton
                                    className="btn btn-sm btn-primary"
                                    loading={submitting}
                                >
                                    Invia <i className="fas fa-envelope-o" />
                                </LoadingButton>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        );
    }
}

ModalAssistenza.propTypes = {
    dispatch: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialize: PropTypes.func,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
};

ModalAssistenza.defaultProps = {
    isOpen: false,
};

function validate(values) {
    return validator(
        values,
        (v) => {
            v.validate('motivo', 'testo').required();
        },
        (msg, field) => {
            return 'Campo obbligatorio';
        }
    );
}

function mapStateToProps(state, ownProps) {
    const motivoAssistenzaValue = ownProps.motivo
        ? ownProps.motivo
        : 'commerciale';

    const motivoObj = motivoOptions.find((obj) => {
        return obj.value === motivoAssistenzaValue;
    });

    return {
        form: `assistenza_${motivoAssistenzaValue}`,
        initialValues: {
            motivo: motivoObj,
        },
    };
}

export default connect(mapStateToProps)(
    reduxForm({
        // form: 'assistenza',
        validate,
    })(ModalAssistenza)
);
