import helper from 'helpers/steinerHelper';
import apiClient from 'apis/api';
import contrattiClient from 'apis/contratti';
import { getCurrentContractYear } from 'helpers/date';

const apis = helper.createApi('HotelDati', apiClient, {
    top: 'perPage',
    page: 'page',
    filter: 'q',
    comune: 'comune',
    posizione: 'posizione',
    produttore: 'produttore',
    categoria: 'categoria',
    customer_category: 'customer_category',
    orderby: 'orderKey',
    orderdir: 'orderDirection'
});

apis.fetchContratti = (id, contractYear) => {
    const year = contractYear || getCurrentContractYear();

    return contrattiClient({
        url: `/contratto/${id}`,
        params: {
            year
        }
    });
};

export function fetchContrattiToday(id) {
    return contrattiClient({
        url: `/contratto/${id}/today`
    });
}

apis.fetchContrattiToday = fetchContrattiToday;

export default apis;