import helper from 'helpers/steinerHelper';

const defaultActionTypes = helper.createActionTypes('hotelServizi');

export const actionTypes = Object.assign({}, defaultActionTypes, {
    updateServiziBase: 'hotelServizi/UPDATE_SERVIZI_BASE',
    updateServiziBaseSuccess: 'hotelServizi/UPDATE_SERVIZI_BASE_SUCCESS',
    updateServiziBaseFail: 'hotelServizi/UPDATE_SERVIZI_BASE_FAIL'
});

const defaultActions = helper.createActions('hotelServizi', actionTypes);

export const actions = Object.assign({}, defaultActions, {
    updateServiziBaseSuccess(response, notification) {
        return {
            type: actionTypes.updateServiziBaseSuccess,
            payload: response,
            notification
        };
    },
    updateServiziBaseFail(error, notification) {
        return {
            type: actionTypes.updateServiziBaseFail,
            error,
            notification
        };
    }
});
