import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';
// import pathToRegexp from 'path-to-regexp';

import { roles } from '../../../helpers/auth';
import helper from '../../../helpers/steinerHelper';
import hotelStore from '../../../helpers/hotelStore';
import routeRegister from '../../../helpers/routeRegister';

import { selectors } from '../reducers/hotel';
import { selectors as currentHotelSelectors } from '../../../reducers/currentHotel';

const routes = helper.generateRoutes('hotel', selectors, {
    itemLabelKey: 'nome_hotel'
});

const patternContratti = '/hotel/:id/contratti';
const patternAnagrafica = '/hotel/:id/anagrafica';
const patternDashboard = '/hotel/:id/dashboard';
const patternQuestionario = '/hotel/:id/questionario';

routes.patterns.contratti = patternContratti;
routes.patterns.anagrafica = patternAnagrafica;
routes.patterns.questionario = patternQuestionario;

routes.list.contratti = {
    breadcrumb: 'Contratti',
    componentPath: 'containers/ContrattiLoader',
    path: patternContratti,
    hotelFilter: true
};

routes.list.anagrafica = {
    breadcrumb: 'Anagrafica',
    componentPath: 'containers/HotelLoader',
    path: patternAnagrafica,
    hotelFilter: true
};

routes.list.dashboard = {
    breadcrumb: 'Dashboard',
    componentPath: 'containers/HotelDashboard',
    path: patternDashboard,
    hotelFilter: true
};

routes.list.questionario = {
    breadcrumb: 'Questionario',
    componentPath: 'containers/QuestionarioLoader',
    path: patternQuestionario,
};

const requiredRoles = [
    roles.admin,
    roles.amministrazione,
    roles.developer,
    roles.employee,
    roles.produttore
];

// console.warn(routes);
routes.list.list.requiredRoles = requiredRoles;
routes.list.edit.requiredRoles = requiredRoles;
routes.list.edit.exact = true;
routes.list.edit.breadcrumb = function(state, ownProps) {
    if (ownProps.match.params.id === 'create') {
        return {
            breadcrumbName: 'Crea nuovo'
        };
    }

    const current = selectors.currentSelector(state);

    if (ownProps.match.isExact && current && current.item) {
        return {
            breadcrumbName: `${current.item.nome_hotel} - ${
                current.item.nome_comune
            } (${current.item.idHotel})`
        };
    }

    const currentHotel = currentHotelSelectors.getCurrentHotel(state);

    // console.warn(ownProps);
    // console.warn(currentHotel);

    const sameHotel =
        parseInt(ownProps.match.params.id, 10) ===
        parseInt(currentHotel.value, 10);

    if (!sameHotel) {
        const hotel = hotelStore.get(ownProps.match.params.id);

        if (hotel) {
            return {
                breadcrumbName: hotel.label
            };
        } else {
            const parentHotel = selectors.currentSelector(state).item;

            if (parentHotel) {
                return {
                    breadcrumbName: `${parentHotel.nome_hotel} - ${
                        parentHotel.nome_comune
                    } (${parentHotel.idHotel})`
                };
            }
        }
    }

    // TODO: come ottenere i dati dell'hotel se non è tra i correnti?

    if (sameHotel && currentHotel && currentHotel.label) {
        return {
            breadcrumbName: currentHotel.label
        };
    }

    return {
        breadcrumbName: '...'
    };
};

const list = _.values(routes.list).map(route => ({
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'hotel' */ `modules/hotel/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('hotel', routes.patterns);
routeRegister.addLinks('hotel', links);

// console.log(list);

export default list;
