import { reducerCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { createHandlers } from 'helpers/hotelModuleHelper';
import { actionTypes } from '../actions/sitiClienti';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            hotelId: null,
            dominio: null,
            id_sito: null,
        },
    },
});

const handlers = createHandlers(actionTypes, {
    items: 'data.data',
    total: 'data.meta.total',
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('sitiClienti');
