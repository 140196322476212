import apiClient from '../../../apis/portali';
import { portaleApi } from '../../../helpers/portaleModuleHelper';

const api = portaleApi('hotelDati', apiClient, {
    top: 'perPage',
    page: 'page',
    filter: 'q',
    orderby: 'orderKey',
    orderdir: 'orderDirection',
    include: 'redirect',
    portal_id: 'portaleId',
    query({ idComune }) {
        if (idComune !== null) {
            return `idComune||=||${idComune}`;
        }

        return null;
    }
});

api.fetch = (id, portaleId) => {
    return apiClient({
        url: `hotelDati/${id}?portal_id=${portaleId}&include=info`
    });
};

export default api;
