import React from 'react';
import PropTypes from 'prop-types';
import { auth } from '@adrias-online/steiner';
import { Link, withRouter } from 'react-router-dom';
import TranslatorHoc from '../TranslatorHoc';
import { confirm } from '../../helpers/confirm/confirm';

const SidebarProfilo = (props) => {
    const { setIsSidebarProfiloOpen, isSidebarProfiloOpen } = props;

    // const toggleHelpModal = (e) => {
    //     e.preventDefault();
    //     props.onToggleHelpModal();
    // };

    const requestLogout = (e) => {
        e.preventDefault();

        props.dispatch(
            confirm.actions.showConfirmDialog({
                message: props.logoutMessage,
                onSuccess: () => props.dispatch(auth.actions.logoutRequest()),
            })
        );
    };

    return (
        <div
            className={`sidebar-profilo text-center${
                isSidebarProfiloOpen ? ' active' : ''
            }`}
        >
            <ul className="list-group list-group-flush">
                <li
                    className="list-group-item"
                    onClick={() =>
                        setIsSidebarProfiloOpen(!isSidebarProfiloOpen)
                    }
                >
                    <i className="fal fa-times fa-lg"></i>
                </li>
                <li className="list-group-item" onClick={requestLogout}>
                    <i className="far fa-sign-out-alt fa-lg"></i>
                    <br />
                    <small>logout</small>
                </li>
                <li className="list-group-item">
                    <Link to="/PersonalData" className="text-white">
                        <i className="fas fa-sliders-h fa-lg"></i>
                        <br />
                        <small>profilo</small>
                    </Link>
                </li>
                <li className="list-group-item">
                    <Link to="/profile" className="text-white">
                        <i className="fas fa-key fa-lg"></i>
                        <br />
                        <small>password</small>
                    </Link>
                </li>
                {/* <li className="list-group-item" onClick={toggleHelpModal}>
                    <i className="far fa-info-circle fa-lg"></i>
                    <br />
                    <small>info</small>
                </li> */}
            </ul>
        </div>
    );
};

SidebarProfilo.propTypes = {
    dispatch: PropTypes.func,
    logoutMessage: PropTypes.string,
    onToggleHelpModal: PropTypes.func,
    isSidebarProfiloOpen: PropTypes.bool,
    setIsSidebarProfiloOpen: PropTypes.func.isRequired,
};

SidebarProfilo.defaultProps = {
    logoutMessage: "Sei sicuro di voler uscire dall'area riservata?",
};

export default TranslatorHoc(
    withRouter(SidebarProfilo, {
        logoutMessage: 'steiner.messages.confirmLogout',
    })
);
