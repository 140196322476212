import axios from 'axios';

const client = axios.create({
    baseURL: window.__RUNTIME_CONFIG__.REACT_APP_API_ROOT,
    // baseURL: 'http://www.adrias-web.it/api/public/v1/',
    // headers: {
    //     Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOjQwLCJpc3MiOiJodHRwOlwvXC9hZHJpYXMtd2ViLml0XC9hcGlcL3B1YmxpY1wvdjFcL2F1dGhcL2xvZ2luIiwiaWF0IjoiMTQ3Njg2ODk2MyIsImV4cCI6IjE1Mzk5NDA5NjMiLCJuYmYiOiIxNDc2ODY4OTYzIiwianRpIjoiOTYzOWU0Y2JjY2I5YWE1NzdlZjViNzVhZjUzZTIwMTAifQ.czr5iHLh_ciz7yKXjcoQD3IqjnCYwphHaQfjreWp0-A'
    // },
    responseType: 'json'
});

export default client;
