// import _ from 'lodash';
// import Immutable from 'seamless-immutable';
// import { createSelector } from 'reselect';

import { reducerCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import { actionTypes } from '../actions/monitorRichiesteCrm';


export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            orderDirection: 'ASC',
            orderKey: "id",
            page: 1,
            limit: 999,
            q: '',
            dateRequest: null,
            hotelId: null,
        },
    },
});

const handlers = helper.createHandlers(actionTypes, {
    items: 'data.data',
    idKey: 'id',
    total: 'data.meta.total',
    per_page: 'data.meta.per_page',
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('monitorRichiesteCrm');