import { createSagas, bootSagas } from 'helpers/hotelModuleHelper';
import { actions, actionTypes } from '../actions/hotelServizi';
import { selectors, DEFAULT_STATE } from '../reducers/hotelServizi';
import { put, call, takeEvery } from 'redux-saga/effects';
import api from '../apis/hotelServizi';

const sagas = createSagas(
    'hotelServizi',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE
);

sagas.updateServiziBase = function*(action) {
    try {
        const response = yield call(
            api.updateServiziBase,
            action.payload.idHotel,
            action.payload
        );

        const notification = {
            title: 'Successo!',
            message: 'Servizi base aggiornati correttamente',
            status: 'success'
        };

        yield put(actions.updateServiziBaseSuccess(response, notification));
    } catch (error) {
        const notification = {
            title: 'Attenzione!',
            message:
                "Si è verificato un errore durante l'aggiornamento dei servizi",
            status: 'error'
        };

        const errorPayload = {
            message: 'Si è verificato un errore imprevisto!'
        };

        yield put(actions.updateServiziBaseFail(errorPayload, notification));
    }
};

const defaultSagas = bootSagas(sagas, actionTypes);

export default [
    ...defaultSagas,
    takeEvery(actionTypes.updateServiziBase, sagas.updateServiziBase)
];
