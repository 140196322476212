import helper from 'helpers/steinerHelper';
import apiClient from 'apis/security';

const apis = helper.createApi('management/user', apiClient, {
    limit: 'perPage',
    page: 'page',
    filter: 'q',
    orderBy: 'orderKey',
    sort: 'orderDirection'
});

apis.updatePermissions = function(id, permissions = {}) {
    return apiClient({
        url: `/management/user/${id}/permission/change`,
        method: 'patch',
        data: {
            permissions
        }
    });
};

export default apis;
