import helper from 'helpers/steinerHelper';

const defaultActionTypes = helper.createActionTypes('ruoli');

const customActionTypes = {
    updatePermission: 'ruoli/UPDATE_PERMISSION',
    updatePermissionSuccess: 'ruoli/UPDATE_PERMISSION_SUCCESS',
    updatePermissionFail: 'ruoli/UPDATE_PERMISSION_FAIL'
};

export const actionTypes = Object.assign(
    {},
    defaultActionTypes,
    customActionTypes
);

const defaultActions = helper.createActions('ruoli', actionTypes);

const customActions = {
    updatePermission: function(data) {
        return {
            type: actionTypes.updatePermission,
            payload: {
                data
            }
        };
    },
    updatePermissionSuccess: function(response, notification) {
        return {
            type: actionTypes.updatePermissionSuccess,
            payload: response,
            notification
        };
    },
    updatePermissionFail: function(error, notification) {
        return {
            type: actionTypes.updatePermissionFail,
            error,
            notification
        };
    }
};

export const actions = Object.assign({}, defaultActions, customActions);
