import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { connect } from 'react-redux';
import { selectors as hotelSelectors } from '../reducers/currentHotel';
import WithUser from 'components/WithUser';

// import DashboardAdmin from './dashboard/dashboardAdmin';
import DashboardCliente from './dashboard/DashboardCliente';
import DashboardDipendente from './dashboard/DashboardDipendente';
import LoginPage from './auth/LoginPage';
import LoginForm from './LoginForm';
import DashboardProduttore from './dashboard/DashboardProduttore';

export class Welcome extends Component {
    state = {
        redirectAfterLogin: false,
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.user && this.props.user === null) {
            // Necessario per evitare strani quirks con la url nel browser...

            setTimeout(() => {
                this.setState({
                    redirectAfterLogin: true,
                });
            }, 200);
        }
    }

    render() {
        const { location, user, userRole } = this.props;

        if (this.state.redirectAfterLogin) {
            const nextPathname = _.get(location, 'state.from.pathname')
                ? _.get(location, 'state.from.pathname')
                : '/';
            const nextSearch = _.get(location, 'state.from.search')
                ? _.get(location, 'state.from.search')
                : '';

            // if (userRole === 'produttore') {
            //     return (
            //         <Redirect
            //             to={{
            //                 pathname: 'dashboardCommerciali',
            //                 search: nextSearch,
            //                 state: {},
            //             }}
            //         />
            //     );
            // }
            if (nextPathname !== '/') {
                return (
                    <Redirect
                        to={{
                            pathname: nextPathname,
                            search: nextSearch,
                            state: {},
                        }}
                    />
                );
            }
        }

        if (!user) {
            return (
                <LoginPage formTitle="Login" formComponent={<LoginForm />} />
            );
        }

        const nomeUtente = user ? user.name : '';

        return (
            <>
                <Helmet>
                    <title>Dashboard</title>
                </Helmet>
                <div className="row pt-3 pb-4">
                    <div className="col-12 text-center">
                        <h2 className="text-primary">Ciao {nomeUtente}</h2>
                        {(userRole === 'user' || userRole === 'multihotel') && (
                            <div>
                                Benvenuto nel Back Office di Adrias Online!
                                <br />
                                Qui trovi la tua dashboard di inizio lavori,
                                tutto sotto controllo in una sola schermata!
                            </div>
                        )}
                    </div>
                </div>
                {(userRole === 'user' || userRole === 'multihotel') && (
                    <DashboardCliente currentHotel={this.props.currentHotel} />
                )}
                {/* {userRole === 'multihotel' && (
                    <DashboardCliente
                        currentHotel={this.props.currentHotel}
                    />
                )} */}
                {(userRole === 'admin' ||
                    userRole === 'developer' ||
                    userRole === 'amministrazione' ||
                    userRole === 'employee') && (
                    <DashboardDipendente
                        currentHotel={this.props.currentHotel}
                    />
                )}
                {userRole === 'produttore' && <DashboardProduttore />}
            </>
        );
    }
}

Welcome.propTypes = {
    location: PropTypes.object,
    user: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        currentHotel: hotelSelectors.getCurrentHotel(state),
    };
}

export default connect(mapStateToProps)(WithUser(Welcome));
