import client from 'apis/api';

import { createApi } from 'helpers/siteModuleHelper';

const apis = createApi('SitePhotogallery', client, {
    top: 'perPage',
    page: 'page',
    filter: 'q',
    orderby: 'orderKey',
    orderdir: 'orderDirection'
});

apis.orderPhotos = function(galleryId, photos) {
    return client({
        url: `SitePhotogallery/${galleryId}/attachphotos`,
        method: 'POST',
        data: {
            images: photos,
            sort: true
        }
    });
};

apis.linkPhotos = function(galleryId, photos) {
    return client({
        url: `SitePhotogallery/${galleryId}/attachphotos`,
        method: 'POST',
        data: {
            images: photos
        }
    });
};

apis.unlinkPhotos = function(galleryId, photos) {
    return client({
        url: `SitePhotogallery/${galleryId}/detachphotos`,
        method: 'POST',
        data: {
            images: photos
        }
    });
};

export default apis;
