import helper from 'helpers/steinerHelper';

export const actionTypes = helper.createActionTypes('hotel');
export const actions = helper.createActions('hotel', actionTypes);

// const defaultActionTypes = helper.createActionTypes('hotel');

// const contrattiActionTypes = {
//     fetchContratti: 'hotel/FETCH_CONTRATTI',
//     fetchContrattiSuccess: 'hotel/FETCH_CONTRATTI_SUCCESS',
//     fetchContrattiFail: 'hotel/FETCH_CONTRATTI_FAIL',
// };

// export const actionTypes = Object.assign({}, defaultActionTypes, contrattiActionTypes);

// const defaultActions = helper.createActions('hotel', actionTypes);

// const contrattiActions = {
//     fetchContratti(id) {
//         return {
//             type: actionTypes.fetchContratti,
//             payload: {
//                 id
//             }
//         };
//     }
// };

// export const actions = Object.assign({}, defaultActions, contrattiActions);
