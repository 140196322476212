import { sagaCreator } from '@adrias-online/steiner';

import helper from '../../../helpers/steinerHelper';
import { actions, actionTypes } from '../actions/portaliGalleries';
import { selectors, DEFAULT_STATE } from '../reducers/portaliGalleries';
import api from '../apis/portaliGalleries';

const sagas = helper.createSagas(
    'portaliGalleries',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE,
    {
        resourceLabel: 'Gallery foto portali'
    }
);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [...defaultSagas];
