import axios from 'axios';

const { REACT_APP_GERARD } = window.__RUNTIME_CONFIG__;

const client = axios.create({
    baseURL: REACT_APP_GERARD,
    responseType: 'json'
});

export default client;
