import { reducerCreator } from '@adrias-online/steiner';
import _ from 'lodash';

import helper from 'helpers/steinerHelper';
import { createHandlers } from 'helpers/hotelModuleHelper';
import { actionTypes } from '../actions/hotelListino';

export const DEFAULT_STATE = helper.createDefaultState({
    prices: {
        activeBoards: {},
        activeRooms: [],
        boardsById: {},
        currentRoom: 0,
        roomsById: {}
    },
    list: {
        filters: {
            hotelId: null
        }
    }
});

const handlers = createHandlers(actionTypes, {
    items: 'data.values',
    idKey: 'idListino'
});

handlers[actionTypes.initPricesData] = function(state, action) {
    const { boards, rooms, rows } = action.payload;

    const activeRooms =
        _.isObject(rows) && Object.keys(rows).length > 0
            ? Object.keys(rows).map(row => parseInt(row, 10))
            : rooms.map(room => room.id);

    const prices = {
        activeBoards: activeRooms.reduce((prev, roomId) => {
            prev[roomId] =
                _.isObject(rows) && rows[roomId]
                    ? Object.keys(_.first(rows[roomId]).prezzi).map(board =>
                          parseInt(board, 10)
                      )
                    : boards.map(board => board.id);
            return prev;
        }, {}),
        activeRooms,
        boardsById: boards.reduce((prev, item) => {
            prev[item.id] = item;
            return prev;
        }, {}),
        currentRoom: 0,
        roomsById: rooms.reduce((prev, item) => {
            prev[item.id] = item;
            return prev;
        }, {})
    };

    return state.setIn(['prices'], prices);
};

handlers[actionTypes.addRoom] = function(state, action) {
    const { id, boards } = action.payload;

    return state
        .updateIn(['prices', 'activeRooms'], activeRooms => {
            return [].concat(activeRooms, id);
        })
        .setIn(['prices', 'activeBoards', id], boards);
};

handlers[actionTypes.removeRoom] = function(state, action) {
    return state.updateIn(['prices'], prices => {
        return {
            ...prices,
            activeRooms: [].concat(
                _.without(prices.activeRooms, action.payload)
            ),
            currentRoom:
                prices.currentRoom >= prices.activeRooms.length - 2
                    ? prices.activeRooms.length - 2
                    : prices.currentRoom
        };
    });
};

handlers[actionTypes.toggleBoardFromRoom] = function(state, action) {
    const { boardId, roomId } = action.payload;

    return state.updateIn(['prices', 'activeBoards', roomId], activeBoards => {
        if (_.indexOf(activeBoards, boardId) > -1) {
            return [].concat(_.without(activeBoards, boardId));
        }

        return [].concat(activeBoards, boardId);
    });
};

handlers[actionTypes.selectRoom] = function(state, action) {
    return state.setIn(['prices', 'currentRoom'], action.payload);
};

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

const defaultSelectors = reducerCreator.createSelectors('hotelListino');

const customSelectors = {
    getActiveRooms(state) {
        return _.orderBy(
            state.hotelListino.prices.activeRooms.map(
                id => state.hotelListino.prices.roomsById[id]
            ),
            'id'
        );
    },
    getActiveRoomsId(state) {
        return state.hotelListino.prices.activeRooms;
    },
    getActiveBoardsByRoom(state, roomId) {
        return _.orderBy(
            state.hotelListino.prices.activeBoards[roomId].map(
                id => state.hotelListino.prices.boardsById[id]
            ),
            'id'
        );
    },
    getActiveBoardsId(state) {
        return state.hotelListino.prices.activeBoards;
    },
    getBoards(state) {
        return _.values(state.hotelListino.prices.boardsById);
    },
    getCurrentRoom(state) {
        return state.hotelListino.prices.currentRoom;
    },
    getRooms(state) {
        return _.values(state.hotelListino.prices.roomsById);
    }
};

export const selectors = Object.assign({}, defaultSelectors, customSelectors);
