import helper from 'helpers/steinerHelper';

const defaultActionTypes = helper.createActionTypes('portfolio');

export const actionTypes = Object.assign(defaultActionTypes, {
    revealPhone: 'portfolio/REVEAL_PHONE'
});

const defaultActions = helper.createActions('portfolio', actionTypes);

export const actions = Object.assign(defaultActions, {
    revealPhone(id) {
        return {
            type: actionTypes.revealPhone,
            payload: {
                id
            }
        };
    }
});
