import apiClient from '../../../apis/portali';
import { portaleApi } from '../../../helpers/portaleModuleHelper';

const api = portaleApi('photo', apiClient, {
    top: 'perPage',
    page: 'page',
    filter: 'q',
    query({ portaleId }) {
        if (typeof portaleId === 'string' || typeof portaleId === 'number') {
            return `portal_id||=||${portaleId}`;
        }

        return null;
    }
});

api.editPhotoData = (photoId, data) => {
    return apiClient({
        url: `photo/${photoId}`,
        method: 'patch',
        data
    });
};

api.uploadPhoto = (portaleId, data) => {
    return apiClient({
        url: `uploads/portalPhoto/${portaleId}`,
        method: 'post',
        data
    });
};

export default api;
