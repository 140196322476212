import { sagaCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { actions, actionTypes } from '../actions/dipendenti';
import { selectors, DEFAULT_STATE } from '../reducers/dipendenti';
import api from '../apis/dipendenti';

const sagas = helper.createSagas('dipendenti', actionTypes, actions, api, selectors, DEFAULT_STATE);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [
    ...defaultSagas
];