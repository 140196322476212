import helper from 'helpers/steinerHelper';
import { buildParams } from '@adrias-online/steiner/lib/helpers/apiCreator';
import qs from 'query-string';
import _ from 'lodash';

import client from 'apis/apiv2';

const paramsMap = {
    limit: 'perPage',
    page: 'page',
    email: 'q',
    lang: 'lang',
    fromId: 'fromId'
};

export function list(idHotel, filters) {
    return client({
        url: `/Newsletter/${idHotel}/Site`,
        params: buildParams(filters, helper.getFinalParamsMap(paramsMap, {}))
    });
}

export function getExportCsvUrl(idHotel, filters, token) {
    const params = _.omit(
        buildParams(filters, helper.getFinalParamsMap(paramsMap, {})),
        'limit'
    );

    params.limit = 0;

    return `${
        window.__RUNTIME_CONFIG__.REACT_APP_API_ROOT_V2
    }/Newsletter/${idHotel}/Site/Export?${qs.stringify({ ...params, token })}`;
}

export default {
    list
};
