import React from 'react';
import PropTypes from 'prop-types';
import HotelWidgetLink from 'components/HotelWidgetLink';

const WidgetClienteAmministrazione = (props) => {
    return (
        <div className="widget" style={{ padding: '0 10px 5px' }}>
            <h2 className="p-3">Amministrazione</h2>
            <div className="row">
                <div className="col-md-12">
                    <HotelWidgetLink
                        to="/hotel/:hotelId/contratti"
                        icon="fas fa-chart-line"
                    >
                        Consulta ordini
                    </HotelWidgetLink>
                </div>
                <div className="col-md-12">
                    <HotelWidgetLink icon="fas fa-clock" to="/">
                        Scadenza
                    </HotelWidgetLink>
                </div>
                <div className="col-md-12">
                    <HotelWidgetLink icon="fas fa-university" to="/">
                        Iban Adrias Online
                    </HotelWidgetLink>
                </div>
            </div>
        </div>
    );
};

WidgetClienteAmministrazione.propTypes = {
    userHotelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default WidgetClienteAmministrazione;
