import { createApi } from 'helpers/hotelModuleHelper';
import client from 'apis/api';
import { format } from 'date-fns';
import { getCurrentCalendarYear } from 'helpers/date';

const apis = createApi('HotelOfferta', client, {
    top: 'perPage',
    page: 'page',
    filter: 'q',
    orderby: 'orderKey',
    orderdir: 'orderDirection',
    query({ expiration }) {
        const today = format(new Date(), 'YYYY-MM-DD');

        if (expiration === 'actual') {
            return `data_pubblicazione||<=||${today}::data_fine_pubblicazione||>=||${today}`;
        } else if (expiration === 'expired') {
            return `data_fine||<||${today}`;
        }

        return null;
    },
});

apis.create = function (data) {
    return client({
        url: `HotelOfferta`,
        method: 'post',
        data,
    });
};

apis.fetchAssociazioni = (hotelId) => {
    return client.get(`Hotel/${hotelId}/HotelOfferta/Associazioni`);
};

apis.sortOfferte = (hotelId, data) => {
    return client({
        url: `Hotel/${hotelId}/HotelOfferta/Sort`,
        method: 'post',
        data,
    });
};

apis.pinOffer = (hotelId, offerId) => {
    return client({
        url: `Hotel/${hotelId}/HotelOfferta/${offerId}/Pin`,
        method: 'patch',
    });
};

apis.translateOfferta = (offerId) => {
    return client({
        url: '/HotelOfferta/translate',
        method: 'POST',
        data: {
            offers_id: [offerId],
        },
    });
};

apis.dupeOfferta = (offerId, title) => {
    return client({
        url: `HotelOfferta/${offerId}/duplicate`,
        method: 'POST',
        data: {
            titolo: title,
        },
    });
};

const today = format(new Date(), 'YYYY-MM-DD');

apis.fetchOfferteAttive = (hotelId) => {
    return client({
        url: `Hotel/${hotelId}/HotelOfferta?top=999&page=1&orderdir=ASC&query=data_pubblicazione||<=||${today}::data_fine_pubblicazione||>=||${today}`,
    });
};

apis.fetchOfferteCurrentYear = (hotelId) => {
    return client({
        url: `Hotel/${hotelId}/HotelOfferta?top=999&page=1&orderdir=ASC&query=data_pubblicazione||<=||${today}::data_fine_pubblicazione||>=||${getCurrentCalendarYear()}-01-01`,
    });
};

export default apis;
