import React, { Component } from 'react';
import HotelWidgetLink from 'components/HotelWidgetLink';

export default class WidgetGestioneAdmin extends Component {
    render() {
        return (
            <div className="widget">
                <h2>Configurazioni</h2>
                <div className="row">
                    <div className="col-md-4">
                        <HotelWidgetLink icon="fas fa-user-tag" to="/ruoli">
                            Ruoli
                        </HotelWidgetLink>
                    </div>
                    <div className="col-md-4">
                        <HotelWidgetLink icon="fas fa-gavel" to="/permessi">
                            Permessi
                        </HotelWidgetLink>
                    </div>
                    <div className="col-md-4">
                        <HotelWidgetLink icon="fas fa-folder-tree" to="/macros">
                            Macro
                        </HotelWidgetLink>
                    </div>
                    <div className="col-md-4">
                        <HotelWidgetLink
                            icon="fas fa-mail-bulk"
                            to="/mailTemplateAssoc"
                        >
                            Associazione template email
                        </HotelWidgetLink>
                    </div>
                    <div className="col-md-4">
                        <HotelWidgetLink
                            icon="fas fa-shield-alt"
                            to="/sephirothMailAssoc"
                        >
                            Associazione configurazione email
                        </HotelWidgetLink>
                    </div>
                </div>
            </div>
        );
    }
}