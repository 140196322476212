const SET_HOTEL = 'SET_HOTEL';
const SYNC_HOTEL = 'SYNC_HOTEL';

function setHotel(hotel) {
    return {
        type: SET_HOTEL,
        payload: hotel
    };
}

function syncHotel(hotel) {
    return {
        type: SYNC_HOTEL,
        payload: hotel
    };
}

export const actionTypes = {
    setHotel: SET_HOTEL,
    syncHotel: SYNC_HOTEL,
    [SET_HOTEL]: SET_HOTEL,
    [SYNC_HOTEL]: SYNC_HOTEL
};

export const actions = {
    setHotel,
    syncHotel
};
