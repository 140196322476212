import apiClient from '../../../apis/portali';
import { portaleApiWithId } from '../../../helpers/portaleModuleHelper';

const api = portaleApiWithId('primopiano/getData', apiClient, {
    filter: 'q'
});

api.edit = (idPost, idPortale, data) => {
    const { value } = data;

    return apiClient({
        url: `primopiano/updateComune/${idPortale}/${idPost}`,
        method: 'POST',
        data: {
            comune_id: value
        }
    });
};

export default api;
