import apiClient from '../../../apis/portali';
import { portaleApi } from '../../../helpers/portaleModuleHelper';

const api = portaleApi('redirectPages', apiClient, {
    top: 'perPage',
    page: 'page',
    filter: 'q',
    orderby: 'orderKey',
    orderdir: 'orderDirection',
    query({ portaleId }) {
        if (typeof portaleId === 'number') {
            return `portal_id||=||${portaleId}`;
        }

        return null;
    }
});

api.create = data => {
    return apiClient({
        url: 'redirectPages',
        method: 'POST',
        data
    });
};

export default api;
