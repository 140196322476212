import React from 'react';
import PropTypes from 'prop-types';

const SidebarProfiloToggle = (props) => {
    const { setIsSidebarProfiloOpen, isSidebarProfiloOpen, nomeUtente } = props;

    return (
        <button
            style={{ cursor: 'pointer' }}
            className="btn ms-2 text-white"
            onClick={() => setIsSidebarProfiloOpen(!isSidebarProfiloOpen)}
        >
            <span className="d-none d-lg-inline-block">{nomeUtente}</span>
            <i className="fas fa-user-circle fa-lg ms-2" />
        </button>
    );
};

SidebarProfiloToggle.propTypes = {
    isSidebarProfiloOpen: PropTypes.bool,
    nomeUtente: PropTypes.string,
    setIsSidebarProfiloOpen: PropTypes.func.isRequired,
};

export default SidebarProfiloToggle;
