import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import WithUser from '../WithUser';
// import { roles } from '../../helpers/auth';
import { selectors as portaleSelectors } from '../../reducers/currentPortaleV2';
// import WidgetUltimeModificheSito from 'components/widget/WidgetUltimeModificheSito';
import WidgetSelectGestione from 'components/widget/WidgetSelectGestione';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import SelectPortaleV2 from '../SelectPortaleV2';
import WidgetGestionePortale from '../widget/WidgetGestionePortale';
import WidgetStatistichePortale from '../widget/WidgetStatistichePortale';
import WidgetUtilityPortale from '../widget/WidgetUtilityPortale';

class DashboardPortali extends Component {
    constructor(props) {
        super(props);

        this.state = {
            onToggleCambioPortale: false,
        };
        this.toggleCambioPortale = this.toggleCambioPortale.bind(this);
    }

    toggleCambioPortale = () => {
        this.setState({
            onToggleCambioPortale: !this.state.onToggleCambioPortale,
        });
    };

    render() {
        const {
            user,
            //userRole,
            currentPortale,
        } = this.props;
        const { onToggleCambioPortale } = this.state;

        if (!user) {
            return <span>Perfavore effettua il login</span>;
        }

        // const isUser = userRole === roles.user;

        // const showPortaleFilter = !isUser;

        const title = currentPortale.label.replace(/ *\([^)]*\) */g, '');

        return (
            <Fragment>
                <div className="row py-2">
                    <div className="col-12">
                        <Helmet>
                            <title>{`Portale > ${title}`}</title>
                        </Helmet>
                        <h2 className="text-primary text-center">
                            Stai gestendo {title}
                        </h2>
                    </div>
                </div>
                {!onToggleCambioPortale && (
                    <div className="row py-3 align-items-center">
                        <div className="col-md-4 offset-md-4 text-md-center">
                            Ultima modifica ???
                        </div>
                        <div className="col-md-4 text-md-end">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.toggleCambioPortale}
                            >
                                Cambia Portale{' '}
                                <i className="fas fa-exchange-alt fa-lg ms-2"></i>
                            </button>
                        </div>
                    </div>
                )}
                <div className="row py-3">
                    {onToggleCambioPortale && (
                        <WidgetSelectGestione
                            title="Gestisci Portale"
                            toggleChiudiSelect={this.toggleCambioPortale}
                        >
                            <SelectPortaleV2
                                toggleCambioPortale={this.toggleCambioPortale}
                            />
                        </WidgetSelectGestione>
                    )}
                    <div className="col-md-12">
                        <WidgetGestionePortale
                            nomePortale={title}
                            portaleId={currentPortale.value}
                        />
                    </div>
                    <div className="col-md-4">
                        <WidgetStatistichePortale
                            portaleId={currentPortale.value}
                        />
                    </div>
                    <div className="col-md-4">
                        <WidgetUtilityPortale currentPortale={currentPortale} />
                    </div>
                    {/* <div className="col-md-4">
                        <WidgetUltimeModificheSito />
                    </div> */}
                </div>
            </Fragment>
        );
    }
}

export default withRouter(
    connect((state) => ({
        currentPortale: portaleSelectors.getCurrentPortale(state),
    }))(WithUser(DashboardPortali))
);
