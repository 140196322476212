import { sagaCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import { actions, actionTypes } from '../actions/macros';
import { selectors, DEFAULT_STATE } from '../reducers/macros';
import api from '../apis/macros';

const sagas = helper.createSagas(
    'macros',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE,
    {
        resourceLabel: 'Macro'
    }
);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [...defaultSagas];
