import React, { Component } from 'react';

export default class WidgetGestioneUtility extends Component {
    render() {
        return (
            <div className="widget">
                <h2>Utility</h2>
                <div className="row">
                    <div className="col-md-4">
                        <div className="widget-link">
                            <a
                                href="http://debserver.com:9999/"
                                target="_blank"
                                rel="noreferrer"
                                className="d-flex align-items-center"
                            >
                                <i
                                    className="fa fab fa-gitlab widget-icon"
                                    aria-hidden="true"
                                ></i>
                                GitLab
                            </a>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="widget-link">
                            <a
                                href="https://github.com/"
                                target="_blank"
                                rel="noreferrer"
                                className="d-flex align-items-center"
                            >
                                <i
                                    className="fa fab fa-github widget-icon"
                                    aria-hidden="true"
                                ></i>
                                Github
                            </a>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="widget-link">
                            <a
                                href="http://192.168.0.114:9980/"
                                target="_blank"
                                rel="noreferrer"
                                className="d-flex align-items-center"
                            >
                                <i
                                    className="fa fab fa-jenkins widget-icon"
                                    aria-hidden="true"
                                ></i>
                                Jenkins
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
