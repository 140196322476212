import helper from 'helpers/steinerHelper';
import client from 'apis/contratti';

const apis = helper.createApi('rubrica', client, {
    limit: 'perPage',
    page: 'page',
    filter: 'q',
    codice_produttore: 'produttore',
    without_produttore: 'without_produttore',
    comune: 'comune',
    posizione: 'posizione',
    year: 'year',
    orderBy: 'orderKey',
    orderDir: 'orderDirection',
    customer_category_struttura: 'customer_category_struttura'
});

apis.revealPhone = id => {
    return client({
        url: `rubrica/opens/${id}`
    });
};

export default apis;
