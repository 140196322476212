import { sagaCreator } from '@adrias-online/steiner/lib/helpers';

import helper from 'helpers/steinerHelper';
import { actions, actionTypes } from '../actions/dashboardCommerciali';
import { selectors, DEFAULT_STATE } from '../reducers/dashboardCommerciali';
import api from '../apis/dashboardCommerciali';

const sagas = helper.createSagas(
    'dashboardCommerciali', 
    actionTypes,
    actions, 
    api, 
    selectors, 
    DEFAULT_STATE
);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [ ...defaultSagas];