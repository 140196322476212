import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/cookieConfigAuto';

const routes = helper.generateRoutes('cookieConfigAuto', selectors);

routes.list.list.breadcrumb = 'Configurazione Cookie';


const list = _.values(routes.list).map(route => ({
    ...route,
    // hotelFilter: true,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'cookieConfig' */ `modules/cookieConfig/${
                route.componentPath
                }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('cookieConfigAuto', routes.patterns, {
    it: 'Configurazione Cookie'
});
routeRegister.addLinks('cookieConfigAuto', links);

export default list;
