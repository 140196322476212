import { sagaCreator } from '@adrias-online/steiner';

import { createSagas } from '../../../helpers/portaleModuleHelper';
import { actions, actionTypes } from '../actions/portaliLandingPage';
import { selectors, DEFAULT_STATE } from '../reducers/portaliLandingPage';
import api from '../apis/portaliLandingPage';

const sagas = createSagas(
    'portaliLandingPage',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE,
    {
        resourceLabel: 'Landing Page'
    }
);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [...defaultSagas];
