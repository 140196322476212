import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { addNotification as notify } from 'reapop';
import { selectors as portaleSelectors } from '../reducers/currentPortale';

import apiPortali from '../apis/portali';

import { Loader } from 'react-overlay-loader';
import ListFilterWrapper from './ListFilterWrapper';

// TODO: Check and finish the tools when the api will be ready

class ToolsPortali extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };

        this.generateInvalidReport = this.generateInvalidReport.bind(this);
        this.buildSitemaps = this.buildSitemaps.bind(this);
        this.calculateIndicators = this.calculateIndicators.bind(this);
    }

    generateInvalidReport() {
        const { value } = this.props.currentPortale;

        this.setState({ loading: true });

        apiPortali
            .get(`tools/checkInvalidDescriptions/${value}?format=csv`, {
                responseType: 'text'
            })
            .then(response => {
                const { data } = response;
                const fileName = `invalid_${value}.csv`;
                const blob = new Blob([data]);

                const url = window.URL;
                const downloadUrl = url.createObjectURL(blob, {
                    type: 'application/csv'
                });

                const linkDownload = document.createElement('a');
                linkDownload.href = downloadUrl;
                linkDownload.download = fileName;
                document.body.appendChild(linkDownload);
                linkDownload.click();

                setTimeout(() => url.revokeObjectURL(downloadUrl), 100);
            })
            .catch(error => {
                const { dispatch } = this.props;

                dispatch(
                    notify({
                        title: 'Errore',
                        message: `Si è verificato un errore inaspettato -> ${error}`,
                        status: 'error'
                    })
                );
            })
            .finally(() => this.setState({ loading: false }));
    }

    buildSitemaps() {
        const { value } = this.props.currentPortale;

        this.setState({ loading: true });

        apiPortali
            .get(`tools/regenerateSitemaps/${value}`)
            .then(response => {
                const { message } = response.data;
                const { dispatch } = this.props;

                dispatch(
                    notify({
                        title: 'Successo',
                        message,
                        status: 'success'
                    })
                );
            })
            .catch(error => {
                const { dispatch } = this.props;

                dispatch(
                    notify({
                        title: 'Errore',
                        message: `Si è verificato un errore imprevisto! -> ${error}`,
                        status: 'error'
                    })
                );
            })
            .finally(() => this.setState({ loading: false }));
    }

    calculateIndicators() {
        const { value } = this.props.currentPortale;

        this.setState({ loading: true });

        apiPortali
            .get(`crons/buildHotelIndicators/${value}`)
            .then(() => {
                const { dispatch } = this.props;

                dispatch(
                    notify({
                        title: 'Successo',
                        message: 'Indicatori ricalcolati correttamente',
                        status: 'success'
                    })
                );
            })
            .catch(() => {
                const { dispatch } = this.props;

                dispatch(
                    notify({
                        title: 'Error',
                        message: 'Si è verificato un errore imprevisto',
                        status: 'error'
                    })
                );
            })
            .finally(() => this.setState({ loading: false }));
    }

    render() {
        const { label } = this.props.currentPortale,
            { loading } = this.state;

        return (
                <ListFilterWrapper
                    title={`Tools ${label}`}
                    btnLink="/dashboardPortali"
                    btnTitle="Dashboard portali"
                >
                    <Helmet>
                        <title>Tools Portali</title>
                    </Helmet>
                    <Loader fullPage={true} loading={loading} />
                    <div className="list-group py-2">
                        <div className="list-group-item">
                            <h4>Invalid Descriptions</h4>
                            <p>
                                Genera un elenco delle pagine del portale con campi
                                descriptions mancanti o non validi.
                            </p>
                            <button
                                className="btn btn-info"
                                type="button"
                                onClick={this.generateInvalidReport}
                            >
                                <i className="fas fa-fax" aria-hidden="true" />{' '}
                                Genera Report
                            </button>
                        </div>
                        <div className="list-group-item">
                            <h4>Rigenera Sitemaps</h4>
                            <p>
                                Rigenera le sitemaps per le pagine e i posts del
                                portale selezionato.
                            </p>
                            <button
                                className="btn btn-info"
                                type="button"
                                onClick={this.buildSitemaps}
                            >
                                <i className="fas fa-sitemap" aria-hidden="true" />{' '}
                                Rigenera Sitemaps
                            </button>
                        </div>
                        <div className="list-group-item">
                            <h4>Ricalcola Indicatori</h4>
                            <p>
                                Forza il ricalcolo degli inidcatori mail per il
                                portale selezionato.
                            </p>
                            <button
                                className="btn btn-info"
                                type="button"
                                onClick={this.calculateIndicators}
                            >
                                <i className="fas fa-bar-chart" aria-hidden="true" />{' '}
                                Ricalcola Indicatori
                            </button>
                        </div>
                    </div>
                </ListFilterWrapper>
        );
    }
}

ToolsPortali.propTypes = {
    currentPortale: PropTypes.object,
    dispatch: PropTypes.func
};

function mapStateToProps(state) {
    return {
        currentPortale: portaleSelectors.getCurrentPortale(state)
    };
}

export default connect(mapStateToProps)(ToolsPortali);
