import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch /*, BrowserRouter*/ } from 'react-router-dom';
import NotificationsSystem from 'reapop';
import LoadingBar from 'react-redux-loading-bar';
import { Helmet } from 'react-helmet';
import theme from 'reapop-theme-wybo';
import { getUser } from './helpers/auth/reducer';
import { updateProfileSuccess } from './helpers/auth/actions';
import { getCurrentRoute } from '@adrias-online/steiner/lib/routing/reducer';
import { getSettings } from './helpers/settings/reducer';
import TranslatorProvider from 'components/TranslatorProvider';
import queryString from 'query-string';
import _ from 'lodash';

import routeRegister from 'helpers/routeRegister';
import { roles, fetchUserContract } from 'helpers/auth';
import Header from './components/Header';
import Welcome from './components/Welcome';
import Profile from './components/Profile';
import ModalHelp from './components/ModalHelp';
import MatchWhenRole from './components/MatchWhenRole';
import RecuperoPassword from './components/auth/RecuperoPassword';
import ResetPassword from './components/auth/ResetPassword';
import FoglioPresenze from './components/FoglioPresenze';
import routes from './routes';
import history from './apphistory';
import { actions as hotelActions } from './actions/currentHotel';
import { selectors as hotelSelectors } from './reducers/currentHotel';
import { selectors as portaleSelectors } from './reducers/currentPortale';
import { selectors as notificationSelectors } from './reducers/systemNotifications';
import { getUserHotelId, getUserRole } from 'reducers/selectors';
import hotelApi from 'modules/hotel/apis/hotel';
import ToolsPortali from './components/ToolsPortali';
import DashboardGestionaleAmministrazione from 'components/dashboard/DashboardGestionaleAmministrazione';
import DashboardGestionaleAdmin from 'components/dashboard/DashboardGestionaleAdmin';
import DashboardGestionaleHotel from 'components/dashboard/DashboardGestionaleHotel';
import DashboardGestionalePortali from './components/dashboard/DashboardGestionalePortali';
import DashboardPortali from './components/dashboard/DashboardPortali';
import ClientEmail from './components/ClientEmail';
import PersonalData from './components/PersonalData';
import MatchWhenAuthorized from './helpers/MatchWhenAuthorized';
import ControlledRouter from './helpers/ControlledRouter';
import MatchWhenGuest from './helpers/MatchWhenGuest';
import KeyBinderHoc from './components/KeyBinderHoc';
import withClearCache from "./components/ClearCache";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOmniboxOpen: false,
            isHelpModalOpen: false,
            unreadNotifications: 0,
        };

        this.fetchDataCall = null;

        this.omniboxOptions = routeRegister.getOmniboxOptions(
            routes,
            'produttore'
        );
    }

    componentDidMount() {
        this.syncCurrentHotel();
        // this.syncCurrentPortale();

        if (this.props.user) {
            this.checkCurrentHotel();
            // this.checkNotifiche();
        }

        this.props.bindShortcut(
            ['ctrl+p', 'command+p'],
            (e) => {
                e.preventDefault();
                this.toggleOmnibox();
            },
            true
        );

        this.props.bindShortcut(
            ['ctrl+h', 'command+h'],
            (e) => {
                e.preventDefault();
                this.toggleHelpModal();
            },
            true
        );

        // TODO: muovere in un metodo dedicato
        // this.omniboxOptions = routeRegister.getOmniboxOptions(
        //     routes,
        //     'produttore'
        // );
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.user, this.props.user)) {
            this.checkCurrentHotel();
            // this.checkNotifiche();
            this.checkContratti();
        }
    }

    async checkContratti() {
        if (
            this.props.user !== null &&
            typeof this.props.user.contratti === 'undefined'
        ) {
            const contratti = await fetchUserContract(this.props.userHotelId);
            // const contratti = await fetchUserContract(673);

            this.props.dispatch(
                updateProfileSuccess({
                    contratti,
                })
            );
        }
    }

    checkCurrentHotel() {
        if (this.props.user && _.isNull(this.fetchDataCall)) {
            if (
                this.props.userRole !== roles.user &&
                this.props.currentHotel.value === null
            ) {
                this.fetchHotelData();
            }

            if (
                this.props.userRole === roles.user &&
                this.props.currentHotel.comuneId === null
            ) {
                this.fetchHotelData();
            }
        }
    }

    async fetchHotelData() {
        let res = null;
        let hotelData = null;

        this.fetchDataCall = true;

        if (this.props.userRole === roles.user) {
            res = await hotelApi.fetch(this.props.userHotelId);

            hotelData = this.formatHotelData(res.data);
        } else {
            res = await hotelApi.list();

            hotelData = this.formatHotelData(res.data.values[0]);
        }

        this.props.dispatch(hotelActions.setHotel(hotelData));
        this.fetchDataCall = null;
    }

    // checkNotifiche() {
    //     if (this.props.user) {
    //         this.props.dispatch(notificationActions.refreshUnreadCount());
    //     }
    // }

    formatHotelData(item) {
        return {
            value: item.idHotel,
            label: `${item.nome_hotel} - ${item.nome_comune} (${item.idHotel})`,
            comuneId: item.idComune,
            magnewsDb: item.magnews_db_id,
        };
    }

    syncCurrentHotel() {
        const params = queryString.parse(window.location.search);

        // TODO: spostare in index.js per prendere dati prima del caricamento dello store?
        if (params.hotelId) {
            this.props.dispatch(
                hotelActions.syncHotel({
                    value: parseInt(params.hotelId, 10),
                })
            );
        } else {
            if (this.props.currentHotel && this.props.currentHotel.value) {
                this.props.dispatch(
                    hotelActions.syncHotel(this.props.currentHotel)
                );
            }
        }
    }

    // syncCurrentPortale() {
    //     const params = queryString.parse(window.location.search);
    //
    //     console.warn(params);
    //     console.warn(parseInt(params.portaleId));
    //
    //     if (params.portaleId) {
    //         this.props.dispatch(
    //             portaleActions.syncPortale({
    //                 value: parseInt(params.portaleId, 10)
    //             })
    //         );
    //     } else {
    //         if (this.props.currentPortale && this.props.currentPortale.value) {
    //             this.props.dispatch(
    //                 portaleActions.syncPortale(this.props.currentPortale)
    //             );
    //         }
    //     }
    // }

    toggleOmnibox = () => {
        this.setState({
            isOmniboxOpen: this.props.user ? !this.state.isOmniboxOpen : false,
        });
    };

    toggleHelpModal = () => {
        this.setState({
            isHelpModalOpen: !this.state.isHelpModalOpen,
        });
    };

    render() {
        const {
            user,
            currentHotel,
            currentRoute,
            dispatch,
            translations,
            settings,
            unreadNotifications,
            userRole,
        } = this.props;

        const { isHelpModalOpen } = this.state;

        const isUser = userRole === roles.user;
        const isMultihotel = userRole === roles.multihotel;
        const isAdmin = userRole === roles.admin;

        let containerClass;

        if (user) {
            containerClass = 'container mt-60 mb-4';
        }

        return (
            <>
                <ControlledRouter
                    history={history}
                    location={currentRoute.location}
                    action={currentRoute.action}
                    dispatch={dispatch}
                >
                    <TranslatorProvider
                        locale={settings.language}
                        messages={translations}
                    >
                        <>
                            <Helmet
                                titleTemplate={`${window.__RUNTIME_CONFIG__.REACT_APP_NAME} | %s`}
                            />
                            {/* <ReactCSSTransitionGroup
                                transitionName="Omnibox__slide"
                                transitionEnterTimeout={300}
                                transitionLeaveTimeout={300}
                            ></ReactCSSTransitionGroup> */}
                            {user && (
                                <>
                                    <ModalHelp
                                        isOpen={isHelpModalOpen}
                                        onClose={this.toggleHelpModal}
                                    />
                                    <LoadingBar
                                        style={{
                                            zIndex: 1031,
                                            backgroundColor: '#9DCF68',
                                        }}
                                        updateTime={150}
                                        maxProgress={95}
                                    />
                                    <Header
                                        onToggleHelpModal={this.toggleHelpModal}
                                        routes={routes}
                                        dispatch={this.props.dispatch}
                                        unreadNotifications={
                                            unreadNotifications
                                        }
                                        currentHotel={currentHotel}
                                        omniboxOptions={this.omniboxOptions}
                                        toggleOmnibox={this.toggleOmnibox}
                                    />
                                </>
                            )}

                            <div className={containerClass}>
                                <Switch>
                                    {user ? (
                                        <Route
                                            path="/"
                                            exact={true}
                                            component={
                                                user.lastName
                                                    ? Welcome
                                                    : PersonalData
                                            }
                                            location={currentRoute.location}
                                        />
                                    ) : (
                                        <Route
                                            path="/"
                                            exact={true}
                                            component={Welcome}
                                            location={currentRoute.location}
                                        />
                                    )}
                                    <MatchWhenGuest
                                        path="/recuperoPassword"
                                        component={RecuperoPassword}
                                        user={user}
                                        location={currentRoute.location}
                                    />
                                    <MatchWhenGuest
                                        path="/resetPassword"
                                        component={ResetPassword}
                                        user={user}
                                        location={currentRoute.location}
                                    />
                                    <MatchWhenAuthorized
                                        user={user}
                                        path="/profile"
                                        component={Profile}
                                        redirectTo="/"
                                    />
                                    <MatchWhenAuthorized
                                        user={user}
                                        path="/personalData"
                                        component={PersonalData}
                                        redirectTo="/"
                                    />
                                    <MatchWhenAuthorized
                                        user={user}
                                        path="/clientEmail"
                                        component={ClientEmail}
                                        redirectTo="/"
                                    />
                                    <MatchWhenAuthorized
                                        user={!isUser}
                                        path="/portali/tools"
                                        component={ToolsPortali}
                                        redirectTo="/"
                                    />
                                    <MatchWhenAuthorized
                                        user={!isUser && !isMultihotel}
                                        path="/presenze"
                                        component={FoglioPresenze}
                                        redirectTo="/"
                                    />
                                    <MatchWhenAuthorized
                                        user={user}
                                        path="/gestionaleAziendale"
                                        exact={true}
                                        component={
                                            DashboardGestionaleAmministrazione
                                        }
                                        redirectTo="/"
                                    />
                                    <MatchWhenAuthorized
                                        user={user}
                                        path="/gestionaleHotel"
                                        exact={true}
                                        component={DashboardGestionaleHotel}
                                        redirectTo="/"
                                    />
                                    <MatchWhenAuthorized
                                        user={!isUser}
                                        path="/gestionalePortali"
                                        exact={true}
                                        component={DashboardGestionalePortali}
                                        redirectTo="/"
                                    />
                                    <MatchWhenAuthorized
                                        user={isAdmin}
                                        path="/gestionaleAdmin"
                                        exact={true}
                                        component={DashboardGestionaleAdmin}
                                        redirectTo="/"
                                    />
                                    <MatchWhenAuthorized
                                        user={!isUser}
                                        path="/dashboardPortali"
                                        exact={true}
                                        component={DashboardPortali}
                                        redirectTo="/"
                                    />
                                    {routes.map((route, i) => (
                                        <MatchWhenRole key={i} {...route} />
                                    ))}
                                </Switch>
                            </div>
                            <NotificationsSystem theme={theme} />
                        </>
                    </TranslatorProvider>
                </ControlledRouter>
            </>
        );
    }
}

App.propTypes = {
    bindShortcut: PropTypes.func,
    currentHotel: PropTypes.object,
    currentPortale: PropTypes.object,
    currentRoute: PropTypes.object,
    dispatch: PropTypes.func,
    settings: PropTypes.object,
    translations: PropTypes.object,
    unreadNotifications: PropTypes.number,
    user: PropTypes.object,
    userHotelId: PropTypes.string,
    userRole: PropTypes.string,
};

const KeyedApp = KeyBinderHoc(App);

const ClearCacheComponent = withClearCache(KeyedApp);

function mapStateToProps(state) {
    return {
        currentHotel: hotelSelectors.getCurrentHotel(state),
        currentPortale: portaleSelectors.getCurrentPortale(state),
        currentRoute: getCurrentRoute(state),
        settings: getSettings(state),
        user: getUser(state),
        userHotelId: getUserHotelId(state),
        userRole: getUserRole(state),
        unreadNotifications: notificationSelectors.getUnreadCount(state),
    };
}

export default connect(mapStateToProps)(ClearCacheComponent);
