import helper from 'helpers/steinerHelper';
import contrattiClient from 'apis/contratti';

const paramsMap = {
    limit: 'perPage',
    page: 'page',
    orderBy: 'orderKey',
    orderDir: 'orderDirection',
    hotel_id: 'hotel_id',
    article_id: 'article_id',
    year: 'year',
    produttore: 'produttore'
};

const apis = helper.createApi('reports', contrattiClient, paramsMap);

// apis.readReport = (id) => {
//     return contrattiClient({
//         url: `/reports/${id}/read`
//     });
// }

export const getReadReportLink = (id, token) => {
    return `${
        window.__RUNTIME_CONFIG__.REACT_APP_ORDERS_API_ROOT
    }/reports/${id}/read?token=${token}`;
};

export default apis;
