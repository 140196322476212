import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import { selectors } from '../reducers/macros';
import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';

const routes = helper.generateRoutes('macros', selectors);

routes.list.edit.breadcrumb = 'Modifica macro';

const list = _.values(routes.list).map((route) => ({
    ...route,
    requiredRoles: ['admin'],
    getComponent() {
        return new Promise((resolve) => {
            import(
                /* webpackChunkName: 'macros' */ `modules/macros/${route.componentPath}`
            ).then((response) => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('macros', routes.patterns);
routeRegister.addLinks('macros', links);

export default list;
