import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/specsServices';

const routes = helper.generateRoutes('specsServices', selectors);

routes.list.list.breadcrumb = 'Specifiche Servizi';
routes.list.edit.breadcrumb = 'Crea-Modifica specifiche servizi';

const list = _.values(routes.list).map(route => ({
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'specsServices' */ `modules/specsServices/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('specsServices', routes.patterns);
routeRegister.addLinks('specsServices', links);

export default list;
