export const COLOR01 = '#9DB7C8'; 
export const COLOR02 = '#97C4AC'; 
export const COLOR03 = '#9AC8C5'; 
export const COLOR04 = '#8BA7A6'; 
export const COLOR05 = '#3783AD'; 
export const COLOR06 = '#539C74'; 
export const COLOR07 = '#5D87A4'; 
export const COLOR08 = '#3F6C6A'; 
export const COLOR09 = '#57A49E'; 
export const COLOR10 = '#1E6288'; 
export const COLOR11 = '#377955'; //non specificato -- -
export const COLOR12 = '#40657F'; 
export const COLOR13 = '#254D4C'; 
export const COLOR14 = '#3B7F7A'; 

export const COLOR15 = '#59B28F'; 
export const COLOR16 = '#99BD78'; 
export const COLOR17 = '#B9AB68'; 
export const COLOR18 = '#CD9A6C'; 
export const COLOR19 = '#D38B7E'; 
