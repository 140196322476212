import isAfter from 'date-fns/is_after';
import format from 'date-fns/format';

export function getCurrentCalendarYear() {
    const d = new Date();

    return d.getFullYear();
}

/**
 * Funzione per calcolare l'anno contrattuale
 * @return {number} Anno Corrente | Anno Corrente + 1
 */
export function getCurrentContractYear() {
    const currentYear = getCurrentCalendarYear();
    const today = new Date();

    const limit = `${currentYear}-09-30`;

    if (isAfter(today, limit)) {
        return currentYear + 1;
    }

    return currentYear;
}

export function getYearRangesOptions(year = null, before = 2, after = 1) {
    const currentYear = year !== null ? year : getCurrentCalendarYear();
    const beforeOptions = [];
    const afterOptions = [];

    if (before > 0) {
        for (let i = currentYear - 1; i >= currentYear - before; i--) {
            beforeOptions.push({ value: `${i}`, label: `${i}` });
        }
    }

    const currentOption = { value: `${currentYear}`, label: `${currentYear}` };

    if (after > 0) {
        for (let i = currentYear + 1; i <= currentYear + after; i++) {
            afterOptions.push({ value: `${i}`, label: `${i}` });
        }
    }

    return [].concat(beforeOptions.reverse(), currentOption, afterOptions);
}

export function getYearRangesOptionsV2(firstYear, lastYear) {
    const currentYear = getCurrentCalendarYear();
    const beforeOptions = [];
    const afterOptions = [];

    firstYear = firstYear ? firstYear : 2017;
    lastYear = lastYear ? lastYear : currentYear + 1;

    if (firstYear > 0) {
        for (let i = currentYear - 1; i >= firstYear; i--) {
            beforeOptions.push({ value: `${i}`, label: `${i}` });
        }
    }

    const currentOption = { value: `${currentYear}`, label: `${currentYear}` };

    if (lastYear > 0) {
        for (let i = currentYear + 1; i <= lastYear; i++) {
            afterOptions.push({ value: `${i}`, label: `${i}` });
        }
    }

    return [].concat(beforeOptions.reverse(), currentOption, afterOptions);
}

export function generateYearArraySince(year, howManyYears = null) {
    const currentYear = getCurrentCalendarYear();
    const years = [];

    if (howManyYears === null) {
        switch (true) {
            case year > currentYear:
                for (let i = currentYear; i <= year + howManyYears; i++) {
                    years.push({ value: `${i}`, label: `${i}` });
                }
                break;
            case year < currentYear:
                for (let i = year; i <= currentYear + howManyYears; i++) {
                    years.push({ value: `${i}`, label: `${i}` });
                }
                break;
            case year === currentYear:
                years.push({ value: `${year}`, label: `${year}` });
                break;
            default:
                return years;
        }
    } else {
        for (let i = year; i <= year + howManyYears; i++) {
            years.push({ value: `${i}`, label: `${i}` });
        }
    }

    return years;
}

export function fromCustomToISO(date) {
    if (!date) {
        return null;
    }

    const parts = date.split('/');

    return `${parts[2]}-${parts[1]}-${parts[0]}`;
}

export function fromCustomDashToISO(date) {
    if (!date) {
        return null;
    }

    const parts = date.split('-');

    return `${parts[2]}-${parts[1]}-${parts[0]}`;
}

export function fromISOToCustom(date) {
    if (!date) {
        return null;
    }

    return format(date, 'DD/MM/YYYY');
}

export function fromAtomToCustom(date) {
    return fromISOToCustom(fromAtomToIso(date), 'DD/MM/YYYY');
}

export function fromAtomToIso(date) {
    return date.slice(0,10);
}

export function controllaFormatoAtom(date) {
    const controllaFormatoData = new RegExp(DATETIME_ATOM_REGEX);

    return controllaFormatoData.test(date);
}

export function controllaFormatoYyyymmdd(date) {
    const controllaFormatoData = new RegExp(DATETIME_YYYYMMDD_REGEX);
    
    return controllaFormatoData.test(date);
}

export const DATETIME_ATOM_REGEX = /^([+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24:?00)([.,]\d+(?!:))?)?(\17[0-5]\d([.,]\d+)?)?([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;
export const DATETIME_YYYYMMDD_REGEX = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;
