import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { createAutoSelectAsyncLoaders } from '../../helpers/async';
import AutoSelectAsync from '../../components/AutoSelectAsync';

import api from '../../modules/calendarioAttivita/apis/calendarioAttivita';

const { loadItems, loadItemById } = createAutoSelectAsyncLoaders({
    apiCallItem(id) {
        return api.clienti.fetch(id);
    },
    apiCallItems(q) {
        return api.clienti.list({ q });
    },
    dataPathItems: 'data',
    optionExtractor(item) {
        const { id, descrizione } = item;

        return {
            value: id,
            label: descrizione,
        };
    },
});

export default class SelectHotelAo extends Component {
    render() {
        const { onChange, value, options, ...rest } = this.props;

        return (
            <AutoSelectAsync
                value={value}
                namespace="hotel"
                options={options}
                loadOptions={loadItems}
                fetchLabel={loadItemById}
                onChange={onChange}
                {...rest}
                placeholder="Seleziona un cliente"
            />
        );
    }
}

SelectHotelAo.propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array,
    value: PropTypes.any,
};
