import { reducerCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';

import { actionTypes } from '../actions/crmContatti';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            comune: null,
            posizione: null,
            produttore: null,
            year: null
        },
    },
});

const handlers = helper.createHandlers(actionTypes, {
    items: 'data.data',
    total: 'data.meta.pagination.total'
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('crmContatti');