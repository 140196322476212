import helper from '../../../helpers/steinerHelper';
import macrosClient from '../../../apis/gerard';

export default helper.createApi('macros', macrosClient, {
    filter: 'q',
    limit: 'per_page',
    order_by: 'orderKey',
    order_dir: 'orderDirection',
    page: 'page',
    with_submacros: 'with_submacros'
});
