import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class WidgetUltimeModifichePortale extends Component {
    createInitialFormValues(item) {
        return item;
    }

    render() {
        return (
            <div className="widget">
                <h2 className="pb-2">Ultime modifiche</h2>
                <ul className="list-group">
                    <li className="list-group-item py-1">
                        <NavLink to="/">
                            Prenota prima marzo... - Hotel Jumbo
                            <br />
                            <span className="small text-primary float-start">
                                Donatella
                            </span>
                            <span className="small text-secondary float-end">
                                11/09/2020
                            </span>
                        </NavLink>
                    </li>
                    <li className="list-group-item py-1">
                        <NavLink to="/">
                            Listini - Hotel Jumbo
                            <br />
                            <span className="small text-primary float-start">
                                Donatella
                            </span>
                            <span className="small text-secondary float-end">
                                11/09/2020
                            </span>
                        </NavLink>
                    </li>
                    <li className="list-group-item py-1">
                        <NavLink to="/">
                            Trattamenti - Hotel Jumbo
                            <br />
                            <span className="small text-primary float-start">
                                Matteo
                            </span>
                            <span className="small text-secondary float-end">
                                11/09/2020
                            </span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        );
    }
}
