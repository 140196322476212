import React from 'react';
import PropTypes from 'prop-types';

export default function LoginWrapper({ children, message, title }) {
    return (
        // <div className="l--login-panel l--position-absolute l--center g--shadow">
        //     <div className="logo-heading"></div>
        //     <div className="panel panel-primary form-signin">
        //         <div className="panel-heading h3 mb-3 fw-normal text-primary">
        //             {title}
        //         </div>
        //         <div className="panel-body">{children}</div>
        //     </div>
        //     {message && message}
        // </div>
        <>
            {children}
            {message && message}
        </>
    );
}

LoginWrapper.propTypes = {
    children: PropTypes.node,
    message: PropTypes.node,
    title: PropTypes.string,
};
