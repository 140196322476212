import { routeRegister } from '@adrias-online/steiner';
import _ from 'lodash';

// const currentHotelRoutes = ['offerte', 'hotelListino'];

class WalleRouteRegister extends routeRegister {
    // getSidebarLinks(language) {
    //     const links = [];

    //     _.forOwn(this.patterns, (patterns, key) => {
    //         const link = {
    //             to: patterns['list'],
    //             name: this.getKeyLabel(key, language)
    //         };

    //         if (_.includes(currentHotelRoutes, key)) {
    //             link.hotelAware = true;
    //         }

    //         links.push(link);
    //     });

    //     return _.sortBy(links, 'name');
    // }

    getOmniboxOptions(routes, userRole) {
        // console.warn(routes);
        const filtered = _.filter(routes, route => {
            if (route.showInOmnibox === false) {
                return false;
            }

            if (_.isUndefined(route.requiredRoles)) {
                return true;
            }

            return _.includes(route.requiredRoles, userRole);
        });

        const patterns = _.transform(
            this.patterns,
            (result, value, key) => {
                _.forOwn(value, (v, k) => {
                    result[`${key}::${k}`] = v;
                });

                return result;
            },
            {}
        );

        // console.warn(patterns);

        const options = filtered.map((route, i) => {
            const pattern = _.findKey(patterns, p => p === route.path);

            if (!pattern) {
                return null;
            }

            const [resource, action] = pattern.split('::');

            if (action === 'edit') {
                if (patterns[`${resource}::create`]) {
                    return {
                        id: i,
                        type: 'link',
                        path: patterns[`${resource}::create`],
                        label: `${this.getKeyLabel(
                            resource,
                            'it'
                        )} - ${this.getActionLabel('create', 'it')}`,
                        hotelAware: route.hotelFilter,
                        portaleAware: route.portaleFilter
                    };
                }

                return null;
            }

            return {
                id: i,
                type: 'link',
                path: route.path,
                label: `${this.getKeyLabel(
                    resource,
                    'it'
                )} - ${this.getActionLabel(action, 'it')}`,
                hotelAware: route.hotelFilter,
                portaleAware: route.portaleFilter
            };
        });

        // console.warn(_.compact(options));

        return _.compact(_.sortBy(options, 'label'));
    }

    // getOmniboxOptions(language) {
    //     let i = 1;

    //     const options = [];

    //     this.staticOptions.forEach(option => {
    //         options.push({
    //             ...option,
    //             id: i
    //         });

    //         i++;
    //     });

    //     const validActions = ['list', 'create'];

    //     _.forOwn(this.patterns, (patterns, key) => {
    //         _.forOwn(patterns, (path, action) => {
    //             if (_.includes(validActions, action)) {
    //                 const option = {
    //                     id: i,
    //                     type: 'link',
    //                     path,
    //                     label: `${this.getKeyLabel(key, language)}: ${this.getActionLabel(action, language)}`
    //                 };

    //                 if (_.includes(currentHotelRoutes, key)) {
    //                     option.hotelAware = true;
    //                 }

    //                 options.push(option);

    //                 i++;
    //             }
    //         });
    //     });

    //     return _.sortBy(options, 'label');
    // }

    // getLinkTo(route, props) {
    //     const linkGenerator = _.get(this.links, route);

    //     if (! linkGenerator) {
    //         return '';
    //     }

    //     return linkGenerator(props);
    // }
}

const register = new WalleRouteRegister({
    actionTranslations: {
        create: {
            it: 'crea'
        },
        edit: {
            it: 'modifica'
        },
        list: {
            it: 'lista'
        }
    }
});

// register.addStaticOptions([{
//     type: 'link',
//     path: '/profile',
//     label: 'Profilo'
// }]);

export default register;
