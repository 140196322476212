import { reducerCreator } from '@adrias-online/steiner';

import helper from '../../../helpers/steinerHelper';
import { createHandlers } from '../../../helpers/portaleModuleHelper';
import { actionTypes } from '../actions/portaliPages';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            portaleId: null,
            tipoPagina: null,
            localita: null,
            padre: null,
            offerte_start: null,
            offerte_end: null
        }
    }
});

const handlers = createHandlers(actionTypes, {
    items: 'data.data',
    total: 'data.meta.pagination.total'
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('portaliPages');
