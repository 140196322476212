import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import WithUser from '../WithUser';
import { roles } from '../../helpers/auth';

import { selectors as hotelSelectors } from '../../reducers/currentHotel';
import { selectors as portaleSelectors } from '../../reducers/currentPortale';

import SidebarMenuItem from './SidebarMenuItem';
import { slide as Menu } from 'react-burger-menu';
import './Sidebar.css';

const SidebarPrincipale = (props) => {
    const { user, isOpen, onStateChange, closeMenu, currentHotel, userRole } =
        props;

    const isUser = userRole === roles.user;
    const isMultihotel = userRole === roles.multihotel;
    const isProduttore = userRole === roles.produttore;
    const isAdmin = userRole === roles.admin;

    if (!user) {
        return <span>Perfavore effettua il login</span>;
    }

    return (
        <Menu
            id={'main-sidebar'}
            className={'sidebar-menu-wrapper'}
            width={280}
            disableAutoFocus
            isOpen={isOpen}
            onStateChange={onStateChange}
            customBurgerIcon={false}
            customCrossIcon={false}
        >
            <SidebarMenuItem
                title="Home"
                description="La tua dashboard di inizio lavori. Tutto sotto controllo
                    in una sola schermata"
                icon="far fa-long-arrow-alt-right"
                link="/"
                onClick={closeMenu}
            />

            {!isUser && !isMultihotel && (
                <SidebarMenuItem
                    title="Gestionale"
                    description="Tutto sui nostri clienti: anagrafiche, contratti, area amministrativa e dati per i commerciali"
                    icon="far fa-long-arrow-alt-right"
                    link="/gestionaleAziendale"
                    onClick={closeMenu}
                />
            )}

            <SidebarMenuItem
                title="Hotel"
                description="Gestisci offerte, listino e contenuti dell’hotel, consulta statistiche e dati da monitorare"
                icon="far fa-long-arrow-alt-right"
                link={
                    isUser || isMultihotel
                        ? `/hotel/${currentHotel.value}/dashboard`
                        : '/gestionaleHotel'
                }
                onClick={closeMenu}
            />
            {!isUser && !isMultihotel && !isProduttore && (
                <SidebarMenuItem
                    title="Portali"
                    description="Configura e gestisci Portali e Landing Page, consulta le statistiche e gli indicatori email"
                    icon="far fa-long-arrow-alt-right"
                    link="/gestionalePortali"
                    onClick={closeMenu}
                />
            )}
            {!isUser && !isMultihotel && !isProduttore && (
                <SidebarMenuItem
                    title="Interazioni"
                    description="Crea e gestisci le interazioni degli utenti sul sito web dell’hotel"
                    icon="far fa-long-arrow-alt-right"
                    link="/hotelInterazioni"
                    onClick={closeMenu}
                />
            )}
            {isAdmin && (
                <SidebarMenuItem
                    title="Admin"
                    description="Gestisci impostazioni da Admin"
                    icon="far fa-long-arrow-alt-right"
                    link="/gestionaleAdmin"
                    onClick={closeMenu}
                />
            )}
            {(isUser || isMultihotel) && (
                <>
                    <SidebarMenuItem
                        title="Statistiche"
                        description="Le tue statistiche personalizzate: potrai filtrare per data o canale di provenienza"
                        icon="far fa-long-arrow-alt-right"
                        link={`/statistiche`}
                        onClick={closeMenu}
                    />
                    <SidebarMenuItem
                        title="Amministrazione"
                        description="Qui trovi i tuoi contratti e le specifiche dei servizi, ma anche i dati di contatto dell’hotel"
                        icon="far fa-long-arrow-alt-right"
                        link="/gestionaleAziendale"
                        onClick={closeMenu}
                    />
                </>
            )}
        </Menu>
    );
};

SidebarPrincipale.propTypes = {
    user: PropTypes.object,
    isOpen: PropTypes.bool,
    onStateChange: PropTypes.func,
    closeMenu: PropTypes.func,
};

export default withRouter(
    connect((state) => ({
        currentHotel: hotelSelectors.getCurrentHotel(state),
        currentPortale: portaleSelectors.getCurrentPortale(state),
    }))(WithUser(SidebarPrincipale))
);
