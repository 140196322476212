import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';

import { selectors } from '../reducers/currentPortaleV2';

class PortaleFilterWidgetLink extends Component {
    render() {
        const { children, portaleId, to, classes, icon } = this.props;

        if (!portaleId) {
            return <NavLink className="link-disabled">{children}</NavLink>;
        }

        const location = {
            pathname: to,
            search: `?portaleId=${portaleId}`,
        };

        return (
            <div className="widget-link">
                <NavLink className={classes} to={location} >
                    <i className={`${icon} widget-icon`} aria-hidden="true" />{' '}
                    {children}
                </NavLink>
            </div>
        );
    }
}

PortaleFilterWidgetLink.propTypes = {
    children: PropTypes.any,
    to: PropTypes.string,
    portaleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    classes: PropTypes.string,
};

export default withRouter(
    connect((state) => ({ portaleId: selectors.getCurrentPortaleId(state) }))(
        PortaleFilterWidgetLink
    )
);
