import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import routeRegister from '../../../helpers/routeRegister';

const routes = {
    list: {
        list: {
            breadcrumb: 'Statistiche Mail Portale',
            componentPath: 'containers/PortaliStatisticheLayout',
            path: '/statistichePortale',
            exact: true
        }
    },
    patterns: {
        list: '/statistichePortale'
    }
};

const list = _.values(routes.list).map(route => ({
    ...route,
    // hotelFilter: true,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'portaliStatistiche' */ `modules/portaliStatistiche/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('statistichePortale', routes.patterns);
routeRegister.addLinks('statistichePortale', links);

export default list;
