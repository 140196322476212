import { reducerCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { createHandlers } from 'helpers/hotelModuleHelper';
import { actionTypes } from '../actions/trattamenti';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            hotelId: null
        }
    }
});

const handlers = createHandlers(actionTypes, {
    items: 'data',
    total: null
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('trattamenti');
