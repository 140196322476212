import React from 'react';
import PropTypes from 'prop-types';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@vx/scale';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { Bar } from '@visx/shape';
// import { Grid } from '@visx/grid';
// import { BarStack } from '@visx/shape';
import { LegendOrdinal } from '@visx/legend';
// import { withParentSize } from '@visx/responsive';

import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';

import { formatCurrency } from 'helpers/formatters';

let SellerBillingBarChart = (props) => {
    const { data, height, palette, width } = props;

    const margin = 25;

    // assi
    const asseX = (d) =>
        d.label; /* il valore di asseX è il nome delle colonne */
    const asseY = (d) => d.value; /* il valore di asseY è il cash*/

    // misure
    const xMax = width - 80;
    const yMax = height - 80;

    // scales
    const xScale = scaleBand({
        rangeRound: [0, xMax],
        domain: data.map(asseX),
        padding: 0.6,
    });

    const yScale = scaleLinear({
        rangeRound: [0, yMax],
        domain: [Math.max(...data.map(asseY)), 0],
    });

    const linearScale = scaleOrdinal({
        domain: [`${data[0].label}`, `${data[1].label}`, `${data[2].label}`],
        range: [palette.COLOR16, palette.COLOR15, palette.COLOR18],
    });
    const {
        tooltipOpen,
        tooltipLeft,
        tooltipTop,
        tooltipData,
        hideTooltip,
        showTooltip,
    } = useTooltip();

    const { containerRef, TooltipInPortal } = useTooltipInPortal();

    const tooltipStyles = {
        ...defaultStyles,
        minWidth: 60,
        backgroundColor: 'rgba(255,255,255,1)',
        color: 'rgba(var(--bs-secondary-rgb), var(--bs-text-opacity))',
    };

    let tooltipTimeout;

    return (
        <>
            <svg width={width} height={height} ref={containerRef}>
                <Group top={margin} left={55}>
                    <AxisLeft
                        hideZero={true}
                        scale={yScale}
                        numTicks={10}
                        // label="Fatturato"
                        // labelProps={{
                        //     fill: 'var(--bs-body-color)',
                        //     fontWeight: 700,
                        //     fontSize: 14,
                        //     textAnchor: 'middle',
                        // }}
                        hideTicks
                        tickLabelProps={() => ({
                            fill: 'var(--bs-body-color)',
                            fontSize: 10,
                            textAnchor: 'end',
                            verticalAnchor: 'middle',
                        })}
                        // strokeWidth={2}
                        stroke="var(--bs-body-color)"
                        // labelOffset={40}
                    />
                    {data.map((d, i) => {
                        const label = asseX(d);
                        const barWidth = xScale.bandwidth();
                        const barHeight = yMax - yScale(asseY(d));
                        const barX = xScale(label);
                        const barY = yMax - barHeight;
                        return (
                            <Bar
                                key={i}
                                x={barX}
                                y={barY}
                                fill={d.color}
                                width={barWidth}
                                height={barHeight}
                                onMouseLeave={() => {
                                    tooltipTimeout = window.setTimeout(() => {
                                        hideTooltip();
                                    }, 300);
                                }}
                                onMouseMove={(event) => {
                                    const coords = localPoint(
                                        event.target.ownerSVGElement,
                                        event
                                    );
                                    if (tooltipTimeout)
                                        clearTimeout(tooltipTimeout);
                                    showTooltip({
                                        tooltipData: formatCurrency(d.value),
                                        tooltipTop: coords.y,
                                        tooltipLeft: coords.x,
                                    });
                                }}
                            />
                        );
                    })}
                    <AxisBottom
                        scale={xScale}
                        // label="Obiettivi"
                        // labelProps={{
                        //     fill: 'var(--bs-body-color)',
                        //     fontWeight: 700,
                        //     fontSize: 14,
                        //     textAnchor: 'middle',
                        // }}
                        labelOffset={15}
                        top={yMax}
                        hideTicks
                        tickLabelProps={() => ({
                            fill: 'var(--bs-body-color)',
                            fontSize: 10,
                            textAnchor: 'middle',
                        })}
                        // strokeWidth={2}
                        stroke="var(--bs-body-color)"
                        tickClassName={'d-none d-md-inline'}
                    />
                </Group>
            </svg>
            {tooltipOpen && (
                <TooltipInPortal
                    key={Math.random()}
                    top={tooltipTop}
                    left={tooltipLeft}
                    style={tooltipStyles}
                >
                    <strong>{tooltipData}</strong>
                </TooltipInPortal>
            )}
            <LegendOrdinal
                scale={linearScale}
                className="d-md-none"
                direction="row"
                labelMargin="0 20px 0 5px"
                shapeMargin="1px 0 0"
            />
        </>
    );
};

SellerBillingBarChart.propTypes = {
    data: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    palette: PropTypes.object,
};

SellerBillingBarChart.defaultProps = {
    data: [],
};

export default SellerBillingBarChart;
