import React from 'react';

import PropTypes from 'prop-types';

const SidebarPrincipaleToggle = (props) => {
    const { isMenuOpen, position, onClick } = props;

    return (
        <button
            type="button"
            className={`Sidebar__${position}-toggle ms-1 me-3`}
            onClick={onClick}
        >
            {!isMenuOpen ? (
                <i className="fas fa-bars"></i>
            ) : (
                <i className="fas fa-times"></i>
            )}
            <span className="small">MENU</span>
        </button>
    );
};

SidebarPrincipaleToggle.propTypes = {
    position: PropTypes.oneOf(['navbar', 'sidebar']),
    onClick: PropTypes.func.isRequired,
};

export default SidebarPrincipaleToggle;
