import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as notificationsReducer } from 'reapop';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { auth, routing, settings } from '@adrias-online/steiner';
import _ from 'lodash';

import articoli from '../modules/articoli/reducers/articoli';
import articoliListino from '../modules/articoliListino/reducers/articoliListino';
import camere from '../modules/camere/reducers/camere';
import calendarioAttivita from '../modules/calendarioAttivita/reducers/calendarioAttivita';
import clienti from '../modules/clienti/reducers/clienti';
import clusters from '../modules/clusters/reducers/clusters';
import commerciali from '../modules/commerciali/reducers/commerciali';
import cookieConfig from '../modules/cookieConfig/reducers/cookieConfig';
import cookieConfigAuto from '../modules/cookieConfig/reducers/cookieConfigAuto';
import cookies from '../modules/cookies/reducers/cookies';
import currentHotel from './currentHotel';
import currentPortale from './currentPortale';
import dashboardCommerciali from '../modules/dashboardCommerciali/reducers/dashboardCommerciali';
import elencoAttivita from '../modules/elencoAttivita/reducers/elencoAttivita';
import funnel from '../modules/funnel/reducers/funnel';
import hotel from '../modules/hotel/reducers/hotel';
import hotelConfig from '../modules/hotelConfig/reducers/hotelConfig';
import hotelInterazioni from '../modules/hotelInterazioni/reducers/hotelInterazioni';
import hotelListino from '../modules/hotelListino/reducers/hotelListino';
import mailTemplateAssoc from '../modules/mailTemplateAssoc/reducers/mailTemplateAssoc';
import hotelNewsletter from '../modules/hotelNewsletter/reducers/hotelNewsletter';
import interazioni from '../modules/interazioni/reducers/interazioni';
import interazioniEmail from '../modules/interazioniEmail/reducers/interazioniEmail';
import interazioniPagine from '../modules/interazioniPagine/reducers/interazioniPagine';
import interessiMarketing from '../modules/interessiMarketing/reducers/interessiMarketing';
import iscrittiNewsletter from '../modules/iscrittiNewsletter/reducers/iscrittiNewsletter';
import hotelServizi from '../modules/hotelServizi/reducers/hotelServizi';
import macros from '../modules/macros/reducers/macros';
import mailArchive from '../modules/mailArchive/reducers/mailArchive';
import sephirothMailAssoc from '../modules/sephirothMailAssoc/reducers/sephirothMailAssoc';
import notification from '../modules/notification/reducers/notification';
import obbVenditaAnnuali from '../modules/obbVenditaAnnuali/reducers/obbVenditaAnnuali';
import offerte from '../modules/offerte/reducers/offerte';
import ordini from '../modules/ordini/reducers/ordini';
import venduto from '../modules/venduto/reducers/venduto';
import permessi from '../modules/permessi/reducers/permessi';
import portaliArchivioMail from '../modules/portaliArchivioMail/reducers/portaliArchivioMail';
import portaliArticoli from '../modules/portaliArticoli/reducers/portaliArticoli';
import portaliConsigliati from '../modules/portaliConsigliati/reducers/portaliConsigliati';
import portaliGalleries from '../modules/portaliGalleries/reducers/portaliGalleries';
import portaliIndicatori from '../modules/portaliIndicatori/reducers/portaliIndicatori';
import portaliIndicatoriHotels from '../modules/portaliIndicatoriHotels/reducers/portaliIndicatoriHotels';
import portaliLandingPage from '../modules/portaliLandingPage/reducers/portaliLandingPage';
import portaliPages from '../modules/portaliPages/reducers/portaliPages';
import portaliPhoto from '../modules/portaliPhoto/reducers/portaliPhoto';
import portaliRedirects from '../modules/portaliRedirects/reducers/portaliRedirects';
import portaliSchedeHotel from '../modules/portaliSchedeHotel/reducers/portaliSchedeHotel';
import portaliStatistiche from '../modules/portaliStatistiche/reducers/portaliStatistiche';
import portfolio from '../modules/portfolio/reducers/portfolio';
import questionari from '../modules/questionari/reducers/questionari';
import reports from '../modules/reports/reducers/reports';
import ruoli from '../modules/ruoli/reducers/ruoli';
import statistiche from '../modules/statistiche/reducers/statistiche';
import sidebarStatus from './sidebar';
import sitePage from '../modules/sitePage/reducers/sitePage';
import sitePhoto from '../modules/sitePhoto/reducers/sitePhoto';
import sitePhotogallery from '../modules/sitePhotogallery/reducers/sitePhotogallery';
import sitiClienti from '../modules/sitiClienti/reducers/sitiClienti';
import specsServices from '../modules/specsServices/reducers/specsServices';
import statVenditaCommerciale from '../modules/statVenditaCommerciale/reducers/statVenditaCommerciale';
import systemNotifications from './systemNotifications';
import trattamenti from '../modules/trattamenti/reducers/trattamenti';
import utenti from '../modules/utenti/reducers/utenti';
import criteriQualitativi from '../modules/criteriQualitativi/reducers/CriteriQualitativi';
import coefficienti from '../modules/coefficienti/reducers/coefficienti';
import monitorRichiesteCrm from '../modules/monitorRichiesteCrm/reducers/monitorRichiesteCrm';
import fatturatoHotels from '../modules/fatturatoHotels/reducers/fatturatoHotels';
import dipendenti from '../modules/dipendenti/reducers/dipendenti';
import crmContatti from '../modules/crmContatti/reducers/crmContatti';
import WebReputation from '../modules/WebReputation/reducers/WebReputation';
import WebReputationKeywords from '../modules/WebReputationKeywords/reducers/WebReputationKeywords';
import WebReputationScore from '../modules/WebReputationScore/reducers/WebReputationScore';
import mediaGallery from '../modules/mediaGallery/reducers/mediaGallery';
import mediaGalleryPrivata from '../modules/mediaGalleryPrivata/reducers/mediaGalleryPrivata';
import interazioniLayout from '../modules/interazioniLayout/reducers/interazioniLayout';

const dontClearKeys = ['settings'];

const clearOn = (type, reducers) => {
    const state = Object.keys(reducers).reduce((result, key) => {
        result[key] = (state, action) => {
            return action &&
                action.type === type &&
                !_.includes(dontClearKeys, key)
                ? reducers[key](undefined, {}) //reinitialize
                : reducers[key](state, action); // pass through
        };

        return result;
    }, {});

    return state;
};

const defaultNotification = {
    position: 'br',
    closeButton: true,
    allowHTML: true,
    dismissAfter: 10000,
    dismissible: true,
};

const rootReducer = combineReducers(
    clearOn(auth.actionTypes.logoutSuccess, {
        // const rootReducer = combineReducers({
        articoli,
        articoliListino,
        camere,
        calendarioAttivita,
        clienti,
        clusters,
        commerciali,
        cookieConfig,
        cookieConfigAuto,
        cookies,
        currentHotel,
        currentPortale,
        dashboardCommerciali,
        elencoAttivita,
        form: formReducer,
        funnel,
        hotel,
        hotelConfig,
        hotelInterazioni,
        hotelListino,
        mailTemplateAssoc,
        hotelNewsletter,
        interazioni,
        interazioniEmail,
        interazioniPagine,
        interessiMarketing,
        iscrittiNewsletter,
        hotelServizi,
        loadingBar: loadingBarReducer,
        macros,
        mailArchive,
        sephirothMailAssoc,
        notification,
        notifications: notificationsReducer(defaultNotification),
        obbVenditaAnnuali,
        offerte,
        ordini,
        permessi,
        portaliArchivioMail,
        portaliArticoli,
        portaliConsigliati,
        portaliGalleries,
        portaliIndicatori,
        portaliIndicatoriHotels,
        portaliLandingPage,
        portaliPages,
        portaliPhoto,
        portaliRedirects,
        portaliSchedeHotel,
        portaliStatistiche,
        portfolio,
        questionari,
        reports,
        router: routing.reducer,
        ruoli,
        statistiche,
        statVenditaCommerciale,
        sidebarStatus,
        sitePage,
        sitePhoto,
        sitePhotogallery,
        sitiClienti,
        specsServices,
        systemNotifications,
        settings: settings.reducer,
        trattamenti,
        user: auth.reducer,
        utenti,
        venduto,
        criteriQualitativi,
        coefficienti,
        monitorRichiesteCrm,
        fatturatoHotels,
        dipendenti,
        crmContatti,
        WebReputation,
        WebReputationKeywords,
        WebReputationScore,
        mediaGallery,
        mediaGalleryPrivata,
        interazioniLayout
    })
,);

export default rootReducer;
