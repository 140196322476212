import helper from 'helpers/steinerHelper';
import client from 'apis/apiCartaTurista';

const apis = helper.createApi('interessi', client);

apis.modificaInteresse = (interesseId, slug, descrizione) => {
    return client({
        url: `interessi/${interesseId}`,
        method: 'patch',
        data: { slug: slug, descrizione: descrizione }
    });
};

apis.creaInteresse = (slug, descrizione) => {
    return client({
        url: 'interessi',
        method: 'post',
        data: { slug: slug, descrizione: descrizione }
    });
};

apis.fetchInteressi = () => {
    return client({
        url: 'interessi',
        method: 'get'
    })
}

apis.fetchPeriodi = () => {
    return client({
        url: 'periodi',
        method: 'get'
    })
}

export default apis;