import React, { Component } from 'react';

class WidgetSelectGestione extends Component {
    render() {
        const { toggleChiudiSelect, title } = this.props;
        return (
            <div className="col-12">
                {toggleChiudiSelect && (
                    <div className="text-end">
                        <div
                            onClick={toggleChiudiSelect}
                            className="btn btn-link"
                        >
                            <i className="fal fa-times ms-2"></i>
                        </div>
                    </div>
                )}
                <div className="widget g--hotel-selected">
                    <div className="row align-items-center">
                        <div className="col-md-4 border-end">
                            <h2 className="text-primary">{title}</h2>
                        </div>
                        <div className="col-md-8">{this.props.children}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WidgetSelectGestione;
