import { reducerCreator } from '@adrias-online/steiner';

import helper from '../../../helpers/steinerHelper';
import { createHandlers } from '../../../helpers/portaleModuleHelper';
import { actionTypes } from '../actions/portaliLandingPage';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            portaleId: null,
            tipologia: null,
            online: null
        }
    }
});

const handlers = createHandlers(actionTypes, {
    items: 'data.data',
    total: 'data.meta.pagination.total'
});

handlers[actionTypes.switchOnlineLanding] = function(state, action) {
    const { id, value } = action.payload;

    return state.setIn(['list', 'itemsById', id, 'online'], value);
};

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('portaliLandingPage');
