const SET_SIDEBAR_STATUS = 'SET_SIDEBAR_STATUS';

function setSidebarStatus(status) {
    return {
        type: SET_SIDEBAR_STATUS,
        payload: status
    };
}

export const actionTypes = {
    setSidebarStatus: SET_SIDEBAR_STATUS,
    [SET_SIDEBAR_STATUS]: SET_SIDEBAR_STATUS
};

export const actions = {
    setSidebarStatus
};
