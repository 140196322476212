import apiClient from '../../../apis/portali';
import { portaleApi } from '../../../helpers/portaleModuleHelper';

const api = portaleApi('landingPage', apiClient, {
    top: 'perPage',
    page: 'page',
    filter: 'q',
    orderby: 'orderKey',
    orderdir: 'orderDirection',
    query({ portaleId, tipologia, online }) {
        let params = [],
            filters;

        if (typeof portaleId === 'number') {
            params.push(`sito||=||${portaleId}`);
        }

        if (tipologia !== null) {
            params.push(`tipologia||=||${tipologia}`);
        }

        if (online !== null) {
            params.push(`online||=||${online}`);
        }

        params.length > 0 ? (filters = params.join('::')) : (filters = null);

        return filters;
    }
});

api.setOnlineLanding = (idLanding, online) => {
    return apiClient({
        url: `landingPage/${idLanding}`,
        method: 'PATCH',
        data: {
            online
        }
    });
};

api.dupeLanding = (_idLanding, _newName) => {
    return apiClient({
        url: `landingPage/dupe/${_idLanding}?nome=${_newName}`,
        method: 'GET',
        data: {
            nome: _newName
        }
    });
};

export default api;
