import helper from '../../../helpers/steinerHelper';
import client from '../../../apis/gerard';

const api = helper.createApi('IndexSellerTarget', client, {
    q: 'q',
    seller_id: 'seller_id',
    year: 'year',
    limit: 'perPage',
    page: 'page'
});

export default api;
