import { sagaCreator } from '@adrias-online/steiner';
import { put, call, select } from 'redux-saga/effects';

import { createSagas } from '../../../helpers/portaleModuleHelper';
import { actions, actionTypes } from '../actions/portaliSchedeHotel';
import { selectors, DEFAULT_STATE } from '../reducers/portaliSchedeHotel';
import { selectors as currentPortaleSelectors } from '../../../reducers/currentPortale';
import api from '../apis/portaliSchedeHotel';

function success(type, response, loadingBar) {
    const action = {
        type,
        payload: response
    };

    if (loadingBar) {
        action.loadingBar = loadingBar;
    }

    return action;
}

function fail(type, response, loadingBar) {
    const action = {
        type,
        error: response
    };

    if (loadingBar) {
        action.loadingBar = loadingBar;
    }

    return action;
}

const sagas = createSagas(
    'portaliSchedeHotel',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE,
    {
        resourceLabel: 'Schede Hotel'
    }
);

sagas.fetch = function*(action) {
    try {
        const portale_id = yield select(
            currentPortaleSelectors.getCurrentPortaleId
        );
        const response = yield call(api.fetch, action.payload.id, portale_id);

        yield put(success(actionTypes.fetchSuccess, response));
    } catch (error) {
        yield put(fail(actionTypes.fetchFail, error));
    }
};

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [...defaultSagas];
