import { sagaCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { actions, actionTypes } from '../actions/hotel';
import { selectors, DEFAULT_STATE } from '../reducers/hotel';
import api from '../apis/hotel';

const sagas = helper.createSagas(
    'hotel',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE,
    {
        idKey: 'idHotel'
    }
);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [...defaultSagas];
