import { reducerCreator } from '@adrias-online/steiner';

import helper from '../../../helpers/steinerHelper';
import { actionTypes } from '../actions/portaliIndicatoriHotels';
import { createHandlers } from '../../../helpers/portaleModuleHelper';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            comune: null,
            portaleId: null,
            in_landing: null,
            mail_cost_diff: null,
            cost_previous: null,
            cost_current: null,
            categoria: null
        }
    }
});

const handlers = createHandlers(actionTypes, {
    items: 'data.data',
    total: 'data.meta.pagination.total'
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('portaliIndicatoriHotels');