import { routeCreator } from '@adrias-online/steiner';
import _ from 'lodash';

import { generateRoutes } from '../../../helpers/hotelModuleHelper';
import routeRegister from 'helpers/routeRegister';
import { selectors } from '../reducers/WebReputationScore';

const patternWebReputationScore = '/hotel/:id/WebReputationScore';

const routes = generateRoutes('WebReputationScore', selectors);

routes.list.list = {
    breadcrumb: 'Web Reputation Analysis',
    componentPath: 'containers/WebReputationScoreListLayout',
    path: patternWebReputationScore,
    hotelFilter: true
};

const list = _.values(routes.list).map(route => ({
    ...route,
    hotelFilter: true,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'WebReputationScore' */ `modules/WebReputationScore/${route.componentPath}`)
                .then(response => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('WebReputationScore', routes.patterns);
routeRegister.addLinks('WebReputationScore', links);

export default list;