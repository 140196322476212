import axios from 'axios';
import auth from '@adrias-online/steiner/lib/auth';
import { addNotification as notify } from 'reapop';
import { validator } from 'validate-this';
import apiPortali from '../apis/portali';

import { AUTH_REFRESH_FAIL } from '../actions/auth';
import { fetchContrattiToday } from '../modules/hotel/apis/hotel';
import { getUser } from './auth/reducer';

const DEFAULT_TIMEOUT = 900000;

export default class AuthHelper {
    constructor(store) {
        this.refreshTimeout = null;
        this.user = null;
        this.store = store;

        this.init();
    }

    init() {
        this.user = getUser(this.store.getState());

        // console.warn(this.user);

        if (this.user && this.user.token) {
            // console.warn('Set token in axios header');
            this.setToken(this.user.token);

            if (this.user.last_refresh) {
                const now = Date.now();

                const elapsed = now - this.user.last_refresh;

                // Refresh with the remaining timeout
                this.queueRefresh(
                    elapsed > DEFAULT_TIMEOUT ? 0 : DEFAULT_TIMEOUT - elapsed
                );
            }
        }

        this.store.subscribe(() => {
            const user = getUser(this.store.getState());

            // console.warn(user);
            // Se prima non avevo un utente e ora si è stato eseguito login
            if (!this.user && user && user.token) {
                // console.warn('Set token in axios header');
                this.setToken(user.token);

                // TODO: potrei impostare qui il valore di last_refresh eliminando il middleware

                // Start refresh with the default interval
                this.queueRefresh();
            }

            // Se ora ho un utente e prima no è stato eseguito logout
            if (this.user && !user) {
                // console.warn('LOGOUT');
                this.setToken('INVALID');
            }

            this.user = user;
        });
    }

    setToken(token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        apiPortali.defaults.params = { token };
    }

    queueRefresh(timeout = DEFAULT_TIMEOUT) {
        if (process.env.NODE_ENV === 'development') {
            console.warn(`Refreshing the token with timeout ${timeout}`);
        }

        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }

        this.refreshTimeout = setTimeout(() => {
            axios
                .post(
                    // 'https://security.adriasonline.com/api/v1/auth/jwt/refresh-token'
                    `${window.__RUNTIME_CONFIG__.REACT_APP_SECURITY_API_ROOT}/auth/jwt/refresh-token`
                )
                .then((res) => {
                    this.store.dispatch({
                        type: auth.actionTypes.updateProfileSuccess,
                        payload: {
                            token: res.data.token,
                            last_refresh: Date.now(),
                        },
                    });

                    this.setToken(res.data.token);

                    this.queueRefresh();
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        // Se non riesco a refreshare il token disconnetto l'utente attuale
                        this.store.dispatch(auth.actions.logoutRequest());
                        setTimeout(() => {
                            this.store.dispatch({
                                type: AUTH_REFRESH_FAIL,
                                error,
                            });
                        }, 500);
                    }
                });
        }, timeout);
    }
}

export function isAdmin(user) {
    return user && user.roleName === ADMIN_ROLE;
}

export const ADMIN_ROLE = 'admin';
export const AMMINISTRAZIONE_ROLE = 'amministrazione';
export const DEVELOPER_ROLE = 'developer';
export const EMPLOYEE_ROLE = 'employee';
export const PRODUTTORE_ROLE = 'produttore';
export const MULTIHOTEL_ROLE = 'multihotel';
export const USER_ROLE = 'user';

export const roles = {
    admin: ADMIN_ROLE,
    amministrazione: AMMINISTRAZIONE_ROLE,
    developer: DEVELOPER_ROLE,
    employee: EMPLOYEE_ROLE,
    produttore: PRODUTTORE_ROLE,
    multihotel: MULTIHOTEL_ROLE,
    user: USER_ROLE,
};

export const permissions = {
    USER_ALL: 'aosecurity.user.*',
    NOTIFICATION_ALL: 'aosecurity.user_notification.*',
    WALLE_MODULES_COMMERCIALI_ALL: 'walle.commerciali.*',
    WALLE_MODULES_CLIENTI_STATISTICHE_ALL: 'walle.clienti_statistiche.*',
    WALLE_MODULES_PORTALI_STATISTICHE_ALL: 'walle.portali_statistiche.*',
    WALLE_MODULES_AMMINISTRAZIONE_ALL: 'walle.amministrazione.*',
};

// export function validatePassword({ password, password_confirm }) {
//     const errors = {};

//     if (! password || password === '') {
//         errors.password = 'La password è obbligatoria';
//     }

//     if (! password_confirm || password_confirm === '') {
//         errors.password_confirm = 'E\' necessario confermare la password.';
//     }

//     if (password && password !== '') {
//         if (password.length < 6) {
//             errors.password = 'La password é troppo corta';
//         }

//         if (password !== password_confirm) {
//             errors.password_confirm = 'Le due password devono essere identiche';
//         }
//     }

//     // console.warn(errors);

//     return errors;
// }

export function validatePassword(values) {
    return validator(
        values,
        (v) => {
            v.validate('password', 'password_confirm').required();
            v.validate('password_confirm').matches('password');
        },
        (msg, field) => {
            switch (msg) {
                case 'required':
                    return 'Campo obbligatorio';
                case 'mismatch':
                    return 'Le due password devono essere identiche';
                default:
                    return 'Valore non valido';
            }
        }
    );
}

export function handleChangePasswordError(err, dispatch) {
    if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.error.code === 500
    ) {
        dispatch(
            notify({
                title: 'Errore!',
                message: 'Non puoi usare la vecchia password!',
                status: 'error',
                dismissAfter: 10000,
            })
        );
    } else {
        dispatch(
            notify({
                title: 'Errore!',
                message:
                    'Si è verificato un errore imprevisto durante il cambio della password!',
                status: 'error',
                dismissAfter: 10000,
            })
        );
    }
}

export async function fetchUserContract(hotelId) {
    if (!hotelId) {
        return [];
    }

    const res = await fetchContrattiToday(hotelId);

    return res.data;
}
