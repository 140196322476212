import { routeCreator } from '@adrias-online/steiner';
import _ from 'lodash';

import { generateRoutes } from 'helpers/hotelModuleHelper';
import routeRegister from 'helpers/routeRegister';
import { selectors } from '../reducers/interazioniEmail';

const routes = generateRoutes('interazioniEmail', selectors);
const list = _.values(routes.list).map((route) => ({
    ...route,
    hotelFilter: true,
    getComponent() {
        return new Promise((resolve) => {
            import(
                /* webpackChunkName: 'interazioniEmail' */ `modules/interazioniEmail/${route.componentPath}`
            ).then((response) => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('interazioniEmail', routes.patterns);
routeRegister.addLinks('interazioniEmail', links);

export default list;
