import axios from 'axios';

const { REACT_APP_MARKETING_SUITE } = window.__RUNTIME_CONFIG__;

const client = axios.create({
    baseURL: REACT_APP_MARKETING_SUITE,
    responseType: 'json',
    // headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Expose-Headers': 'Authorization',
    //     'Content-Type': 'multipart/form-data',
    // }
});

export default client;
