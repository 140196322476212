import apiClient from '../../../apis/portali';
import sherlock from '../../../apis/sherlock';
import { portaleApi } from '../../../helpers/portaleModuleHelper';

const api = portaleApi('page', apiClient, {
    top: 'perPage',
    page: 'page',
    filter: 'q',
    orderby: 'orderKey',
    orderdir: 'orderDirection',
    query({ portaleId, tipoPagina, localita }) {
        let params = [],
            filters;

        if (typeof portaleId === 'number') {
            params.push(`portal_id||=||${portaleId}`);
        }

        if (tipoPagina !== null) {
            params.push(`page_type_id||=||${tipoPagina}`);
        }

        if (localita !== null) {
            params.push(`comune_id||=||${localita}`);
        }

        params.length > 0 ? (filters = params.join('::')) : (filters = null);

        return filters;
    }
});

api.create = data => {
    return apiClient({
        url: 'page',
        method: 'POST',
        data
    });
};

api.dupePage = (_idPage, _newSlug) => {
    return apiClient({
        url: `page/dupe/${_idPage}?slug=${_newSlug}`,
        method: 'GET',
        data: {
            slug: _newSlug
        }
    });
};

export default api;

const sherlockApi = sherlock;

sherlockApi.getBalance = () => {
    return sherlock({
        url: 'balance',
        method: 'GET'
    });
};

sherlockApi.getPlagiarisms = (text, excludedSites) => {
    let excluded_sites;

    if (typeof excludedSites !== 'undefined' && excludedSites !== null)
        excluded_sites = excludedSites;

    const data = {
        text,
        excluded_sites
    };

    return sherlock({
        url: 'plagiarism',
        method: 'POST',
        data
    });
};

export { sherlockApi };
