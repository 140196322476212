import React, { Component } from 'react';
import SelectHotelV2 from '../SelectHotelV2';
import SelectPortaleV2 from '../SelectPortaleV2';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import WithUser from '../WithUser';
import { roles } from '../../helpers/auth';

import { actions as sidebarActions } from '../../actions/sidebar';
import { selectors as hotelSelectors } from '../../reducers/currentHotel';
import { selectors as portaleSelectors } from '../../reducers/currentPortale';
import { selectors as sidebarSelectors } from '../../reducers/sidebar';
import WidgetMiniAmministrazione from 'components/widget/WidgetMiniAmministrazione';
import WidgetMiniCommerciali from 'components/widget/WidgetMiniCommerciali';
// import WidgetUltimeModificheSito from 'components/widget/WidgetUltimeModificheSito';
import WidgetCalendario from 'components/widget/WidgetCalendario';
import WidgetSelectGestione from 'components/widget/WidgetSelectGestione';

class DashboardDipendente extends Component {
    render() {
        const { user, userRole } = this.props;

        if (!user) {
            return <span>Perfavore effettua il login</span>;
        }

        const isUser = userRole === roles.user;

        const showHotelFilter = !isUser;

        const showPortaleFilter = !isUser;

        return (
            <div className="row py-3">
                {showHotelFilter && (
                    <WidgetSelectGestione title="Gestisci Hotel">
                        <SelectHotelV2 />
                    </WidgetSelectGestione>
                )}
                {showPortaleFilter && (
                    <WidgetSelectGestione title="Gestisci Portale">
                        <SelectPortaleV2 />
                    </WidgetSelectGestione>
                )}
                <div className="col-md-4">
                    <WidgetMiniAmministrazione />
                </div>
                <div className="col-md-4">
                    <WidgetMiniCommerciali />
                </div>
                <div className="col-md-4">
                    <WidgetCalendario />
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    const boundActions = bindActionCreators(sidebarActions, dispatch);

    return {
        setStatus: boundActions.setSidebarStatus,
    };
}

export default withRouter(
    connect(
        (state) => ({
            currentHotel: hotelSelectors.getCurrentHotel(state),
            currentPortale: portaleSelectors.getCurrentPortale(state),
            sidebarStatus: sidebarSelectors.getCurrentSidebarStatus(state),
        }),
        mapDispatchToProps
    )(WithUser(DashboardDipendente))
);
