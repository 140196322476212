import { sagaCreator } from '@adrias-online/steiner';
import helper from 'helpers/steinerHelper';

import { actions, actionTypes } from '../actions/sitiClienti';
import { selectors, DEFAULT_STATE } from '../reducers/sitiClienti';
import api from '../apis/sitiClienti';

const sagas = helper.createSagas(
    'sitiClienti',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE,
    {
        numberFilters: ['hotelId']
    }
);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [...defaultSagas];
