import { sagaCreator } from '@adrias-online/steiner';

import { createSagas } from 'helpers/siteModuleHelper';
import { actions, actionTypes } from '../actions/sitePhoto';
import { selectors, DEFAULT_STATE } from '../reducers/sitePhoto';
import api from '../apis/sitePhoto';

const sagas = createSagas(
    'sitePhoto',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE
);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [...defaultSagas];
