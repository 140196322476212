import { createApi } from '../../../helpers/funnelModuleHelper';
import funnelClient from '../../../apis/gerard';

const api = createApi('funnels', funnelClient, {
    q: 'q',
    seller_code: 'seller_code',
    hotel_id: 'hotel_id',
    funnel_year: 'year',
    limit: 'perPage',
    page: 'page'
});

export default api;
