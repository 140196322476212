import { call, takeEvery } from 'redux-saga/effects';
import { sagaCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { actions, actionTypes } from '../actions/portfolio';
import { selectors, DEFAULT_STATE } from '../reducers/portfolio';
import api from '../apis/portfolio';

const sagas = helper.createSagas(
    'portfolio',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE,
    {
        numberFilters: ['comune', 'posizione']
    }
);

function* revealPhone(action) {
    yield call(api.revealPhone, action.payload.id);
}

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [
    ...defaultSagas,
    takeEvery(actionTypes.revealPhone, revealPhone)
];
