import React, { useEffect, useState } from 'react';
import HotelFilterLink from '../HotelFilterLink';
import statsClient from '../../apis/stats';
import { format, subDays } from 'date-fns';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';

const WidgetClienteRichieste = (props) => {
    const [richiesteAnno, setRichiesteAnno] = useState(0);
    const [richiesteMese, setRichiesteMese] = useState(0);
    const [richiesteTrimestre, setRichiesteTrimestre] = useState(0);
    const [richiesteSemestre, setRichiesteSemestre] = useState(0);

    const today = format(new Date(), 'YYYY-MM-DD');

    const paramsAnno = {
        stat_from: `${new Date().getFullYear()}-01-01`,
        stat_to: today,
        id_hotel: props.userHotelId,
    };

    useEffect(() => {
        const clientRichiesteAnno = async () => {
            const params = paramsAnno;
            const response = await statsClient({
                url: `/stats/summary`,
                params,
            });
            const richiesteData = await response.data;
            setRichiesteAnno(richiesteData.messages);
        };
        const clientRichiesteSubDays = async (giorni) => {
            const params = {
                stat_from: format(subDays(today, giorni), 'YYYY-MM-DD'),
                stat_to: today,
                id_hotel: props.userHotelId,
            };

            const response = await statsClient({
                url: `/stats/summary`,
                params,
            });
            const richiesteData = await response.data;
            if (giorni === 30) {
                setRichiesteMese(richiesteData.messages);
            }
            if (giorni === 90) {
                setRichiesteTrimestre(richiesteData.messages);
            }
            if (giorni === 180) {
                setRichiesteSemestre(richiesteData.messages);
            }
        };
        clientRichiesteAnno();
        clientRichiesteSubDays(30);
        clientRichiesteSubDays(90);
        clientRichiesteSubDays(180);
    }, []);

    return (
        <div className="widget richieste p-0">
            <div
                className="d-flex align-items-center flex-shrink-1 px-0 bg-primary text-center text-white position-relative"
                style={{ borderRadius: '10px' }}
            >
                <div className="p-3 mx-auto">
                    <i className="widget-cliente-icon fas fa-envelope fa-2x"></i>
                    <div className="py-2">
                        {richiesteSemestre !== 0 ? (
                            <CountUp
                                start={0}
                                end={richiesteSemestre}
                                duration={2}
                                delay={0}
                            >
                                {({ countUpRef }) => (
                                    <span
                                        ref={countUpRef}
                                        className="widget-cliente-counting"
                                    />
                                )}
                            </CountUp>
                        ) : (
                            <span className="widget-cliente-counting">0</span>
                        )}
                        <h3 className="text-white">
                            Richieste di prenotazione
                        </h3>
                    </div>
                    <div className="widget-icon-bottom">Ultimi 6 mesi</div>
                </div>
            </div>
            <div className="p-3 text-center">
                <HotelFilterLink to="/mailArchive" className="mb-2 d-block">
                    Archivio richieste di preventivo
                </HotelFilterLink>
                <HotelFilterLink
                    to="/statistiche"
                    className="btn btn-sm btn-tertiary"
                >
                    <i className="fas fa-archive me-2"></i> Guarda le
                    statistiche
                </HotelFilterLink>
            </div>
            <div className="py-2">
                <div className="row sfondo-colore-body p-2 my-1 mx-3">
                    <div className="col-2 text-end">
                        <div className="text-tertiary fw-bold">
                            {richiesteMese}
                        </div>
                    </div>
                    <div className="col-10">Ultimo mese</div>
                </div>
                <div className="row sfondo-colore-body p-2 my-1 mx-3">
                    <div className="col-2 text-end">
                        <div className="text-tertiary fw-bold">
                            {richiesteTrimestre}
                        </div>
                    </div>
                    <div className="col-10">Ultimo trimestre</div>
                </div>
                <div className="row sfondo-colore-body p-2 my-1 mx-3">
                    <div className="col-2 text-end">
                        <div className="text-tertiary fw-bold">
                            {richiesteAnno}
                        </div>
                    </div>
                    <div className="col-10">Anno 2021</div>
                </div>
            </div>
        </div>
    );
};

WidgetClienteRichieste.propTypes = {
    userHotelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default WidgetClienteRichieste;
