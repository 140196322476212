import { createSagas, bootSagas } from 'helpers/hotelModuleHelper';

// import helper from 'helpers/steinerHelper';
import { actions, actionTypes } from '../actions/camere';
import { selectors, DEFAULT_STATE } from '../reducers/camere';
import api from '../apis/camere';

const sagas = createSagas(
    'camere',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE
);

const defaultSagas = bootSagas(sagas, actionTypes);

export default [...defaultSagas];
