import React, { Component } from 'react';

export default class WidgetGestioneCms extends Component {
    render() {
        return (
            <div className="widget">
                <h2>CMS</h2>
                <div className="row">
                    <div className="col-md-4">
                        <div className="widget-link">
                            <a
                                href="http://192.168.0.140:3001/app/siteConfig"
                                target="_blank"
                                rel="noreferrer"
                                className="d-flex align-items-center"
                            >
                                <i
                                    className="fa fa fa-list-alt widget-icon"
                                    aria-hidden="true"
                                ></i>
                                Elenco siti
                            </a>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="widget-link">
                            <a
                                href="http://192.168.0.140:3001/app/siteTwigsBase"
                                target="_blank"
                                rel="noreferrer"
                                className="d-flex align-items-center"
                            >
                                <i
                                    className="fa fa fa-plug widget-icon"
                                    aria-hidden="true"
                                ></i>
                                Twig base
                            </a>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="widget-link">
                            <a
                                href="http://192.168.0.140:3001/app/siteFilesBase"
                                target="_blank"
                                rel="noreferrer"
                                className="d-flex align-items-center"
                            >
                                <i
                                    className="fa fa fa-folder-open widget-icon"
                                    aria-hidden="true"
                                ></i>
                                Files base
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
