import React, { Component } from 'react';
import HotelWidgetLink from 'components/HotelWidgetLink';

export default class WidgetGestioneCommerciali extends Component {
    render() {
        return (
            <div className="widget">
                <h2>Commerciali</h2>
                <div className="row">
                    <div className="col-md-4">
                        <HotelWidgetLink icon="fas fa-chart-bar" to="/venduto">
                            Statistiche venduto
                        </HotelWidgetLink>
                    </div>
                    <div className="col-md-4">
                        <HotelWidgetLink icon="fas fa-chart-line" to="/statVenditaCommerciale">
                            Report vendite
                        </HotelWidgetLink>
                    </div>
                    <div className="col-md-4">
                        <HotelWidgetLink icon="fas fa-address-book" to="/crmContatti">
                            Rubrica
                        </HotelWidgetLink>
                    </div>
                </div>
            </div>
        );
    }
}
