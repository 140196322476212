import { reducerCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { actionTypes } from '../actions/interazioni';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            orderDirection: 'ASC',
            orderKey: null,
            page: 1,
            per_page: 20,
            q: '',
            expiration: '',
            ao_hotel_id: null
        },
    },
});

// const handlers = helper.createHandlers(actionTypes);

const handlers = helper.createHandlers(actionTypes, {
    items: 'data.data',
    idKey: 'id',
    total: 'data.meta.total',
    per_page: 'data.meta.per_page',
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('interazioni');
