import articoli from '../modules/articoli/routes/articoli';
import articoliListino from '../modules/articoliListino/routes/articoliListino';
import camere from '../modules/camere/routes/camere';
import calendarioAttivita from '../modules/calendarioAttivita/routes/calendarioAttivita';
import clienti from '../modules/clienti/routes/clienti';
import commerciali from '../modules/commerciali/routes/commerciali';
import cookieConfig from '../modules/cookieConfig/routes/cookieConfig';
import cookieConfigAuto from '../modules/cookieConfig/routes/cookieConfigAuto';
import cookies from '../modules/cookies/routes/cookies';
import dashboardCommerciali from '../modules/dashboardCommerciali/routes/dashboardCommerciali';
import elencoAttivita from '../modules/elencoAttivita/routes/elencoAttivita';
import funnel from '../modules/funnel/routes/funnel';
import hotel from '../modules/hotel/routes/hotel';
import hotelConfig from '../modules/hotelConfig/routes/hotelConfig';
import hotelListino from '../modules/hotelListino/routes/hotelListino';
import mailTemplateAssoc from '../modules/mailTemplateAssoc/routes/mailTemplateAssoc';
import hotelNewsletter from '../modules/hotelNewsletter/routes/hotelNewsletter';
import iscrittiNewsletter from '../modules/iscrittiNewsletter/routes/iscrittiNewsletter';
import hotelServizi from '../modules/hotelServizi/routes/hotelServizi';
import macros from '../modules/macros/routes/macros';
import mailArchive from '../modules/mailArchive/routes/mailArchive';
import sephirothMailAssoc from '../modules/sephirothMailAssoc/routes/sephirothMailAssoc';
import notification from '../modules/notification/routes/notification';
import obbVenditaAnnuali from '../modules/obbVenditaAnnuali/routes/obbVenditaAnnuali';
import offerte from '../modules/offerte/routes/offerte';
import ordini from '../modules/ordini/routes/ordini';
import permessi from '../modules/permessi/routes/permessi';
import portaliArchivioMail from '../modules/portaliArchivioMail/routes/portaliArchivioMail';
import portaliArticoli from '../modules/portaliArticoli/routes/portaliArticoli';
import portaliConsigliati from '../modules/portaliConsigliati/routes/portaliConsigliati';
import portaliGalleries from '../modules/portaliGalleries/routes/portaliGalleries';
import portaliIndicatori from '../modules/portaliIndicatori/routes/portaliIndicatori';
import portaliIndicatoriHotels from '../modules/portaliIndicatoriHotels/routes/portaliIndicatoriHotels';
import portaliLandingPage from '../modules/portaliLandingPage/routes/portaliLandingPage';
import portaliPages from '../modules/portaliPages/routes/portaliPages';
import portaliPhoto from '../modules/portaliPhoto/routes/portaliPhoto';
import portaliRedirects from '../modules/portaliRedirects/routes/portaliRedirects';
import portaliSchedeHotel from '../modules/portaliSchedeHotel/routes/portaliSchedeHotel';
import portaliStatistiche from '../modules/portaliStatistiche/routes/portaliStatistiche';
import portfolio from '../modules/portfolio/routes/portfolio';
import reports from '../modules/reports/routes/reports';
import ruoli from '../modules/ruoli/routes/ruoli';
import sitePage from '../modules/sitePage/routes/sitePage';
import sitePhoto from '../modules/sitePhoto/routes/sitePhoto';
import sitePhotogallery from '../modules/sitePhotogallery/routes/sitePhotogallery';
import sitiClienti from '../modules/sitiClienti/routes/sitiClienti';
import specsServices from '../modules/specsServices/routes/specsServices';
import statistiche from '../modules/statistiche/routes/statistiche';
import statVenditaCommerciale from '../modules/statVenditaCommerciale/routes/statVenditaCommerciale';
import trattamenti from '../modules/trattamenti/routes/trattamenti';
import utenti from '../modules/utenti/routes/utenti';
import venduto from '../modules/venduto/routes/venduto';
import hotelInterazioni from '../modules/hotelInterazioni/routes/hotelInterazioni';
import interazioni from '../modules/interazioni/routes/interazioni';
import clusters from '../modules/clusters/routes/clusters';
import interazioniPagine from '../modules/interazioniPagine/routes/interazioniPagine';
import interazioniEmail from '../modules/interazioniEmail/routes/interazioniEmail';
import interessiMarketing from '../modules/interessiMarketing/routes/interessiMarketing';
import questionari from '../modules/questionari/routes/questionari';
import criteriQualitativi from '../modules/criteriQualitativi/routes/CriteriQualitativi';
import coefficienti from '../modules/coefficienti/routes/coefficienti';
import monitorRichiesteCrm from '../modules/monitorRichiesteCrm/routes/monitorRichiesteCrm';
import fatturatoHotels from '../modules/fatturatoHotels/routes/fatturatoHotels';
import dipendenti from '../modules/dipendenti/routes/dipendenti';
import crmContatti from '../modules/crmContatti/routes/crmContatti';
import WebReputation from '../modules/WebReputation/routes/WebReputation';
import WebReputationKeywords from '../modules/WebReputationKeywords/routes/WebReputationKeywords';
import WebReputationScore from '../modules/WebReputationScore/routes/WebReputationScore';
import mediaGallery from '../modules/mediaGallery/routes/mediaGallery';
import mediaGalleryPrivata from '../modules/mediaGalleryPrivata/routes/mediaGalleryPrivata';
import interazioniLayout from '../modules/interazioniLayout/routes/interazioniLayout';

const statics = [
    {
        path: '/profile',
        breadcrumb: 'Profilo',
    },
    {
        path: '/lastminute',
        breadcrumb: 'Lastminute',
    },
    {
        path: '/portali/tools',
        breadcrumb: 'Tools Portali',
    },
];

export default [
    ...articoli,
    ...articoliListino,
    ...camere,
    ...calendarioAttivita,
    ...clienti,
    ...commerciali,
    ...cookieConfig,
    ...cookieConfigAuto,
    ...cookies,
    ...dashboardCommerciali,
    ...elencoAttivita,
    ...funnel,
    ...hotel,
    ...hotelConfig,
    ...hotelListino,
    ...mailTemplateAssoc,
    ...hotelNewsletter,
    ...iscrittiNewsletter,
    ...hotelServizi,
    ...macros,
    ...mailArchive,
    ...sephirothMailAssoc,
    ...notification,
    ...obbVenditaAnnuali,
    ...offerte,
    ...ordini,
    ...permessi,
    ...portaliArchivioMail,
    ...portaliArticoli,
    ...portaliConsigliati,
    ...portaliGalleries,
    ...portaliIndicatori,
    ...portaliIndicatoriHotels,
    ...portaliLandingPage,
    ...portaliPages,
    ...portaliPhoto,
    ...portaliRedirects,
    ...portaliSchedeHotel,
    ...portaliStatistiche,
    ...portfolio,
    ...reports,
    ...ruoli,
    ...sitePage,
    ...sitePhoto,
    ...sitePhotogallery,
    ...sitiClienti,
    ...specsServices,
    ...statistiche,
    ...trattamenti,
    ...utenti,
    ...venduto,
    ...statics,
    ...statVenditaCommerciale,
    ...hotelInterazioni,
    ...interazioni,
    ...clusters,
    ...interazioniPagine,
    ...interazioniEmail,
    ...interessiMarketing,
    ...questionari,
    ...criteriQualitativi,
    ...coefficienti,
    ...monitorRichiesteCrm,
    ...fatturatoHotels,
    ...dipendenti,
    ...crmContatti,
    ...WebReputation,
    ...WebReputationKeywords,
    ...WebReputationScore,
    ...mediaGallery,
    ...mediaGalleryPrivata,
    ...interazioniLayout
];
