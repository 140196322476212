import helper from '../../../helpers/steinerHelper';
import { buildParams } from '@adrias-online/steiner/lib/helpers/apiCreator';

import contrattiClient from '../../../apis/contrattiNew';
// import { getCurrentContractYear } from '../../../helpers/date';

const paramsMap = {
    orderBy: 'orderKey',
    orderDir: 'orderDirection',
    hotel_id: 'hotel_id',
    article_code: 'article_id',
    article_year: 'article_year',
    row_hotel_id: 'row_hotel_id',
    produttore: 'produttore',
    reg_date_from: 'from_date',
    reg_date_to: 'to_date',
    macro_id: 'macro_id'
};

const apis = helper.createApi('sales', contrattiClient, paramsMap);

apis.fetch = function(idHotel, year) {
    return contrattiClient({
        url: '/sales',
        params: {
            row_hotel_id: idHotel,
            article_year: year
        }
    });
};

apis.fetchMedia = function(filters) {
    return contrattiClient({
        url: '/sales/average',
        params: buildParams(filters, paramsMap)
    });
};

export default apis;
