import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/sitePage';

const routes = helper.generateRoutes('sitePage', selectors, {
    omit: ['create']
});

routes.list.list.breadcrumb = 'Pagine sito';

const list = _.values(routes.list).map(route => ({
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'pagine-sito' */ `modules/sitePage/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('sitePage', routes.patterns, {
    it: 'Pagine sito'
});
routeRegister.addLinks('sitePage', links);

export default list;
