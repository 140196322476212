import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import apiContratti from '../../modules/hotel/apis/hotel';
import { getCurrentContractYear } from 'helpers/date';
import ModalAssistenza from '../ModalAssistenza';
import { getUser } from '../../helpers/auth/reducer';

const WidgetClienteHoteldoor = (props) => {
    const [isHoteldoor, setIsHoteldoor] = useState(false);
    const [modalCommercialeOpen, setModalCommercialeOpen] = useState(false);

    useEffect(() => {
        if (typeof props.user.contratti === 'undefined') {
            const clientContrattoHoteldoor = async () => {
                const response = await apiContratti.fetchContratti(
                    props.userHotelId,
                    getCurrentContractYear()
                );
                const contrattiData = await response.data;

                setIsHoteldoor(
                    contrattiData.data.some((el) => el.article_id === 'HDOOR1')
                );
            };

            clientContrattoHoteldoor();
        } else {
            setIsHoteldoor(props.user.contratti.includes('HDOOR1'));
        }
    }, []);

    return (
        <>
            <ModalAssistenza
                isOpen={modalCommercialeOpen}
                onClose={() => setModalCommercialeOpen(false)}
                motivo="commerciale"
            />
            <div className="widget p-0">
                <div
                    className="d-flex align-items-center flex-shrink-1 px-0 bg-primary text-center text-white position-relative"
                    style={{ borderRadius: '10px' }}
                >
                    <div className="p-3 mx-auto">
                        <i className="widget-cliente-icon fas fa-sack-dollar fa-2x"></i>
                        <div className="py-2">
                            {/* {numeroOfferte !== 0 ? (
                            <CountUp
                                start={0}
                                end={numeroOfferte}
                                duration={2}
                                delay={0}
                            >
                                {({ countUpRef }) => (
                                    <span
                                        ref={countUpRef}
                                        className="widget-cliente-counting"
                                    />
                                )}
                            </CountUp>
                        ) : ( */}
                            <span className="widget-cliente-counting">€</span>
                            {/* )} */}
                            <h3 className="text-white">
                                Guarda il tuo fatturato
                            </h3>
                        </div>
                        <div className="widget-icon-bottom">
                            {isHoteldoor
                                ? 'Servizio attivo'
                                : 'Servizio da attivare'}
                        </div>
                    </div>
                </div>
                <div className="p-3 text-center">
                    <div
                        onClick={() => setModalCommercialeOpen(true)}
                        className="mb-2 text-tertiary"
                        style={{ cursor: 'pointer' }}
                    >
                        Contatta il tuo commerciale
                    </div>
                    {isHoteldoor ? (
                        <a
                            href="https://secure.hoteldoor.it/Account/LogOn"
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-sm btn-tertiary ms-1"
                        >
                            <i className="fas fa-sign-in-alt me-2"></i> Vai a
                            HotelDoor
                        </a>
                    ) : (
                        <button
                            onClick={() => setModalCommercialeOpen(true)}
                            className="btn  btn-sm btn-tertiary ms-1"
                        >
                            <i className="fas fa-check me-2"></i> Attiva
                            HotelDoor
                        </button>
                    )}
                </div>
                <div className="py-2">
                    <div className="row sfondo-colore-body p-2 my-1 mx-3">
                        <div className="col-2 text-end">
                            <div className="text-tertiary fw-bold">-</div>
                        </div>
                        <div className="col-10">Ultimo mese</div>
                    </div>
                    <div className="row sfondo-colore-body p-2 my-1 mx-3">
                        <div className="col-2 text-end">
                            <div className="text-tertiary fw-bold">-</div>
                        </div>
                        <div className="col-10">Ultimo trimestre</div>
                    </div>
                    <div className="row sfondo-colore-body p-2 my-1 mx-3">
                        <div className="col-2 text-end">
                            <div className="text-tertiary fw-bold">-</div>
                        </div>
                        <div className="col-10">Anno 2021</div>
                    </div>
                </div>
            </div>
        </>
    );
};

WidgetClienteHoteldoor.propTypes = {
    userHotelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

function mapStateToProps(state) {
    return {
        user: getUser(state),
    };
}

export default connect(mapStateToProps)(WidgetClienteHoteldoor);
