import _ from 'lodash';
import { buildParams } from '@adrias-online/steiner/lib/helpers/apiCreator';
import { select } from 'redux-saga/effects';

import { getUserConfigId } from 'reducers/selectors';
import helper from 'helpers/steinerHelper';

export const createApi = function(endpoint, client, paramsMap, options) {
    const apis = helper.createApi(endpoint, client, paramsMap, options);

    apis.list = function(filters, siteId) {
        const finalParamsMap = helper.getFinalParamsMap(paramsMap, {});
        const params = buildParams(filters, finalParamsMap);

        const difference = _.difference(
            Object.keys(filters),
            Object.values(finalParamsMap)
        );

        if (difference && difference.length > 0) {
            const queryObj = difference.reduce((q, key) => {
                if (filters[key]) {
                    q[key] = filters[key];
                }

                return q;
            }, {});

            let query = '';

            _.forOwn(queryObj, (v, k) => {
                // TODO: come gestire passaggio di operandi diversi da "="?
                query += `${k}||=||${v}::`;
            });

            if (query !== '') {
                params.query = query;
            }
        }
        return client({
            url: `SiteConfig/${siteId}/${endpoint}`,
            params
        });
    };

    return apis;
};

const createSagasDefaultOptions = {
    getApiListParams: function* getApiListParams(selectors) {
        const filters = yield select(selectors.getFilters);
        const siteId = yield select(getUserConfigId);

        return [filters, siteId];
    }
};

export const createSagas = function(
    resource,
    actionTypes,
    actions,
    api,
    selectors,
    defaultState,
    options = {}
) {
    _.defaults(options, createSagasDefaultOptions);

    return helper.createSagas(
        resource,
        actionTypes,
        actions,
        api,
        selectors,
        defaultState,
        options
    );
};
