import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';

import { selectors } from '../reducers/venduto';

const routes = helper.generateRoutes('venduto', selectors, {
    itemLabelKey: 'data[0].hotel_name',
    omit: ['create', 'delete']
});

routes.list.list.breadcrumb = 'Statistiche venduto';

const list = _.values(routes.list).map(route => ({
    requiredRoles: ['admin', 'developer', 'employee', 'amministrazione'],
    ...route,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'venduto' */ `modules/venduto/containers/VendutoListLayout`).then(
                response => resolve(response.default)
            );
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('venduto', routes.patterns, {
    it: 'Statistiche venduto'
});

routeRegister.addLinks('venduto', links);

export default list;
