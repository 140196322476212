import { reducerCreator } from '@adrias-online/steiner';

import helper from 'helpers/steinerHelper';
import { createHandlers } from 'helpers/hotelModuleHelper';
import { actionTypes } from '../actions/iscrittiNewsletter';

export const DEFAULT_STATE = helper.createDefaultState({
    list: {
        filters: {
            per_page: 10,
            page: 1,
            orderDirection: 'DESC',
            orderKey: 'data_iscrizione'
        },
    },
});

const handlers = createHandlers(actionTypes, {
    items: 'data.data',
    total: 'data.meta.total',
    idKey: 'id',
    per_page: 'data.meta.per_page',
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('iscrittiNewsletter');
