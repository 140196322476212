import { routeCreator } from '@adrias-online/steiner/lib/helpers';
import _ from 'lodash';


import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/fatturatoHotels';

const routes = helper.generateRoutes('fatturatoHotels', selectors);
const list = _.values(routes.list).map(route => ({
    ...route,
    hotelFilter: true,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'fatturatoHotels' */ `modules/fatturatoHotels/${route.componentPath}`)
                .then(response => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('fatturatoHotels', routes.patterns);
routeRegister.addLinks('fatturatoHotels', links);

export default list;