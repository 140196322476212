import { call, put, select, takeEvery } from 'redux-saga/effects';
// import queryString from 'query-string';
import { routing } from '@adrias-online/steiner';
import _ from 'lodash';
import qs from 'query-string';

import { actions, actionTypes } from '../actions/currentHotel';
import { selectors } from '../reducers/currentHotel';
import apis from '../modules/hotel/apis/hotel';
import hotelStore from '../helpers/hotelStore';

function* syncHotel(action) {
    // const hotelId = yield select(selectors.getCurrentHotelId);

    // const currentQs = queryString.parse(window.location.search);

    // currentQs.hotelId = hotelId;

    // const location = {
    //     pathname: window.location.pathname,
    //     search: `?${queryString.stringify(currentQs)}`,
    //     query: currentQs
    // };

    // yield put(routing.actions.navigate(location, 'PUSH'));

    // TODO: questo controllo potrebbe andare prima, se ho gia i dati in hotelStore posso evitarmi di chiamare SET_HOTEL due volte
    if (!action.payload.label) {
        let label;
        let comuneId;
        const hotelId = action.payload.value;

        const hotel = hotelStore.get(hotelId);

        if (hotel) {
            label = hotel.label;
            comuneId = hotel.comuneId;
        } else {
            const response = yield call(apis.fetch, hotelId);

            label = `${response.data.nome_hotel} - ${
                response.data.nome_comune
            } (${response.data.idHotel})`;
            comuneId = response.data.idComune;

            hotelStore.set(hotelId, {
                label,
                comuneId
            });
        }

        yield put(
            actions.syncHotel({
                value: hotelId,
                label,
                comuneId
            })
        );
    }
}

function* setHotel(action) {
    const { value, label, comuneId } = action.payload;

    let pathname = window.location.pathname;

    const hotelId = yield select(selectors.getCurrentHotelId);

    if (pathname.match(/^\/hotel/)) {
        const parts = _.compact(pathname.split('/'));

        // console.warn('HOTEL CHANGED');
        // console.warn(parts);

        // TODO: questa parte potrebbe rompersi facilmente...
        if (parts.length > 3) {
            pathname = `/${parts.splice(0, 3).join('/')}`;
        }

        // console.warn(pathname);

        const newPathName = pathname.replace(
            /(\/hotel\/)(.*)(\/.*)/,
            `$1${hotelId}$3`
        );

        // console.warn(newPathName);

        // TODO: tenersi dietro la querystring?
        const location = {
            pathname: newPathName
            // search: `?${queryString.stringify(currentQs)}`,
            // query: currentQs
        };

        yield put(routing.actions.navigate(location, 'PUSH'));
    } else {
        const query = qs.parse(window.location.search);

        if (query.hotelId) {
            query.hotelId = hotelId;

            const location = {
                pathname,
                search: qs.stringify(query)
            };

            yield put(routing.actions.navigate(location, 'PUSH'));
        }
    }

    hotelStore.set(value, {
        label,
        comuneId
    });
}

export default [
    takeEvery(actionTypes.syncHotel, syncHotel),
    takeEvery(actionTypes.setHotel, setHotel)
];
