import helper from 'helpers/steinerHelper';

// console.log(helper);

// helper.options.listSuccessOptions.items = 'data.data';
// helper.options.listSuccessOptions.total = 'data.meta.total';
// helper.options.paramsMap.limit = 'per_page';

export const actionTypes = helper.createActionTypes('hotelInterazioni');
export const actions = helper.createActions('hotelInterazioni', actionTypes);
