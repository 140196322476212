import React, {Component} from "react";
import apis from "../apis/crmContatti";
import {TableCreateButton, TableEditButton} from "../../../components/buttons";
import routeRegister from 'helpers/routeRegister';
import TimeAgo from "@adrias-online/vivi/lib/TimeAgo";
import {parse} from "date-fns";

export default class WidgetUltimiIncarichi extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tasks: []
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        const codiceProduttore = this.props.userCodiceProduttore;
        if (codiceProduttore !== undefined) {
            apis.fetchCrmIncarichiByProduttore(codiceProduttore).then((res) => {
                this.setState({
                    tasks: res.data.data,
                });
            });
        }
    };

    render() {
        const {tasks} = this.state;
        return (
            <div className="widget">
                <h2 className="pb-2">Attività</h2>
                {tasks && tasks.length > 0 && (
                    <div className="row">
                        <div className="col-12">
                            <ul className="list-group">
                                {tasks.filter((item, idx) => idx <= 9).map((task, i) => (
                                    <li key={i} className="list-group-item py-1">
                                        <div className="row">
                                            <div className="col-4 text-end">
                                                <p className="text-start">
                                                    {task.nome_referente} - {task.telefono_referente}
                                                </p>
                                            </div>
                                            <div className="col-4">
                                                <p className="text-start"><strong>Note</strong>: {task.description}</p>
                                            </div>
                                            <div className="col-2">
                                                <TimeAgo
                                                    date={parse(
                                                        task.date
                                                    )}
                                                    tooltip={true}
                                                    tooltipDateFormat="DD/MM/YYYY - HH:mm:ss"
                                                />
                                            </div>
                                            <div className="col-2 text-end">
                                                <TableEditButton
                                                    to={routeRegister.getLinkTo('crmContatti.edit', {
                                                        id: task.rubrica_id
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </li>))}
                            </ul>
                            <p>Le attività in scadenza</p>
                        </div>
                    </div>
                )}
                <div className="row mt-4">
                    <div className="col-12">
                        <TableCreateButton
                            label="Aggiungi attività"
                            className="w-100"
                            to={routeRegister.getLinkTo('crmContatti.list')}
                        />
                    </div>
                </div>
            </div>
        );
    }
}