import { sagaCreator } from '@adrias-online/steiner';
import helper from 'helpers/steinerHelper';

import { actions, actionTypes } from '../actions/clienti';
import { selectors, DEFAULT_STATE } from '../reducers/clienti';
import api from '../apis/clienti';

const sagas = helper.createSagas(
    'clienti',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE,
    {
        numberFilters: ['hotelId']
    }
);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [...defaultSagas];
