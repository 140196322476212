import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LoadingButton from 'components/form/v1/LoadingButton';

export default class FormControls extends Component {
    getSaveText() {
        let text = '';
        if (window.location.href.indexOf('create') > -1) {
            text = 'Pubblica online';
        } else {
            text = 'Salva modifiche';
        }
        return text;
    }

    render() {
        const {
            submitting,
            // dirty,
            cancelLink,
            // onReset,
            showCancelBtn,
        } = this.props;

        return (
            <div className="col-12 text-center">
                {showCancelBtn && (
                    <Fragment>
                        {submitting ? (
                            <button
                                className="btn btn-sm btn-outline-light"
                                disabled={true}
                            >
                                Torna indietro
                                {/* <i className="fas fa-times-circle ms-2" /> */}
                            </button>
                        ) : (
                            <Link
                                to={cancelLink}
                                className="btn btn-sm btn-outline-light"
                            >
                                Torna indietro
                                {/* <i className="fas fa-times-circle ms-2" /> */}
                            </Link>
                        )}
                    </Fragment>
                )}
                {/* {submitting || !dirty ? (
                    <button className="btn btn-link" disabled={true}>
                        {' '}
                        <i className="fas fa-undo" />
                        <small>Resetta</small>
                    </button>
                ) : (
                    <button onClick={onReset} className="btn btn-link">
                        {' '}
                        <i className="fas fa-undo" />
                        <small>Resetta</small>
                    </button>
                )} */}
                <LoadingButton
                    className="btn btn-sm btn-primary ms-2"
                    loading={submitting}
                    loadingMsg=" "
                >
                    {this.getSaveText()}
                    {/* <i className="fas fa-save ms-2" /> */}
                </LoadingButton>
            </div>
        );
    }
}

FormControls.propTypes = {
    cancelLink: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dirty: PropTypes.bool,
    onReset: PropTypes.func,
    submitting: PropTypes.bool,
    showCancelBtn: PropTypes.bool,
};

FormControls.defaultProps = {
    showCancelBtn: true,
};
