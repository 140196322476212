import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SelectAsync from '@adrias-online/vivi/lib/SelectAsync';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import apiClient from 'apis/api';
import { actions } from '../actions/currentHotel';
import { selectors } from '../reducers/currentHotelV2';
import { Link, Redirect, Switch } from 'react-router-dom';
import WithUser from './WithUser';
import { roles } from 'helpers/auth';

function fetchHotels(q, resolve) {
    apiClient.get(`HotelDati?filter=${q}`).then((res) => {
        const options = res.data.values.map((item) => ({
            value: item.idHotel,
            label: `${item.nome_hotel} - ${item.nome_posizione} (${item.idHotel})`,
            comuneId: item.idComune,
            item: item,
        }));

        resolve({ options });
    });
}

const debouncedFetchHotels = _.debounce(fetchHotels, 500);

function loadHotels(q) {
    // TODO: handle reject???
    return new Promise((resolve) => {
        debouncedFetchHotels(q, resolve);
    });
}

class SelectHotelV2 extends Component {
    state = {
        redirect: false,
    };

    handleChange = (value) => {
        const currentId = this.props.currentHotel.value;

        if (_.isNull(value) || _.isArray(value)) {
            return this.props.onChange({ value: null, label: null });
        }

        this.setState({ redirect: !this.state.redirect });

        if (window.location.pathname === `/hotel/${currentId}/dashboard`) {
            this.props.toggleCambioHotel();
        }

        if (
            window.location.pathname !== '/' &&
            window.location.pathname !== '/gestionaleHotel' &&
            window.location.pathname !== '/hotelInterazioni'
        ) {
            this.props.currentItemUpdate(value.item);
        }

        this.props.onChange(value);

        if (this.props.setMagnewsDbId != null) {
            this.props.setMagnewsDbId(value.item.magnews_db_id);
        }

        if (
            window.location.href.indexOf('sitePage') > -1 ||
            window.location.href.indexOf('sitePhoto') > -1
        ) {
            window.location.reload();
        }
    };

    render() {
        const { autofocus, currentHotel, userRole } = this.props;
        const currentId = this.props.currentHotel.value;

        const isUser = userRole === roles.user;

        // console.log(currentHotel);

        if (!isUser) {
            if (
                this.state.redirect &&
                window.location.href.indexOf(
                    `/hotel/${currentId}/dashboard`
                ) === -1 &&
                window.location.href.indexOf(`/hotelInterazioni`) === -1
            ) {
                return (
                    <Switch>
                        <Redirect push to={`/hotel/${currentId}/dashboard`} />
                    </Switch>
                );
            }

            if (
                this.state.redirect &&
                window.location.pathname === '/hotelInterazioni'
            ) {
                return (
                    <Switch>
                        <Redirect push to={`/hotel/${currentId}/interazioni`} />
                    </Switch>
                );
            }
        }

        return (
            <div className="d-flex dashboard-select-v2">
                <SelectAsync
                    autoFocus={autofocus}
                    ref={(ref) => (this.select = ref)}
                    value={
                        currentHotel && currentHotel.value ? currentHotel : null
                    }
                    placeholder="Seleziona un Hotel"
                    loadOptions={loadHotels}
                    onChange={this.handleChange}
                    clearable={false}
                    openOnFocus={true}
                    className="flex-grow-1"
                />
                {!isUser && (
                    <Link
                        to={`/hotel/${currentHotel.value}/dashboard`}
                        className={'btn btn-tertiary'}
                    >
                        <i className="fas fa-chevron-right" />
                    </Link>
                )}
            </div>
        );
    }
}

SelectHotelV2.propTypes = {
    autofocus: PropTypes.bool,
    currentHotel: PropTypes.object,
    onChange: PropTypes.func,
};

SelectHotelV2.defaultProps = {
    autofocus: false,
};

function mapStateToProps(state) {
    return {
        currentHotel: selectors.getCurrentHotel(state),
    };
}

function mapDispatchToProps(dispatch) {
    const boundActions = bindActionCreators(actions, dispatch);

    return {
        onChange: boundActions.setHotel,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WithUser(SelectHotelV2));
