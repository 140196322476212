import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/cookieConfig';

const routes = helper.generateRoutes('cookieConfig', selectors);

routes.list.list.breadcrumb = 'Configurazione Cookie';
routes.list.automaticEdit = {};
routes.list.automaticEdit = {
            breadcrumb: 'Configurazioni Cookie Automatiche',
            componentPath: 'containers/CookieConfigAutomaticheLoader',
            path: '/cookie/Automatiche/:id',
            exact: true
        };
routes.patterns.automaticEdit = "/cookie/Automatiche/:id";

const list = _.values(routes.list).map(route => ({
    ...route,
    // hotelFilter: true,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'cookieConfig' */ `modules/cookieConfig/${
                route.componentPath
                }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('cookieConfig', routes.patterns, {
    it: 'Configurazione Cookie'
});
routeRegister.addLinks('cookieConfig', links);

export default list;
