import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import routeRegister from '../../../helpers/routeRegister';
import { generateRoutes } from '../../../helpers/hotelModuleHelper';
import { selectors } from '../reducers/hotelNewsletter';

const routes = generateRoutes('hotelNewsletter', selectors);

routes.list.list.breadcrumb = 'Iscritti newsletter';

const list = _.values(routes.list).map(route => ({
    ...route,
    hotelFilter: true,
    getComponent() {
        return new Promise(resolve => {
            import(/* webpackChunkName: 'hotelNewsletter' */ `modules/hotelNewsletter/${
                route.componentPath
            }`).then(response => resolve(response.default));
        });
    }
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('hotelNewsletter', routes.patterns);
routeRegister.addLinks('hotelNewsletter', links);

export default list;
