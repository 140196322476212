import helper from '../../../helpers/steinerHelper';
import client from 'apis/apiDipendenti';

const paramsMap = {
    limit: 'perPage',
    page: 'page'
};

const apis =  helper.createApi('dipendenti',client,paramsMap);

export default apis;