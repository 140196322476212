import _ from 'lodash';
import { routeCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import routeRegister from '../../../helpers/routeRegister';
import { selectors } from '../reducers/ordini';

const routes = helper.generateRoutes('ordini', selectors, {
    itemLabelKey: 'id',
    omit: ['create'],
});

const list = _.values(routes.list).map((route) => ({
    ...route,
    requiredRoles: [
        'admin',
        'developer',
        'employee',
        'amministrazione',
        'produttore',
    ],
    getComponent() {
        return new Promise((resolve) => {
            import(
                /* webpackChunkName: 'ordini' */ `modules/ordini/${route.componentPath}`
            ).then((response) => resolve(response.default));
        });
    },
}));

const links = routeCreator.generateLinks(routes.patterns);

routeRegister.addPatterns('ordini', routes.patterns);
routeRegister.addLinks('ordini', links);

export default list;
