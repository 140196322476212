import { sagaCreator } from '@adrias-online/steiner/lib/helpers';

import helper from '../../../helpers/steinerHelper';
import { actions, actionTypes } from '../actions/specsServices';
import { selectors, DEFAULT_STATE } from '../reducers/specsServices';
import api from '../apis/specsServices';

const sagas = helper.createSagas(
    'specsServices',
    actionTypes,
    actions,
    api,
    selectors,
    DEFAULT_STATE,
    {
        resourceLabel: 'Specifica servizi'
    }
);

const defaultSagas = sagaCreator.bootSagas(sagas, actionTypes);

export default [...defaultSagas];
