import { reducerCreator } from '@adrias-online/steiner/lib/helpers';

import helper from 'helpers/steinerHelper';
import { actionTypes } from '../actions/dashboardCommerciali';

export const DEFAULT_STATE = helper.createDefaultState();

const handlers = helper.createHandlers(actionTypes, {
    items: 'data.data',
    total: 'data.meta.total'
});

export default reducerCreator.createReducer(handlers, DEFAULT_STATE);

export const selectors = reducerCreator.createSelectors('dashboardCommerciali');