import React, { useState } from 'react';
import HotelWidgetLink from 'components/HotelWidgetLink';
import Modal from 'react-modal';
import NuovaAttivitaModal from '../../modules/calendarioAttivita/components/NuovaAttivitaModal';

const WidgetCalendario = (props) => {
    const [isShowingModal, setIsShowingModal] = useState(false);
    return (
        <div className="widget">
            <h2 className="pb-2">Time tracking</h2>
            <div className="row">
                <div className="col-12" style={{ minHeight: '71px' }}>
                    <button
                        className="btn btn-primary w-100 mx-2 mt-3 py-2 d-flex"
                        onClick={() => setIsShowingModal(true)}
                    >
                        <i className="fal fa-clock fa-lg my-auto mx-2"> </i>
                        <span className="ms-3">Nuovo orario attività </span>
                    </button>
                    <Modal
                        className="modal-dialog modal-dialog-centered"
                        isOpen={isShowingModal}
                        onRequestClose={() => setIsShowingModal(false)}
                        contentLabel="Edit Interazioni"
                        shouldCloseOnOverlayClick={true}
                        ariaHideApp={false}
                    >
                        <NuovaAttivitaModal
                            dispatch={props.dispatch}
                            onClose={() => setIsShowingModal(false)}
                        />
                    </Modal>
                </div>
                <div className="col-12">
                    <HotelWidgetLink
                        to="/CalendarioAttivita"
                        icon="fas fa-calendar-day"
                    >
                        Visualizza Calendario{' '}
                    </HotelWidgetLink>
                </div>
            </div>
        </div>
    );
};

export default WidgetCalendario;
