import helper from '../../../helpers/steinerHelper';
import client from '../../../apis/gerard';

const api = helper.createApi('/sellers/dashboard', {
    seller_id: 'seller_id'
});

api.fetchDashboardCommerciali = (seller_id)=> {
    return client({
        url:`/sellers/dashboard`,
        params: {
            seller_id
        }}
    )
};

export default api;
