import { createSagas, bootSagas } from 'helpers/hotelModuleHelper';

import { actions, actionTypes } from '../actions/mediaGallery';
import { selectors, DEFAULT_STATE } from '../reducers/mediaGallery';
import api from '../apis/mediaGallery';

const sagas = createSagas('mediaGallery', actionTypes, actions, api, selectors, DEFAULT_STATE);

const defaultSagas = bootSagas(sagas, actionTypes);

export default [
    ...defaultSagas
];