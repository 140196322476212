import React, { Component } from 'react';
import PortaleFilterWidgetLink from '../PortaleFilterWidgetLink';

export default class WidgetStatistichePortale extends Component {
    render() {
        const { portaleId } = this.props;

        return (
            <div className="widget">
                <h2 className="pb-2">Statistiche portale</h2>
                <div className="row">
                    <div className="col-12">
                        <PortaleFilterWidgetLink
                            to="/statistichePortale"
                            icon="fas fa-archive"
                            portaleId={portaleId}
                        >
                            Statistiche mail
                        </PortaleFilterWidgetLink>
                    </div>
                    <div className="col-12">
                        <PortaleFilterWidgetLink
                            to="/portaliArchivioMail"
                            icon="fas fa-chart-line"
                            portaleId={portaleId}
                        >
                            Archivio mail
                        </PortaleFilterWidgetLink>
                    </div>
                </div>
            </div>
        );
    }
}
